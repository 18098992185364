import React, { useEffect, useState } from 'react';
import { CircularProgress, ClickAwayListener, Grid, MenuItem, TextField } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useAppSelector } from '../../../app/hooks';
import { getCallStatus, getErrorMsg } from '../../../app/slices/apiCallSlice';
import { getLoadingState } from '../../../app/slices/loadingSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useNavigate } from 'react-router-dom';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import classes from "../../../assets/styles/User.module.css";
import CheckIcon from '@mui/icons-material/Check';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import Message from '../../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import * as UserRolesConsts from "../../../user-roles-consts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ConfigureAccess from './Dialogs/ConfigureAccess';
import userAccessIcon from "../../../assets/images/icons/userAccessIcon.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import * as taskConsts from "../../../task-consts";
import CloseIcon from '@mui/icons-material/Close';
import * as salutationConst from "../../../salutation-consts";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
    color: 'black',
  },
}));

export interface UserProps {
  activeTab: any;
  setActiveSection: any;
  addedTms: any;
  setAddedTms: any;
  roles: any;
  addedClients: any;
  setAddedClients: any;
  taxAdvisorClients: any;
  setTaxAdvisorClients: any;
  modules: any;
  selectedCompanies: any;
  setSelectedCompanies: any;
  clientsInfo:any;
  setClientsInfo: any;
}

const AddUser = (props: UserProps) => {
  const { activeTab, setActiveSection, addedTms, setAddedTms, roles, addedClients, setAddedClients, taxAdvisorClients, modules,
    setTaxAdvisorClients, selectedCompanies, setSelectedCompanies, clientsInfo, setClientsInfo
  } = props

  const navigate = useNavigate();
  const intl = useIntl();

  const [isHovered, setIsHovered] = useState(false);
  const [hoverId, setHoverId] = useState<number | null>(null);
  const [isCompaniesHovered, setIsCompaniesHovered] = useState(false);
  const [companiesHoverId, setCompaniesHoverId] = useState<number | null>(null);

  const [lineIndex, setLineIndex] = useState<number>();
  const [tmpCurrentTab, setTmpCurrentTab] = useState<string>();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('');

  const [nameError, setNameError] = useState<boolean>(false);
  const [clientError, setClientError] = useState<boolean>(false);

  const [deleteIconHover, setDeleteIconHover] = useState<boolean>(false);

  const [listError, setListError] = useState<boolean>(false);

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);

  const [, updateState] = React.useState({});

  const [openComapniesTooltip, setOpenComapniesTooltip] = useState(false);
  const [openCompaniesId, setOpenCompaniesId] = useState<number | null>(null);

  const [openConfigureAccessDialog, setOpenConfigureAccessDialog] = useState(false);
  const [user, setUser] = useState<any>(undefined);
  const [originalUser, setOriginalUser] = useState();

  const [isRolesOpen, setIsRolesOpen] = useState(false);
  const [isModuleOpen, setIsModuleOpen] = useState(false);

  const [openModuleId, setOpenModuleId] = useState<number | null>(null);

  const [openRoleId, setOpenRoleId] = useState<number | null>(null);

  const [checked, setChecked] = useState<{ [key: string]: any }>({});

  const handleCheckboxChange = (sc: any, field: string) => {
    setChecked((prev) => {
      const newCheckedState = !prev[sc]?.[field];
  
      setAddedClients((prevClients: any) =>
        prevClients.map((client: any) => {
          if (client.clientId !== sc) return client;

          const moduleId = field === "onboarding" ? taskConsts.ONBOARDING_ID : field === "personal" ? taskConsts.HR_ID : field === "accounting" ? taskConsts.FINANCIAL_ACCOUNTING_ID : taskConsts.ANNUAL_FINANCIAL_STATEMENTS_ID;

          const updatedModules = newCheckedState
            ? [...client.modules, moduleId] 
            : client.modules.filter((module: any) => module !== moduleId); 
            
          return { ...client, modules: updatedModules };
        })
      );
      return {
        ...prev,
        [sc]: { ...prev[sc], [field]: newCheckedState },
      };
    });
  };
  

  // Create a memoized callback to force re-render
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  // const addUserObject = () => {
  //   const listToCheck = activeTab === 'Tms' ? addedTms : addedClients;

  //   const allUsersValid = listToCheck.every((user: { email: string; roles: string | any[]; }) =>
  //     user.email.trim() !== "" && user.roles.length > 0
  //   );
  //   if (allUsersValid) {
  //     const tmUser = {
  //       id: addedTms.length + 1, // Unique id for the new user
  //       roles: [],
  //       email: "",
  //       accessModules: localStorage.getItem('userType') === '1' ? [] : null,
  //       modules: localStorage.getItem('userType') === '1' ? null : [taskConsts.GENERAL_ID]
  //     };
  //     const clientUser = {
  //       id: addedClients.length + 1, // Unique id for the new user
  //       roles: [UserRolesConsts.CLIENT_ADMIN_ID],
  //       email: "",
  //       name: "",
  //       clientNumber: "",
  //       modules: [taskConsts.GENERAL_ID],
  //       salutation: null
  //     };
  //     if (activeTab === 'Tms') {
  //       setAddedTms([...addedTms, tmUser]);
  //     }
  //     if (activeTab === 'Clients') {
  //       setAddedClients([...addedClients, clientUser]);
  //     }

  //   }
  //   else setListError(true)
  // }
  const [search, setSearch] = useState('');
  const [filteredRoles, setFilteredRoles] = useState(roles || []);
  const [companiesSearch, setCompaniesSearch]= useState('');
  const [filteredCompanies, setFilteredCompanies]= useState( (localStorage.getItem('userType') === '1' ? taxAdvisorClients : modules) || []);
  const [modulesSearch, setModulesSearch]= useState('');
  const [filteredModules, setFilteredModules]= useState(modules || []);
  
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
  
    if (!searchValue) {
      setFilteredRoles(roles || []);
      return;
    }
  
    const filtered = roles?.filter((role: any) =>
      role.name.toLowerCase().includes(searchValue)
    );
    setFilteredRoles(filtered);
  };
  
  useEffect(() => {
    setFilteredRoles(roles || []);
  }, [roles, activeTab]); 
  
  const handleCompaniesSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setCompaniesSearch(searchValue);
  
    if (!searchValue) {
      setFilteredCompanies((localStorage.getItem('userType') === '1' ? taxAdvisorClients : modules) || []);
      return;
    }
  
    const option = localStorage.getItem('userType') === '1' ? taxAdvisorClients : modules;
    const filtered = option?.filter((company: any) =>
    company?.name?.toLowerCase().includes(searchValue)
  );
    setFilteredCompanies(filtered);
  };
  
  useEffect(() => {
    setFilteredCompanies((localStorage.getItem('userType') === '1' ? taxAdvisorClients : modules) || []);
  }, [taxAdvisorClients, modules, activeTab]);

  const handleModulesSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setModulesSearch(searchValue);
  
    if (!searchValue) {
      setFilteredModules(modules || []);
      return;
    }
  
    const filtered = modules?.filter((modul: any) =>
      modul?.name?.toLowerCase().includes(searchValue)
    );
    setFilteredModules(filtered);
  };
  
  useEffect(() => {
    setFilteredModules(modules || []);
  }, [modules, activeTab]); 

  useEffect(() => {
    const newAddedClients = selectedCompanies.map((clientId: any) => {
      const client = clientsInfo.find((c: any) => c.id === clientId);
      if (!client) return null;
      return {
        clientId: client.id,
        name: client.name,
        clientNumber: (client.number).toString(),
        email: "",
        roles: [UserRolesConsts.CLIENT_ADMIN_ID],
        salutation: null,
        modules: [taskConsts.GENERAL_ID],
      };
    }).filter(Boolean);
  
    setAddedClients(newAddedClients);
  }, [selectedCompanies]);
  
  useEffect(() => {
    console.log('addedClients', addedClients);
  }, [addedClients]);
  

  return (
    <Grid item container direction='column' padding={3} rowGap={1} flexWrap='nowrap' justifyContent='center'>
      <Grid item className='black desktop-header-5'>
        {activeTab === 'Tms' ? <Message id="text.add-your-team" className="Field-input desktop-header-5 font-weight-400" />
          :
          <Message id="text.add-company" className="Field-input desktop-header-5 font-weight-400" />
        } </Grid>
      {/* <Grid item className='black labels'> <Message id="text.add-multiple-users" className="Field-input labels font-weight-400" /></Grid> */}
      {/* <Grid item container direction='row' className='black labels' columnGap={2} marginTop={3}>
        <Grid item xs={activeTab === 'Tms' ?4:2.5}>  {activeTab === 'Tms' ? <Message id="ta.add.role" className="Field-input labels font-weight-400" />
          :
          <Message id="ta.add.level-of-access" className="Field-input labels font-weight-400" />
        } </Grid>
        {activeTab === 'Clients' ?
        <>
          <Grid item xs={2.5}><Message id="ta.add.name" className="Field-input labels font-weight-400" /></Grid>
          <Grid item xs={2}>Client Number *</Grid>
          </>
          :
          <></>
        }
        <Grid item xs={3}> <Message id="ta.add.email" className="Field-input labels font-weight-400" /></Grid>
      </Grid> */}
      {activeTab === 'Tms' ?

        addedTms.map((val: any, index: number) => (
          <Grid item container key={val.id} direction='row' columnSpacing={2} rowGap={2} alignItems='flex-start'>
            <Grid item container direction='column' xs={11}>
              <Grid item> <Message id="ta.add.email" className="Field-input font-16 font-weight-400 black" /> </Grid>
              <Grid item>
                <TextField
                  value={val.email}
                  placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Tms' ? emailError : false}
                  helperText={emailError && lineIndex === index && tmpCurrentTab === 'Tms' ? emailErrorMsg : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Tms');
                    val.email = e.target.value;
                    forceUpdate();
                    if (!e.target.value || !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    )) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                    else {
                      setEmailError(false);
                      setEmailErrorMsg('')
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={0.5} marginTop={4}>
              {val.email && val.roles.length > 0 ?
                <CheckCircleOutlineRoundedIcon htmlColor='rgba(0, 182, 155, 1)' />
                :
                <></>
              }
            </Grid>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Grid item container rowGap={1.5} padding={1.5} maxWidth='170px'>

                    <Grid item container alignItems='center' columnGap={0.5} style={{ cursor: 'pointer' }} paddingTop={1}
                      onMouseOver={(e) => {
                        setLineIndex(index);
                      }}
                      onMouseOut={() => {
                        setLineIndex(-1);
                      }}
                      onClick={() => {
                        const duplicateUser = { ...val, id: addedTms.length + 1, email: "" }
                        setAddedTms([...addedTms, duplicateUser]);
                      }}
                      className='black label'>
                      <ContentCopyIcon
                        htmlColor='#93919A'
                        fontSize='small'
                      //  htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Tms' ? "#EF4737" : "#93919a"}
                      />
                      <Message id="text.duplicate-current-user" className="Field-input font-11 font-weight-400" />
                    </Grid>

                    <Grid item container alignItems='center' columnGap={0.5} style={{ cursor: 'pointer' }} paddingTop={1}
                      onMouseOver={(e) => {
                        setLineIndex(index);
                        setDeleteIconHover(true);
                      }}
                      onMouseOut={() => {
                        setLineIndex(-1);
                        setDeleteIconHover(false);
                      }}
                      onClick={() => {
                        setAddedTms((prevTms: any[]) => prevTms.filter(tm => tm.id !== val.id));
                      }}
                      className='red label'>
                      <DeleteOutlineIcon
                        htmlColor='#EF4737'
                        fontSize='small'
                      //  htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Tms' ? "#EF4737" : "#93919a"}
                      />
                      <Message id="text.delete-current-user" className="Field-input font-11 font-weight-400" />
                    </Grid>

                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item xs={0.5} style={{ cursor: 'pointer' }} marginTop={4}><MoreVertIcon htmlColor='#93919a' /></Grid>
            </HtmlTooltip>
            <Grid item container direction='column' xs={12}>
              <Grid item> <Message id="ta.add.role" className="Field-input font-16 font-weight-400 black" /></Grid>

              {/* <ClickAwayListener onClickAway={(event) => {
                const target = event.target as HTMLElement;
                if (
                  // isRolesOpen
                  openRoleId
                ) {
                  // setIsRolesOpen(false);
                  setOpenRoleId(null);
                }
              }} */}
              <ClickAwayListener onClickAway={() => setOpenRoleId(null)}>
                <Grid item>
                  <HtmlTooltip
                    // open={isRolesOpen}
                    open={openRoleId === val.id}
                    disableHoverListener
                    // onClose={() => setIsRolesOpen(false)}
                    // onClose={() => setOpenRoleId(null)}
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} onClick={(e)=> e.stopPropagation()}>
                        <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e)}
                      /></Grid>
                          <Grid item container maxHeight='228px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width: '257px' }} rowGap={1}>
                            {/* <Grid item container rowGap={1} maxHeight='100px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}> */}
                            {filteredRoles?.filter((r: { typeId: number; }) => (localStorage.getItem('userType') === '1' ? r.typeId === 1 : r.typeId === 2))?.map((role: any, index: number) => (
                              <Grid item container
                                // style={{ cursor: 'pointer' }} 
                                alignItems='center' key={role.id} columnGap={2}
                                justifyContent='flex-start'
                                style={{
                                  flexWrap: 'nowrap',
                                  width: '100%',
                                  fontSize: '14px',
                                  marginBottom: '18px'
                                }}
                                // <Grid item container alignItems='center' key={role.id} columnGap={1} className={classes[lineIndex === index || val.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                onMouseOver={(e) => {
                                  const target = e.target as HTMLElement;
                                  // if (!val.roles?.includes(role.id)) {
                                  target.style.cursor = 'pointer';
                                  setLineIndex(index);
                                  // }
                                }}
                                onMouseOut={() => {
                                  setLineIndex(-1);
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (!val.roles?.includes(role.id)) {
                                    const updatedRoles = [...val.roles, role.id];
                                    val.roles = updatedRoles;
                                    forceUpdate();
                                    // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                  }
                                  else {
                                    const updatedRoles = val.roles.filter((r: { id: any; }) => r !== role.id)
                                    val.roles = updatedRoles;
                                    forceUpdate();
                                  }
                                }}
                              >
                                <Grid className='bordered-box'
                                  style={{ borderColor: val.roles?.includes(role.id) ? '#fff' : '#E6E6E8' }}
                                  bgcolor={val.roles?.includes(role.id) ? '#CCF0EB' : '#fff'}>
                                  {
                                    val.roles?.includes(role.id) ?
                                      // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />

                                      <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                      :
                                      <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  }
                                </Grid>
                                <Grid item className='font-14 font-weight-400 '>{role.name}</Grid>


                                {/* <Grid item>{role.name}</Grid>
                              <Grid className='bordered-box' bgcolor={lineIndex === index || val.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                              </Grid>
                            ))}

                          </Grid>
                          <Grid item container xs={12} alignItems='center' columnGap={1} style={{ cursor: 'pointer' }}
                            className='normalFontWeight'
                            onClick={() => {
                              val.roles = []
                              forceUpdate();
                            }}>
                            <RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    }
                  >
                    <Grid item container className='textField' columnGap={1} rowGap={1}>
                      {/* <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} onClick={() => { setIsRolesOpen((prev: any) => !prev); }} /> */}
                      <ControlPointIcon onMouseEnter={() => setHoverId(val.id)} onMouseLeave={() => setHoverId(null)}
                        htmlColor={hoverId === val.id ? '#93919A' : openRoleId === val.id ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }}
                        // onClick={() => { setIsRolesOpen((prev: any) => !prev); }} 
                        onClick={() => {
                          setTimeout(() => {
                            setOpenRoleId(openRoleId === val.id ? null : val.id);
                          }, 0);
                        }}
                      />
                      {val.roles.map((role: any) => (
                        <Grid item key={role} className={classes['roleBox']}>{roles?.find((r: { id: any; }) => r.id === role)?.name} <CloseIcon sx={{ fontSize: 18, marginLeft: '3px', cursor: 'pointer' }} onClick={() => {
                          val.roles = val.roles.filter((r: any) => r !== role)
                          forceUpdate()
                        }} />
                        </Grid>
                      ))}
                    </Grid>

                  </HtmlTooltip >

                </Grid>
              </ClickAwayListener>
            </Grid>
            {val.email && val.roles.length > 0 ?
              <>


                <Grid item container xs={12} direction='column' rowGap={1}>
                  <Grid item className="Field-input font-16 font-weight-400 black" style={{ display: 'flex' }}><Message id="text.configure-access" className='Field-input font-weight-400 mobile-paragraph' /><span style={{ marginLeft: '5px', marginTop: '2px' }}>{val.email} </span> </Grid>
                  <Grid item container className='textField' columnGap={1} rowGap={1} style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setLineIndex(index);
                      // setOpenComapniesTooltip(!openComapniesTooltip)
                      setOpenCompaniesId(openCompaniesId === val.id ? null : val.id);
                    }
                    }
                  >
                    {(localStorage.getItem('userType') === '1' ? val.accessModules : val.modules)?.map((c: any) => (
                      <Grid item key={c.clientId} className={classes['roleBox']}>
                        {localStorage.getItem('userType') === '1' ? c.name : modules?.find((m: any) => m.id === c)?.name}
                        {c !== 1 && (
                          <CloseIcon sx={{ fontSize: 18, marginLeft: '3px', cursor: 'pointer' }} onClick={() => {
                            const updatedModules = (localStorage.getItem('userType') === '1' ? val.accessModules : val.modules)
                              .filter((mod: any) => mod !== c);

                            if (localStorage.getItem('userType') === '1') {
                              val.accessModules = updatedModules;
                            } else {
                              val.modules = updatedModules;
                            }
                            forceUpdate();
                          }} />
                        )}
                      </Grid>
                    ))}
                    {/* {val.accessModules?.map((m:any) => (
                  <Grid item>{m.name}</Grid>
                ))} */}
                    {/* <ClickAwayListener onClickAway={(event) => {
                      const target = event.target as HTMLElement;
                      if (
                        openComapniesTooltip
                      ) {
                        setOpenComapniesTooltip(false);
                      }
                    }} */}
                    <ClickAwayListener onClickAway={() => setOpenCompaniesId(null)}>
                      <Grid item>
                        <HtmlTooltip
                          open={openCompaniesId === val.id}
                          // onClose={() => {
                          //   setOpenComapniesTooltip(false)
                          // }}
                          title={
                            <React.Fragment>
                              <Grid item container rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }} onClick={(e) => e.stopPropagation()}>
                              <Grid item>
                      <TextField
                        value={companiesSearch}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleCompaniesSearch(e)}
                      /></Grid>
                                <Grid item container maxHeight='228px' display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width: '257px' }} rowGap={0.5}>
                                  {filteredCompanies?.map((i: any, index: number) => (
                                    <Grid item container alignItems='center' key={i.id} columnGap={2} rowGap={1}
                                      justifyContent='flex-start'
                                      style={{
                                        flexWrap: 'nowrap',
                                        width: '100%',
                                        fontSize: '14px',
                                        marginBottom: '18px'
                                      }}
                                      // onMouseOver={(e) => {
                                      //   const target = e.target as HTMLElement;
                                      //   // if (!val.roles?.includes(role.id)) {
                                      //   target.style.cursor = 'pointer';
                                      //   setLineIndex(index);
                                      //   // }
                                      // }}
                                      // onMouseOut={() => {
                                      //   setLineIndex(-1);
                                      // }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (localStorage.getItem('userType') === '1') {
                                          if (!val.accessModules?.find((x: { clientId: any; }) => x.clientId === i.id)) {
                                            const updatedAccess = [...val.accessModules, { clientId: i.id, name: i.name, modules: [taskConsts.GENERAL_ID] }];
                                            val.accessModules = updatedAccess;
                                            forceUpdate();
                                            // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                          }
                                          else {
                                            const updatedAccess = val.accessModules.filter((r: { clientId: any; }) => r.clientId !== i.id)
                                            val.accessModules = updatedAccess;
                                            forceUpdate();
                                          }
                                        }

                                        if (localStorage.getItem('userType') === '2') {
                                          if (!val.modules?.find((x: any) => x === i.id)) {
                                            const updatedModules = [...val.modules, i.id];
                                            val.modules = updatedModules;
                                            forceUpdate();
                                            // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                          }
                                          else {
                                            if (i.id !== taskConsts.GENERAL_ID) {
                                              const updatedModules = val.modules.filter((m: any) => m !== i.id)
                                              val.modules = updatedModules;
                                              forceUpdate();
                                            }
                                          }
                                        }

                                      }}
                                    >
                                      <Grid className='bordered-box'
                                        style={{ cursor: localStorage.getItem('userType') === '2' && i.id === taskConsts.GENERAL_ID ? 'unset' : 'pointer' }}

                                        bgcolor={
                                          ((localStorage.getItem('userType') === '1' && val.accessModules?.find((x: { clientId: any; }) => x.clientId === i.id)) || (localStorage.getItem('userType') === '2' && val.modules?.find((m: any) => m === i.id))) ? '#CCF0EB' : '#fff'}>
                                        {
                                          ((localStorage.getItem('userType') === '1' && val.accessModules?.find((x: { clientId: any; }) => x.clientId === i.id)) || (localStorage.getItem('userType') === '2' && val.modules?.find((m: any) => m === i.id))) ?
                                            // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />

                                            <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                            :
                                            <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                        }
                                      </Grid>
                                      <Grid item className='font-14 font-weight-400 '>{i.name}</Grid>

                                    </Grid>
                                  ))}
                                  <Grid item container xs={12} alignItems='center' columnGap={1} style={{ cursor: 'pointer' }}
                                    className='normalFontWeight'
                                    onClick={() => {
                                      if (localStorage.getItem('userType') === '1') val.accessModules = [];
                                      else val.modules = [];
                                      forceUpdate();
                                    }}>
                                    <RestartAltIcon fontSize='small' /> Reset
                                  </Grid>
                                </Grid>


                                {/* <Grid item container direction='row' justifyContent='flex-end' marginTop={1} marginBottom={2} columnSpacing={1}>
                        <Grid item xs={6}
                          onClick={() => {
                            setOpenComapniesTooltip(false)

                          }}>
                          <button className='greyButton'><Message id="button.cancel" className='labels-extra-bold Field-input labelsLinks' /></button>
                        </Grid>
                        <Grid item xs={6}>
                          <button
                            className='blueButton'
                            disabled={val.accessModules?.length === 0}
                            onClick={() => setOpenComapniesTooltip(false)}
                          >
                            Ok
                          </button>
                        </Grid>
                      </Grid> */}


                              </Grid>
                            </React.Fragment>
                          }
                        >
                          <ControlPointIcon onMouseEnter={() => setCompaniesHoverId(val.id)} onMouseLeave={() => setCompaniesHoverId(null)}
                            htmlColor={companiesHoverId === val.id ? '#93919A' : openCompaniesId === val.id ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setTimeout(() => {
                                setOpenCompaniesId(openCompaniesId === val.id ? null : val.id);
                              }, 0);
                            }}
                          />
                        </HtmlTooltip>
                      </Grid>
                    </ClickAwayListener>
                  </Grid>


                </Grid>



              </>
              :
              <></>
            }
            {
              localStorage.getItem('userType') === '1' && val.email && val.roles.length > 0 && val.accessModules.length > 0 ?
                <>

                  {val.accessModules.some((item: { modules: string | any[]; }) => item.modules.length > 0) ?
                    <Grid item container direction='column' rowGap={1}>

                      <Grid item container direction='row'>
                        <Grid item xs={4} className='bold black'><Message id="ta.review.company" className="bold black" /> </Grid>
                        <Grid item xs={8} className='bold black'> <Message id="ta.review.modules" className="bold black" /></Grid>
                      </Grid>

                      {val.accessModules?.map((a: any) => (
                        <Grid item container direction='row' key={a.clientId}>
                          <Grid item xs={4} className=' black'> {a.name} </Grid>
                          <Grid item xs={8} className=' black'>
                            {a.modules?.map((m: any, index: any) => (
                              <span>{modules.find((x: any) => x.id === m)?.name} {index === a.modules?.length - 1 ? "" : ","} </span>
                            ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    :
                    <></>
                  }


                  <Grid item>
                    <button
                      className='greenAccessButton'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setUser(val);
                        setOriginalUser(val);
                        setOpenConfigureAccessDialog(true)
                      }}
                    >
                      <img src={userAccessIcon} />
                      <Message id="button.apply-configuration" className="Field-input labels font-weight-400" />
                    </button> </Grid>
                </>
                :
                <></>
            }




            {
              index !== addedTms.length - 1 ?
                <Grid item className='greyLine' xs={12} marginTop={1.5} marginBottom={1.5}></Grid>

                :
                <></>
            }

          </Grid >
        ))
        :
        // clientsInfo.map((val: any, index: number) => (
        <>
          <Grid item container direction='row' columnGap={2} alignItems='flex-start'>
               {/*  <Grid item container direction='column' xs={2}>
          <Grid item>  <Message id="ta.add.level-of-access" className="Field-input black font-weight-400" /></Grid> 
              <Grid item container className='textField' columnGap={1} rowGap={1} alignItems='center'>
                {val.roles.map((role: any) => (
                  <Grid item key={role} className={classes['roleBox']}>{roles?.find((r: { id: any; }) => r.id === role)?.name}</Grid>
                ))}
                <Grid item>
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Grid item container rowGap={1} maxHeight='100px' padding={1} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                        {roles?.filter((r: { typeId: number; }) => r.typeId === 2)?.map((role: any, index: number) => (
                          <Grid item container alignItems='center' key={role.id} columnGap={1} className={classes[lineIndex === index || val.roles?.includes(role.id) ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              // if (!val.roles?.includes(role.id)) {
                              target.style.cursor = 'pointer';
                              setLineIndex(index);
                              // }
                            }}
                            onMouseOut={() => {
                              setLineIndex(-1);
                            }}
                            onClick={(e) => {
                              if (!val.roles?.includes(role.id)) {
                                const updatedRoles = [...val.roles, role.id];
                                val.roles = updatedRoles;
                                forceUpdate();
                                // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                              }
                              else {
                                const updatedRoles = val.roles.filter((r: { id: any; }) => r !== role.id)
                                val.roles = updatedRoles;
                                forceUpdate();
                              }
                            }}
                          >
                            <Grid className='bordered-box' bgcolor={lineIndex === index || val.roles?.includes(role.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(role.id) ? '#00B69B' : '#9E9E9E'} /></Grid>
                            <Grid item>{role.name}</Grid>
                          </Grid>
                        ))}

                      </Grid>
                    </React.Fragment>
                  }
                >
                  <ControlPointIcon htmlColor='#C3C2C7' style={{ cursor: 'pointer' }} />
                </HtmlTooltip >
              </Grid> 
              </Grid>
            </Grid>*/}
            {/* <Grid item container direction='column' xs={1}>
              <Grid item><Message id="user.salutation" className='labels neutral-9' /></Grid>
              <Grid item style={{ marginTop: '3px', maxWidth: '75px' }}>
                <TextField
                  select
                  value={val.salutation}
                  placeholder={intl.formatMessage({ id: 'ta.enter-surname' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  // error={lineIndex === index && tmpCurrentTab === 'Clients' ? nameError : false}
                  // helperText={nameError && lineIndex === index && tmpCurrentTab === 'Clients' ? intl.formatMessage({ id: 'error.enter-a-name' }) : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    val.salutation = e.target.value;
                    forceUpdate();
                    // if (!e.target.value) {
                    //   setNameError(true);
                    // }
                    // else {
                    //   setNameError(false);
                    // }
                  }}
                // onBlur={(e) => {
                //   if (!e.target.value) {
                //     setNameError(true);
                //   }
                // }}
                >
                  {salutationConst.salutations?.map((s: any) => (
                    <MenuItem value={s.id} key={s.id}>{s.selection}</MenuItem>
                  ))}

                </TextField>

              </Grid>
            </Grid> */}

            <Grid item container direction='column' xs={12} marginTop='15px'>
              <Grid item style={{display:'flex'}}><Message id="text.search-for-company" className="Field-input black font-weight-400" />
              <span style={{color:'red', marginLeft:'4px'}}>*</span>
              </Grid>
              <Grid item>
                <TextField
                  value={selectedCompanies || []}
                  select
                  placeholder={intl.formatMessage({ id: 'text.search-for-company' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  SelectProps={{
                    multiple: true,
                    displayEmpty: true,
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: '200px', 
                        },
                      },
                    },
                    // renderValue: (selected: any) => {
                    //   if (!selected || selected.length === 0) {
                    //     return <span style={{ color: '#A0A0A0' }}>{intl.formatMessage({ id: 'text.select-company' })}</span>;
                    //   }
                    //   return clientsInfo
                    //     ?.filter((option: any) => selected.includes(option.id))
                    //     .map((option: any) => option.name)
                    //     .join(', ');
                    renderValue: (selected: any) =>
                      selected?.length === 0 ? (
                        <span style={{ color: '#A0A0A0' }}>{intl.formatMessage({ id: 'text.select-company' })}</span>
                      ) :  clientsInfo
                      ?.filter((option: any) => selected.includes(option.id)).map((option: any) => option.name).join(', '),
                      }}
                  
                      onChange={(e) => {
                        const selectedIds = e.target.value;
                        // typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
                        // setLineIndex(index);
                        setTmpCurrentTab('Clients');
                        setSelectedCompanies(selectedIds);
                        forceUpdate();
                      }}
                    >
                      {clientsInfo?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                      </TextField>
              </Grid>
            </Grid>

            {/* <Grid item container direction='column' xs={2}>
              <Grid item className="Field-input black font-weight-400" > <Message id="ta.add.client.nb" className="Field-input black font-weight-400" /></Grid>
              <Grid item >
                <TextField
                  value={val.clientNumber}
                  type='number'
                  placeholder='Enter Client Nb'
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Clients' ? clientError : false}
                  helperText={clientError && lineIndex === index && tmpCurrentTab === 'Clients' ? 'Enter Client Number' : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]+$/.test(value) || !value) {
                      setLineIndex(index);
                      setTmpCurrentTab('Clients');
                      val.clientNumber = value;
                      forceUpdate();

                      if (!value) {
                        setClientError(true);
                      } else {
                        setClientError(false);
                      }
                    }

                    // setLineIndex(index);
                    // setTmpCurrentTab('Clients');
                    // val.clientNumber = e.target.value;
                    // forceUpdate();
                    // if (!e.target.value) {
                    //   setClientError(true);
                    // }
                    // else {
                    //   setClientError(false);
                    // }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setClientError(true);
                    }
                  }}
                />
              </Grid>
            </Grid> */}
            {/* <Grid item container direction='column' xs={3}>
              <Grid item> <Message id="ta.add.email" className="Field-input black font-weight-400" /></Grid>
              <Grid item>
                <TextField
                  value={val.email}
                  placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Clients' ? emailError : false}
                  helperText={emailError && lineIndex === index && tmpCurrentTab === 'Clients' ? emailErrorMsg : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    val.email = e.target.value;
                    forceUpdate();
                    if (!e.target.value || !e.target.value.match(
                      /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/
                    )) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                    else {
                      setEmailError(false);
                      setEmailErrorMsg('')
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setEmailError(true);
                      setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }))
                    }
                  }}
                />
              </Grid>
            </Grid> */}


            {/* <Grid item style={{ cursor: 'pointer' }} paddingTop={1} marginTop={3.5}
              onMouseOver={(e) => {
                setLineIndex(index);
                setDeleteIconHover(true);
              }}
              onMouseOut={() => {
                setLineIndex(-1);
                setDeleteIconHover(false);
              }}
              onClick={() => {
                setAddedClients((prevTms: any[]) => prevTms.filter(tm => tm.id !== val.id));
              }}>
              <DeleteOutlineIcon htmlColor={lineIndex === index && deleteIconHover && activeTab === 'Clients' ? "#EF4737" : "#93919a"} />
            </Grid> */}

            {/* <Grid item container direction='column' xs={10.7} marginTop={2}>
              <Grid item className="Field-input black font-weight-400" style={{ display: 'flex' }}> <Message id="text.configure-module-access" className='Field-input font-weight-400 mobile-paragraph' /><span style={{ marginLeft: '5px', marginTop: '2px' }}>{val.email}</span>  </Grid>
              {/* <ClickAwayListener onClickAway={(event) => {
                const target = event.target as HTMLElement;
                if (
                  isModuleOpen
                ) {
                  setIsModuleOpen(false);
                }
              }} 
              <ClickAwayListener onClickAway={() => setOpenModuleId(null)}>
                <Grid item>
                  <HtmlTooltip
                    // open={isModuleOpen}
                    open={openModuleId === val.id}
                    disableHoverListener
                    // onClose={() => setOpenModuleId(null)}
                    title={
                      <React.Fragment>
                        <Grid item container rowGap={0.5} maxHeight='228px' padding={1.5} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto', width: '257px' }}>
                        <Grid item>
                      <TextField
                        value={modulesSearch}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleModulesSearch(e)}
                      /></Grid>
                          {filteredModules?.map((module: any, index: number) => (
                            <Grid item container alignItems='center' key={module.id} columnGap={1}
                              className="font-14 normalFontWeight"
                              //className={classes[lineIndex === index || val.roles?.includes(module.id) ? 'filter-selected' : 'filter-not-selected']} 
                              justifyContent='flex-start'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onMouseOver={(e) => {
                                const target = e.target as HTMLElement;
                                // if (!val.roles?.includes(role.id)) {
                                if (module.id === taskConsts.GENERAL_ID) target.style.cursor = 'unset';
                                else target.style.cursor = 'pointer';
                                setLineIndex(index);
                                // }
                              }}
                              onMouseOut={() => {
                                setLineIndex(-1);
                              }}
                              onClick={(e) => {
                                if (!val.modules?.includes(module.id)) {
                                  const updatedModules = [...val.modules, module.id];
                                  val.modules = updatedModules;
                                  forceUpdate();
                                  // setSelectedRoles((prevTenants: any) => [...prevTenants, val.id]);
                                }
                                else {
                                  if (module.id !== taskConsts.GENERAL_ID) {
                                    const updatedModules = val.modules.filter((m: { id: any; }) => m !== module.id)
                                    val.modules = updatedModules;
                                    forceUpdate();
                                  }
                                }
                              }}
                            >
                              <Grid className='bordered-box'
                                //bgcolor={lineIndex === index || val.modules?.includes(module.id) ? '#CCF0EB' : '#E0E0E0'}
                                style={{ borderColor: val.modules?.includes(module.id) ? '#fff' : '#E6E6E8', cursor: module.id === 1 ? 'unset' : 'pointer' }}
                                bgcolor={val.modules?.includes(module.id) ? '#CCF0EB' : '#fff'}
                              >
                                {/* <CheckIcon fontSize='small' htmlColor={lineIndex === index || val.roles?.includes(module.id) ? '#00B69B' : '#9E9E9E'} /> 
                                {
                                  val.modules?.includes(module.id) ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '13px', height: '12px' }} />
                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px', cursor: module.id !== 1 ? 'pointer' : 'unset' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              <Grid item>{module.name}</Grid>

                            </Grid>
                          ))}

                        </Grid>
                      </React.Fragment>
                    }
                  >

                    <Grid item container className='textField' columnGap={1} rowGap={1}>
                      <ControlPointIcon onMouseEnter={() => setHoverId(val.id)} onMouseLeave={() => setHoverId(null)}
                        htmlColor={hoverId === val.id ? '#93919A' : openModuleId === val.id ? '#5D5FEF' : '#C3C2C7'} style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setTimeout(() => {
                            setOpenModuleId(openModuleId === val.id ? null : val.id);
                          }, 0);
                        }} />
                      {val.modules?.map((module: any) => (
                        <Grid item key={module} className={classes['roleBox']}>{modules?.find((m: { id: any; }) => m.id === module)?.name}
                          {module !== 1 && (
                            <CloseIcon sx={{ fontSize: 18, marginLeft: '3px', cursor: 'pointer' }}
                              onClick={() => {
                                val.modules = val.modules.filter((mod: any) => mod !== module)
                                forceUpdate()
                              }} />
                          )}
                        </Grid>
                      ))}

                    </Grid>

                  </HtmlTooltip >
                </Grid>
              </ClickAwayListener>

            </Grid> */}
            {(selectedCompanies.length>0) && (

          <Grid item container xs={12} style={{marginTop:'30px'}}>
          <Grid item container className='table-header Field-input' alignItems='center' justifyContent='flex-end'>
              <Grid item xs={2}>
                <Message id="text.company-name" className='table-header Field-input' />
                </Grid>
                <Grid item xs={1}>
                <Message id="user.salutation" className='table-header Field-input' />
                </Grid>
                <Grid item xs={2.5}>
                <Message id="text.client-admin-email" className='table-header Field-input' />
                  </Grid>
                  <Grid item xs={1.28} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Message id="filter.general" className='table-header Field-input' />
                </Grid>
                  <Grid item xs={1.28} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Message id="text.personal" className='table-header Field-input' />
                </Grid>
                <Grid item xs={1.28} style={{display:'flex', justifyContent:'center', alignItems:'center',marginLeft:'10px', marginRight:'-8px'}}>
                <Message id="text.accounting" className='table-header Field-input' />
                  </Grid>
                  <Grid item xs={1.28} style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'15px', marginRight:'-15px'}}>
                  <Message id="text.annual-financial-statements" className='table-header Field-input' />
                </Grid>
                <Grid item xs={1.28} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Message id="text.onboarding" className='table-header Field-input' />
                  </Grid>
          </Grid>
          <Grid item container xs={12} className='greyLine' style={{marginTop:'5px'}}></Grid>
          
          {addedClients.map((sc:any, index:any)=>(
            
          <Grid item container key={sc} alignItems='center' justifyContent='center'>
                <Grid item xs={2} style={{height:'60px', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                  <Grid item style={{marginRight:'15px'}}>
                  <HtmlTooltip
                  title={
                  <React.Fragment>
                  <Grid item container rowGap={1.5} padding={1.5} maxWidth='170px'>
                    <Grid item container alignItems='center' columnGap={0.5} style={{ cursor: 'pointer' }} paddingTop={1}
                      onMouseOver={(e) => {
                        setDeleteIconHover(true);
                      }}
                      onMouseOut={() => {
                        setLineIndex(-1);
                        setDeleteIconHover(false);
                      }}
                      onClick={() => {
                        setSelectedCompanies((prev: any) => prev.filter((company: any) => company !== sc));
                      }}
                      className='red label'>
                      <DeleteOutlineIcon
                        htmlColor='#EF4737'
                        fontSize='small'
                      />
                      <Message id="text.delete-current-user" className="Field-input font-11 font-weight-400" />
                    </Grid>

                  </Grid>
                </React.Fragment>
              }
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -25],
                    },
                  },
                ],
              }}
            >
              <Grid item xs={0.5} style={{ cursor: 'pointer' }} marginTop='5px'><MoreVertIcon htmlColor='#93919a' /></Grid>
            </HtmlTooltip>
                  </Grid>
                  <Grid item className='black'>
                 {sc.name}
                  </Grid>
                </Grid>
                <Grid item xs={1} style={{height:'60px', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                <Grid item style={{ marginTop: '3px', width: '80px' }}>
                <TextField
                  select
                  value={sc.salutation}
                  placeholder={intl.formatMessage({ id: 'ta.enter-surname' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
                    // val.salutation = e.target.value;
                    const salutation = e.target.value;
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
              
                    const updatedClients = [...addedClients];
                    updatedClients[index] = {
                      ...updatedClients[index],
                      salutation: salutation, 
                    };
              
                    setAddedClients(updatedClients);
                    forceUpdate();
                  }}
                >
                  {salutationConst.salutations?.map((s: any) => (
                    <MenuItem value={s.id} key={s.id}>{s.selection}</MenuItem>
                  ))}

                </TextField>

              </Grid>
              </Grid>
                <Grid item xs={2.5} style={{height:'60px', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                  {/* <Grid item className='textField' style={{width:'100%'}}>raytest3210@gmail.com</Grid> */}
                  <Grid item style={{width:'100%'}}>
                <TextField
                  value={sc.email}
                  placeholder={intl.formatMessage({ id: 'login.enter-your_email-here' })}
                  className="Field-input"
                  InputLabelProps={{ shrink: true }}
                  error={lineIndex === index && tmpCurrentTab === 'Clients' ? emailError : false}
                  helperText={emailError && lineIndex === index && tmpCurrentTab === 'Clients' ? emailErrorMsg : ''}
                  size='medium'
                  InputProps={{
                    style: {
                      borderRadius: '12px',
                      fontFamily: 'DM Sans',
                      height: '50px',
                    },
                  }}
                  onChange={(e) => {
                    const newEmail = e.target.value;
                    setLineIndex(index);
                    setTmpCurrentTab('Clients');
              
                    const updatedClients = [...addedClients];
                    updatedClients[index] = {
                      ...updatedClients[index],
                      email: newEmail, 
                    };
              
                    setAddedClients(updatedClients);
              
                    if (!newEmail || !newEmail.match(/^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/)) {
                      setEmailError(true);
                      // setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }));
                    } else {
                      setEmailError(false);
                      // setEmailErrorMsg('');
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setEmailError(true);
                      // setEmailErrorMsg(intl.formatMessage({ id: 'error.enter-a-valid-email-address' }));
                    }
                  }}
                />
              </Grid>
                  </Grid>
                  <Grid item xs={1.28} style={{height:'60px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src={CheckedIcon} style={{ width: '20px', height: '20px'}} />
                </Grid>
                  <Grid item xs={1.28} style={{height:'60px', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={() => handleCheckboxChange(sc, 'personal')}>
                    {checked[sc]?.personal ? <img src={CheckedIcon} style={{ width: '20px', height: '20px',cursor:'pointer' }} /> :  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} />}
                </Grid>
                <Grid item xs={1.28} style={{height:'60px', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={() => handleCheckboxChange(sc, 'accounting')}>
                {checked[sc]?.accounting ? <img src={CheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} /> :  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} />} 
                  </Grid>
                  <Grid item xs={1.28} style={{height:'60px', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={() => handleCheckboxChange(sc, 'financial')}>
                  {checked[sc]?.financial ? <img src={CheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} /> :  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} />}
                </Grid>
                <Grid item xs={1.28} style={{height:'60px', display:'flex', justifyContent:'center', alignItems:'center'}} onClick={() => handleCheckboxChange(sc, 'onboarding')}>
                {checked[sc]?.onboarding ? <img src={CheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} /> :  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px', cursor:'pointer' }} />}
                  </Grid>
                  <Grid item container xs={12} className='greyLine'></Grid>
          </Grid>
         
          ))}
    </Grid>
            )}
      

          </Grid>
          </>

        // ))
      }

      {/* <Grid item className='iris-100 bold' marginTop={2}
        onClick={() => {
          addUserObject()
        }}
      >
        <HtmlTooltip placement='bottom-start'
          title=
          {
            <Grid item padding={1}>

              {listError ? <Message id="text.user-missing-field" className="Field-input labels font-weight-400" /> : <Message id="text.click-to-add-user" className="Field-input labels font-weight-400" />}

            </Grid>
          }>
          <span style={{ cursor: 'pointer' }}><Message id="ta.add.users" className="Field-input labels labels-extra-bold" /></span>
        </HtmlTooltip>
      </Grid> */}
     
    
      <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1}>
        <Grid item xs={2.5} >
          <button className='greyButton'
            onClick={() => navigate('/users')}
          ><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
        </Grid>
        <Grid item xs={2.5}>
          <button
            className={callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton'}
            disabled={loading || callStatus === 'Pass' || callStatus === 'Fail' || (activeTab !== 'Tms' && selectedCompanies.length === 0) || emailError || (activeTab === 'Tms' &&
              ((localStorage.getItem('userType') === '1' && !addedTms.every((user: any) => user.email.trim() !== "" && user.roles.length > 0 && user.accessModules.length > 0))
                || (localStorage.getItem('userType') === '2' && !addedTms.every((user: any) => user.email.trim() !== "" && user.roles.length > 0 && user.modules.length > 0))
              ))
              || (activeTab === 'Clients' && !addedClients.every((user: { email: string; name: string; salutation: number | any[]; }) =>
                user.email.trim() !== "" && user.salutation !== null))}

            onClick={() => {
              setActiveSection('Review')
            }
            }
          >
            {loading ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
              : callStatus === 'Pass' ? <span><CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                : callStatus === 'Fail' ? "Failed"
                  : <Message id="button.next" className="Field-input labelsLinks labels-extra-bold" />}
          </button>
          <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
        </Grid>
      </Grid>

      <ConfigureAccess
        openConfigureAccessDialog={openConfigureAccessDialog}
        setOpenConfigureAccessDialog={setOpenConfigureAccessDialog}
        user={user}
        setUser={setUser}
        modules={modules}
        taxAdvisorClients={taxAdvisorClients}
        setTaxAdvisorClients={setTaxAdvisorClients}
        type='Add TM'
        userDataLoading={false}
        updateAddedTms={() => {
          setAddedTms((prevAddedTms: any) => {
            const updatedTms = prevAddedTms.filter((u: any) => u.id !== user?.id);  // Remove the old user if exists
            updatedTms.push(user);  // Add the updated user
            return updatedTms;  // Return the updated array
          });
        }}
      />


    </Grid >
  )
}

export default AddUser