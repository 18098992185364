import { configureStore } from '@reduxjs/toolkit'
import userDataSlice from "./slices/userDataSlice";
import loadingSlice from './slices/loadingSlice';
import apiCallSlice from './slices/apiCallSlice';
import localsSlice from './slices/localsSlice';
import modifySlice from './slices/modifySlice';
import formsSlice from './slices/formsSlice';

export const store = configureStore({
  reducer: {
    userData: userDataSlice,
    loading: loadingSlice,
    apiCall: apiCallSlice,
    locale: localsSlice,
    modify: modifySlice,
    forms: formsSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch