import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridColDef, GridColType } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import filterIcon from '../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CircularProgress, Grid, TextField } from "@mui/material";
import classes from "../../../assets/styles/Table.module.css";
import CheckIcon from "@mui/icons-material/Check";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UpdateRole from "./Dialogs/UpdateRole";
import { useIntl } from 'react-intl';
import * as userServices from "../../../services/user-services.proxy";
import * as clientServices from "../../../services/client-services.proxy";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getLoadingState, setLoading } from "../../../app/slices/loadingSlice";
import {
  getCallStatus,
  getErrorMsg,
  setCallStatus,
  setErrorMsg,
} from "../../../app/slices/apiCallSlice";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_Cell,
} from 'material-react-table';
import DeleteDialog from "../../../sharedComponents/Dialogs/DeleteDialog";
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles } from "../../../app/slices/userDataSlice";
import Message from "../../../sharedComponents/ui/Message";
import ConfigureAccess from "./Dialogs/ConfigureAccess";
import accessIcon from "../../../assets/images/icons/accessIcon.png";
import deleteIcon from "../../../assets/images/icons/delete.png";
import moment from "moment";
import { FastfoodOutlined } from "@mui/icons-material";
// @ts-ignore
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale/en-GB';


const updateTMRole = userServices.updateTMRole;
const deleteClient = clientServices.deleteClient;
const getClientData = clientServices.getClientData;
const updateClientModule = clientServices.updateClientModule;
const getUserData = userServices.getUserData


export interface TableProps {
  rows: any;
  setRows: any;
  dataLength: any;
  setDataLength: any;
  roles: any;
  setRoles: any;
  selectedRoles: any;
  setSelectedRoles: any;
  modify: any;
  setModify: any;
  selectedStatuses: number[],
  setSelectedStatuses: CallableFunction;
  paginationModel: any,
  setPaginationModel: any,
  clickedStatus: number[],
  setClickedStatus: CallableFunction,
  clickedRoles: number[],
  setClickedRoles: CallableFunction,
  isOpenRoles: boolean;
  setIsOpenRoles: CallableFunction;
  isOpenStatus: boolean;
  setIsOpenStatus: CallableFunction;
  activeTooltip: any;
  setActiveTooltip: CallableFunction;
  // pageNb: any,
  // setPageNb: any,
  // pageSize: any,
  // setPageSize: any,
  loadMoreRows: any;
  setLoadMoreRows: any;
  setOldRows: any;
  setTake: any;
  initiallistLoading: any;
  dialogLoading: any;
  noMoreRows: any;
  setNoMoreRows: any;
  gridRef: any;
  notLoad: any;
  setNotLoad: any;
  clickedUserNames: any;
  setClickedUserNames: any;
  selectedUserNames: any;
  setSelectedUserNames: any;
  userNamesList: any;
  clickedEmails: any;
  setClickedEmails: any;
  selectedEmails: any;
  setSelectedEmails: any;
  emailsList: any;
  clickedLastActiveDates: any;
  setClickedLastActiveDates: any;
  selectedLastActiveDates: any;
  setSelectedLastActiveDates: any;
  lastActiveDatesList: any;
  statusesList: any;

  clickedPhoneNumbers: any;
  setClickedPhoneNumbers: any;
  selectedPhoneNumbers: any;
  setSelectedPhoneNumbers: any;
  phoneNumbersList: any;

  clickedCountries: any;
  setClickedCountries: any;
  selectedCountries: any;
  setSelectedCountries: any;
  countriesList: any;

  clickedCreationDates: any;
  setClickedCreationDates: any;
  selectedCreationDates: any;
  setSelectedCreationDates: any;
  creationDatesList: any;

  setOriginalClientsTable: any;
  dateRange: any;
  setDateRange: any;
}


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: "0px 2px 4px 0px #0000001F",
    backgroundColor: "#FEFEFF",
    borderRadius: "10px",
    padding: 0,
  },
}));

const ClientsTable = (props: TableProps) => {
  const {
    rows,
    setRows,
    roles,
    setRoles,
    selectedRoles,
    setSelectedRoles,
    modify,
    setModify,
    selectedStatuses,
    setSelectedStatuses,
    dataLength,
    paginationModel,
    setPaginationModel,
    clickedStatus,
    setClickedStatus,
    clickedRoles,
    setClickedRoles,
    isOpenRoles,
    setIsOpenRoles,
    isOpenStatus,
    setIsOpenStatus,
    activeTooltip,
    setActiveTooltip,
    loadMoreRows,
    setLoadMoreRows,
    setOldRows,
    setTake,
    initiallistLoading,
    dialogLoading,
    noMoreRows,
    setNoMoreRows,
    gridRef,
    notLoad,
    setNotLoad,
    clickedUserNames,
    setClickedUserNames,
    selectedUserNames,
    setSelectedUserNames,
    userNamesList,
    clickedEmails,
    setClickedEmails,
    selectedEmails,
    setSelectedEmails,
    emailsList,
    clickedLastActiveDates,
    setClickedLastActiveDates,
    selectedLastActiveDates,
    setSelectedLastActiveDates,
    lastActiveDatesList,
    statusesList,
    clickedPhoneNumbers,
    setClickedPhoneNumbers,
    selectedPhoneNumbers,
    setSelectedPhoneNumbers,
    phoneNumbersList,

    clickedCountries,
    setClickedCountries,
    selectedCountries,
    setSelectedCountries,
    countriesList,

    clickedCreationDates,
    setClickedCreationDates,
    selectedCreationDates,
    setSelectedCreationDates,
    creationDatesList,
    setOriginalClientsTable,
    dateRange,
    setDateRange
  } =
    props;

  const planStatuses: number[] = [1, 2, 3]
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const clientRoles = roles?.filter((r: { typeId: number; }) => r.typeId === 2);


  // useEffect(() => {
  //   if (activeTooltip === 'lastActive' && clickedLastActiveDates.length === 2) {
  //     const startDate = new Date(clickedLastActiveDates[0]);
  //     const endDate = new Date(clickedLastActiveDates[1]);
  //     setDateRange([{ startDate, endDate, key: 'selection' }]);
  //   }
  // }, [activeTooltip, clickedLastActiveDates]);

  const [filteredUserNames, setFilteredUserNames] = useState<any>(userNamesList);
  const [filteredEmails, setFilteredEmails] = useState<any>(emailsList);
  const [filteredRoles, setFilteredRoles] = useState<any>(clientRoles);
  const [filteredLastActive, setFilteredLastActive] = useState<any>(lastActiveDatesList);
  const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState<any>(phoneNumbersList);
  const [filteredCountries, setFilteredCountries] = useState<any>(countriesList);
  const [filteredCreationDates, setFilteredCreationDates] = useState<any>(creationDatesList);



  const [isRoleIconClicked, setIsRoleIconClicked] = useState(false);
  const [isStatusIconClicked, setIsStatusIconClicked] = useState(false);

  //store states
  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const userRoles = useAppSelector(selectRoles);

  const [filterIndex, setFilterIndex] = useState<number>();
  const [search, setSearch] = useState<string>("");

  //update role
  const [openUpdateRoleDialog, setOpenUpdateRoleDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [selectedUser, setSelectedUser] = useState<any>();
  const [selectedUserToDelete, setSelectedUserToDelete] = useState<any>();

  const [selectedClient, setSelectedClient] = useState<any>();

  const [openConfigureAccessDialog, setOpenConfigureAccessDialog] = useState(false);

  const [taxAdvisorClients, setTaxAdvisorClients] = useState([]);
  const [modules, setModules] = useState([]);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [noVerticalScroll, setNoVerticalScroll] = useState(false);

  useEffect(() => {
    console.log("usersnamess", userNamesList)
  })

  const extractRolesIds = (rolesString: string) => {
    if (rolesString.length > 0) {
      const roleNames = rolesString?.split(",").map((name) => name.trim());

      const ids = roles
        .filter((role: { name: string }) => roleNames?.includes(role.name))
        .map((role: { id: any }) => role.id);

      return ids;
    }
    else return []

  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    // const filtered = clientRoles?.filter((role: any) =>
    //   role.name.toLowerCase()?.includes(searchValue)
    // );
    // setFilteredRoles(filtered);
    if (type === 'username') {
      const filtered = userNamesList?.filter((val: any) =>
        val.toLowerCase()?.includes(searchValue)
      );
      setFilteredUserNames(filtered);
    }
    else if (type === 'role') {
      const filtered = roles?.filter((role: any) =>
        role.name.toLowerCase()?.includes(searchValue)
      );
      setFilteredRoles(filtered);
    }
    else if (type === 'lastActive') {
      const filtered = roles?.filter((role: any) =>
        role.name.toLowerCase()?.includes(searchValue)
      );
      setFilteredLastActive(filtered);
    }
    else if (type === 'phoneNb') {
      const filtered = roles?.filter((role: any) =>
        role.name.toLowerCase()?.includes(searchValue)
      );
      setFilteredPhoneNumbers(filtered);
    }
    else if (type === 'countries') {
      const filtered = roles?.filter((role: any) =>
        role.name.toLowerCase()?.includes(searchValue)
      );
      setFilteredCountries(filtered);
    }
    else if (type === 'creationDate') {
      const filtered = roles?.filter((role: any) =>
        role.name.toLowerCase()?.includes(searchValue)
      );
      setFilteredCreationDates(filtered);
    }
  }

  useEffect(() => {
    getUserData().then((x) => {
      let tmpClients = x.clients.map((client: any) => ({
        ...client,
        expand: true
      }));
      setTaxAdvisorClients(tmpClients);
      // setTaxAdvisorClients(x.clients);
      setModules(x.modules)
    })
  }, [])

  useEffect(() => {

    if (activeTooltip === 'username') {
      setFilteredUserNames(userNamesList);
      setSearch('');
    }
    else if (activeTooltip === 'email') {
      setFilteredEmails(emailsList);
      setSearch('');
    }
    else if (activeTooltip === 'roles') {
      setFilteredRoles(roles);
      setSearch('');
    }
    else if (activeTooltip === 'lastActive') {
      setFilteredLastActive(lastActiveDatesList);
      setSearch('');
    }
    else if (activeTooltip === 'phoneNb') {
      setFilteredPhoneNumbers(phoneNumbersList);
      setSearch('');
    }
    else if (activeTooltip === 'countries') {
      setFilteredCountries(countriesList);
      setSearch('');
    }
    else if (activeTooltip === 'creationDate') {
      setFilteredCreationDates(creationDatesList);
      setSearch('');
    }
    else if (activeTooltip === 'lastActive' && clickedLastActiveDates.length === 2) {
      const startDate = new Date(clickedLastActiveDates[0]);
      const endDate = new Date(clickedLastActiveDates[1]);
      setDateRange([{ startDate, endDate, key: 'selection' }]);
    }
  }, [activeTooltip]);


  const columnss: GridColDef[] = [
    ...(userRoles?.includes(userRolesConst.ADMIN_ID) ? [{
      field: 'actions',
      type: 'actions' as GridColType,
      width: 10,
      sortable: false,
      renderCell: (params: { row: { id: any; email: any; roles: any; }; }) => {
        if (params.row.id !== 'Empty') {
          return (
            <HtmlTooltip
              placement='bottom-start'
              title={
                <React.Fragment>
                  <Grid item container rowGap={1.5} padding={1.5} maxWidth='260px' className="Field-input labelsLinks font-weight-400 font-14">

                    <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                      onClick={() => {
                        //setOldRows([])
                        setSelectedClient(params.row);
                        setOpenConfigureAccessDialog(true)
                        getClientDataCall(params.row.id);
                      }}
                    >
                      <img src={accessIcon} /> <Message id="text.edit-company-module" className="Field-input labelsLinks font-weight-400" />
                    </Grid>

                    <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                      onClick={() => {
                        //setOldRows([])
                        setSelectedUserToDelete(() => ({
                          userId: params.row.id,
                          email: params.row.email,
                          roles: params.row.roles?.map((r: any) => r.name)?.join(','),
                        }));
                        setOpenDeleteDialog(true);
                      }}
                    >
                      {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /> */}
                      <img src={deleteIcon} width='20px' height='20px' />
                      <Message id="ta.delete" className="Field-input labelsLinks font-weight-400" />
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
            </HtmlTooltip>
          );
        }
        return null;
      },

    }] : []),
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   width: 10,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <HtmlTooltip
    //       title={
    //         <React.Fragment>
    //           <Grid item container rowGap={1.5} padding={1.5} maxWidth='120px'>
    //             {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID || userRolesConst.ADMIN_ID) ? */}
    //             {/* <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
    //               onClick={() => {
    //                 setSelectedUser(() => ({
    //                   userId: params.row.userId,
    //                   email: params.row.email,
    //                   roles: extractRolesIds(params.row.roles),
    //                   type: 2,
    //                 }));
    //                 setOpenUpdateRoleDialog(true);
    //               }}

    //             >
    //               <EditOutlinedIcon htmlColor='#605e6b' fontSize='small' /><Message id="ta.edit.edit-role" className="Field-input labelsLinks font-weight-400" />
    //             </Grid> */}
    //             {/* :
    //             <></>} */}


    //             {/* {userRoles.includes(userRolesConst.SUPER_ADMIN_ID) ? */}
    //             <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
    //               onClick={() => {
    //                 setSelectedUserToDelete(() => ({
    //                   userId: params.row.id,
    //                   email: params.row.email,
    //                   roles: params.row.roles,
    //                 }));
    //                 setOpenDeleteDialog(true);
    //               }}
    //             ><DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><Message id="ta.delete" className="Field-input labelsLinks font-weight-400" /></Grid>
    //             {/* :
    //             <></>
    //           } */}

    //           </Grid>
    //         </React.Fragment>
    //       }
    //     >
    //       <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
    //     </HtmlTooltip>
    //   ),
    // },
    {
      field: "name", headerName: intl.formatMessage({ id: 'user.profile.user-name' }),
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedUserNames(selectedUserNames);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'username' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenRoles(false);
              setActiveTooltip(null);
              // setIsRoleIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenRoles((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'username' ? null : 'username'));
              // if (activeTooltip !== 'roles') {
              //   setIsRoleIconClicked(false);
              // }
              // e.stopPropagation();
            }}>
              <Message id="user.profile.user-name" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'username'}
                // onClose={() => setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'username')}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredUserNames?.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val} columnGap={1}
                            // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedRoles?.includes(val.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setFilterIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setFilterIndex(-1);
                            // }}
                            onClick={(e) => {
                              setClickedUserNames((prevSelected: any) => {
                                if (prevSelected.includes(val)) {
                                  return prevSelected.filter((v: any) => v !== val);
                                }
                                else {
                                  return [...prevSelected, val];
                                }
                              })
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedUserNames?.includes(val) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedUserNames?.includes(val) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedUserNames?.includes(val) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                            {/* <Grid item>{val.name}</Grid> */}
                            <Grid item>
                              {val}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedUserNames.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedUserNames([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                          setClickedUserNames(selectedUserNames);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedUserNames(clickedUserNames)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon
          style={{
            transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease', 
          }}
        />  */}
                  <img src={selectedUserNames.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    // setIsRoleIconClicked(true);
                    setClickedUserNames(selectedUserNames);
                    // setIsOpenRoles((prev) => !prev)
                    setActiveTooltip((prev: any) => (prev === 'username' ? null : 'username'));
                  }} />
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
      width: 240, sortable: false,
    },
    {
      field: 'email', headerName: intl.formatMessage({ id: 'user.profile.email' }),
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedUserNames(selectedUserNames);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'email' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenRoles(false);
              setActiveTooltip(null);
              // setIsRoleIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenRoles((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
              // if (activeTooltip !== 'roles') {
              //   setIsRoleIconClicked(false);
              // }
              // e.stopPropagation();
            }}>
              <Message id="user.profile.email" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'email'}
                // onClose={() => setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'email')}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredEmails?.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val} columnGap={1}
                            // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedRoles?.includes(val.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setFilterIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setFilterIndex(-1);
                            // }}
                            onClick={(e) => {
                              setClickedEmails((prevSelected: any) => {
                                if (prevSelected.includes(val)) {
                                  return prevSelected.filter((v: any) => v !== val);
                                }
                                else {
                                  return [...prevSelected, val];
                                }
                              })
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedEmails?.includes(val) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedEmails?.includes(val) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedEmails?.includes(val) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                            {/* <Grid item>{val.name}</Grid> */}
                            <Grid item>
                              {val}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedEmails.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedEmails([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                          setClickedEmails(selectedEmails);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedEmails(clickedEmails)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon
            style={{
              transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          />  */}
                  <img src={selectedEmails.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    // setIsRoleIconClicked(true);
                    setClickedEmails(selectedEmails);
                    // setIsOpenRoles((prev) => !prev)
                    setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
                  }} />
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
      width: 290, sortable: false,
    },
    {
      field: 'roles', headerName: intl.formatMessage({ id: 'user.user-role' }), width: 190,
      // sortable: (activeTooltip !== 'roles' && !isRoleIconClicked),
      sortable: false,
      renderCell: (params) => {
        return (
          <Grid item>
            {params.row?.roles?.map((r: any) => r.name)?.join(',')}
          </Grid>
        );
      },
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedRoles(selectedRoles);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'roles' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenRoles(false);
              setActiveTooltip(null);
              setIsRoleIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal'
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                // setIsOpenRoles((prev:any)=>!prev)
                setActiveTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
                if (activeTooltip !== 'roles') {
                  setIsRoleIconClicked(false);
                }
                // e.stopPropagation();
              }}>
              <Message id="user.user-role" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'roles'}
                // onClose={()=>setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' width='307px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'roles')}
                        /></Grid>

                      {filteredRoles?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val.id} columnGap={2}
                          //  className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          className='font-14 black normalFontWeight'
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          onMouseOver={(e) => {
                            const target = e.target as HTMLElement;
                            if (!clickedRoles?.includes(val.id)) {
                              target.style.cursor = 'pointer';
                              setFilterIndex(index);
                            }
                          }}
                          onMouseOut={() => {
                            setFilterIndex(-1);
                          }}
                          onClick={(e) => {
                            // if (!clickedRoles?.includes(val.id)) {
                            //   setClickedRoles((prevTenants: any) => [...prevTenants, val.id]);
                            // }
                            setClickedRoles((prevSelectedRoles: any) => {
                              if (prevSelectedRoles.includes(val.id)) {
                                return prevSelectedRoles.filter((id: any) => id !== val.id);
                              }
                              else {
                                return [...prevSelectedRoles, val.id];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedRoles?.includes(val.id) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedRoles?.includes(val.id) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedRoles?.includes(val.id) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                          {/* <Grid item>{val.name}</Grid> */}
                          <Grid item>
                            {val.id === userRolesConst.CLIENT_ADMIN_ID ? <Message id="filter.client-admin" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_AUHTORIZED_SIGNER_ID ? <Message id="filter.client-authorized-signer" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_EMPLOYEE_ID ? <Message id="filter.client-employee" className="Field-input labels font-weight-400" /> : ''}
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedRoles.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedRoles([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>

                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          //  setIsOpenRoles(false);
                          setClickedRoles(selectedRoles);
                          setActiveTooltip(null);
                          setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedRoles(clickedRoles)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon
              style={{
                transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            />  */}
                  <img src={selectedRoles.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setIsRoleIconClicked(true);
                    setClickedRoles(selectedRoles);
                    setActiveTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
                  }} />
                  {/* {selectedRoles.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>} */}
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
    },

    {
      field: 'lastActiveDate', headerName: intl.formatMessage({ id: 'user.last-active' }),
      renderHeader(params: any) {
        return (
          // <ClickAwayListener onClickAway={(event) => {
          //   setClickedLastActiveDates(selectedLastActiveDates);
          //   const target = event.target as HTMLElement;
          //   if (
          //     activeTooltip === 'lastActive' &&
          //     !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          //   ) {
          //     // setIsOpenRoles(false);
          //     setActiveTooltip(null);
          //     // setIsRoleIconClicked(false);
          //   }
          // }}>
          <ClickAwayListener onClickAway={(event) => {
            const target = event.target as HTMLElement;
            if (activeTooltip === 'lastActive' && !target.closest('.table-header')) {
              setDateRange([{
                startDate: selectedLastActiveDates?.length > 0 ? new Date(selectedLastActiveDates[0]) : new Date(),
                endDate: selectedLastActiveDates?.length > 1 ? new Date(selectedLastActiveDates[1]) : new Date(),
                key: 'selection'
              }]);
              setActiveTooltip(null);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenRoles((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'lastActive' ? null : 'lastActive'));
              // if (activeTooltip !== 'roles') {
              //   setIsRoleIconClicked(false);
              // }
              // e.stopPropagation();
            }}>
              <Message id="user.last-active" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'lastActive'}
                // onClose={() => setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} padding='8px 10px 8px 8px' display='inline-grid' width='307px'>
                      <DateRange
                        style={{ maxWidth: '307px' }}
                        editableDateInputs={false}
                        showDateDisplay={false}
                        onChange={(item: any) => {
                          setDateRange([item.selection]);
                          console.log("Date Range:", dateRange);
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        locale={enGB}
                        className="custom-date-range"
                        rangeColors={['#5D5FEF']}
                      />
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        {/* <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                          setClickedLastActiveDates(selectedLastActiveDates);
                        }}> */}
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e) => {
                          e.stopPropagation();
                          setActiveTooltip(null);
                          setDateRange([{
                            startDate: selectedLastActiveDates?.length > 0 ? new Date(selectedLastActiveDates[0]) : new Date(),
                            endDate: selectedLastActiveDates?.length > 1 ? new Date(selectedLastActiveDates[1]) : new Date(),
                            key: 'selection'
                          }]);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        {/* <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedLastActiveDates(clickedLastActiveDates)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                        }}> */}
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e) => {
                          e.stopPropagation();
                          const startDate = dateRange[0].startDate.toISOString();
                          const endDate = dateRange[0].endDate.toISOString();
                          setSelectedLastActiveDates([startDate, endDate]);
                          setActiveTooltip(null);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                    {/* <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'email')}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredLastActive?.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val} columnGap={1}
                            // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedRoles?.includes(val.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setFilterIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setFilterIndex(-1);
                            // }}
                            onClick={(e) => {
                              setClickedLastActiveDates((prevSelected: any) => {
                                if (prevSelected.includes(val)) {
                                  return prevSelected.filter((v: any) => v !== val);
                                }
                                else {
                                  return [...prevSelected, val];
                                }
                              })
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedLastActiveDates?.includes(val) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedLastActiveDates?.includes(val) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedLastActiveDates?.includes(val) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                    {/* <Grid item>{val.name}</Grid> */}
                    {/* <Grid item>
                              {val}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedLastActiveDates.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedLastActiveDates([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                          setClickedLastActiveDates(selectedLastActiveDates);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedLastActiveDates(clickedLastActiveDates)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>  */}
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon
          style={{
            transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease', 
          }}
        />  */}
                  <img src={selectedLastActiveDates.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    // setIsRoleIconClicked(true);
                    setClickedLastActiveDates(selectedLastActiveDates);
                    // setIsOpenRoles((prev) => !prev)
                    setActiveTooltip((prev: any) => (prev === 'lastActive' ? null : 'lastActive'));
                  }} />
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
      width: 300, sortable: false,
      renderCell: (params) => {
        if (params.row.id !== 'Empty') {
          return (
            <span>{moment(params.row.lastActiveDate).format('DD.MM.YYYY HH:mm:ss A')}</span>
          )
        }
        return null;
      }
    },
    {
      field: 'statusId', headerName: intl.formatMessage({ id: 'user.status' }), width: 170,
      // sortable: (activeTooltip !== 'status' && !isStatusIconClicked),
      sortable: false,
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedStatus(selectedStatuses);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'status' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenStatus(false);
              setActiveTooltip(null);
              setIsStatusIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenStatus((prev:any) => !prev)
              // e.stopPropagation();
              setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
              if (activeTooltip !== 'status') {
                setIsStatusIconClicked(false);
              }
            }}>
              <Message id="user.status" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'status'}
                // onClose={() => setIsOpenStatus(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} direction='row' rowGap={0.5} width='307px' maxHeight='378px' padding={2}>
                      {statusesList.map((val: number, index: number) => (
                        <Grid item container alignItems='center' columnGap={2}
                          // className={classes[filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') || (statusId === 3 && val === 'Invited') ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          className='font-14 black normalFontWeight'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if ((clickedStatus !== 1 && val === 1) || (clickedStatus !== 2 && val === 2) || (clickedStatus !== 3 && val === 3)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          onMouseOver={(e) => {
                            const target = e.target as HTMLElement;
                            if (
                              !(clickedStatus.includes(1) && val === 1) &&
                              !(clickedStatus.includes(2) && val === 2) &&
                              !(clickedStatus.includes(3) && val === 3)
                            ) {
                              target.style.cursor = 'pointer';
                              setFilterIndex(index);
                            }
                          }}

                          onMouseOut={() => {
                            setFilterIndex(-1);
                          }}
                          // onClick={() => {
                          //   if (val === 1) setClickedStatus(prevStatus => (prevStatus === 1 ? 0 : 1))
                          //   if (val === 2) setClickedStatus(prevStatus => (prevStatus === 2 ? 0 : 2))
                          //   if (val === 3) setClickedStatus(prevStatus => (prevStatus === 3 ? 0 : 3))

                          // }}
                          onClick={() => {
                            setClickedStatus((prevSelectedStatus: any) => {
                              if (prevSelectedStatus.includes(val)) {
                                return prevSelectedStatus.filter((status: any) => status !== val);
                              } else {
                                return [...prevSelectedStatus, val];
                              }
                            });
                          }}

                        >
                          <Grid className='bordered-box'
                            style={{
                              borderColor: ((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ? '#fff' : '#E6E6E8',
                            }}
                            bgcolor={((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ? '#CCF0EB' : '#fff'}>
                            {
                              ((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>

                          {/* <Grid className='bordered-box' bgcolor={
                          filterIndex === index ||
                            (statusId === 1 && val === 'Active') ||
                            (statusId === 2 && val === 'Inactive') ? '#CCF0EB' :
                            (statusId === 3 && val === 'Invited') ? '#ff9900' :
                              '#E0E0E0'
                        }><CheckIcon
                            fontSize='small'
                            htmlColor={
                              filterIndex === index ||
                                (statusId === 1 && val === 'Active') ||
                                (statusId === 2 && val === 'Inactive') ? '#00B69B' :
                                (statusId === 3 && val === 'Invited') ? '#000000' :
                                  '#9E9E9E'
                            } /></Grid> */}
                          <Grid item>
                            {
                              val === 1 ? <Message id="filter.active" className="Field-input labels font-weight-400" /> :
                                val === 2 ? <Message id="filter.inactive" className="Field-input labels font-weight-400" /> :
                                  val === 3 ? <Message id="filter.invited" className="Field-input labels font-weight-400" /> : ''
                            }
                          </Grid>
                        </Grid>
                      ))}
                      {/* <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 1 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                  onMouseOver={() => {
                                      if (statusId !== 1) {
                                          setFilterIconIndex('active')
                                      }
                                  }}
                                  onMouseOut={() => {
                                      setFilterIconIndex('')
                                  }}
                              >
                                  <Grid className='bordered-box' bgcolor={statusId === 1 || filterIconIndex === 'active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 1 || filterIconIndex === 'active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                  <Grid item>Active</Grid>
                              </Grid>
                              <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 2 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                  onMouseOver={() => {
                                      if (statusId !== 2) {
                                          setFilterIconIndex('in-active')
                                      }
                                  }}
                                  onMouseOut={() => {
                                      setFilterIconIndex('')
                                  }}
                              >
                                  <Grid className='bordered-box' bgcolor={statusId === 2 || filterIconIndex === 'in-active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 2 || filterIconIndex === 'in-active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                  <Grid item>Inactive</Grid>
                              </Grid> */}
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: clickedStatus ? 'pointer' : 'unset' }} marginLeft={0.5}
                        onClick={() => {
                          setClickedStatus([]);
                        }}><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                      {/* <Grid item container width='80px' marginLeft={7}>
                                  <button className='blueButton' disabled={loading}>Ok</button></Grid> */}
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedStatus([]);
                          //  setIsOpenStatus(false);
                          setClickedStatus(selectedStatuses);
                          setActiveTooltip(null);
                          setIsStatusIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedStatuses(clickedStatus)
                          // setIsOpenStatus(false);
                          setActiveTooltip(null);
                          setIsStatusIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
              style={{
                transform: isOpenStatus ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                <img src={selectedStatuses?.length ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setIsStatusIconClicked(true);
                  setClickedStatus(selectedStatuses);
                  setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                }} />
              </HtmlTooltip >
            </Grid >
          </ClickAwayListener>
        )
      },
      renderCell: (params) => {
        return (
          <Grid item container direction='row' style={{ marginTop: '-15px', height: '15px' }} columnGap={1.5}>
            <Grid item className={params.value === statusesConsts.ACTIVE_STATUS_ID ? 'active-status' : params.value === statusesConsts.INVITED_STATUS_ID ? 'invited-status' : params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? 'not-active-status' : ''} width='70px'>
              {params.value === statusesConsts.ACTIVE_STATUS_ID ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : params.value === statusesConsts.IN_ACTIVE_STATUS_ID ? <Message id="filter.in-active" className="Field-input labels font-weight-400" /> : params.value === statusesConsts.INVITED_STATUS_ID ? <Message id="filter.invited" className="Field-input labels font-weight-400" /> : ''}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'phoneNumber', headerName: intl.formatMessage({ id: 'user.profile.phone-number' }),
      renderHeader(params: any) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedUserNames(selectedUserNames);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'phoneNb' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenRoles(false);
              setActiveTooltip(null);
              // setIsRoleIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenRoles((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
              // if (activeTooltip !== 'roles') {
              //   setIsRoleIconClicked(false);
              // }
              // e.stopPropagation();
            }}>
              <Message id="user.profile.phone-number" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'phoneNb'}
                // onClose={() => setIsOpenRoles(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'email')}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredPhoneNumbers?.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val} columnGap={1}
                            // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedRoles?.includes(val.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setFilterIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setFilterIndex(-1);
                            // }}
                            onClick={(e) => {
                              setClickedPhoneNumbers((prevSelected: any) => {
                                if (prevSelected.includes(val)) {
                                  return prevSelected.filter((v: any) => v !== val);
                                }
                                else {
                                  return [...prevSelected, val];
                                }
                              })
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedPhoneNumbers?.includes(val) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedPhoneNumbers?.includes(val) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedPhoneNumbers?.includes(val) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                            {/* <Grid item>{val.name}</Grid> */}
                            <Grid item>
                              {val}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedPhoneNumbers.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedPhoneNumbers([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRoles([]);
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                          setClickedPhoneNumbers(selectedPhoneNumbers);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedPhoneNumbers(clickedPhoneNumbers)
                          // setIsOpenRoles(false);
                          setActiveTooltip(null);
                          // setIsRoleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <ArrowDropDownIcon
          style={{
            transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease', 
          }}
        />  */}
                  <img src={selectedPhoneNumbers.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    // setIsRoleIconClicked(true);
                    setClickedPhoneNumbers(selectedPhoneNumbers);
                    // setIsOpenRoles((prev) => !prev)
                    setActiveTooltip((prev: any) => (prev === 'phoneNb' ? null : 'phoneNb'));
                  }} />
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
      width: 200, sortable: false,
    },
    // { field: 'primaryContactPerson', headerName: intl.formatMessage({ id: 'user.primary-contact-person' }), width: 200, sortable: false, },
    // {
    //   field: 'country', headerName: intl.formatMessage({ id: 'user.country' }),
    //   renderHeader(params: any) {
    //     return (
    //       <ClickAwayListener onClickAway={(event) => {
    //         setClickedCountries(selectedCountries);
    //         const target = event.target as HTMLElement;
    //         if (
    //           activeTooltip === 'countries' &&
    //           !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
    //         ) {
    //           // setIsOpenRoles(false);
    //           setActiveTooltip(null);
    //           // setIsRoleIconClicked(false);
    //         }
    //       }}>
    //         <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //           // setIsOpenRoles((prev) => !prev)
    //           setActiveTooltip((prev: any) => (prev === 'countries' ? null : 'countries'));
    //           // if (activeTooltip !== 'roles') {
    //           //   setIsRoleIconClicked(false);
    //           // }
    //           // e.stopPropagation();
    //         }}>
    //           <Message id="user.country" className="Field-input labels font-weight-400" />
    //           <HtmlTooltip
    //             open={activeTooltip === 'countries'}
    //             // onClose={() => setIsOpenRoles(false)}
    //             disableHoverListener
    //             disableFocusListener
    //             title={
    //               <React.Fragment>
    //                 <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
    //                   <Grid item>
    //                     <TextField
    //                       value={search}
    //                       fullWidth
    //                       InputProps={{
    //                         startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
    //                         style: {
    //                           borderRadius: '35px',
    //                           fontFamily: 'DM Sans',
    //                           height: '37px',
    //                           backgroundColor: '#F7F9FF',
    //                           border: 0,
    //                           borderColor: '#E6E6E8',
    //                           fontSize: '12px',
    //                           marginBottom: '10px'
    //                         },
    //                       }}
    //                       sx={{
    //                         '& .MuiInputBase-input::placeholder': {
    //                           fontWeight: 'bold',
    //                         },
    //                       }}
    //                       placeholder={intl.formatMessage({ id: 'text.search' })}
    //                       onChange={(e: any) => handleSearch(e, 'countries')}
    //                     /></Grid>
    //                   <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
    //                     className='font-14 black normalFontWeight' padding={1}
    //                   >
    //                     {filteredCountries?.map((val: any, index: number) => (
    //                       <Grid item container alignItems='center' key={val} columnGap={1}
    //                         // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
    //                         justifyContent='flex-start'
    //                         style={{
    //                           flexWrap: 'nowrap',
    //                           width: '100%',
    //                           fontSize: '14px',
    //                           marginBottom: '18px'
    //                         }}
    //                         // onMouseOver={(e) => {
    //                         //   const target = e.target as HTMLElement;
    //                         //   if (!clickedRoles?.includes(val.id)) {
    //                         //     target.style.cursor = 'pointer';
    //                         //     setFilterIndex(index);
    //                         //   }
    //                         // }}
    //                         // onMouseOut={() => {
    //                         //   setFilterIndex(-1);
    //                         // }}
    //                         onClick={(e) => {
    //                           setClickedCountries((prevSelected: any) => {
    //                             if (prevSelected.includes(val)) {
    //                               return prevSelected.filter((v: any) => v !== val);
    //                             }
    //                             else {
    //                               return [...prevSelected, val];
    //                             }
    //                           })
    //                         }}
    //                       >
    //                         <Grid className='bordered-box'
    //                           style={{ borderColor: clickedCountries?.includes(val) ? '#fff' : '#E6E6E8' }}
    //                           bgcolor={clickedCountries?.includes(val) ? '#CCF0EB' : '#fff'}>
    //                           {
    //                             clickedCountries?.includes(val) ?
    //                               // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

    //                               <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
    //                               :
    //                               <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
    //                           }
    //                         </Grid>
    //                         {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
    //                         {/* <Grid item>{val.name}</Grid> */}
    //                         <Grid item>
    //                           {val}
    //                         </Grid>
    //                       </Grid>
    //                     ))}

    //                   </Grid>
    //                   <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedCountries.length > 0 ? 'pointer' : 'unset' }}
    //                     onClick={() => setClickedCountries([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
    //                   </Grid>
    //                   <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
    //                     <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //                       e.stopPropagation();
    //                       // setClickedRoles([]);
    //                       // setIsOpenRoles(false);
    //                       setActiveTooltip(null);
    //                       // setIsRoleIconClicked(false);
    //                       setClickedCountries(selectedCountries);
    //                     }}>
    //                       <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
    //                     </button>
    //                     <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //                       setOldRows([]);
    //                       setNoMoreRows(false);
    //                       e.stopPropagation();
    //                       setSelectedCountries(clickedCountries)
    //                       // setIsOpenRoles(false);
    //                       setActiveTooltip(null);
    //                       // setIsRoleIconClicked(false);
    //                     }}>
    //                       <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
    //                     </button>

    //                   </Grid>
    //                 </Grid>
    //               </React.Fragment>
    //             }
    //           >
    //             <Grid item style={{ display: 'flex', alignItems: 'center' }}>
    //               {/* <ArrowDropDownIcon
    //     style={{
    //       transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
    //       transition: 'transform 0.3s ease', 
    //     }}
    //   />  */}
    //               <img src={selectedCountries.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
    //                 e.stopPropagation();
    //                 // setIsRoleIconClicked(true);
    //                 setClickedCountries(selectedCountries);
    //                 // setIsOpenRoles((prev) => !prev)
    //                 setActiveTooltip((prev: any) => (prev === 'countries' ? null : 'countries'));
    //               }} />
    //             </Grid>
    //           </HtmlTooltip>
    //         </Grid >
    //       </ClickAwayListener>
    //     )
    //   },
    //   width: 200, sortable: false,
    // },
    // { field: 'position', headerName: 'Position', width: 200 },
    // {
    //   field: 'creationDate', headerName: intl.formatMessage({ id: 'user.creation-date' }),
    //   renderHeader(params: any) {
    //     return (
    //       <ClickAwayListener onClickAway={(event) => {
    //         setClickedCountries(selectedCountries);
    //         const target = event.target as HTMLElement;
    //         if (
    //           activeTooltip === 'creationDate' &&
    //           !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
    //         ) {
    //           // setIsOpenRoles(false);
    //           setActiveTooltip(null);
    //           // setIsRoleIconClicked(false);
    //         }
    //       }}>
    //         <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    //           // setIsOpenRoles((prev) => !prev)
    //           setActiveTooltip((prev: any) => (prev === 'creationDate' ? null : 'creationDate'));
    //           // if (activeTooltip !== 'roles') {
    //           //   setIsRoleIconClicked(false);
    //           // }
    //           // e.stopPropagation();
    //         }}>
    //           <Message id="user.creation-date" className="Field-input labels font-weight-400" />
    //           <HtmlTooltip
    //             open={activeTooltip === 'creationDate'}
    //             // onClose={() => setIsOpenRoles(false)}
    //             disableHoverListener
    //             disableFocusListener
    //             title={
    //               <React.Fragment>
    //                 <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
    //                   <Grid item>
    //                     <TextField
    //                       value={search}
    //                       fullWidth
    //                       InputProps={{
    //                         startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
    //                         style: {
    //                           borderRadius: '35px',
    //                           fontFamily: 'DM Sans',
    //                           height: '37px',
    //                           backgroundColor: '#F7F9FF',
    //                           border: 0,
    //                           borderColor: '#E6E6E8',
    //                           fontSize: '12px',
    //                           marginBottom: '10px'
    //                         },
    //                       }}
    //                       sx={{
    //                         '& .MuiInputBase-input::placeholder': {
    //                           fontWeight: 'bold',
    //                         },
    //                       }}
    //                       placeholder={intl.formatMessage({ id: 'text.search' })}
    //                       onChange={(e: any) => handleSearch(e, 'creationDate')}
    //                     /></Grid>
    //                   <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
    //                     className='font-14 black normalFontWeight' padding={1}
    //                   >
    //                     {filteredCreationDates?.map((val: any, index: number) => (
    //                       <Grid item container alignItems='center' key={val} columnGap={1}
    //                         // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
    //                         justifyContent='flex-start'
    //                         style={{
    //                           flexWrap: 'nowrap',
    //                           width: '100%',
    //                           fontSize: '14px',
    //                           marginBottom: '18px'
    //                         }}
    //                         // onMouseOver={(e) => {
    //                         //   const target = e.target as HTMLElement;
    //                         //   if (!clickedRoles?.includes(val.id)) {
    //                         //     target.style.cursor = 'pointer';
    //                         //     setFilterIndex(index);
    //                         //   }
    //                         // }}
    //                         // onMouseOut={() => {
    //                         //   setFilterIndex(-1);
    //                         // }}
    //                         onClick={(e) => {
    //                           setClickedCreationDates((prevSelected: any) => {
    //                             if (prevSelected.includes(val)) {
    //                               return prevSelected.filter((v: any) => v !== val);
    //                             }
    //                             else {
    //                               return [...prevSelected, val];
    //                             }
    //                           })
    //                         }}
    //                       >
    //                         <Grid className='bordered-box'
    //                           style={{ borderColor: clickedCreationDates?.includes(val) ? '#fff' : '#E6E6E8' }}
    //                           bgcolor={clickedCreationDates?.includes(val) ? '#CCF0EB' : '#fff'}>
    //                           {
    //                             clickedCreationDates?.includes(val) ?
    //                               // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

    //                               <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
    //                               :
    //                               <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
    //                           }
    //                         </Grid>
    //                         {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
    //                         {/* <Grid item>{val.name}</Grid> */}
    //                         <Grid item>
    //                           {val}
    //                         </Grid>
    //                       </Grid>
    //                     ))}

    //                   </Grid>
    //                   <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedCreationDates.length > 0 ? 'pointer' : 'unset' }}
    //                     onClick={() => setClickedCreationDates([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
    //                   </Grid>
    //                   <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
    //                     <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //                       e.stopPropagation();
    //                       // setClickedRoles([]);
    //                       // setIsOpenRoles(false);
    //                       setActiveTooltip(null);
    //                       // setIsRoleIconClicked(false);
    //                       setClickedCreationDates(selectedCreationDates);
    //                     }}>
    //                       <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
    //                     </button>
    //                     <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //                       setOldRows([]);
    //                       setNoMoreRows(false);
    //                       e.stopPropagation();
    //                       setSelectedCreationDates(clickedCreationDates)
    //                       // setIsOpenRoles(false);
    //                       setActiveTooltip(null);
    //                       // setIsRoleIconClicked(false);
    //                     }}>
    //                       <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
    //                     </button>

    //                   </Grid>
    //                 </Grid>
    //               </React.Fragment>
    //             }
    //           >
    //             <Grid item style={{ display: 'flex', alignItems: 'center' }}>
    //               {/* <ArrowDropDownIcon
    //     style={{
    //       transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
    //       transition: 'transform 0.3s ease', 
    //     }}
    //   />  */}
    //               <img src={selectedCreationDates.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
    //                 e.stopPropagation();
    //                 // setIsRoleIconClicked(true);
    //                 setClickedCreationDates(selectedCreationDates);
    //                 // setIsOpenRoles((prev) => !prev)
    //                 setActiveTooltip((prev: any) => (prev === 'creationDate' ? null : 'creationDate'));
    //               }} />
    //             </Grid>
    //           </HtmlTooltip>
    //         </Grid >
    //       </ClickAwayListener>
    //     )
    //   },
    //   width: 200, sortable: false,
    //   renderCell: (params) => {
    //     if (params.row.id !== 'Empty') {
    //       return (
    //         <span>{moment(params.row.creationDate).format('DD.MM.YYYY')}</span>
    //       )
    //     }
    //     return null;
    //   }
    // },
  ];

  const updateClientRoleCall = () => {
    dispatch(setLoading(true));
    updateTMRole({
      userId: selectedUser.userId,
      roles: selectedUser.roles,
    }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenUpdateRoleDialog(false);
          dispatch(setModify(!modify));
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const checkNeedToAddRows = () => {
    const gridContainer = gridRef.current;
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
      const totalRowsHeight = rowHeight * rows.length;

      if (totalRowsHeight <= virtualScroller.clientHeight) {
        console.log("Vertical scrollbar is hidden (no scroll is needed).");
        return true
      } else {
        console.log("Vertical scrollbar is visible (scrolling is possible).");
        return false
      }
    }
  }

  const deleteClientCall = () => {
    dispatch(setLoading(true));
    deleteClient(selectedUserToDelete.userId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setRows(rows.filter((r: any) => r.id !== selectedUserToDelete.userId));
        setOriginalClientsTable(rows.filter((r: any) => r.id !== selectedUserToDelete.userId))
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenDeleteDialog(false);
          //dispatch(setModify(!modify));
          if (checkNeedToAddRows()) {
            setLoadMoreRows(true);
            setOldRows(rows.filter((r: any) => r.id !== selectedUserToDelete.userId));
            setTake(3)
          }
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const handlePaginationModelChange = (model: any) => {
    // const adjustedPage = Math.max(1, model.page);
    // // Catch the new page number
    // setPageNb(adjustedPage);
    // setPageSize(model.pageSize);
    // Update the pagination model
    setPaginationModel(model);
  };

  // const updateClientModuleCall = () => {
  //   dispatch(setLoading(true));
  //   updateClientModule(selectedClient.id).then((x) => {
  //     if (x.ErrorMessage) {
  //       dispatch(setErrorMsg(x.ErrorMessage));
  //       dispatch(setCallStatus("Fail"));
  //       setTimeout(() => {
  //         dispatch(setErrorMsg(""));
  //         dispatch(setCallStatus(""));
  //       }, 4500);
  //     } else {
  //       dispatch(setCallStatus("Pass"));
  //       setTimeout(() => {
  //         dispatch(setErrorMsg(""));
  //         dispatch(setCallStatus(""));
  //         setOpenDeleteDialog(false);
  //         dispatch(setModify(!modify));
  //       }, 1000);
  //     }
  //     dispatch(setLoading(false));
  //   });
  // };

  const getClientDataCall = (clientId: string) => {
    setUserDataLoading(true);
    getClientData(clientId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setSelectedClient(x);
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 1000);
      }
      setUserDataLoading(false);
    });
  };

  // const gridRef = useRef<any>(null);

  const prevScrollLeft = useRef<number>(0); // Track the previous horizontal scroll position
  const prevScrollTop = useRef<number>(0);

  // useEffect(() => {
  //   console.log("rows check", rows)
  //   const handleScroll = (event: Event) => {
  //     const target = event.target as HTMLElement;

  //     // Check if we're inside the DataGrid's virtual scroller content
  //     if (target && target.classList.contains("MuiDataGrid-virtualScroller")) {
  //       const { scrollTop, scrollHeight, clientHeight, scrollLeft } = target;

  //       const isVerticalScroll = Math.abs(scrollTop - prevScrollTop.current) > Math.abs(scrollLeft - prevScrollLeft.current);
  //       if (notLoad && prevScrollLeft.current !== scrollLeft) setNotLoad(false)

  //       // Log the scroll position and sizes
  //       //console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
  //       // if (scrollLeft === 0 || scrollLeft === scrollWidth - clientHeight) {
  //       // Determine if the user has reached the bottom
  //       if (isVerticalScroll && !notLoad) {
  //         if (scrollTop + clientHeight >= scrollHeight - 1) {
  //           // console.log("Reached bottom of the scroll!");
  //           setTake(10)
  //           setLoadMoreRows(true);
  //           setOldRows(rows);
  //           console.log("oldd rowsss", rows)
  //         } else {
  //           //console.log("Not at the bottom yet");
  //         }
  //         prevScrollTop.current = scrollTop;

  //       }
  //       prevScrollLeft.current = scrollLeft;

  //       // }
  //     }
  //   };

  //   const gridContainer = gridRef.current;
  //   if (gridContainer) {
  //     // Get the virtual scroller container from the DataGrid
  //     const virtualScroller = gridContainer.querySelector(
  //       ".MuiDataGrid-virtualScroller"
  //     );

  //     if (virtualScroller) {
  //       // Attach the scroll event listener
  //       //console.log("Found virtual scroller container");
  //       virtualScroller.addEventListener("scroll", handleScroll);
  //     } else {
  //       //console.log("MuiDataGrid-virtualScroller not found");
  //     }
  //   } else {
  //     //console.log("Grid container not found");
  //   }

  //   return () => {
  //     const gridContainer = gridRef.current;
  //     if (gridContainer) {
  //       const virtualScroller = gridContainer.querySelector(
  //         ".MuiDataGrid-virtualScroller"
  //       );
  //       if (virtualScroller) {
  //         // Remove the scroll event listener when the component unmounts
  //         virtualScroller.removeEventListener("scroll", handleScroll);
  //       }
  //     }
  //   };
  // });

  const checkNeedToAddEmptyRow = () => {
    const gridContainer = gridRef.current;
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
      const totalRowsHeight = rowHeight * (rows.length);

      if (totalRowsHeight >= virtualScroller.clientHeight) {
        return true
      } else {
        return false
      }
    }
  }

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 320);

  const handleResize = () => {
    const screenHeight = window.innerHeight;
    setTableHeight(screenHeight - 320);
    console.log("screenHeight", screenHeight)

    if (screenHeight >= 850) {
      console.log("rows", rows)
      setTake(10)
      setLoadMoreRows(true)
    }

  };

  // useEffect(() => {
  //   // Add resize event listener
  //   window.addEventListener("resize", handleResize);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);


  // useEffect(() => {
  //   const gridContainer = gridRef.current;
  //   if (gridContainer) {
  //     // Get the virtual scroller container from the DataGrid
  //     const virtualScroller = gridContainer.querySelector(
  //       ".MuiDataGrid-virtualScroller"
  //     );

  //     const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
  //     const totalRowsHeight = rowHeight * rows.length;

  //     if (totalRowsHeight <= virtualScroller.clientHeight) {
  //       console.log("Vertical scrollbar is hidden (no scroll is needed).");
  //       setNoVerticalScroll(true);

  //       const emptyExists = rows.find((r: any) => r.id === 'Empty') ? true : false
  //       if (!emptyExists && checkNeedToAddEmptyRow()) {
  //         console.log("rows", rows)
  //         let newUserbj = {
  //           id: "Empty",
  //           email: null,
  //           firstName: null,
  //           lastName: null,
  //           phoneNumber: null,
  //           position: null,
  //           creationDate: null,
  //           statusId: 0,
  //           roles: null,
  //           lastActiveDate: null,
  //           taskCount: 0

  //         }
  //         let tmp = [...rows];
  //         tmp.splice(rows?.length, 0, newUserbj);
  //         setRows(tmp);

  //       }

  //       // if(!rows.find((r:any)=> r.id === ' Empty')){


  //       // let newUserbj = {
  //       //   id: "Empty",
  //       //   email: null,
  //       //   firstName: null,
  //       //   lastName: null,
  //       //   phoneNumber: null,
  //       //   position: null,
  //       //   creationDate: null,
  //       //   statusId: 3,
  //       //   roles: null,
  //       //   lastActiveDate: null,
  //       //   taskCount: 0

  //       // }
  //       //   let tmp = [...rows];
  //       //   tmp.splice(rows?.length, 0, newUserbj);
  //       //   setRows(tmp);

  //       // }


  //     } else {
  //       console.log("Vertical scrollbar is visible (scrolling is possible).");
  //       setNoVerticalScroll(false)

  //     }
  //   }

  // }, [rows])
  const data: any[] = rows;
  const columns: MRT_ColumnDef<any>[] = [
    {

      id: 'actions',
      header: '',
      size: 50, // control width
      enableResizing: false,
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const roles = row.original.roles;
        const hasRoles = roles && Array.isArray(roles) && roles.length > 0;
        const mappedRoles = hasRoles ? roles?.map((r: any) => r.name)?.join(',') : '';

        return (
          <HtmlTooltip
            placement='bottom-start'
            title={
              <React.Fragment>
                <Grid item container rowGap={1.5} padding={1.5} maxWidth='260px' className="Field-input labelsLinks font-weight-400 font-14">

                  <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                    onClick={() => {
                      //setOldRows([])
                      setSelectedClient(row);
                      setOpenConfigureAccessDialog(true)
                      getClientDataCall(row.original.id);
                    }}
                  >
                    <img src={accessIcon} /> <Message id="text.edit-company-module" className="Field-input labelsLinks font-weight-400" />
                  </Grid>

                  <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                    onClick={() => {
                      //setOldRows([])
                      setSelectedUserToDelete(() => ({
                        userId: row.original.id,
                        email: row.getValue('email'),
                        roles: mappedRoles,
                      }));
                      setOpenDeleteDialog(true);
                    }}
                  >
                    {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /> */}
                    <img src={deleteIcon} width='20px' height='20px' />
                    <Message id="ta.delete" className="Field-input labelsLinks font-weight-400" />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          >
            <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
          </HtmlTooltip>
        )
      }


    },
    {
      accessorKey: 'name', //access nested data with dot notation
      header: 'Username',
      minSize: 170,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedUserNames(selectedUserNames);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'username' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenRoles(false);
            setActiveTooltip(null);
            // setIsRoleIconClicked(false);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenRoles((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'username' ? null : 'username'));
            // if (activeTooltip !== 'roles') {
            //   setIsRoleIconClicked(false);
            // }
            // e.stopPropagation();
          }}>
            <Message id="user.profile.user-name" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'username'}
              // onClose={() => setIsOpenRoles(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'username')}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                      className='font-14 black normalFontWeight' padding={1}
                    >
                      {filteredUserNames?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val} columnGap={1}
                          // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if (!clickedRoles?.includes(val.id)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          // onMouseOut={() => {
                          //   setFilterIndex(-1);
                          // }}
                          onClick={(e) => {
                            setClickedUserNames((prevSelected: any) => {
                              if (prevSelected.includes(val)) {
                                return prevSelected.filter((v: any) => v !== val);
                              }
                              else {
                                return [...prevSelected, val];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedUserNames?.includes(val) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedUserNames?.includes(val) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedUserNames?.includes(val) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                          {/* <Grid item>{val.name}</Grid> */}
                          <Grid item>
                            {val}
                          </Grid>
                        </Grid>
                      ))}

                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedUserNames.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedUserNames([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedRoles([]);
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        // setIsRoleIconClicked(false);
                        setClickedUserNames(selectedUserNames);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedUserNames(clickedUserNames)
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        // setIsRoleIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ArrowDropDownIcon
        style={{
          transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
          transition: 'transform 0.3s ease', 
        }}
      />  */}
                <img src={selectedUserNames.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  // setIsRoleIconClicked(true);
                  setClickedUserNames(selectedUserNames);
                  // setIsOpenRoles((prev) => !prev)
                  setActiveTooltip((prev: any) => (prev === 'username' ? null : 'username'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      Cell: ({ cell }) => (
        <Grid item>
          {cell.getValue<string>()}
        </Grid>
      ),
    },
    {
      accessorKey: 'email', //access nested data with dot notation
      header: 'Email',
      minSize: 240,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedUserNames(selectedUserNames);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'email' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenRoles(false);
            setActiveTooltip(null);
            // setIsRoleIconClicked(false);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenRoles((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
            // if (activeTooltip !== 'roles') {
            //   setIsRoleIconClicked(false);
            // }
            // e.stopPropagation();
          }}>
            <Message id="user.profile.email" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'email'}
              // onClose={() => setIsOpenRoles(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'email')}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                      className='font-14 black normalFontWeight' padding={1}
                    >
                      {filteredEmails?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val} columnGap={1}
                          // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if (!clickedRoles?.includes(val.id)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          // onMouseOut={() => {
                          //   setFilterIndex(-1);
                          // }}
                          onClick={(e) => {
                            setClickedEmails((prevSelected: any) => {
                              if (prevSelected.includes(val)) {
                                return prevSelected.filter((v: any) => v !== val);
                              }
                              else {
                                return [...prevSelected, val];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedEmails?.includes(val) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedEmails?.includes(val) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedEmails?.includes(val) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                          {/* <Grid item>{val.name}</Grid> */}
                          <Grid item>
                            {val}
                          </Grid>
                        </Grid>
                      ))}

                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedEmails.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedEmails([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedRoles([]);
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        // setIsRoleIconClicked(false);
                        setClickedEmails(selectedEmails);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedEmails(clickedEmails)
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        // setIsRoleIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ArrowDropDownIcon
          style={{
            transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease', 
          }}
        />  */}
                <img src={selectedEmails.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  // setIsRoleIconClicked(true);
                  setClickedEmails(selectedEmails);
                  // setIsOpenRoles((prev) => !prev)
                  setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      )
    },
    {
      accessorKey: 'roles', //access nested data with dot notation
      header: 'Roles',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedRoles(selectedRoles);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'roles' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenRoles(false);
            setActiveTooltip(null);
            setIsRoleIconClicked(false);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal'
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenRoles((prev:any)=>!prev)
              setActiveTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
              if (activeTooltip !== 'roles') {
                setIsRoleIconClicked(false);
              }
              // e.stopPropagation();
            }}>
            <Message id="user.user-role" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'roles'}
              // onClose={()=>setIsOpenRoles(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' width='307px' padding={2} display='inline-grid' style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'roles')}
                      /></Grid>

                    {filteredRoles?.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val.id} columnGap={2}
                        //  className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                        className='font-14 black normalFontWeight'
                        justifyContent='flex-start'
                        style={{
                          flexWrap: 'nowrap',
                          width: '100%',
                          fontSize: '14px',
                          marginBottom: '18px'
                        }}
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (!clickedRoles?.includes(val.id)) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}
                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        onClick={(e) => {
                          // if (!clickedRoles?.includes(val.id)) {
                          //   setClickedRoles((prevTenants: any) => [...prevTenants, val.id]);
                          // }
                          setClickedRoles((prevSelectedRoles: any) => {
                            if (prevSelectedRoles.includes(val.id)) {
                              return prevSelectedRoles.filter((id: any) => id !== val.id);
                            }
                            else {
                              return [...prevSelectedRoles, val.id];
                            }
                          })
                        }}
                      >
                        <Grid className='bordered-box'
                          style={{ borderColor: clickedRoles?.includes(val.id) ? '#fff' : '#E6E6E8' }}
                          bgcolor={clickedRoles?.includes(val.id) ? '#CCF0EB' : '#fff'}>
                          {
                            clickedRoles?.includes(val.id) ?
                              // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />
                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                              <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                          }
                        </Grid>
                        {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                        {/* <Grid item>{val.name}</Grid> */}
                        <Grid item>
                          {val.id === userRolesConst.CLIENT_ADMIN_ID ? <Message id="filter.client-admin" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_AUHTORIZED_SIGNER_ID ? <Message id="filter.client-authorized-signer" className="Field-input labels font-weight-400" /> : val.id === userRolesConst.CLIENT_EMPLOYEE_ID ? <Message id="filter.client-employee" className="Field-input labels font-weight-400" /> : ''}
                        </Grid>
                      </Grid>
                    ))}
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedRoles.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedRoles([])} ><RestartAltIcon fontSize='small' />  <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>

                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedRoles([]);
                        //  setIsOpenRoles(false);
                        setClickedRoles(selectedRoles);
                        setActiveTooltip(null);
                        setIsRoleIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedRoles(clickedRoles)
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        setIsRoleIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ArrowDropDownIcon
            style={{
              transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          />  */}
                <img src={selectedRoles.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setIsRoleIconClicked(true);
                  setClickedRoles(selectedRoles);
                  setActiveTooltip((prev: any) => (prev === 'roles' ? null : 'roles'));
                }} />
                {/* {selectedRoles.length > 0 ? <img src={selectedFilter} alt='icon' /> : <></>} */}
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      Cell: ({ cell }) => (
        <Grid item>
          {(cell.row.getValue('roles') as any[])?.map((r) => r.name).join(', ') || ''}
        </Grid>
      ),
    },
    {
      accessorKey: 'lastActiveDate', //access nested data with dot notation
      header: 'Last Active',
      minSize: 200,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          const target = event.target as HTMLElement;
          if (activeTooltip === 'lastActive' && !target.closest('.table-header')) {
            setDateRange([{
              startDate: selectedLastActiveDates.length > 0 ? selectedLastActiveDates[0] : null,
              endDate: selectedLastActiveDates.length > 0 ? selectedLastActiveDates[1] : null,
              key: 'selection'
            }])
            setActiveTooltip(null);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenRoles((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'lastActive' ? null : 'lastActive'));
            // if (activeTooltip !== 'roles') {
            //   setIsRoleIconClicked(false);
            // }
            // e.stopPropagation();
          }}>
            <Message id="user.last-active" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'lastActive'}
              // onClose={() => setIsOpenRoles(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} padding='8px 10px 8px 8px' display='inline-grid' width='307px'>
                    <DateRange
                      style={{ maxWidth: '307px' }}
                      editableDateInputs={false}
                      showDateDisplay={false}
                      onChange={(item: any) => setDateRange([{
                        startDate: item.selection.startDate || null,  
                        endDate: item.selection.endDate || null,
                        key: 'selection'
                      }])}
                      moveRangeOnFirstSelection={false}
                       ranges={[{
                          startDate: dateRange[0].startDate || new Date(),  // Prevent errors
                          endDate: dateRange[0].endDate || new Date(),
                          key: 'selection'
                        }]}
                      locale={enGB}
                      className="custom-date-range"
                      rangeColors={['#5D5FEF']}
                    />
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>

                      <button className="greyButton" style={{ width: '80px' }} onClick={(e) => {
                        e.stopPropagation();
                        setActiveTooltip(null);
                        setClickedLastActiveDates(selectedLastActiveDates);
                        setDateRange([{
                          startDate: selectedLastActiveDates.length > 0 ? selectedLastActiveDates[0] : null,
                          endDate: selectedLastActiveDates.length > 0 ? selectedLastActiveDates[1] : null,
                          key: 'selection'
                        }])
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e) => {
                        e.stopPropagation();
                        if (dateRange[0].startDate && dateRange[0].endDate) {
                          setSelectedLastActiveDates([
                            dateRange[0].startDate.toISOString(),
                            dateRange[0].endDate.toISOString()
                          ]);
                        } else {
                          setSelectedLastActiveDates([]);
                        }
                        setActiveTooltip(null);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ArrowDropDownIcon
          style={{
            transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease', 
          }}
        />  */}
                <img src={selectedLastActiveDates.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  // setIsRoleIconClicked(true);
                  setClickedLastActiveDates(selectedLastActiveDates);
                  // setIsOpenRoles((prev) => !prev)
                  setActiveTooltip((prev: any) => (prev === 'lastActive' ? null : 'lastActive'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      Cell: ({ cell }) => {
        if (cell.row.getValue('id') !== 'Empty') {
          return <span>{cell.row.getValue('lastActiveDate')?moment(cell.row.getValue('lastActiveDate')).format('DD.MM.YYYY HH:mm:ss A'):'N/A'}</span>;
        }
        return null;
      },
    },

    {
      accessorKey: 'statusId', //access nested data with dot notation
      header: 'Status',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedStatus(selectedStatuses);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'status' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenStatus(false);
            setActiveTooltip(null);
            setIsStatusIconClicked(false);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenStatus((prev:any) => !prev)
            // e.stopPropagation();
            setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
            if (activeTooltip !== 'status') {
              setIsStatusIconClicked(false);
            }
          }}>
            <Message id="user.status" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'status'}
              // onClose={() => setIsOpenStatus(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} direction='row' rowGap={0.5} width='307px' maxHeight='378px' padding={2}>
                    {statusesList.map((val: number, index: number) => (
                      <Grid item container alignItems='center' columnGap={2}
                        // className={classes[filterIndex === index || (statusId === 1 && val === 'Active') || (statusId === 2 && val === 'Inactive') || (statusId === 3 && val === 'Invited') ? 'filter-selected' : 'filter-not-selected']}
                        justifyContent='flex-start'
                        className='font-14 black normalFontWeight'
                        style={{
                          flexWrap: 'nowrap',
                          width: '100%',
                          fontSize: '14px',
                          marginBottom: '18px'
                        }}
                        // onMouseOver={(e) => {
                        //   const target = e.target as HTMLElement;
                        //   if ((clickedStatus !== 1 && val === 1) || (clickedStatus !== 2 && val === 2) || (clickedStatus !== 3 && val === 3)) {
                        //     target.style.cursor = 'pointer';
                        //     setFilterIndex(index);
                        //   }
                        // }}
                        onMouseOver={(e) => {
                          const target = e.target as HTMLElement;
                          if (
                            !(clickedStatus.includes(1) && val === 1) &&
                            !(clickedStatus.includes(2) && val === 2) &&
                            !(clickedStatus.includes(3) && val === 3)
                          ) {
                            target.style.cursor = 'pointer';
                            setFilterIndex(index);
                          }
                        }}

                        onMouseOut={() => {
                          setFilterIndex(-1);
                        }}
                        // onClick={() => {
                        //   if (val === 1) setClickedStatus(prevStatus => (prevStatus === 1 ? 0 : 1))
                        //   if (val === 2) setClickedStatus(prevStatus => (prevStatus === 2 ? 0 : 2))
                        //   if (val === 3) setClickedStatus(prevStatus => (prevStatus === 3 ? 0 : 3))

                        // }}
                        onClick={() => {
                          setClickedStatus((prevSelectedStatus: any) => {
                            if (prevSelectedStatus.includes(val)) {
                              return prevSelectedStatus.filter((status: any) => status !== val);
                            } else {
                              return [...prevSelectedStatus, val];
                            }
                          });
                        }}

                      >
                        <Grid className='bordered-box'
                          style={{
                            borderColor: ((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ? '#fff' : '#E6E6E8',
                          }}
                          bgcolor={((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ? '#CCF0EB' : '#fff'}>
                          {
                            ((clickedStatus.includes(1) && val === 1) || (clickedStatus.includes(2) && val === 2) || (clickedStatus.includes(3) && val === 3)) ?
                              // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                              <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                          }
                        </Grid>

                        {/* <Grid className='bordered-box' bgcolor={
                        filterIndex === index ||
                          (statusId === 1 && val === 'Active') ||
                          (statusId === 2 && val === 'Inactive') ? '#CCF0EB' :
                          (statusId === 3 && val === 'Invited') ? '#ff9900' :
                            '#E0E0E0'
                      }><CheckIcon
                          fontSize='small'
                          htmlColor={
                            filterIndex === index ||
                              (statusId === 1 && val === 'Active') ||
                              (statusId === 2 && val === 'Inactive') ? '#00B69B' :
                              (statusId === 3 && val === 'Invited') ? '#000000' :
                                '#9E9E9E'
                          } /></Grid> */}
                        <Grid item>
                          {
                            val === 1 ? <Message id="filter.active" className="Field-input labels font-weight-400" /> :
                              val === 2 ? <Message id="filter.inactive" className="Field-input labels font-weight-400" /> :
                                val === 3 ? <Message id="filter.invited" className="Field-input labels font-weight-400" /> : ''
                          }
                        </Grid>
                      </Grid>
                    ))}
                    {/* <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 1 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                onMouseOver={() => {
                                    if (statusId !== 1) {
                                        setFilterIconIndex('active')
                                    }
                                }}
                                onMouseOut={() => {
                                    setFilterIconIndex('')
                                }}
                            >
                                <Grid className='bordered-box' bgcolor={statusId === 1 || filterIconIndex === 'active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 1 || filterIconIndex === 'active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                <Grid item>Active</Grid>
                            </Grid>
                            <Grid item container alignItems='center' columnGap={1} className={classes[statusId === 2 ? 'filter-selected' : 'filter-not-selected']} justifyContent='flex-start'
                                onMouseOver={() => {
                                    if (statusId !== 2) {
                                        setFilterIconIndex('in-active')
                                    }
                                }}
                                onMouseOut={() => {
                                    setFilterIconIndex('')
                                }}
                            >
                                <Grid className='bordered-box' bgcolor={statusId === 2 || filterIconIndex === 'in-active' ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={statusId === 2 || filterIconIndex === 'in-active' ? '#00B69B' : '#9E9E9E'} /></Grid>
                                <Grid item>Inactive</Grid>
                            </Grid> */}
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} style={{ cursor: clickedStatus ? 'pointer' : 'unset' }} marginLeft={0.5}
                      onClick={() => {
                        setClickedStatus([]);
                      }}><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" /></Grid>
                    {/* <Grid item container width='80px' marginLeft={7}>
                                <button className='blueButton' disabled={loading}>Ok</button></Grid> */}
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedStatus([]);
                        //  setIsOpenStatus(false);
                        setClickedStatus(selectedStatuses);
                        setActiveTooltip(null);
                        setIsStatusIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedStatuses(clickedStatus)
                        // setIsOpenStatus(false);
                        setActiveTooltip(null);
                        setIsStatusIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
            style={{
              transform: isOpenStatus ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          /> */}
              <img src={selectedStatuses?.length ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setIsStatusIconClicked(true);
                setClickedStatus(selectedStatuses);
                setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
              }} />
            </HtmlTooltip >
          </Grid >
        </ClickAwayListener>
      ),
      Cell: ({ cell }) => (
        <Grid item container direction='row' style={{ marginTop: '-15px', height: '15px' }} columnGap={1.5}>
          <Grid item className={cell.getValue<number>() === statusesConsts.ACTIVE_STATUS_ID ? 'active-status' : cell.getValue<number>() === statusesConsts.INVITED_STATUS_ID ? 'invited-status' : cell.getValue<number>() === statusesConsts.IN_ACTIVE_STATUS_ID ? 'not-active-status' : ''} width='70px'>
            {cell.getValue<number>() === statusesConsts.ACTIVE_STATUS_ID ? <Message id="filter.active" className="Field-input labels font-weight-400" /> : cell.getValue<number>() === statusesConsts.IN_ACTIVE_STATUS_ID ? <Message id="filter.in-active" className="Field-input labels font-weight-400" /> : cell.getValue<number>() === statusesConsts.INVITED_STATUS_ID ? <Message id="filter.invited" className="Field-input labels font-weight-400" /> : ''}
          </Grid>
        </Grid>
      ),
    },
    {
      accessorKey: 'contactPhoneNumber', //access nested data with dot notation
      header: 'Phone Number',
      minSize: 100,
      enableResizing: false,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedUserNames(selectedUserNames);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'phoneNb' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenRoles(false);
            setActiveTooltip(null);
            // setIsRoleIconClicked(false);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenRoles((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
            // if (activeTooltip !== 'roles') {
            //   setIsRoleIconClicked(false);
            // }
            // e.stopPropagation();
          }}>
            <Message id="user.profile.phone-number" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'phoneNb'}
              // onClose={() => setIsOpenRoles(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'email')}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                      className='font-14 black normalFontWeight' padding={1}
                    >
                      {filteredPhoneNumbers?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val} columnGap={1}
                          // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if (!clickedRoles?.includes(val.id)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          // onMouseOut={() => {
                          //   setFilterIndex(-1);
                          // }}
                          onClick={(e) => {
                            setClickedPhoneNumbers((prevSelected: any) => {
                              if (prevSelected.includes(val)) {
                                return prevSelected.filter((v: any) => v !== val);
                              }
                              else {
                                return [...prevSelected, val];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedPhoneNumbers?.includes(val) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedPhoneNumbers?.includes(val) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedPhoneNumbers?.includes(val) ?
                                // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          {/* <Grid className='bordered-box' bgcolor={filterIndex === index || selectedRoles?.includes(val.id) ? '#CCF0EB' : '#E0E0E0'}><CheckIcon fontSize='small' htmlColor={filterIndex === index || selectedRoles?.includes(val.id) ? '#00B69B' : '#9E9E9E'} /></Grid> */}
                          {/* <Grid item>{val.name}</Grid> */}
                          <Grid item>
                            {val}
                          </Grid>
                        </Grid>
                      ))}

                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedPhoneNumbers.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedPhoneNumbers([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedRoles([]);
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        // setIsRoleIconClicked(false);
                        setClickedPhoneNumbers(selectedPhoneNumbers);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedPhoneNumbers(clickedPhoneNumbers)
                        // setIsOpenRoles(false);
                        setActiveTooltip(null);
                        // setIsRoleIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                {/* <ArrowDropDownIcon
        style={{
          transform: isOpenRoles ? 'rotate(180deg)' : 'rotate(0deg)', 
          transition: 'transform 0.3s ease', 
        }}
      />  */}
                <img src={selectedPhoneNumbers.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  // setIsRoleIconClicked(true);
                  setClickedPhoneNumbers(selectedPhoneNumbers);
                  // setIsOpenRoles((prev) => !prev)
                  setActiveTooltip((prev: any) => (prev === 'phoneNb' ? null : 'phoneNb'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      Cell: ({ cell }) => (
        <Grid item>
          {cell.getValue<string>()}
        </Grid>
      ),
    }
  ]

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnResizing: true,
    rowCount: 1000,
    // Disable top toolbar features
    enableTopToolbar: false,
    // Or keep toolbar but disable specific features
    enableToolbarInternalActions: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableSorting: false,
    muiTableContainerProps: {
      sx: {
        // height: '250px', // Set your desired height here
        height: tableHeight,
      }
    },
    // Optional: control what happens when content overflows
    muiTablePaperProps: {
      sx: {
        overflow: 'auto'
      }
    },
  });


  return (
    <div style={{ height: tableHeight, width: "100%" }}>
      {/* <DataGrid
        ref={gridRef}
        rows={rows}
        columns={columnss}
        // pagination
        // paginationMode='server'
        // paginationModel={paginationModel}
        // onPaginationModelChange={handlePaginationModelChange} // Handle pagination changes
        // rowCount={dataLength} // Total number of rows for server-side pagination
        // pageSizeOptions={[25, 50, 100]}
        // checkboxSelection
        disableColumnMenu
        sx={{
          border: 2,
          height: tableHeight,
          borderColor: '#FEFEFF',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          "& .MuiDataGrid-footerContainer": { display: "none" },
          '& .MuiDataGrid-overlay': {
            display: 'none',  // Hide the overlay in general
          },
          '& .MuiDataGrid-emptyOverlay': {
            display: 'none',  // Specifically hide the 'No rows' message
          },
        }}
      /> */}

      {/* {(noVerticalScroll || noMoreRows) && !dialogLoading && !initiallistLoading && !loadMoreRows && (
        <div
          style={{
            position: "absolute",
            bottom: "22px",
            width: "170px",
            padding: '10px',
            textAlign: "center",
            zIndex: 10,
            color: '#404040',
            left: '45%',
            borderRadius: '10px'

          }}
        >
          {rows.length === 0 ? 'No rows to display' : 'No more rows to load'}

        </div>
      )}

      {loadMoreRows && (
        <div
          style={{
            position: "absolute",
            bottom: "25px",
            width: "100%",
            textAlign: "center",
            zIndex: 10,
          }}
        >
          <CircularProgress style={{ color: '#5D5FEF', width: '22px', height: '22px' }} />
        </div>
      )} */}
      <MaterialReactTable table={table} />

      {(noVerticalScroll || noMoreRows) && !dialogLoading && !initiallistLoading && !loadMoreRows && (
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            width: "170px",
            padding: '10px',
            textAlign: "center",
            zIndex: 10,
            color: '#404040',
            left: '45%',
            borderRadius: '10px'

          }}
        >
          {rows.length === 0 ? 'No rows to display' : 'No more rows to load'}
        </div>
      )}

      {selectedUser ? (
        <UpdateRole
          openUpdateRoleDialog={openUpdateRoleDialog}
          setOpenUpdateRoleDialog={setOpenUpdateRoleDialog}
          user={selectedUser}
          setUser={setSelectedUser}
          roles={roles}
          confirmAction={updateClientRoleCall}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
        />
      ) : (
        <></>
      )}

      {selectedUserToDelete ? (
        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
          object={selectedUserToDelete}
          confirmAction={deleteClientCall}
          type='client'
        />
      ) : (
        <></>
      )}
      <ConfigureAccess
        openConfigureAccessDialog={openConfigureAccessDialog}
        setOpenConfigureAccessDialog={setOpenConfigureAccessDialog}
        user={selectedClient}
        setUser={setSelectedClient}
        modules={modules}
        taxAdvisorClients={taxAdvisorClients}
        setTaxAdvisorClients={setTaxAdvisorClients}
        type='Update Client'
        userDataLoading={userDataLoading}
      />



    </div>
  );
};

export default ClientsTable;