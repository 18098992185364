import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid, InputAdornment, TextField, Typography, CircularProgress } from '@mui/material';
import Message from '../../../sharedComponents/ui/Message';
import timeIcon from "../../../assets/images/icons/timeIcon.png";
import minimizeIcon from "../../../assets/images/icons/minimize.png"
import maximizeIcon from "../../../assets/images/icons/maximize.png";
import minimizeIconHovered from "../../../assets/images/icons/minimizeHover.png"
import maximizeIconHovered from "../../../assets/images/icons/maximizeHover.png";
import monthFieldIcon from "../../../assets/images/icons/monthField.png";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, getDay } from 'date-fns';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectLocale } from '../../../app/slices/localsSlice';
import SearchIcon from '@mui/icons-material/Search';
import { useIntl } from "react-intl";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { maxWidth } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import * as DatevServices from "../../../services/datev-services.proxy";
import * as TaskServices from "../../../services/task-services.proxy";
import { getDialogLoadingState, getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { getModifyValue, setModify } from '../../../app/slices/modifySlice';

const getReasonsForAbsence = DatevServices.getReasonsForAbsence;
const updateSurvey = TaskServices.updateSurvey;
const moveWorkingHoursToDatev = TaskServices.moveWorkingHoursToDatev;



export interface ComponentProps {
    openRightDrawer: any;
    setOpenRightDrawer: any;
    taskDetails: any;
    employeesList: any;
    setEmployeesList: any;
    groupedDatevRecords: any;
}

interface DayInfo {
    day: number;
    dayName: {
        en: string;
        de: string;
    };
    date: string;
}
interface MonthSchedule {
    month: number;
    year: number;
    monthLabel: string;
    days: DayInfo[];
}

interface Employee {
    id: number;
    name: string;
    schedule: MonthSchedule[];
}

interface HoursObject {
    employeeId: string,
    date: string | null,
    id: string;
    // reasonId: string | null;
    // hours: number | null;
    reasonForAbsence: string | null;
    hours: number | null;
    isDatev: boolean
}


const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: '0px 2px 4px 0px #0000001F',
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '10px',
        maxWidth: 362
    },
}));

const TimeManagement = (props: ComponentProps) => {
    const { openRightDrawer, setOpenRightDrawer, taskDetails, employeesList, setEmployeesList, groupedDatevRecords } = props;

    const [hoverIcon, setHoverIcon] = useState(false);
    const local = useAppSelector(selectLocale);
    const modify = useAppSelector(getModifyValue);

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);

    const dispatch = useAppDispatch();
    const errorMsg = useAppSelector(getErrorMsg);
    const loading = useAppSelector(getLoadingState);
    const callStatus = useAppSelector(getCallStatus);
    const actionCallFrom = useAppSelector(getActionCallFrom);

    const workingReasonsIds = ["1", "2", "3", "4", "5", "AA", "AE", "LA", "ME", "SO"];
    const unPaidIds = ["AF", "AM", "AS", "QB", "QK", "QT", "EÜ", "ER", "EZ", "KA", "FS", "FN", "FU", "QC", "QU", "QN", "GR", "EK", "KG", "U6", "KK", "KV", "KW", "MK", "OS", "KS", "KT",
        "VK", "KE", "K6", "KO", "WK", "VS", "KP", "MS", "PK", "PO", "PS", "PU", "PZ", "QA", "RA", "UE", "UU", "UA", "UF", "VL", "VG", "PB", "WE", "ZD"
    ]

    const MONTHS = [
        { value: 0, labelEn: 'Jan', labelGr: 'Jan' },
        { value: 1, labelEn: 'Feb', labelGr: 'Feb' },
        { value: 2, labelEn: 'Mar', labelGr: 'Mär' },
        { value: 3, labelEn: 'Apr', labelGr: 'Apr' },
        { value: 4, labelEn: 'May', labelGr: 'Mai' },
        { value: 5, labelEn: 'June', labelGr: 'Juni' },
        { value: 6, labelEn: 'July', labelGr: 'Juli' },
        { value: 7, labelEn: 'Aug', labelGr: 'Aug' },
        { value: 8, labelEn: 'Sept', labelGr: 'Sept' },
        { value: 9, labelEn: 'Oct', labelGr: 'Okt' },
        { value: 10, labelEn: 'Nov', labelGr: 'Nov' },
        { value: 11, labelEn: 'Dec', labelGr: 'Dez' }
    ];

    // Updated day names with both English and German
    const DAY_NAMES = [
        { en: 'Sunday', gr: 'Sonntag' },
        { en: 'Monday', gr: 'Montag' },
        { en: 'Tuesday', gr: 'Dienstag' },
        { en: 'Wednesday', gr: 'Mittwoch' },
        { en: 'Thursday', gr: 'Donnerstag' },
        { en: 'Friday', gr: 'Freitag' },
        { en: 'Saturday', gr: 'Samstag' }
    ];

    // Get current date information
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // State for selected month, year and days in month
    const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [daysInMonth, setDaysInMonth] = useState<any>([]);

    console.log("currentDate",currentDate)
    console.log("currentMonthIndex",currentMonthIndex)
    console.log("currentYear",currentYear)



    // Check if a date is in the past
    const isDateInPast = (year: any, month: any) => {
        if (year < currentYear) return true;
        if (year === currentYear && month < currentMonthIndex) return true;
        return false;
    };

    const isWeekend = (dateString: string) => {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
        const dayOfWeek = date.getDay();

        // Check if it's Saturday (6) or Sunday (0)
        return dayOfWeek === 0 || dayOfWeek === 6;
    }

    // Employees state

    const scrollableDivRef = useRef<HTMLDivElement>(null);
    const syncedDivRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        if (scrollableDivRef.current && syncedDivRef.current) {
            syncedDivRef.current.scrollTop = scrollableDivRef.current.scrollTop;
        }
    };

    const [reasonSearch, setReasonSearch] = useState<string | null>();
    const [openReasonsTooltip, setOpenReasonsTooltip] = useState<boolean>(false);
    const [hourId, setHourId] = useState(null);
    const [reasonsList, setReasonsList] = useState<any>(
        // [
        //     {
        //         "id": "FS",
        //         "name": "Fehlstunden unbez. (k. vollen Tage)"
        //     },
        //     {
        //         "id": "FU",
        //         "name": "Fehltag unentschuldigt"
        //     },
        //     {
        //         "id": "GR",
        //         "name": "Grund- bzw. freiwilliger Wehrdienst"
        //     },
        //     {
        //         "id": "K",
        //         "name": "Krankheit (Lohnfortzahlung)"
        //     },
        //     {
        //         "id": "K6",
        //         "name": "Krankheit mit Kranken(tage)geldanspruch"
        //     },
        //     {
        //         "id": "FK",
        //         "name": "Feiertag im Kug-Zeitraum (SV durch AG)"
        //     },
        //     {
        //         "id": "KB",
        //         "name": "Krankheit Betriebsunfall (LFZ)"
        //     },
        //     {
        //         "id": "KE",
        //         "name": "Krankengeldende (nach 78 Wochen)"
        //     },
        //     {
        //         "id": "FE",
        //         "name": "Fehlt entschuldigt (bezahlt)"
        //     },
        //     {
        //         "id": "F",
        //         "name": "Feiertag"
        //     },
        //     {
        //         "id": "ER",
        //         "name": "Einstellung Krankengeld wegen voller Erwerbsminderungsrente"
        //     },
        //     {
        //         "id": "KO",
        //         "name": "Krankheit ohne Kranken(tage)geldanspruch"
        //     },
        //     {
        //         "id": "EP",
        //         "name": "Elternzeit, privat versichert"
        //     },
        //     {
        //         "id": "KS",
        //         "name": "Krankengeld bei witterungsbed. Ausf. (Gerüstbau-Stunden)"
        //     },
        //     {
        //         "id": "KT",
        //         "name": "Krankengeld bei witterungsbed. Ausf. (Gerüstbau-Tage)"
        //     },
        //     {
        //         "id": "KU",
        //         "name": "Kurzarbeit (Kug)"
        //     },
        //     {
        //         "id": "KV",
        //         "name": "Krank während Überbrückungsgeld bei witterungsbed. Ausfall (Gerüstbau)"
        //     },
        //     {
        //         "id": "KW",
        //         "name": "Krank während witterungsbedingtem Ausfall"
        //     },
        //     {
        //         "id": "LA",
        //         "name": "Lohnausgleich"
        //     },
        //     {
        //         "id": "ME",
        //         "name": "Mehrarbeit"
        //     },
        //     {
        //         "id": "EK",
        //         "name": "Krank ab Eintrittsdatum ohne Lohnfortzahlung"
        //     },
        //     {
        //         "id": "AM",
        //         "name": "Ausbildung zum Meister"
        //     },
        //     {
        //         "id": "MS",
        //         "name": "Mutterschutzfrist"
        //     },
        //     {
        //         "id": "AE",
        //         "name": "Arbeitszeitkonto Entnahme"
        //     },
        //     {
        //         "id": "AA",
        //         "name": "Arbeitszeitkonto Aufbau"
        //     },
        //     {
        //         "id": "KA",
        //         "name": "Ende Bezug Krankengeld/Beginn Bezug Arbeitslosengeld"
        //     },
        //     {
        //         "id": "PK",
        //         "name": "Pflege krankes Kind mit Kranken(tage)geld"
        //     },
        //     {
        //         "id": "PO",
        //         "name": "Pflege krankes Kind ohne Kranken(tage)geld"
        //     },
        //     {
        //         "id": "PS",
        //         "name": "Pflegestunden krankes Kind unbez. (k. vollen Tage)"
        //     },
        //     {
        //         "id": "PU",
        //         "name": "Pflegeunterstützungsgeld"
        //     },
        //     {
        //         "id": "PZ",
        //         "name": "Pflegezeit"
        //     },
        //     {
        //         "id": "QA",
        //         "name": "Quarantäne mit Entschädigungszahlung"
        //     },
        //     {
        //         "id": "QB",
        //         "name": "Beaufsichtigung Kind (Stunden) wegen Schulschließung (§56. Abs. 1a IfSG)"
        //     },
        //     {
        //         "id": "QC",
        //         "name": "Freiwillige Einstellung der beruflichen Tätigkeit (unbez. Freistellung)"
        //     },
        //     {
        //         "id": "QD",
        //         "name": "Freiwillige Einstellung der beruflichen Tätigkeit (bez. Freistellung)"
        //     },
        //     {
        //         "id": "QF",
        //         "name": "Freiwillige Quarantäne (bezahlte Freistellung)"
        //     },
        //     {
        //         "id": "QG",
        //         "name": "Freiwillige Quarantäne Ausfallstunden (bezahlte Freistellung)"
        //     },
        //     {
        //         "id": "QK",
        //         "name": "Beaufsichtigung Kind wegen Schulschließung (§ 56. Abs. 1a IfSG)"
        //     },
        //     {
        //         "id": "QN",
        //         "name": "Freiwillige Quarantäne Ausfallstunden (unbezahlte Freistellung) "
        //     },
        //     {
        //         "id": "QO",
        //         "name": "Angeordnete Quarantäne (bezahlte Freistellung)"
        //     },
        //     {
        //         "id": "QS",
        //         "name": "Quarantäne Ausfallstunden mit Entschädigungszahlung"
        //     },
        //     {
        //         "id": "QT",
        //         "name": "Berufliches Tätigkeitsverbot"
        //     },
        //     {
        //         "id": "QU",
        //         "name": "Freiwillige Quarantäne (unbezahlte Freistellung)"
        //     },
        //     {
        //         "id": "RA",
        //         "name": "Rechtmäßiger Streik (Tage)"
        //     },
        //     {
        //         "id": "RH",
        //         "name": "Reha/Kur mit Entgeltfortzahlung"
        //     },
        //     {
        //         "id": "SO",
        //         "name": "Sonstige Stunden/Zulagen"
        //     },
        //     {
        //         "id": "ST",
        //         "name": "Sozialstunden"
        //     },
        //     {
        //         "id": "SU",
        //         "name": "Sonderurlaub"
        //     },
        //     {
        //         "id": "SZ",
        //         "name": "Studienzeit (dualer Studiengang)"
        //     },
        //     {
        //         "id": "U",
        //         "name": "Urlaub"
        //     },
        //     {
        //         "id": "U6",
        //         "name": "Krank Betriebsunfall (ohne LFZ)"
        //     },
        //     {
        //         "id": "UA",
        //         "name": "Unrechtm. Arbeitskampf"
        //     },
        //     {
        //         "id": "UB",
        //         "name": "Unwiderrufliche bezahlte Freistellung"
        //     },
        //     {
        //         "id": "UE",
        //         "name": "Übergangsgeld"
        //     },
        //     {
        //         "id": "UF",
        //         "name": "Unwiderrufliche unbezahlte Freistellung"
        //     },
        //     {
        //         "id": "UK",
        //         "name": "Urlaub statt Kur"
        //     },
        //     {
        //         "id": "UL",
        //         "name": "Urlaub statt Lohnfortzahlung"
        //     },
        //     {
        //         "id": "UU",
        //         "name": "Unbezahlter Urlaub"
        //     },
        //     {
        //         "id": "FR",
        //         "name": "Freistellung im Rahmen sonst. flexibler AZ-Regelungen"
        //     },
        //     {
        //         "id": "FN",
        //         "name": "Fehlstunden unbez./unentsch. (k. vollen Tage)"
        //     },
        //     {
        //         "id": "KF",
        //         "name": "Krank am Feiertag"
        //     },
        //     {
        //         "id": "KG",
        //         "name": "Krank am Feiertag in Höhe Kug (SV durch AG)"
        //     },
        //     {
        //         "id": "FA",
        //         "name": "Unwiderrufliche bezahlte Freistellung von der Arbeitsleistung"
        //     },
        //     {
        //         "id": "KK",
        //         "name": "Krank vor Saison-Kug"
        //     },
        //     {
        //         "id": "EZ",
        //         "name": "Elternzeit"
        //     },
        //     {
        //         "id": "EÜ",
        //         "name": "Eignungsübung"
        //     },
        //     {
        //         "id": "ES",
        //         "name": "Entgelt in Höhe Kug (SV durch AN und AG)"
        //     },
        //     {
        //         "id": "KP",
        //         "name": "Kurzzeitige Pflege (bis 10 Arbeitstage)"
        //     },
        //     {
        //         "id": "MK",
        //         "name": "Krankengeld bei Mitaufnahme ins Krankenhaus"
        //     },
        //     {
        //         "id": "EO",
        //         "name": "Entgeltfortzahlung wegen Organ-/Gewebespende"
        //     },
        //     {
        //         "id": "ML",
        //         "name": "Mutterschutzlohn/Beschäftigungsverbot"
        //     },
        //     {
        //         "id": "BR",
        //         "name": "Betriebsratstätigkeit"
        //     },
        //     {
        //         "id": "AS",
        //         "name": "Aussperrung"
        //     },
        //     {
        //         "id": "OS",
        //         "name": "Krankengeld bei Organ-/Blut-/Gewebespende ohne EEL"
        //     },
        //     {
        //         "id": "AF",
        //         "name": "Arbeitsfrei ohne Lohnanspruch"
        //     },
        //     {
        //         "id": "PB",
        //         "name": "Vollständige Freistellung für die Betreuung/Begleitung Pflegebedürftiger"
        //     },
        //     {
        //         "id": "PE",
        //         "name": "Pflege krankes Kind mit Entgeltfortzahlung"
        //     },
        //     {
        //         "id": "5",
        //         "name": "Zeitlohn 5"
        //     },
        //     {
        //         "id": "4",
        //         "name": "Zeitlohn 4"
        //     },
        //     {
        //         "id": "3",
        //         "name": "Zeitlohn 3"
        //     },
        //     {
        //         "id": "2",
        //         "name": "Zeitlohn 2"
        //     },
        //     {
        //         "id": "1",
        //         "name": "Zeitlohn"
        //     },
        //     {
        //         "id": "VG",
        //         "name": "Versorgungsgeld"
        //     },
        //     {
        //         "id": "VK",
        //         "name": "Krankengeld/Krankheit vor Kug"
        //     },
        //     {
        //         "id": "VL",
        //         "name": "Verletztengeld"
        //     },
        //     {
        //         "id": "VS",
        //         "name": "Krankstunden vor Kug"
        //     },
        //     {
        //         "id": "W1",
        //         "name": "Witterungsbedingter Ausfall unter 1 Stunde"
        //     },
        //     {
        //         "id": "W3",
        //         "name": "Wehrübung bis 3 Tage"
        //     },
        //     {
        //         "id": "WA",
        //         "name": "Witterungsbedingte Ausfallstunden"
        //     },
        //     {
        //         "id": "WD",
        //         "name": "Witterungsbedingter Ausfall Dachdecker"
        //     },
        //     {
        //         "id": "WE",
        //         "name": "Wehrübung/Dienstverpflichtung"
        //     },
        //     {
        //         "id": "WK",
        //         "name": "Krankheit während Kug"
        //     },
        //     {
        //         "id": "ZD",
        //         "name": "Zivil- bzw. Bundesfreiwilligendienst"
        //     }
        // ]
    )
    const [temporaryHoursArray, setTemporaryHoursArray] = useState<HoursObject[]>()
    const [filteredReasonsList, setFilteredReasonsList] = useState(reasonsList);
    const [filteredEmployeesList, setFilteredEmployeesList] = useState<Employee[]>(employeesList);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<any>(null);
    const [selectedDayObj, setSelectedDayObj] = useState<any>(null);
    const [opentooltip, setOpentooltip] = useState<boolean>(false);
    const [hoveredCell, setHoveredCell] = useState<boolean>(false);
    const [hoveredSelectedItem, setHoveredSelectedItem] = useState<any>(null);
    const [hoveredSelectedIndex, setHoveredSelectedIndex] = useState<any>(null);

    const [search, setSearch] = useState<string>();
    const intl = useIntl();


    useEffect(() => {
        if (taskDetails.clientId)
            getReasonsForAbsence(taskDetails.clientId).then((x) => {
                setReasonsList(x);
                setFilteredReasonsList(x)
            })

    }, [])

    const updateSurveyCall = () => {
        // console.log("emp upd", employeesList)
        dispatch(setLoading(true));
        dispatch(setActionCallFrom('update working hours'))
        updateSurvey(taskDetails.id, employeesList).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                dispatch(setCallStatus('Pass'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''));
                    dispatch(setActionCallFrom(''));
                    // dispatch(setModify(!modify))
                }, 1500)
            }
            dispatch(setLoading(false));
        })
    }


    // const moveWorkingHoursToDatevCall = () => {
    //     const extractedHoursObjects = employeesList.flatMap((employee: any) =>
    //         employee.schedule.flatMap((schedule: any) =>
    //             schedule.days.flatMap((day: any) =>
    //                 day.hours.filter((hour: any) => hour.reasonForAbsence !== null && hour.hours !== null)
    //             )
    //         )
    //     );
    //     dispatch(setLoading(true));
    //     dispatch(setActionCallFrom('move to datev'))
    //     moveWorkingHoursToDatev(taskDetails.clientId, extractedHoursObjects).then((x) => {
    //         if (x.ErrorMessage) {
    //             dispatch(setErrorMsg(x.ErrorMessage));
    //             dispatch(setCallStatus('Fail'));
    //             setTimeout(() => {
    //                 dispatch(setErrorMsg(''));
    //                 dispatch(setCallStatus(''))
    //             }, 4500)
    //         }
    //         else {
    //             dispatch(setCallStatus('Pass'));
    //             setTimeout(() => {
    //                 dispatch(setErrorMsg(''));
    //                 dispatch(setCallStatus(''));
    //                 dispatch(setActionCallFrom(''));
    //                 dispatch(setModify(!modify))
    //             }, 1500)
    //         }
    //         dispatch(setLoading(false));
    //     })
    // }

    const isValidNumber = (nb: any) => {
        return typeof nb === "number" && !isNaN(nb);
    }


    // Handle previous month selection
    const handlePreviousMonth = () => {
        let newMonth, newYear;

        if (selectedMonth === 0) {
            newMonth = 11; // December
            newYear = selectedYear - 1;
        } else {
            newMonth = selectedMonth - 1;
            newYear = selectedYear;
        }

        // if (!isDateInPast(newYear, newMonth)) {
        setSelectedMonth(newMonth);
        setSelectedYear(newYear);
        // }
    };

    // Handle next month selection
    const handleNextMonth = () => {
        if (selectedMonth === 11) {
            setSelectedMonth(0); // January
            setSelectedYear(selectedYear + 1);
        } else {
            setSelectedMonth(selectedMonth + 1);
        }
    };

    console.log('grouoed', groupedDatevRecords)


    // Generate days of the selected month
    useEffect(() => {
        const days = [];
        const daysCount = new Date(selectedYear, selectedMonth + 1, 0).getDate();

        for (let i = 1; i <= daysCount; i++) {
            const date = new Date(selectedYear, selectedMonth, i);
            const dayIndex = date.getDay();
            days.push({
                day: i,
                dayName: {
                    en: DAY_NAMES[dayIndex].en,
                    gr: DAY_NAMES[dayIndex].gr
                },
                date: date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }),
                hours: [{
                    id: 1,
                    reasonForAbsence: null,
                    employeeId: null,
                    date: moment(date).format('YYYY-MM-DD'),
                    hours: null,
                    isDatev: false,
                }]
            });
        }

        setDaysInMonth(days);
    }, [selectedMonth, selectedYear]);

    const fillDatevHours = () => {
        console.log('reachedd')
    }

    // Handle adding month to employee schedule
    const addMonthToEmployeeSchedule = () => {
        // console.log("adding", employeesList)
        // Check if month already exists in schedule
        const updatedEmployees = [...employeesList];
        updatedEmployees?.map((emp: any) => {
            const monthScheduleExists = emp.schedule.some(
                (s: any) => s.month === selectedMonth && s.year === selectedYear
            );
            const employeeDatevRecords = groupedDatevRecords?.find((em: any) => em.employeeId === emp.id);
            console.log('test 1', employeeDatevRecords)
            if (!monthScheduleExists) {

                // Modify daysInMonth to update all empIds in hours array
                // daysInMonth.map((dd:any) => {
                //     console.log('dd',dd);
                //     console.log("employeeDatevRecords",employeeDatevRecords)
                //     if(employeeDatevRecords?.records?.find((r:any) => moment(r.date).format('YYYY-MM-DD') === moment(dd.date).format('YYYY-MM-DD')))
                //         console.log('foundd',employeeDatevRecords?.records?.find((r:any) => moment(r.date).format('YYYY-MM-DD') === moment(dd.date).format('YYYY-MM-DD')).objects)
                //     else console.log('the else')

                // })
                const updatedDays = daysInMonth.map((day: any) => ({
                    ...day,
                    hours:
                        employeeDatevRecords?.records?.find((r: any) => moment(r.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'))
                            ?
                            employeeDatevRecords?.records?.find((r: any) => moment(r.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD')).objects?.map((ob: any) => ({
                                ...ob, isDatev: true
                            })) :
                            day.hours.map((hour: any, index: number) => ({
                                ...hour,
                                employeeId: emp.id,
                                id: emp.id + "/" + hour.date + "/" + index + 1
                            }))
                }));
                // Add new month to employee's schedule
                const newMonthSchedule: MonthSchedule = {
                    month: selectedMonth,
                    year: selectedYear,
                    monthLabel: `${MONTHS[selectedMonth].labelEn} ${selectedYear}`,
                    days: [...updatedDays]
                };

                emp.schedule.push(newMonthSchedule);
                setEmployeesList(updatedEmployees);
            }
            else {
                if (employeeDatevRecords) {
                    const updatedSchedule = emp.schedule.map((sc: any) => ({
                        ...sc,
                        days: sc.days.map((d: any) => ({
                            ...d,
                            hours: employeeDatevRecords?.records?.find((r: any) => moment(r.date).format('YYYY-MM-DD') === moment(d.date).format('YYYY-MM-DD'))
                                ?
                                employeeDatevRecords?.records?.find((r: any) => moment(r.date).format('YYYY-MM-DD') === moment(d.date).format('YYYY-MM-DD')).objects?.map((ob: any) => ({
                                    ...ob, isDatev: true,
                                })) :
                                d.hours
                        }))
                    }))
                    emp.schedule = updatedSchedule;
                    forceUpdate();
                }

            }
        })
        // const employeeIndex = updatedEmployees.findIndex(emp => emp.id === employeeId);
        // if (employeeIndex === -1) return;
        // const employee = updatedEmployees[employeeIndex];
    };

    // useEffect(() => {
    //     console.log("employees changed", employeesList)

    // }, [employeesList])
    useEffect(() => {
        if (daysInMonth.length > 0) addMonthToEmployeeSchedule();
    }, [daysInMonth]);

    const calculateTotal = (employee: any, dayId: any) => {
        // console.log("test", employee);
        let sum = 0;
        let allNull = false;
        if (dayId !== null) {
            sum = employee.schedule?.find((s: any) => s.month === selectedMonth)?.days?.find((d: any) => d.day === dayId)?.hours?.reduce((acc: any, hour: any) => acc + (isValidNumber(hour.hours) ? hour.hours : 0), 0);
            allNull = employee.schedule?.find((s: any) => s.month === selectedMonth)?.days?.find((d: any) => d.day === dayId)?.hours?.every((hour: any) => hour.hours === null);

        }
        else {
            employee.schedule?.find((s: any) => s.month === selectedMonth)?.days?.map((d: any) => {
                sum += d.hours?.reduce((acc: any, hour: any) => acc + (hour.hours !== null ? hour.hours : 0), 0);
            })

        }

        return { id: employee.schedule?.find((s: any) => s.month === selectedMonth)?.days?.id, totalHours: allNull ? null : sum };
    }

    // useEffect(() => {
    //     console.log("employees", employeesList)
    // }, [employeesList])

    return (
        <Grid container>
            <Grid item>
                <img src={!openRightDrawer ? hoverIcon ? minimizeIconHovered : minimizeIcon : hoverIcon ? maximizeIconHovered : maximizeIcon}
                    onClick={() => {
                        setOpenRightDrawer(!openRightDrawer)
                    }}
                    onMouseOver={() => setHoverIcon(true)}
                    onMouseOut={() => setHoverIcon(false)}
                    alt='icon'
                    style={{ position: 'absolute', top: '-3.7px', right: '13.5px', cursor: 'pointer', width: '47px', height: '42px' }}
                />
            </Grid>
            <Grid
                item
                container
                direction='row'
                alignItems='center'
                justifyContent='center'
                style={{
                    position: 'absolute', left: openRightDrawer ? '79%' : '86%', top: '-1px',
                    backgroundImage: `url(${monthFieldIcon})`,
                    backgroundSize: 'contain', // Optional: scales the image to cover the entire area
                    backgroundRepeat: 'no-repeat',
                    width: '124px'
                }}
            >
                <Grid item>
                    <KeyboardArrowLeftIcon
                        // htmlColor={isDateInPast(
                        //     selectedMonth === 0 ? selectedYear - 1 : selectedYear,
                        //     selectedMonth === 0 ? 11 : selectedMonth - 1
                        // ) ? '#c3c2c7' : '#93919A'}
                        htmlColor='#93919A'
                        style={{
                            // cursor: isDateInPast(
                            //     selectedMonth === 0 ? selectedYear - 1 : selectedYear,
                            //     selectedMonth === 0 ? 11 : selectedMonth - 1
                            // ) ? 'unset' : 'pointer',
                            cursor: 'pointer',
                            // width: '9.42px',
                            // height: '5.48px'
                        }}
                        onClick={() => handlePreviousMonth()} />
                </Grid>
                <Grid item className='font-14 bold iris-100'>  {local === 'en' ? MONTHS[selectedMonth].labelEn : MONTHS[selectedMonth].labelGr}, {selectedYear}</Grid>
                <Grid item>
                    <KeyboardArrowRightIcon
                        htmlColor='#93919A'
                        style={{
                            cursor: 'pointer',
                            // width: '9.42px',
                            // height: '5.48px'
                        }}
                        onClick={() => handleNextMonth()} />
                </Grid>
            </Grid>

            <Grid container padding='13px' >
                <Grid container direction='row' alignItems='center' columnGap={1}>
                    <img src={timeIcon} width='24px' height='24px' alt='icon' />
                    <Message id="task.time-management" className="font-14 black font-weight-700" />
                </Grid>

                <div style={{ display: "flex", overflowX: "auto" }}>
                    {/* Fixed first column */}
                    <div style={{ flexShrink: 0, position: "sticky", left: 0, zIndex: 2 }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent='space-between'
                            width="214px"
                            minWidth="max-content"
                            sx={{
                                height: "100px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {/* Centered Name */}
                            <Grid item sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                                <TextField
                                    value={search}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                                        style: {
                                            borderRadius: '35px',
                                            fontFamily: 'DM Sans',
                                            height: '37px',
                                            backgroundColor: '#F7F9FF',
                                            border: 0,
                                            borderColor: '#E6E6E8',
                                            fontSize: '12px',
                                            marginBottom: '10px'
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#E6E6E8", // Sets the border color
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#E6E6E8", // Ensures border color on hover
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#E6E6E8", // Ensures border color when focused
                                            },
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontWeight: "bold",
                                        },
                                    }}
                                    placeholder={intl.formatMessage({ id: 'text.search' })}
                                    onChange={(e: any) => {
                                        setSearch(e.target.value)
                                        if (e.target.value) {
                                            const tmp = e.target.value
                                            const filtered = employeesList.filter((e: any) => e.email?.toLowerCase().includes(tmp?.toLowerCase()));
                                            setFilteredEmployeesList(filtered)
                                        }
                                        else setFilteredEmployeesList(employeesList)
                                    }}
                                />
                            </Grid>

                            {/* Grey Line */}
                            <Grid item width="100%" className="greyLine"></Grid>
                        </Grid>
                        <div
                            ref={syncedDivRef}
                            style={{
                                overflow: "hidden",
                                maxHeight: openRightDrawer ? '305px' : '610px',
                                minHeight: '50px'
                            }}
                        >
                            {filteredEmployeesList?.length > 0 ? (
                                filteredEmployeesList?.map((emp: any, index: number) => (
                                    <>

                                        <Grid
                                            container
                                            direction="column"
                                            width="214px"
                                            minWidth="max-content"
                                            borderRight="solid 1px #C3C2C7"
                                            sx={{
                                                height: "60px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {/* Centered Name */}
                                            <Grid item sx={{ flexGrow: 1, display: "flex", alignItems: "center", width: '214px' }}>
                                                <HtmlTooltip title={emp.email} style={{ color: 'black' }}>
                                                    {filteredEmployeesList.length > 0 ? (
                                                        <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: '190px' }}> {emp.email}</span>
                                                    ) : (
                                                        <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: '190px' }}> Employee not found</span>
                                                    )}

                                                </HtmlTooltip>
                                            </Grid>

                                            {/* Grey Line */}
                                            <Grid item width="100%" className="greyLine"></Grid>
                                        </Grid>
                                    </>

                                ))
                            )
                                : (<div style={{ fontWeight: "bold", marginTop: "15px" }}>
                                    Employee not found
                                </div>)
                            }
                        </div>
                    </div>

                    {/* Scrollable section */}
                    <div style={{
                        overflowX: "auto", whiteSpace: "nowrap", flexGrow: 1, maxHeight: openRightDrawer ? '405px' : '710px',
                        overflowY: "auto", minHeight: '100px'
                    }}
                        className='scroll-container'
                        ref={scrollableDivRef}
                        onScroll={handleScroll}
                    >
                        <div style={{ minWidth: "max-content" }}>
                            <Grid
                                container
                                justifyContent='space-between'
                                alignItems='center'
                                direction='row'
                                style={{
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 10,
                                    backgroundColor: '#FAFBFF',
                                }}
                                width='100%'
                                sx={{
                                    height: "100px",
                                    display: "flex",
                                }}
                            >
                                <Grid container direction='column' minWidth='90px' maxWidth='90px' alignItems='center' sx={{ position: 'relative', height: '100%' }}
                                    justifyContent='center'>
                                    <Grid item
                                        style={{ backgroundColor: '#5D5FEF', color: 'white', width: '43px', height: '40px', borderRadius: '5px', padding: '10px 3px 10px 3px', textAlign: 'center' }}
                                    >
                                        Total
                                    </Grid>
                                    <Grid item className='font-14 font-weight-400' marginTop={1}>
                                        {moment(daysInMonth[0]?.date).format('DD')}-{moment(daysInMonth[daysInMonth?.length - 1]?.date).format('DD')}
                                        &nbsp; {MONTHS?.find((m: any) => m.value === selectedMonth)?.labelEn}
                                    </Grid>
                                    <Grid item className='greyLine' width='100%' sx={{ position: 'absolute', bottom: 0 }}></Grid>
                                </Grid>

                                {daysInMonth?.map((dayObj: any) => (
                                    <Grid container direction='column' style={{ flexShrink: 0, width: '65px', position: 'relative', height: '100%' }}
                                        justifyContent='center'>
                                        <Grid item className='greyCircle'>
                                            {local === 'en' ? dayObj.dayName.en?.slice(0, 1) : dayObj.dayName.gr?.slice(0, 1)}
                                        </Grid>
                                        <Grid item className='font-14 font-weight-400' marginTop={1}>
                                            {moment(dayObj.date).format('DD.MM')}
                                        </Grid>
                                        <Grid item className='greyLine' width='100%' sx={{ position: 'absolute', bottom: 0 }}></Grid>
                                    </Grid>
                                ))}
                            </Grid>
                            <div>
                                {filteredEmployeesList?.map((emp: any, index: number) => (
                                    <>
                                        {/* {index === 0 ?
                                        <Grid
                                            container
                                            justifyContent='space-between'
                                            alignItems='center'
                                            direction='row'
                                            style={{ display: 'flex', flexWrap: 'nowrap' }}
                                            width='70%'
                                            sx={{
                                                height: "100px",
                                                display: "flex",
                                            }}
                                        >
                                            <Grid container direction='column' minWidth='90px' maxWidth='90px' alignItems='center' sx={{ position: 'relative', height: '100%' }}
                                                justifyContent='center'>
                                                <Grid item
                                                    style={{ backgroundColor: '#5D5FEF', color: 'white', width: '43px', height: '40px', borderRadius: '5px', padding: '10px 3px 10px 3px', textAlign: 'center' }}
                                                >
                                                    Total
                                                </Grid>
                                                <Grid item className='font-14 font-weight-400' marginTop={1}>
                                                    {moment(daysInMonth[0]?.date).format('DD')}-{moment(daysInMonth[daysInMonth?.length - 1]?.date).format('DD')}
                                                    &nbsp; {MONTHS?.find((m: any) => m.value === selectedMonth)?.labelEn}
                                                </Grid>
                                                <Grid item className='greyLine' width='100%' sx={{ position: 'absolute', bottom: 0 }}></Grid>
                                            </Grid>

                                            {m.schedule[0]?.days?.map((dayObj: any) => (
                                                <Grid container direction='column' style={{ flexShrink: 0, width: '65px', position: 'relative', height: '100%' }}
                                                    justifyContent='center'>
                                                    <Grid item className='greyCircle'>
                                                        {local === 'en' ? dayObj.dayName.en?.slice(0, 1) : dayObj.dayName.gr?.slice(0, 1)}
                                                    </Grid>
                                                    <Grid item className='font-14 font-weight-400' marginTop={1}>
                                                        {moment(dayObj.date).format('DD.MM')}
                                                    </Grid>
                                                    <Grid item className='greyLine' width='100%' sx={{ position: 'absolute', bottom: 0 }}></Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        :
                                        <></>
                                    } */}


                                        <Grid container direction='row' style={{ display: 'flex', flexWrap: 'nowrap' }} width='70%'

                                            sx={{
                                                height: "60px",
                                                display: "flex",
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid container justifyContent='center' minWidth='90px' maxWidth='90px' textAlign="center">
                                                <Grid item style={{ width: '45px', height: '30px', borderRadius: '12px', backgroundColor: '#EAE9FC', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {calculateTotal(emp, null).totalHours}
                                                </Grid>

                                            </Grid>

                                            {emp.schedule?.find((s: any) => s.month === selectedMonth)?.days?.map((scheduleItem: any, sIndex: number) => (
                                                <HtmlTooltip
                                                    open={opentooltip && selectedItem?.id === emp.id && selectedIndex === sIndex}
                                                    title={
                                                        <React.Fragment>
                                                            <Grid container direction='column' color='black' width='362px' padding='10px' textAlign='center' rowGap={2}>
                                                                <Grid container direction='row'
                                                                    justifyContent='center'
                                                                    alignItems='center'
                                                                    style={{
                                                                        height: temporaryHoursArray && temporaryHoursArray.length > 0 && temporaryHoursArray[0].reasonForAbsence !== null && isValidNumber(temporaryHoursArray[0].hours) ? '32px' : '',
                                                                    }}
                                                                >
                                                                    {temporaryHoursArray?.map((obj: any, objIndex) => (
                                                                        <>
                                                                            {isValidNumber(obj.hours) && obj.reasonForAbsence !== null ?
                                                                                <Grid item
                                                                                    style={{
                                                                                        backgroundColor: workingReasonsIds?.find((i: string) => i === obj.reasonForAbsence) ? '#CCF0EB' : '#F4D0D0',
                                                                                        borderTopRightRadius: objIndex === temporaryHoursArray.length - 1 ? '12px' : '0px',
                                                                                        borderBottomRightRadius: objIndex === temporaryHoursArray.length - 1 ? '12px' : '0px',
                                                                                        borderTopLeftRadius: objIndex === 0 ? '12px' : '0px',
                                                                                        borderBottomLeftRadius: objIndex === 0 ? '12px' : '0px',
                                                                                        border: unPaidIds?.find((i: string) => i === obj.reasonForAbsence) ? 'solid 1px #EF4737' : '',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        fontSize: '14px'


                                                                                    }}
                                                                                    width='52px' height='100%'>{obj.hours}</Grid>
                                                                                :
                                                                                <></>
                                                                            }

                                                                        </>
                                                                    ))}
                                                                </Grid>
                                                                {temporaryHoursArray?.map((hr: any, hrIndex) => (
                                                                    <Grid container direction='row' alignItems='center' columnGap={0.5} key={hrIndex}>
                                                                        <Grid item key={hr.id}>
                                                                            {hrIndex === temporaryHoursArray.length - 1 ?
                                                                                <AddCircleOutlineIcon htmlColor='#C3C2C7'
                                                                                    style={{ cursor: temporaryHoursArray?.find((h: any) => h.hours === null || h.reasonForAbsence === null) ? 'unset' : 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (!temporaryHoursArray?.find((h: any) => h.hours === null || h.reasonForAbsence === null)) {
                                                                                            const hrObj = {
                                                                                                // id: temporaryHoursArray.length + 1,
                                                                                                id: emp.id + "/" + moment(selectedDayObj.date).format('YYYY-MM-DD') + "/" + temporaryHoursArray.length + 1,
                                                                                                hours: null,
                                                                                                reasonForAbsence: null,
                                                                                                employeeId: emp.id,
                                                                                                date: moment(selectedDayObj.date).format('YYYY-MM-DD'),
                                                                                                isDatev: false
                                                                                            }

                                                                                            setTemporaryHoursArray([...temporaryHoursArray, hrObj])
                                                                                        }
                                                                                    }} />

                                                                                :
                                                                                <RemoveCircleOutlineIcon htmlColor='#C3C2C7'
                                                                                    style={{ cursor: temporaryHoursArray.length === 0 ? 'unset' : 'pointer' }}
                                                                                    onClick={() => {
                                                                                        console.log("deleted hr obj",hr.id)
                                                                                        console.log("deleted hr obj",temporaryHoursArray.find((i:any)=> i.id === hr.id))

                                                                                        if (temporaryHoursArray.length !== 0) {
                                                                                            setTemporaryHoursArray(temporaryHoursArray?.filter((t: any) => t.id !== hr.id))
                                                                                        }
                                                                                    }
                                                                                    } />

                                                                            }</Grid>
                                                                        <Grid item width='51px'>
                                                                            <TextField
                                                                                value={hr.hours || ''}
                                                                                fullWidth
                                                                                type='number'
                                                                                InputProps={{
                                                                                    style: {
                                                                                        borderRadius: '10px',
                                                                                        fontFamily: 'DM Sans',
                                                                                        height: '37px',
                                                                                        backgroundColor: '#F7F9FF',
                                                                                        border: 0,
                                                                                        borderColor: '#E6E6E8',
                                                                                        fontSize: '12px',
                                                                                        marginBottom: '10px',
                                                                                        color: '#C3C2C7'
                                                                                    },
                                                                                    endAdornment: <InputAdornment position="end">
                                                                                        <Typography style={{ color: '#9E9E9E', fontSize: '12px' }}>h</Typography>
                                                                                    </InputAdornment>,
                                                                                    // This prevents the arrows from appearing
                                                                                    inputProps: {
                                                                                        min: 0,
                                                                                        step: "any",
                                                                                        style: {
                                                                                            MozAppearance: "textfield" as any,
                                                                                            // Reduce padding around the input text
                                                                                            padding: '0px 0px 0px 10px'
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                sx={{
                                                                                    "& .MuiOutlinedInput-root": {
                                                                                        "& fieldset": {
                                                                                            borderColor: "#E6E6E8", // Sets the border color
                                                                                        },
                                                                                        "&:hover fieldset": {
                                                                                            borderColor: "#E6E6E8", // Ensures border color on hover
                                                                                        },
                                                                                        "&.Mui-focused fieldset": {
                                                                                            borderColor: "#E6E6E8", // Ensures border color when focused
                                                                                        },
                                                                                        // Reduce padding of the input container
                                                                                        "& .MuiInputBase-input": {
                                                                                            padding: '8px 10px'
                                                                                        }
                                                                                    },
                                                                                    "& .MuiInputBase-input::placeholder": {
                                                                                        fontWeight: "bold",
                                                                                    },
                                                                                    // These styles hide the spinner buttons in WebKit browsers
                                                                                    "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button": {
                                                                                        WebkitAppearance: "none",
                                                                                        margin: 0
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    // hr.hours = e.target.value;
                                                                                    // forceUpdate();
                                                                                    const value = e.target.value;
                                                                                    setTemporaryHoursArray(prev =>
                                                                                        prev?.map(i => i.id === hr.id ? { ...i, hours: parseInt(value) } : i)
                                                                                    )
                                                                                }}
                                                                            />

                                                                        </Grid>
                                                                        <Grid item width='257px'>
                                                                            <HtmlTooltip
                                                                                open={openReasonsTooltip && hourId === hr.id}
                                                                                disableFocusListener
                                                                                disableTouchListener
                                                                                title={
                                                                                    <React.Fragment>
                                                                                        <Grid container direction='column' color='black' width='307px' padding='20px' rowGap={2}

                                                                                            rowSpacing={2}>
                                                                                            <TextField
                                                                                                value={reasonSearch}
                                                                                                fullWidth
                                                                                                InputProps={{
                                                                                                    startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                                                                                                    style: {
                                                                                                        borderRadius: '35px',
                                                                                                        fontFamily: 'DM Sans',
                                                                                                        height: '37px',
                                                                                                        backgroundColor: '#F7F9FF',
                                                                                                        border: 0,
                                                                                                        borderColor: '#E6E6E8',
                                                                                                        fontSize: '12px',
                                                                                                        marginBottom: '10px'
                                                                                                    },
                                                                                                }}
                                                                                                sx={{
                                                                                                    "& .MuiOutlinedInput-root": {
                                                                                                        "& fieldset": {
                                                                                                            borderColor: "#E6E6E8", // Sets the border color
                                                                                                        },
                                                                                                        "&:hover fieldset": {
                                                                                                            borderColor: "#E6E6E8", // Ensures border color on hover
                                                                                                        },
                                                                                                        "&.Mui-focused fieldset": {
                                                                                                            borderColor: "#E6E6E8", // Ensures border color when focused
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiInputBase-input::placeholder": {
                                                                                                        fontWeight: "bold",
                                                                                                    },
                                                                                                }}
                                                                                                placeholder={intl.formatMessage({ id: 'text.search' })}
                                                                                                onChange={(e: any) => {
                                                                                                    setReasonSearch(e.target.value)
                                                                                                    if (e.target.value) {
                                                                                                        const tmp = e.target.value
                                                                                                        const filtered = reasonsList.filter((e: any) => e.name?.toLowerCase().includes(tmp?.toLowerCase()));
                                                                                                        setFilteredReasonsList(filtered)
                                                                                                    }
                                                                                                    else setFilteredReasonsList(reasonsList)
                                                                                                }}


                                                                                            />
                                                                                            <Grid container
                                                                                                direction='column'
                                                                                                rowSpacing={1.5}
                                                                                                style={{ display: 'block', maxHeight: '200px', overflowY: 'auto', fontSize: '14px' }}>

                                                                                                {filteredReasonsList?.map((r: any) => (
                                                                                                    <Grid item
                                                                                                        className={temporaryHoursArray?.find((t: any) => t.reasonForAbsence === r.id) ? 'unset' : 'pointerText'}
                                                                                                        style={{
                                                                                                            color: temporaryHoursArray?.find((t: any) => t.reasonForAbsence === r.id) ? 'grey' : 'black'
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            if (!temporaryHoursArray?.find((t: any) => t.reasonForAbsence === r.id)) {
                                                                                                                // hr.reasonForAbsence = r.id;
                                                                                                                // forceUpdate();
                                                                                                                setTemporaryHoursArray(prev =>
                                                                                                                    prev?.map(i => i.id === hr.id ? { ...i, reasonForAbsence: r.id } : i)
                                                                                                                )
                                                                                                                setOpenReasonsTooltip(false);
                                                                                                                setReasonSearch(null);
                                                                                                                setFilteredReasonsList(reasonsList)
                                                                                                                // console.log("emplo", employeesList)

                                                                                                            }
                                                                                                        }}>{r.name}</Grid>
                                                                                                ))}

                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </React.Fragment>
                                                                                }>
                                                                                <Grid item
                                                                                    style={{
                                                                                        borderRadius: '35px',
                                                                                        fontFamily: 'DM Sans',
                                                                                        height: '37px',
                                                                                        backgroundColor: '#F7F9FF',
                                                                                        border: 'solid 1px #E6E6E8',
                                                                                        borderColor: '#E6E6E8',
                                                                                        fontSize: '14px',
                                                                                        marginBottom: '10px',
                                                                                        cursor: 'pointer',
                                                                                        color: '#C3C2C7',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        padding: '10px',
                                                                                        // overflow: "hidden", // Prevents content from overflowing
                                                                                        // whiteSpace: "nowrap", // Prevents text from wrapping
                                                                                        // textOverflow: "ellipsis", // Adds '...' if text overflows
                                                                                        // maxWidth: "100%", // Ensures the container does not expand beyond its parent

                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setHourId(hr.id);
                                                                                        setOpenReasonsTooltip(!openReasonsTooltip)
                                                                                    }
                                                                                    }>
                                                                                    <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                                                        {hr.reasonForAbsence ? reasonsList?.find((r: any) => r.id === hr.reasonForAbsence)?.name : "Reason"}
                                                                                    </span>                                                                            </Grid>


                                                                            </HtmlTooltip>
                                                                        </Grid>
                                                                    </Grid>

                                                                ))}


                                                                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                                                                    <Grid item xs={3} className='neutral-2 font-14' style={{ display: 'flex', alignItems: 'center', columnGap: '10px', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            setTemporaryHoursArray([
                                                                                {
                                                                                    // id: 1,
                                                                                    id: emp.id + "/" + moment(selectedDayObj.date).format('YYYY-MM-DD') + "/" + 1,
                                                                                    hours: null,
                                                                                    reasonForAbsence: null,
                                                                                    employeeId: emp.id,
                                                                                    date: moment(selectedDayObj.date).format('YYYY-MM-DD'),
                                                                                    isDatev: false
                                                                                }
                                                                            ])
                                                                        }}
                                                                    >
                                                                        <RestartAltIcon htmlColor='#C3C2C7' /> Reset</Grid>
                                                                    <Grid item container columnGap={1} justifyContent='flex-end' xs={8}>
                                                                        <button className="greyButton" style={{ width: '80px' }}
                                                                            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                                                setOpentooltip(false);
                                                                                setSelectedItem(null);
                                                                                setSelectedIndex(null);
                                                                                setSelectedDayObj(null);
                                                                            }}
                                                                        >
                                                                            <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                                                                        </button>
                                                                        <button
                                                                            disabled={temporaryHoursArray?.find((ob: any) => !isValidNumber(ob.hours) || ob.reasonForAbsence === null) ? true : false}
                                                                            className="blueButton" style={{ width: '80px' }}
                                                                            onClick={() => {
                                                                                // console.log("selectedItem", selectedItem)
                                                                                // console.log("selectedIndex", selectedIndex)
                                                                                // console.log("selectedMonth", selectedMonth)
                                                                                // console.log("empss", employeesList);
                                                                                // console.log("temoportar", temporaryHoursArray)
                                                                                setEmployeesList((prevEmps: any) =>
                                                                                    prevEmps.map((emp: any) =>
                                                                                        emp.id === selectedItem.id
                                                                                            ? {
                                                                                                ...emp,
                                                                                                schedule: emp.schedule.map((sch: any) =>
                                                                                                    sch.month === selectedMonth
                                                                                                        ? {
                                                                                                            ...sch,
                                                                                                            days: sch.days.map((d: any) =>
                                                                                                                d.day === selectedIndex + 1
                                                                                                                    ? { ...d, hours: temporaryHoursArray } // Update only the hours
                                                                                                                    : d
                                                                                                            )
                                                                                                        }
                                                                                                        : sch
                                                                                                )
                                                                                            }
                                                                                            : emp
                                                                                    )
                                                                                );
                                                                                setFilteredEmployeesList(prevEmps =>
                                                                                    prevEmps.map(emp =>
                                                                                        emp.id === selectedItem.id
                                                                                            ? {
                                                                                                ...emp,
                                                                                                schedule: emp.schedule.map(sch =>
                                                                                                    sch.month === selectedMonth
                                                                                                        ? {
                                                                                                            ...sch,
                                                                                                            days: sch.days.map(d =>
                                                                                                                d.day === selectedIndex + 1
                                                                                                                    ? { ...d, hours: temporaryHoursArray } // Update only the hours
                                                                                                                    : d
                                                                                                            )
                                                                                                        }
                                                                                                        : sch
                                                                                                )
                                                                                            }
                                                                                            : emp
                                                                                    )
                                                                                );
                                                                                setOpentooltip(false);
                                                                                setSelectedItem(null);
                                                                                setSelectedIndex(null);
                                                                                setSelectedDayObj(null);


                                                                            }}>
                                                                            <Message id="button.apply" className='Field-input font-weight-700' />
                                                                        </button>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </React.Fragment>
                                                    }>
                                                    <Grid item style={{ flexShrink: 0, width: '65px' }}>
                                                        <Grid item style={{
                                                            width: '45px', height: '30px', borderRadius: '12px',
                                                            // backgroundColor: scheduleItem.dayName.en === 'Saturday' || scheduleItem.dayName.en === 'Sunday' ? '#E6E6E8'
                                                            //     // : scheduleItem.hours.length > 1 && scheduleItem.hours?.every((item: any) => item.reasonForAbsence[0] === scheduleItem.hours[0]?.reasonForAbsence[0]) ? '#F7E6D9' 
                                                            //     :
                                                            //     scheduleItem.hours?.every((item: any) => item.reasonForAbsence?.toLowerCase()?.startsWith('k')) ? '#F4D0D0'
                                                            //         : scheduleItem.hours.length > 0 && scheduleItem.hours?.every((item: any) => parseInt(item.reasonForAbsence) >= 1 && parseInt(item.reasonForAbsence) <= 5) ? '#CCF0EB'
                                                            //             : '',
                                                            // border: scheduleItem.hours.length === 1 && scheduleItem.hours[0].reasonForAbsence === null ? 'solid 1px #E1E3E8' : '',
                                                            backgroundColor:
                                                                // scheduleItem.dayName.en === 'Saturday' || scheduleItem.dayName.en === 'Sunday' ? '#E6E6E8'
                                                                //     :
                                                                scheduleItem?.hours?.length === 1 && !workingReasonsIds?.find((i: string) => i === scheduleItem.hours[0].reasonForAbsence) && scheduleItem.hours[0].reasonForAbsence !== null && hoveredCell && hoveredSelectedItem.id === emp.id && hoveredSelectedIndex === sIndex ? '#E49A9A'
                                                                    :
                                                                    scheduleItem?.hours?.length === 1 && !workingReasonsIds?.find((i: string) => i === scheduleItem.hours[0].reasonForAbsence) && scheduleItem.hours[0].reasonForAbsence !== null ? '#F4D0D0'

                                                                        :
                                                                        scheduleItem.hours?.length === 1 && workingReasonsIds?.find((i: string) => i === scheduleItem.hours[0].reasonForAbsence) && hoveredCell && hoveredSelectedItem.id === emp.id && hoveredSelectedIndex === sIndex ? '#8AD6CB'
                                                                            :
                                                                            scheduleItem.hours?.length === 1 && workingReasonsIds?.find((i: string) => i === scheduleItem.hours[0].reasonForAbsence) ? '#CCF0EB'


                                                                                :
                                                                                scheduleItem.hours?.length === 1 && scheduleItem.hours[0].reasonForAbsence === null ? ''
                                                                                    : hoveredCell && hoveredSelectedItem.id === emp.id && hoveredSelectedIndex === sIndex ? '#E1BFA6' : '#F7E6D9',

                                                            border:
                                                                scheduleItem.hours.length === 1 && scheduleItem.hours[0].reasonForAbsence === null && hoveredCell && hoveredSelectedItem.id === emp.id && hoveredSelectedIndex === sIndex
                                                                    // && scheduleItem.dayName.en !== 'Saturday' && scheduleItem.dayName.en !== 'Sunday'
                                                                     ? 'solid 1px #AFB1B5' :
                                                                    scheduleItem.hours.length === 1 && scheduleItem.hours[0].reasonForAbsence === null ? 'solid 1px #E1E3E8'
                                                                        :

                                                                        scheduleItem.hours.length === 1 && unPaidIds?.find((i: string) => i === scheduleItem.hours[0].reasonForAbsence) ? 'solid 1px #EF4737' : '',

                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            // cursor: !isWeekend(scheduleItem.date) ? 'pointer' : 'unset'
                                                            cursor: 'pointer'

                                                        }}
                                                            onClick={() => {
                                                                console.log("shc", scheduleItem)
                                                                if (selectedItem?.id === emp.id && selectedIndex === sIndex) {
                                                                    setOpentooltip(false);
                                                                    setSelectedItem(null);
                                                                    setSelectedIndex(null);
                                                                    setSelectedDayObj(null);
                                                                }
                                                                else {
                                                                    // if (!isWeekend(scheduleItem.date)) {

                                                                    setOpentooltip(true);
                                                                    setSelectedItem(emp);
                                                                    setSelectedIndex(sIndex);
                                                                    setSelectedDayObj(scheduleItem);
                                                                    setTemporaryHoursArray(scheduleItem.hours)
                                                                    console.log('scheduleItwmhours',scheduleItem.hours)
                                                                    // }
                                                                }
                                                            }}
                                                            onMouseEnter={() => {
                                                                setHoveredCell(true)
                                                                setHoveredSelectedItem(emp);
                                                                setHoveredSelectedIndex(sIndex);

                                                            }}
                                                            onMouseLeave={() => {
                                                                setHoveredCell(false)
                                                                setHoveredSelectedItem(null);
                                                                setHoveredSelectedIndex(null);
                                                            }}
                                                        >
                                                            {/* {scheduleItem.dayName.en === 'Saturday' || scheduleItem.dayName.en === 'Sunday' ? "/" :
                                                            scheduleItem.hours.length > 0 && scheduleItem.hours[0].reasonForAbsence === null ? '-' :
                                                                // scheduleItem.hours.length > 0 && scheduleItem.hours?.every((item: any) => item.reasonForAbsence === scheduleItem.hours[0]?.reasonForAbsence) ? 'a' :
                                                                scheduleItem.hours.length > 0 && scheduleItem.hours?.every((item: any) => item.reasonForAbsence?.toLowerCase()?.startsWith('k')) ? 'K'
                                                                    : scheduleItem.hours.length > 0 && scheduleItem.hours?.every((item: any) => parseInt(item.reasonForAbsence) >= 1 && parseInt(item.reasonForAbsence) <= 5) ? <>
                                                                        {calculateTotal(emp, scheduleItem.day).totalHours} </>
                                                                        : ''
                                                        } */}
                                                            {
                                                                // scheduleItem.dayName.en === 'Saturday' || scheduleItem.dayName.en === 'Sunday' ? "/" :
                                                                scheduleItem.hours.length > 0 && scheduleItem.hours[0].reasonForAbsence === null ? '-' :
                                                                    // scheduleItem.hours.length === 1 && !workingReasonsIds?.find((i: string) => i === scheduleItem.hours[0].reasonForAbsence && scheduleItem.hours[0].reasonForAbsence !== null) ? 'K' :
                                                                    <>
                                                                        {calculateTotal(emp, scheduleItem.day).totalHours}
                                                                    </>
                                                            }
                                                            {/* {scheduleItem.hours[0].reasonForAbsence} */}
                                                        </Grid>
                                                    </Grid>

                                                </HtmlTooltip>
                                            ))}
                                        </Grid >

                                    </>
                                ))}
                            </div>
                        </div>
                    </div>


                </div>
                <Grid container justifyContent='flex-end' xs={12} columnGap={2}>
                    {/* {taskDetails?.hoursMovedtoDatev ? */}
                    <Grid item>
                        <button
                            className={actionCallFrom === 'update working hours' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                            style={{ width: '140px', marginTop: '12px' }}
                            onClick={() => {
                                updateSurveyCall()

                            }}>
                            {loading && actionCallFrom === 'update working hours' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                : callStatus === 'Pass' && actionCallFrom === 'update working hours' ? <CheckCircleOutlinedIcon />
                                    : callStatus === 'Fail' && actionCallFrom === 'update working hours' ? "Failed"
                                        :
                                        "Save"
                            }</button>
                    </Grid>
                    {/* :
                    <></>
                     } */}

                    {/* {localStorage.getItem('userType') === '1' ?
                        <>
                            <button
                                className={actionCallFrom === 'move to datev' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                                style={{ width: '140px', marginTop: '12px' }}
                                onClick={() => {
                                    moveWorkingHoursToDatevCall()
                                }}>
                                {loading && actionCallFrom === 'move to datev' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                    : callStatus === 'Pass' && actionCallFrom === 'move to datev' ? <CheckCircleOutlinedIcon />
                                        : callStatus === 'Fail' && actionCallFrom === 'move to datev' ? "Failed"
                                            :
                                            "Move to Datev"
                                }</button></>
                        :
                        <></>} */}

                </Grid>
                {/* <div style={{ overflowX: 'auto' }}>
                    <div style={{ minWidth: 'max-content', padding: '10px' }}>
                        {employeesList?.map((m: any, index: number) => (
                            <>
                                {index === 0 ?
                                    <Grid container direction='row' key={m.id} paddingTop={1}>
                                        <Grid container direction='column' width='200px' minWidth='max-content' justifyContent='space-between'>
                                            search
                                            <Grid item className='greyLine' width='100%'></Grid>

                                        </Grid>
                                        <Grid container direction='row' style={{ display: 'flex', flexWrap: 'nowrap' }} width='70%'>
                                            <Grid container direction='column' minWidth='90px' maxWidth='90px' alignItems='center'>
                                                <Grid item
                                                    style={{ backgroundColor: '#5D5FEF', color: 'white', width: '43px', height: '40px', borderRadius: '5px', padding: '10px 3px 10px 3px', textAlign: 'center' }} >
                                                    Total</Grid>
                                                <Grid item className='font-14 font-weight-400' marginTop={1}>
                                                    {moment(daysInMonth[0]?.date).format('DD')}-{moment(daysInMonth[daysInMonth?.length - 1]?.date).format('DD')}
                                                    &nbsp; {MONTHS?.find((m: any) => m.value === selectedMonth)?.labelEn}
                                                </Grid>
                                                <Grid item className='greyLine' width='100%'></Grid>

                                            </Grid>
                                            {m.schedule[0]?.days?.map((dayObj: any) => (
                                                <Grid container direction='column' style={{ flexShrink: 0, width: '65px' }}>
                                                    <Grid item className='greyCircle'>{local === 'en' ? dayObj.dayName.en?.slice(0, 1) : dayObj.dayName.gr?.slice(0, 1)}</Grid>
                                                    <Grid item className='font-14 font-weight-400' marginTop={1}> {moment(dayObj.date).format('DD.MM')}</Grid>
                                                    <Grid item className='greyLine' width='100%'></Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    :
                                    <></>
                                }
                                <Grid container direction='row' key={m.id} alignItems="center" height='50px'>
                                    <Grid
                                        container
                                        direction="column"
                                        width="200px"
                                        minWidth="max-content"
                                        borderRight="solid 1px #C3C2C7"
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between", // ✅ Ensures grey line is at the bottom
                                        }}>
                                        <Grid item sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                                            {m.name}
                                        </Grid>
                                        <Grid item width='100%' className='greyLine'></Grid>
                                    </Grid>

                                    <Grid container direction='row' style={{ display: 'flex', flexWrap: 'nowrap' }} width='70%' >
                                        <Grid container justifyContent='center' minWidth='90px' maxWidth='90px' textAlign="center">
                                            <Grid item style={{ width: '45px', height: '30px', borderRadius: '12px', backgroundColor: '#EAE9FC', cursor: 'pointer' }}></Grid>

                                        </Grid>

                                        {m.schedule[0]?.days?.map((scheduleItem: any) => (
                                            <Grid item style={{ flexShrink: 0, width: '65px' }}>
                                                <Grid item style={{ width: '45px', height: '30px', borderRadius: '12px', backgroundColor: '#EAE9FC', cursor: 'pointer' }}></Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </div>
                </div> */}

            </Grid>
        </Grid >
    )
}

export default TimeManagement


