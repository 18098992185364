import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, GridColDef, GridColType } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Backdrop, Box, Grid, IconButton, MenuItem, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import DeleteDialog from '../../../sharedComponents/Dialogs/DeleteDialog';
import * as statusesConsts from "../../../status-consts";
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import * as userRolesConst from "../../../user-roles-consts";
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import { useIntl } from "react-intl";
import Message from '../../../sharedComponents/ui/Message';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import orangetask from '../../../assets/images/icons/orangetask.png';
import redtask from '../../../assets/images/icons/redtask.png';
import greentask from '../../../asset/images/icons/greentask.png';
import { useNavigate } from 'react-router-dom';
import classes from "../../../assets/styles/Task.module.css";
import * as TaskServices from '../../../services/task-services.proxy';
import * as StatusesConst from "../../../status-consts";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/en-gb';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import deleteIcon from "../../../assets/images/icons/delete.png";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import * as StatusesConsts from "../../../status-consts";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as taskConsts from "../../../task-consts";
import fileNotificationIcon from "../../../assets/images/icons/fileNotification.png";
import messageNotificationIcon from "../../../assets/images/icons/messageNotification.png";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { useMemo } from 'react';

import CreateTask from './CreateTask';
import ScrollableDataGrid from './TestScroll';
// @ts-ignore
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale/en-GB';

const updateTask = TaskServices.updateTask;
const deleteTask = TaskServices.deleteTask
const duplicateTask = TaskServices.duplicateTask;


export interface TableProps {
  rows: any;
  setRows: any;
  taskName: any;
  dataLength: any;
  setDataLength: any;
  modify: any,
  setModify: any,
  statusesList: any,
  typesList: any,
  moduleList: any,
  assigneesList: any,
  selectedStatus: any,
  setSelectedStatus: any,
  selectedClient: any,
  setSelectedClient: any,
  selectedAssignee: any,
  setSelectedAssignee: any,
  selectedType: any,
  setSelectedType: any,
  selectedTaskName: any,
  setSelectedTaskName: any,
  selectedModule: any,
  setSelectedModule: any,
  selectedPriority: any,
  setSelectedPriority: any,
  selectedPeriod: any,
  setSelectedPeriod: any,
  dueDate: any,
  setDueDate: any,
  clientsList: any,
  paginationModel: any,
  setPaginationModel: any,
  // pageNb: any,
  // setPageNb: any,
  // pageSize: any,
  // setPageSize: any,
  recurringPeriodsList: any;
  internalAssigneesList: any;
  externalAssigneesList: any;
  setExternalAssigneesList: any;
  open: any;
  setOpen: any;
  taskObject: any;
  setTaskObject: any;
  updateTaskStatusCall: any;
  remindersList: any;
  postReminderCall: any;
  inActiveReminderCall: any;
  setOpenCustomReminder: any;
  openCustomReminder: any;
  tableSearchLoading: any;
  clickedModule: number[];
  setClickedModule: React.Dispatch<React.SetStateAction<number[]>>;
  clickedPriority: number[];
  setClickedPriority: React.Dispatch<React.SetStateAction<number[]>>;
  clickedClient: string[];
  setClickedClient: React.Dispatch<React.SetStateAction<string[]>>;
  clickedStatus: number[],
  setClickedStatus: CallableFunction,
  clickedType: number[],
  setClickedType: CallableFunction,
  clickedAssignee: string[],
  setClickedAssignee: CallableFunction,
  clickedPeriod: number[],
  setClickedPeriod: CallableFunction,
  clickedTaskName: string[],
  setClickedTaskName: CallableFunction,
  activeTooltip: any;
  setActiveTooltip: CallableFunction;
  loadMoreRows: any;
  setLoadMoreRows: any;
  setOldRows: any;
  selectedRecurring: any;
  setSelectedRecurring: any;
  clickedRecurring: any;
  setClickedRecurring: any;
  isRecurring: any;
  setIsRecurring: any;
  recurringTaskList: any;
  isOpenCardSelected: any;
  setIsOpenCardSelected: any;
  openStatusesIds: any;
  setTake: any;
  take: any;
  setOriginalTaskId: any;
  doneTasks: any;
  setDoneTasks: any;
  openTasks: any;
  setOpenTasks: any;
  overdueTasks: any;
  setOverdueTasks: any;
  newTasks: any;
  setNewTasks: any;
  scrollingToEmptyRow: any;
  setScrollingToEmptyRow: any;
  initiallistLoading: any;
  dialogLoading: any;
  inputDataLoading: any;
  openOrCompleteLoading: any;
  noMoreRows: any;
  setNoMoreRows: any;
  gridRef: any;
  notLoad: any;
  setNotLoad: any;
  priorityList: any;
  setOriginalTasksTable: any;
  allStatusesList: any;
  dueDateRange: any;
  setDueDateRange: any;
  clickedDueDates: any;
  setClickedDueDates: any;
  selectedDueDates: any;
  setSelectedDueDates: any;
  selectedNotes: any;
  setSelectedNotes: any;
  clickedNotes: any;
  setClickedNotes: any;
  notesList: any,
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
  },
}));

const TasksTable = (props: TableProps) => {

  const {
    rows,
    setRows,
    taskName,
    modify,
    setModify,
    dataLength,
    setDataLength,
    statusesList,
    selectedStatus,
    setSelectedStatus,
    selectedClient,
    setSelectedClient,
    selectedAssignee,
    setSelectedAssignee,
    typesList,
    moduleList,
    selectedType,
    setSelectedType,
    selectedTaskName,
    setSelectedTaskName,
    selectedModule,
    setSelectedModule,
    selectedPriority,
    setSelectedPriority,
    selectedPeriod,
    setSelectedPeriod,
    assigneesList,
    clientsList,
    paginationModel,
    setPaginationModel,
    recurringPeriodsList,
    recurringTaskList,
    internalAssigneesList,
    externalAssigneesList,
    setExternalAssigneesList,
    dueDate,
    setDueDate,
    open,
    setOpen,
    taskObject,
    setTaskObject,
    updateTaskStatusCall,
    remindersList,
    postReminderCall,
    inActiveReminderCall,
    openCustomReminder,
    setOpenCustomReminder,
    tableSearchLoading,
    clickedModule,
    setClickedModule,
    clickedPriority,
    setClickedPriority,
    clickedClient,
    setClickedClient,
    clickedStatus,
    setClickedStatus,
    clickedType,
    setClickedType,
    clickedAssignee,
    setClickedAssignee,
    clickedPeriod,
    setClickedPeriod,
    clickedTaskName,
    setClickedTaskName,
    activeTooltip,
    setActiveTooltip,
    loadMoreRows,
    setLoadMoreRows,
    setOldRows,
    selectedRecurring,
    setSelectedRecurring,
    clickedRecurring,
    setClickedRecurring,
    isRecurring,
    setIsRecurring,
    isOpenCardSelected,
    setIsOpenCardSelected,
    openStatusesIds,
    take,
    setTake,
    setOriginalTaskId,
    openTasks,
    setOpenTasks,
    doneTasks,
    setDoneTasks,
    overdueTasks,
    setOverdueTasks,
    newTasks,
    setNewTasks,
    scrollingToEmptyRow,
    setScrollingToEmptyRow,
    initiallistLoading,
    dialogLoading,
    inputDataLoading,
    openOrCompleteLoading,
    noMoreRows,
    setNoMoreRows,
    gridRef,
    notLoad,
    setNotLoad,
    priorityList,
    setOriginalTasksTable,
    allStatusesList,
    dueDateRange,
    setDueDateRange,
    clickedDueDates,
    setClickedDueDates,
    selectedDueDates,
    setSelectedDueDates,
    selectedNotes,
    setSelectedNotes,
    clickedNotes,
    setClickedNotes,
    notesList
  } = props;

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const loading = useAppSelector(getLoadingState);
  const errorMsg = useAppSelector(getErrorMsg);
  const callStatus = useAppSelector(getCallStatus);
  const userRoles = useAppSelector(selectRoles);
  const userType = useAppSelector(selectUserType);
  const actionCallFrom = useAppSelector(getActionCallFrom);

  //update role 
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


  const [isRecurringIconClicked, setIsRecurringIconClicked] = useState(false);

  const [isTaskIconClicked, setIsTaskIconClicked] = useState(false);
  const [isStatusIconClicked, setIsStatusIconClicked] = useState(false);
  const [isTypeIconClicked, setIsTypeIconClicked] = useState(false);
  const [isClientIconClicked, setIsClientIconClicked] = useState(false);
  const [isAssigneeIconClicked, setIsAssigneeIconClicked] = useState(false);
  const [isPeriodIconClicked, setIsPeriodIconClicked] = useState(false);
  const [isModuleIconClicked, setIsModuleIconClicked] = useState(false);
  const [isPriorityIconClicked, setIsPriorityIconClicked] = useState(false);

  const [selectedTask, setSelectedTask] = useState<any>();
  const [selectedTaskToDelete, setselectedTaskToDelete] = useState<any>();
  const [selectedTaskToDuplicate, setSelectedTaskToDuplicate] = useState<any>();

  const [filterIndex, setFilterIndex] = useState<number>(0);
  const [clientIndex, setClientIndex] = useState<number>(-1);
  const [typeIndex, setTypeIndex] = useState<number>(-1);
  const [moduleIndex, setModuleIndex] = useState<number>(-1);
  const [taskNameIndex, setTaskNameIndex] = useState<number>(-1);
  const [assigneeIndex, setAssigneeIndex] = useState<number>(-1);
  const [periodIndex, setPeriodIndex] = useState(-1);
  const [recurringPeriodIndex, setRecurringPeriodIndex] = useState(-1);

  const [selectedDate, setSelectedDate] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [openAssigneesDropdown, setOpenAssigneesDropdown] = useState(false);
  const [assigneesType, setAssigneesType] = useState('Internal');
  // const [taskObject, setTaskObject] = useState({assigneeId:''});

  // const [clickedStatus, setClickedStatus] = useState<number[]>([]);
  // const [clickedType, setClickedType] = useState<number[]>([]);
  // const [clickedClient, setClickedClient]=useState<string[]>([]);
  // const [clickedPeriod, setClickedPeriod] = useState<number[]>([]);
  // const [clickedModule, setClickedModule]=useState<number[]>([]);
  // const [clickedAssignee, setClickedAssignee] = useState<string[]>([]);
  const [isOpenPeriod, setIsOpenPeriod] = useState(false);
  const [isOpenType, setIsOpenType] = useState(false);
  const [isOpenStatus, setIsOpenStatus] = useState(false);
  const [openTooltipRowId, setOpenTooltipRowId] = useState<string | null>(null);
  const [hoverStatus, setHoverStatus] = useState<string | null>(null);
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenModule, setIsOpenModule] = useState(false);
  const [clientSearch, setClientSearch] = useState('');
  const [taskNameSearch, setTaskNameSearch] = useState('');

  const [filteredTaskNameList, setFilteredTaskNameList] = useState(taskName);
  const [filteredClientsList, setFilteredClientsList] = useState(clientsList);
  const [internalAssigneeSearch, setInternalAssigneeSearch] = useState('');
  const [externalAssigneeSearch, setExternalAssigneeSearch] = useState('');
  const [filteredExternalAssigneesList, setFilteredExternalAssigneesList] = useState(externalAssigneesList);
  const [filteredInternalAssigneesList, setFilteredInternalAssigneesList] = useState(internalAssigneesList);
  const [moduleSearch, setModuleSearch] = useState('');
  const [filteredModuleList, setFilteredModuleList] = useState(moduleList);
  const [statusSearch, setStatusSearch] = useState('');
  const [filteredStatusList, setFilteredStatusList] = useState(statusesList);
  const [typeSearch, setTypeSearch] = useState('');
  const [filteredTypesList, setFilteredTypesList] = useState(typesList);
  const [prioritySearch, setPrioritySearch] = useState('');
  const [filteredPriorityList, setFilteredPriorityList] = useState(priorityList);
  const [filteredNotesList, setFilteredNotesList] = useState(notesList);

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 440);
  const [oldHeight, setOldHeight] = useState(window.innerHeight);

  //reminders
  const [taskId, setTaskId] = useState(null);
  const [reminderTypeId, setReminderTypeId] = useState(0);
  const [reminderDate, setReminderDate] = useState(null);
  const [selectedRowReminders, setSelectedRowReminders] = useState<any>([]);
  const reminderObj = {
    taskId: taskId,
    date: reminderDate,
    typeId: reminderTypeId
  }

  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => {
    // Updating state with an empty object to trigger a re-render
    updateState({});
  }, []);

  const [noVerticalScroll, setNoVerticalScroll] = useState(false);

  const handleCloseCustomReminder = () => {
    setOpenCustomReminder(false);
  };

  const handleReminderDateChange = (newValue: any) => {
    console.log("checkk")
    if (!newValue) {
      setReminderDate(null)
      return;
    }
    const formattedDate = newValue.format('YYYY-MM-DD');
    setReminderDate(formattedDate);
    forceUpdate();
  }

  const handleDateChange = (newValue: any) => {
    setTooltipOpen(false);
    setOldRows([]);
    setTake(10);
    setNoMoreRows(false);
    if (!newValue) {
      setDueDate(null);
      return;
    }
    const formattedDate = newValue.format('YYYY-MM-DD');
    setDueDate(formattedDate);
  }

  useEffect(() => {

    if (activeTooltip === 'name') {
      setFilteredTaskNameList(taskName);
      setSearch('');
    }
    else if (activeTooltip === 'status') {
      setFilteredStatusList(statusesList);
      setSearch('');
    }
    else if (activeTooltip === 'type') {
      setFilteredTypesList(typesList);
      console.log("typesList", typesList)
      setSearch('');
    }
    else if (activeTooltip === 'client') {
      setFilteredClientsList(clientsList);
      setSearch('');
    }
    else if (activeTooltip === 'assignee') {
      setFilteredExternalAssigneesList(externalAssigneesList);
      setSearch('');
      setFilteredInternalAssigneesList(internalAssigneesList);
      setSearch('');
    }
    else if (activeTooltip === 'prio') {
      setFilteredPriorityList(priorityList);
      setSearch('');
    }
    else if (activeTooltip === 'module') {
      setFilteredModuleList(moduleList);
      setSearch('');
    }
    else if (activeTooltip === 'dueDate' && clickedDueDates.length === 2) {
      const startDate = new Date(clickedDueDates[0]);
      const endDate = new Date(clickedDueDates[1]);
      setDueDateRange([{ startDate, endDate, key: 'selection' }]);
    }
    else if (activeTooltip === 'notes') {
      setFilteredNotesList(notesList);
      setSearch('');
    }

  }, [activeTooltip]);

  // useEffect(() => {
  //   // if (isOpenClient) {
  //   if (activeTooltip === 'client') {
  //     setFilteredClientsList(clientsList);
  //     setClientSearch('');
  //   }
  // }, [activeTooltip === 'client', clientsList]);

  // useEffect(() => {
  //   if (activeTooltip === 'name') {
  //     setFilteredTaskNameList(taskName);
  //     setTaskNameSearch('');
  //   }
  // }, [activeTooltip, taskName]);

  // useEffect(() => {
  //   if (activeTooltip === 'module') {
  //     setFilteredModuleList(moduleList);
  //     setModuleSearch('');
  //   }
  // }, [activeTooltip, moduleList]);

  // useEffect(() => {
  //   if (activeTooltip === 'type') {
  //     setFilteredTypesList(typesList);
  //     setTypeSearch('');
  //   }
  // }, [activeTooltip, typesList]);

  // useEffect(() => {
  //   if (activeTooltip === 'status') {
  //     setFilteredStatusList(statusesList);
  //     setStatusSearch('');
  //   }
  // }, [activeTooltip, statusesList]);

  // useEffect(() => {
  //   // if (openAssigneesDropdown) {
  //   if (activeTooltip === 'assignee') {
  //     setFilteredExternalAssigneesList(externalAssigneesList);
  //     setExternalAssigneeSearch('');
  //     setFilteredInternalAssigneesList(internalAssigneesList);
  //     setInternalAssigneeSearch('');
  //   }


  //   // if (assigneesType === "External") {
  //   //   // if (openAssigneesDropdown) {
  //   //   if (activeTooltip === 'assignee') {
  //   //     setFilteredExternalAssigneesList(externalAssigneesList);
  //   //     setExternalAssigneeSearch('');
  //   //   }
  //   // }
  //   // else {
  //   //   // if (openAssigneesDropdown) {
  //   //   if (activeTooltip === 'assignee') {
  //   //     setFilteredInternalAssigneesList(internalAssigneesList);
  //   //     setInternalAssigneeSearch('');
  //   //   }
  //   // }
  // }, [activeTooltip]);

  // useEffect(() => {
  //   if (localStorage.getItem('userId')) {
  //     setSelectedAssignee([localStorage.getItem('userId')])
  //     setClickedAssignee([localStorage.getItem('userId')])
  //   }
  // }, [])

  const [search, setSearch] = useState<string>();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);


    if (type === 'name') {
      const filtered = taskName?.filter((val: any) =>
        val.toLowerCase()?.includes(searchValue)
      );
      setFilteredTaskNameList(filtered);
    }
    else if (type === 'status') {
      const filtered = statusesList?.filter((val: any) =>
        val.name?.toLowerCase()?.includes(searchValue)
      );
      setFilteredStatusList(filtered);
    }
    else if (type === 'type') {
      const filtered = typesList?.filter((val: any) =>
        val.name?.toLowerCase()?.includes(searchValue)
      );
      setFilteredTypesList(filtered);
    }
    else if (type === 'client') {
      const filtered = clientsList?.filter((val: any) =>
        val.name?.toLowerCase()?.includes(searchValue)
      );
      setFilteredClientsList(filtered);
    }
    else if (type === 'prio') {
      if (searchValue?.trim() !== "") {
        const filtered = priorityList?.filter((val: any) =>
          val === parseInt(searchValue)
        );
        setFilteredPriorityList(filtered);

      }
      else setFilteredPriorityList(priorityList);


    }
    else if (type === 'module') {
      const filtered = moduleList?.filter((val: any) =>
        val.name?.toLowerCase()?.includes(searchValue)
      );
      setFilteredModuleList(filtered);
    }
    else if (type === 'note') {
      const filtered = notesList?.filter((val: any) =>
        val.toLowerCase()?.includes(searchValue)
      );
      setFilteredNotesList(filtered);
    }
  };

  const columnss: GridColDef[] = [
    ...((userType === 1 && (userRoles?.includes(userRolesConst.ADMIN_ID) || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID) || userRoles?.includes(userRolesConst.CLERK_ID))) || userType === 2 ? [{
      field: 'actions',
      type: 'actions' as GridColType,
      width: 10,
      sortable: false,
      renderCell: (params: any) => {
        if (params.row.id !== 'Empty') {
          return (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Grid item container rowGap={1.5} padding={1.5} maxWidth='180px'>
                    <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                      onClick={() => {
                        navigate(`/task-details?id=${params.row.id}`)
                        setSelectedTask(() => ({
                          userId: params.row.id,
                        }));
                      }}
                    >
                      <EditOutlinedIcon htmlColor='black' fontSize='small' /><Message id="task.edit" className="black Field-input labelsLinks font-weight-400 font-14" />
                    </Grid>

                    <Grid item container direction='row' className='black labels pointerText normalFontWeight' alignItems='center' columnGap={1}
                      onClick={() => {

                      }}
                    >
                      <NotificationsActiveOutlinedIcon htmlColor='black' fontSize='small' /><Message id="task.set-reminder" className="black Field-input labelsLinks font-weight-400 font-14" />
                      <HtmlTooltip
                        placement='right-start'
                        title={
                          <React.Fragment>
                            <Grid item container rowGap={1.5} padding={2} maxWidth='250px' className='font-14 font-weight-400 black'>
                              {remindersList?.map((r: any) => (
                                <>
                                  <Grid item key={r.id}
                                    className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerUnset' : 'normalFontWeight pointerText'}
                                    onClick={() => {
                                      //setOldRows([]);
                                      let tmp = {
                                        taskId: params.row.id,
                                        date: null,
                                        typeId: r.id
                                      }
                                      setTaskId(params.row.id);
                                      setReminderTypeId(r.id)

                                      if (params.row.reminders.length === 0) {
                                        postReminderCall(tmp, null, params.row.id)
                                      }
                                      else postReminderCall(tmp, params.row.reminders[0].id, params.row.id)

                                    }}>
                                    {r.name === 'Tomorrow' ? <Message id="task.remind-tomorrow" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                      r.name === 'InTwoDays' ? <Message id="task.remind-in-2-days" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                        r.name === 'TwoDaysBeforeDue' ? <Message id="task.remind-before-duedate" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> :
                                          ""}

                                  </Grid>
                                  {r.name === 'Custom' ?
                                    <Grid item
                                      className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText' : 'normalFontWeight pointerText'}
                                      onClick={() => {
                                        //setOldRows([]);
                                        if (params.row.reminders.length > 0 && params.row.reminders[0].typeId) setReminderDate(params.row.reminders[0].date)
                                        // if (params.row.reminders.length === 0) {
                                        setTaskId(params.row.id);
                                        setReminderTypeId(r.id)
                                        setOpenCustomReminder(true);
                                        setSelectedRowReminders(params.row.reminders);
                                        // }
                                      }
                                      }
                                    >
                                      {params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ?
                                        <Message id="task.change-custom.reminder" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} /> : <Message id="task.custom-reminder" className={params.row.reminders.length > 0 && params.row.reminders[0].typeId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} />}

                                    </Grid> : ""}
                                </>

                              ))}
                              {/* <Grid item>Remind me tomorrow</Grid>
                            <Grid item>Remind me in 2 days</Grid>
                            <Grid item>Remind me 2 days before Due date</Grid>
                            <Grid item
                              onClick={() => setOpenCustomReminder(true)}
                            > Set Custom Reminder
                            </Grid> */}
                              <Grid item
                                className={params.row.reminders.length > 0 ? 'pointerText' : 'pointerUnset'}
                                onClick={() => {
                                  if (params.row.reminders.length > 0) {
                                    inActiveReminderCall(params.row.reminders[0].id, taskId)
                                  }
                                }
                                }
                              >
                                <Message id="task.remove-reminder" className={params.row.reminders.length > 0 ? 'bold pointerText font-14' : 'normalFontWeight font-14'} /></Grid>
                            </Grid>
                          </React.Fragment>
                        }>
                        <ArrowRightOutlinedIcon htmlColor='#93919a' />
                      </HtmlTooltip>
                    </Grid>

                    <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                      onClick={() => {
                        // setOldRows([])
                        setOriginalTaskId(params.row.id)
                        setSelectedTaskToDuplicate(() => ({
                          taskId: params.row.id
                        }))

                        setTaskObject(() => ({
                          title: `${params.row.title} (copy)`,
                          status: 1,
                          description: params.row.description,
                          note: params.row.note,
                          recurringPeriod: params.row.recurringPeriod,
                          priority: params.row.priority,
                          dueDate: params.row.dueDate ? moment(params.row.dueDate).format('YYYY-MM-DD') : null,
                          startDate: params.row.startDate ? moment(params.row.startDate).format('YYYY-MM-DD') : null,
                          type: params.row.type,
                          clientId: params.row.clientId,
                          assigneeId: params.row.assigneeId,
                          module: params.row.module
                        }))
                        setOpen(true);
                      }}
                    >
                      <ContentCopyOutlinedIcon htmlColor='black' fontSize='small' width='20px' height='20px' /> <Message id="task.duplicate" className="black Field-input labelsLinks font-weight-400 font-14" />
                    </Grid>

                    <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                      onClick={() => {
                        if (params.row.status !== StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(params.row.id, StatusesConsts.CLOSED_STATUS_ID, params.row.status)
                        if (params.row.status === StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(params.row.id, StatusesConsts.OPEN_STATUS_ID, params.row.status)
                      }}
                    >
                      <CheckIcon htmlColor='black' fontSize='small' />
                      {params.row.status === statusesConsts.CLOSED_STATUS_ID ? <Message id="task.open-task" className="black Field-input labelsLinks font-weight-400 font-14" /> : <Message id="task.complete-task" className="black Field-input labelsLinks font-weight-400 font-14" />}
                    </Grid>

                    {userRoles?.includes(userRolesConst.ADMIN_ID)
                      || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID)
                      || userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID) ?
                      <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                        onClick={() => {
                          //setOldRows([]);
                          setselectedTaskToDelete(() => ({
                            id: params.row.id,
                            title: params.row.title,
                            status: params.row.status
                          }));
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <img src={deleteIcon} width='24px' height='24px' />
                        <Message id="ta.delete" className="red Field-input labelsLinks font-weight-400 font-14" />

                        {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><*/}
                      </Grid>
                      :
                      <></>
                    }

                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
            </HtmlTooltip>
          );
        }

        // Render nothing or placeholder if the 'id' is 'test'
        return null;
      },
    }] : []),

    {
      field: 'title', headerName: intl.formatMessage({ id: 'task.task' }), width: 410,
      // sortable: (activeTooltip !== 'name' && !isTaskIconClicked),
      sortable: false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedTaskName(selectedTaskName);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'name' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              setActiveTooltip(null);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              setActiveTooltip((prev: any) => (prev === 'name' ? null : 'name'));
            }}>
              <Message id="task.task" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'name'}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'name')}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredTaskNameList?.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val} columnGap={1}
                            // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedRoles?.includes(val.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setFilterIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setFilterIndex(-1);
                            // }}
                            onClick={(e) => {
                              setClickedTaskName((prevSelected: any) => {
                                if (prevSelected.includes(val)) {
                                  return prevSelected.filter((v: any) => v !== val);
                                }
                                else {
                                  return [...prevSelected, val];
                                }
                              })
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedTaskName?.includes(val) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedTaskName?.includes(val) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedTaskName?.includes(val) ?
                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>
                              {val}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedTaskName.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedTaskName([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          setActiveTooltip(null);
                          setClickedTaskName(selectedTaskName);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedTaskName(clickedTaskName)
                          setActiveTooltip(null);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={selectedTaskName.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setClickedTaskName(selectedTaskName);
                    setActiveTooltip((prev: any) => (prev === 'name' ? null : 'name'));
                  }} />
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        )
      },
      renderCell: (params) => {
        return (
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item >
              {params.row.title}
            </Grid>
            {params.row.notifications?.length > 0 ?
              <Grid paddingTop={1}>
                {params.row.notifications?.find((n: any) => n.type === taskConsts.comment_Id) ?
                  <img src={messageNotificationIcon} width='24px' height='24px' alt='icon' style={{ paddingRight: '8px' }} />
                  :

                  <></>
                }
                {params.row.notifications?.find((n: any) => n.type === taskConsts.file_Id) ?
                  <img src={fileNotificationIcon} width='15px' height='24px' alt='icon' />
                  :
                  <></>}
                {/* {params.row.notifications?.map((n: any) => (
                  <> {n.type === taskConsts.comment_Id ?
                    <img src={messageNotificationIcon} width='24px' height='24px' alt='icon' />
                    : n.type === taskConsts.file_Id ?
                      <img src={fileNotificationIcon} width='15px' height='24px' alt='icon' />
                      :
                      ""
                  }</>

                ))} */}

              </Grid>
              :
              <></>}

          </Grid>
        )
      }
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'user.status' }),
      width: 180,
      // sortable: (activeTooltip !== 'status' && !isStatusIconClicked),
      sortable: false,
      renderHeader(params) {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedStatus(selectedStatus)
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'status' &&
              // isOpenStatus &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenStatus(false);
              setActiveTooltip(null);
              setIsStatusIconClicked(false);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenStatus((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
              if (activeTooltip !== 'status') {
                setIsStatusIconClicked(false);
              }
              // e.stopPropagation();
            }}>
              <Message id="user.status" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'status'}
                // onClose={() => setIsOpenStatus(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>

                      <Grid item container style={{ height: '40px' }}>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'status')}

                        // onChange={(e) => {
                        //   const searchTerm = e.target.value;
                        //   setStatusSearch(searchTerm);

                        //   // If search term is empty, show full list
                        //   if (searchTerm.trim() === '') {
                        //     setFilteredStatusList(statusesList);
                        //   } else {
                        //     // Filter clients based on search term
                        //     const filtered = statusesList.filter((status: any) =>
                        //       status.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                        //     );

                        //     setFilteredStatusList(filtered);
                        //   }
                        // }}
                        /></Grid>
                      <Grid container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                        {filteredStatusList && filteredStatusList.length > 0 &&
                          // filteredStatusList.filter((status: any) => status.id !== 2).map((status: any, index: number) => (
                          filteredStatusList?.map((status: any, index: number) => (
                            <Grid
                              key={status.id}
                              item
                              container
                              alignItems='center'
                              columnGap={2}
                              //className={classes[(filterIndex === index || selectedStatus.includes(status.id)) ? 'filter-selected' : 'filter-not-selected']}
                              justifyContent='flex-start'
                              className='black'

                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onMouseOver={(e) => {
                                const target = e.target as HTMLElement;
                                if (!clickedStatus?.includes(status.id)) {
                                  target.style.cursor = 'pointer';
                                  setFilterIndex(index);
                                }
                              }}
                              onMouseOut={() => {
                                setFilterIndex(-1);
                              }}
                              onClick={() => {
                                setClickedStatus((prevSelectedStatus: any) => {
                                  if (prevSelectedStatus?.includes(status.id)) {
                                    return prevSelectedStatus?.filter((id: any) => id !== status.id);
                                  }
                                  else {
                                    return [...prevSelectedStatus, status.id];
                                  }
                                });
                              }}
                            >
                              <Grid className='bordered-box'
                                style={{ borderColor: clickedStatus?.includes(status.id) ? '#fff' : '#E6E6E8' }}
                                bgcolor={clickedStatus?.includes(status.id) ? '#CCF0EB' : '#fff'}>
                                {
                                  clickedStatus?.includes(status.id) ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              {/* <Grid item>{status.name}</Grid> */}
                              <Grid item>
                                {status.id === statusesConsts.NEW_STATUS_ID ? <Message id="filter.new" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.OPEN_STATUS_ID ? <Message id="filter.open" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.OVERDUE_STATUS_ID ? <Message id="filter.overdue" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? <Message id="filter.inprogress" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.CLOSED_STATUS_ID ? <Message id="filter.closed" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? <Message id="filter.waiting-for-others" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.UPCOMING_STATUS_ID ? <Message id="filter.upcoming" className="Field-input labels font-weight-400" /> : ''}
                              </Grid>

                              {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                filterIndex === index || selectedStatus.includes(status.id) ? (
                                  status.id === statusesConsts.NEW_STATUS_ID ? '#E3F2FD' :
                                    status.id === statusesConsts.OPEN_STATUS_ID ? '#E8F5E9' :
                                      status.id === statusesConsts.OVERDUE_STATUS_ID ? '#FFEBEE' :
                                        status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? '#FFF3E0' :
                                          status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#F3E5F5' :
                                            status.id === statusesConsts.CLOSED_STATUS_ID ? '#EEEEEE' :
                                              status.id === statusesConsts.UPCOMING_STATUS_ID ? '#E1F5FE' :
                                                '#E0E0E0'
                                ) : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  filterIndex === index || selectedStatus.includes(status.id) ? (
                                    status.id === statusesConsts.NEW_STATUS_ID ? '#2196F3' :
                                      status.id === statusesConsts.OPEN_STATUS_ID ? '#4CAF50' :
                                        status.id === statusesConsts.OVERDUE_STATUS_ID ? '#F44336' :
                                          status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? '#FF9800' :
                                            status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#9C27B0' :
                                              status.id === statusesConsts.CLOSED_STATUS_ID ? '#616161' :
                                                status.id === statusesConsts.UPCOMING_STATUS_ID ? '#03A9F4' :
                                                  '#9E9E9E'
                                  ) : '#9E9E9E'
                                }
                              />
                            </Grid> */}
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems='center'
                        className='black font-weight-400'
                        columnGap={1}
                        style={{ cursor: clickedStatus ? 'pointer' : 'unset' }}
                        marginLeft={0.8}
                        marginTop={0.5}
                        onClick={() => {
                          setClickedStatus([]);
                        }}
                      >
                        <RestartAltIcon fontSize='small' />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedStatus([]);
                          // setIsOpenStatus(false);  
                          setClickedStatus(selectedStatus)
                          setActiveTooltip(null);
                          setIsStatusIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedStatus(clickedStatus)
                          // setIsOpenStatus(false);
                          console.log("clicked", clickedStatus)
                          if (clickedStatus.length === openStatusesIds.length && openStatusesIds.every((status: any) => clickedStatus.includes(status))) setIsOpenCardSelected(true);
                          else setIsOpenCardSelected(false)
                          setActiveTooltip(null);
                          setIsStatusIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>

                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
              style={{
                transform: isOpenStatus ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                <img src={selectedStatus.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsStatusIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
                }} />
              </HtmlTooltip>
            </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params) => {
        return (
          <HtmlTooltip
            open={openTooltipRowId === params.row.id && params.row.status !== StatusesConst.CLOSED_STATUS_ID}
            onClose={() => setOpenTooltipRowId(null)}
            placement='bottom'
            title={
              <React.Fragment>
                <Grid item container direction='column' rowGap={1.5} padding={2} maxWidth='270px' className='font-14 font-weight-400 black'>
                  {allStatusesList?.map((option: any) => (
                    <>
                      {option.id === StatusesConst.IN_PROGRESS_STATUS_ID || option.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ?
                        <Grid item
                          key={option.id}
                          style={{
                            color: option.id !== params.row.status ? 'black' : '#93919A',
                            cursor: option.id !== params.row.status ? 'pointer' : 'unset'
                          }}
                          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation();
                            if (option.id !== params.row.status) updateTaskStatusCall(params.row.id, option.id, params.row.status)
                            setOpenTooltipRowId(null);
                          }}

                        >
                          {option.id === StatusesConst.IN_PROGRESS_STATUS_ID ? "In Progress" :
                            option.id === StatusesConst.WAITING_FOR_OTHERS_STATUS_ID ? "Waiting For Others" : ""}
                        </Grid>
                        :
                        <></>
                      }

                    </>
                  ))}
                </Grid>
              </React.Fragment>
            }>
            <Grid item container direction='row' columnGap={1.5} marginTop={1} data-type="tooltip-icon" onMouseEnter={() => setHoverStatus(params.row.id)} onMouseLeave={() => setHoverStatus(null)} onClick={(e) => {
              if (params.row.status === statusesConsts.CLOSED_STATUS_ID) {
                e.stopPropagation();
              }
            }}>
              <Grid item
                className={classes[
                  params.row.status === statusesConsts.NEW_STATUS_ID ? 'new-task' :
                    params.row.status === statusesConsts.OPEN_STATUS_ID ? 'open-task' :
                      params.row.status === statusesConsts.OVERDUE_STATUS_ID ? 'overdue-task' :
                        params.row.status === statusesConsts.IN_PROGRESS_STATUS_ID ? 'inprogress-task' :
                          params.row.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? 'completed-task' :
                            params.row.status === statusesConsts.CLOSED_STATUS_ID ? 'closed-task' :
                              params.row.status === statusesConsts.UPCOMING_STATUS_ID ? 'upcoming-task' : ''

                ]}>

                {/* {statusesList?.find((s: any) => s.id === params.row.status)?.name} */}
                {
                  params.row.status === statusesConsts.NEW_STATUS_ID ?
                    <Message id="filter.new" className="Field-input labels font-weight-700" />
                    : params.row.status === statusesConsts.OPEN_STATUS_ID ?
                      <Message id="filter.open" className="Field-input labels font-weight-700" />
                      : params.row.status === statusesConsts.OVERDUE_STATUS_ID ?
                        <Message id="filter.overdue" className="Field-input labels font-weight-700" />
                        : params.row.status === statusesConsts.IN_PROGRESS_STATUS_ID ?
                          <Message id="filter.inprogress" className="Field-input labels font-weight-700" />
                          : params.row.status === statusesConsts.CLOSED_STATUS_ID ?
                            <Message id="filter.closed" className="Field-input labels font-weight-700" />
                            : params.row.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ?
                              <Message id="filter.waiting-for-others" className="Field-input labels font-weight-700" />
                              : params.row.status === statusesConsts.UPCOMING_STATUS_ID ?
                                <Message id="filter.upcoming" className="Field-input labels font-weight-700" />
                                :
                                ""
                }

                {params.row.status === statusesConsts.OVERDUE_STATUS_ID ?
                  <LocalFireDepartmentOutlinedIcon sx={{ fontSize: 18, marginLeft: 0.5 }} />

                  :
                  <></>
                }
                {hoverStatus === params.row.id && params.row.status !== statusesConsts.CLOSED_STATUS_ID ?
                  <KeyboardArrowDownIcon
                    data-type="tooltip-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (openTooltipRowId === params.row.id) {
                        setOpenTooltipRowId(null);
                      } else {
                        setOpenTooltipRowId(params.row.id);
                      }
                    }}
                    htmlColor={
                      params.row.status === statusesConsts.NEW_STATUS_ID ? '#1280BE' :
                        params.row.status === statusesConsts.OPEN_STATUS_ID ? '#9F7E3A' :
                          params.row.status === statusesConsts.OVERDUE_STATUS_ID ? '#fff' :
                            params.row.status === statusesConsts.IN_PROGRESS_STATUS_ID ? '#00B69B' :
                              params.row.status === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#6853A9' :
                                params.row.status === statusesConsts.CLOSED_STATUS_ID ? '#fff' :
                                  params.row.status === statusesConsts.UPCOMING_STATUS_ID ? '#FF62E7' : ''

                    } />
                  : <></>}


              </Grid>

            </Grid>
          </HtmlTooltip>

        )
      }
    },
    {
      field: 'type', headerName: intl.formatMessage({ id: 'task.type' }), width: 135,
      // sortable: (activeTooltip !== 'type' && !isTypeIconClicked),
      sortable: false,
      renderHeader: (params: any) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedType(selectedType)
            const target = event.target as HTMLElement;
            if (
              // isOpenType &&
              activeTooltip === 'type' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenType(false);
              setActiveTooltip(null);
              setIsTypeIconClicked(false);
            }
          }}>
            <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenType((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'type' ? null : 'type'));
              if (activeTooltip !== 'type') {
                setIsTypeIconClicked(false);
              }
              // e.stopPropagation();
            }}>
              <Message id="task.type" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'type'}
                // onClose={() => setIsOpenType(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                      <Grid item container style={{ height: '40px' }}>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'type')}

                        // onChange={(e) => {
                        //   const searchTerm = e.target.value;
                        //   setTypeSearch(searchTerm);

                        //   // If search term is empty, show full list
                        //   if (searchTerm.trim() === '') {
                        //     setFilteredTypesList(typesList);
                        //   } else {
                        //     // Filter clients based on search term
                        //     const filtered = typesList.filter((type: any) =>
                        //       type.name.toLowerCase()?.includes(searchTerm.toLowerCase())
                        //     );

                        //     setFilteredTypesList(filtered);
                        //   }
                        // }}
                        /></Grid>
                      <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                        {filteredTypesList && filteredTypesList.length > 0 &&
                          filteredTypesList.map((type: any, index: number) => (
                            <Grid
                              key={type.id}
                              item
                              container
                              alignItems="center"
                              columnGap={2}
                              //className={classes[typeIndex === index || selectedType === type.id ? 'filter-selected' : 'filter-not-selected']}
                              justifyContent='flex-start'
                              className='black'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onMouseOver={(e) => {
                                // const target = e.target as HTMLElement;
                                // if (clickedType !== type.id) {
                                //   target.style.cursor = 'pointer';
                                //   setTypeIndex(index);
                                // }
                                const target = e.target as HTMLElement;
                                if (!clickedType?.includes(type.id)) {
                                  target.style.cursor = 'pointer';
                                  setTypeIndex(index);
                                }
                              }}
                              onMouseOut={() => {
                                setTypeIndex(-1);
                              }}
                              onClick={() => {
                                setClickedType((prevSelectedType: any) => {
                                  // if (prevSelectedType === type.id) {
                                  //   return null;
                                  // } else {
                                  //   return type.id;
                                  // }
                                  if (prevSelectedType?.includes(type.id)) {
                                    return prevSelectedType?.filter((id: any) => id !== type.id);
                                  }
                                  else {
                                    return [...prevSelectedType, type.id];
                                  }
                                });
                              }}
                            >
                              {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                typeIndex === index || selectedType === type.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  typeIndex === index || selectedType === type.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}

                              <Grid className='bordered-box'
                                // style={{ borderColor: clickedType === type.id ? '#fff' : '#E6E6E8' }}
                                // bgcolor={clickedType === type.id ? '#CCF0EB' : '#fff'}>
                                // {
                                //   clickedType === type.id ?
                                style={{ borderColor: clickedType?.includes(type.id) ? '#fff' : '#E6E6E8' }}
                                bgcolor={clickedType?.includes(type.id) ? '#CCF0EB' : '#fff'}>
                                {
                                  clickedType?.includes(type.id) ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              {/* <Grid item>{type.name}</Grid> */}
                              <Grid item>
                                {type.name}
                                {/* {type.id === 1 ? <Message id="filter.type-one" className="Field-input labels font-weight-400" /> : type.id === 2 ? <Message id="filter.type-two" className="Field-input labels font-weight-400" /> : type.id === 3 ? <Message id="filter.type-three" className="Field-input labels font-weight-400" /> : ''} */}
                              </Grid>
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedType ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedType([]);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedType([]);
                          // setIsOpenType(false);
                          setClickedType(selectedType)
                          setActiveTooltip(null);
                          setIsTypeIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedType(clickedType);
                          // setIsOpenType(false);
                          setActiveTooltip(null);
                          setIsTypeIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
              style={{
                transform: isOpenType ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                <img src={selectedType?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsTypeIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'type' ? null : 'type'));
                }} />
              </HtmlTooltip>
            </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params) => {
        return (
          <Grid item style={{ marginTop: '14px' }}>
            {typesList?.find((s: any) => s.id === params.row.type)?.name}
            {/* {params.row.type === 1 ? <Message id="filter.type-one" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : params.row.type === 2 ? <Message id="filter.type-two" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : params.row.type === 3 ? <Message id="filter.type-three" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : ''} */}
          </Grid>
        )
      }
    },
    ...(localStorage.getItem('userType') === '1' ? [{
      field: 'client',
      headerName: intl.formatMessage({ id: 'task.client' }),
      width: 230,
      // sortable: (activeTooltip !== 'client' && !isClientIconClicked),
      sortable: false,
      renderHeader: (params: any) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedClient(selectedClient);
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'client' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              setActiveTooltip(null);
            }
          }}>
            <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              setActiveTooltip((prev: any) => (prev === 'name' ? null : 'name'));
            }}>
              <Message id="task.client" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'client'}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                      <Grid item>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'client')}
                        /></Grid>
                      <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                        className='font-14 black normalFontWeight' padding={1}
                      >
                        {filteredClientsList?.map((val: any, index: number) => (
                          <Grid item container alignItems='center' key={val} columnGap={1}
                            // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedRoles?.includes(val.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setFilterIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setFilterIndex(-1);
                            // }}
                            onClick={(e) => {
                              setClickedClient((prevSelected: any) => {
                                if (prevSelected.includes(val)) {
                                  return prevSelected.filter((v: any) => v !== val);
                                }
                                else {
                                  return [...prevSelected, val];
                                }
                              })
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedClient?.includes(val) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedClient?.includes(val) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedClient?.includes(val) ?
                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>
                              {val}
                            </Grid>
                          </Grid>
                        ))}

                      </Grid>
                      <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedClient.length > 0 ? 'pointer' : 'unset' }}
                        onClick={() => setClickedClient([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          setActiveTooltip(null);
                          setClickedClient(selectedClient);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedClient(clickedClient)
                          setActiveTooltip(null);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={selectedClient.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setClickedClient(selectedClient);
                    setActiveTooltip((prev: any) => (prev === 'client' ? null : 'client'));
                  }} />
                </Grid>
              </HtmlTooltip>
            </Grid >
          </ClickAwayListener>
        );
      },
      renderCell: (params: { row: { clientName: any } }) => {
        return (
          <Grid item>
            {/* {clientsList?.find((i: any) => i.id === params.row.clientId)?.name} */}
            {params.row.clientName}
          </Grid>
        );
      }
    }] : []),
    {
      field: 'assignee',
      headerName: intl.formatMessage({ id: 'task.assignee' }),
      width: 230,
      // sortable: (activeTooltip !== 'assignee' && !isAssigneeIconClicked),
      sortable: false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedAssignee(selectedAssignee)
            const target = event.target as HTMLElement;
            if (
              // openAssigneesDropdown &&
              activeTooltip === 'assignee' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setOpenAssigneesDropdown(false);
              setActiveTooltip(null);
              setIsAssigneeIconClicked(false);
            }
          }}>
            <Grid item container className="table-header" alignItems="center" fontWeight="normal">
              <Message id="task.assignee" className="Field-input labels font-weight-400" />
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  // setOpenAssigneesDropdown((prev) => !prev)
                  setActiveTooltip((prev: any) => (prev === 'assignee' ? null : 'assignee'));
                  if (activeTooltip !== 'assignee') {
                    setIsAssigneeIconClicked(false);
                  }
                  // e.stopPropagation();
                }}
              >
                <HtmlTooltip
                  open={activeTooltip === 'assignee'}
                  // onClose={() => setOpenAssigneesDropdown(false)}
                  disableHoverListener
                  disableFocusListener
                  title={
                    <React.Fragment>
                      <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                        <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                          <Grid
                            item
                            className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                            onClick={() => setAssigneesType('Internal')}
                          >
                            Internal
                          </Grid>
                          <Grid
                            item
                            className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                            onClick={() => setAssigneesType('External')}
                          >
                            External
                          </Grid>
                        </Grid>
                        <Grid item container style={{ height: '40px' }}>
                          <TextField
                            value={assigneesType === "External" ? externalAssigneeSearch : internalAssigneeSearch}
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                              style: {
                                borderRadius: '35px',
                                fontFamily: 'DM Sans',
                                height: '37px',
                                backgroundColor: '#F7F9FF',
                                border: 0,
                                borderColor: '#E6E6E8',
                                fontSize: '12px',
                                marginBottom: '10px'
                              },
                            }}
                            sx={{
                              '& .MuiInputBase-input::placeholder': {
                                fontWeight: 'bold',
                              },
                            }}
                            placeholder={intl.formatMessage({ id: 'text.search' })}
                            onChange={(e) => {
                              const searchTerm = e.target.value;
                              if (assigneesType === 'External') {
                                setExternalAssigneeSearch(searchTerm);
                                if (searchTerm.trim() === '') {
                                  setFilteredExternalAssigneesList(externalAssigneesList);
                                } else {
                                  const filtered = externalAssigneesList.filter((externalAssignee: any) =>
                                    externalAssignee.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                                  );
                                  setFilteredExternalAssigneesList(filtered);
                                }
                              } else {
                                setInternalAssigneeSearch(searchTerm);
                                if (searchTerm.trim() === '') {
                                  setFilteredInternalAssigneesList(internalAssigneesList);
                                } else {
                                  const filtered = internalAssigneesList?.filter((internalAssignee: any) =>
                                    internalAssignee.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                                  );
                                  setFilteredInternalAssigneesList(filtered);
                                }
                              }
                            }}
                          /></Grid>
                        <Grid item container direction="row" rowGap={0.5} padding={2} style={{ maxHeight: '190px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }} className='font-14 black normalFontWeight'>
                          {assigneesType === 'Internal' ? filteredInternalAssigneesList?.map((internalAssignee: any, index: any) => (
                            <Grid
                              item
                              container
                              alignItems='center'
                              key={internalAssignee.id}
                              columnGap={2}
                              // className={classes[assigneeIndex === index || clickedAssignee === internalAssignee.id ? 'filter-selected' : 'filter-not-selected']}
                              justifyContent='flex-start'
                              style={{
                                cursor: 'pointer',
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onClick={() => {
                                // setClickedAssignee(prevAssigneeId => prevAssigneeId === internalAssignee.id ? null : internalAssignee.id);
                                setClickedAssignee((prevSelectedAssignee: any = []) => {
                                  if (prevSelectedAssignee.includes(internalAssignee.id)) {
                                    return prevSelectedAssignee.filter((id: any) => id !== internalAssignee.id);
                                  } else {
                                    return [...prevSelectedAssignee, internalAssignee.id];
                                  }
                                });
                              }}
                            >

                              <Grid
                                className='bordered-box'
                                style={{ borderColor: clickedAssignee?.includes(internalAssignee.id) ? '#fff' : '#E6E6E8' }}
                                bgcolor={
                                  assigneeIndex === index || clickedAssignee?.includes(internalAssignee.id) ?
                                    '#CCF0EB' : '#fff'
                                }
                              >
                                {
                                  clickedAssignee?.includes(internalAssignee.id) ?
                                    //   <CheckIcon
                                    //   fontSize='small'
                                    //   htmlColor='#00B69B'
                                    //   style={{width:'20px', height:'20px'}}
                                    // />
                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }

                              </Grid>
                              <Grid item>{internalAssignee.name}</Grid>
                            </Grid>
                          )) :
                            filteredExternalAssigneesList?.map((externalAssignee: any, index: any) => (
                              <Grid
                                item
                                container
                                alignItems='center'
                                justifyContent='flex-start'
                                key={externalAssignee.id}
                                columnGap={2}
                                className={classes[assigneeIndex === index || clickedAssignee === externalAssignee.id ? 'filter-selected' : 'filter-not-selected']}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  // setClickedAssignee(prevAssigneeId => prevAssigneeId === externalAssignee.id ? null : externalAssignee.id);
                                  setClickedAssignee((prevSelectedAssignee: any = []) => {

                                    if (prevSelectedAssignee?.includes(externalAssignee.id)) {
                                      return prevSelectedAssignee?.filter((id: any) => id !== externalAssignee.id);
                                    }
                                    else {
                                      return [...prevSelectedAssignee, externalAssignee.id];
                                    }
                                  });

                                }}
                              >
                                <Grid
                                  className='bordered-box'
                                  style={{ borderColor: clickedAssignee?.includes(externalAssignee.id) ? '#fff' : '#E6E6E8' }}
                                  bgcolor={
                                    assigneeIndex === index || clickedAssignee?.includes(externalAssignee.id) ?
                                      '#CCF0EB' : '#fff'
                                  }
                                >
                                  {
                                    clickedAssignee?.includes(externalAssignee.id) ?
                                      //   <CheckIcon
                                      //   fontSize='small'
                                      //   htmlColor='#00B69B'
                                      //   style={{width:'20px', height:'20px'}}
                                      // />
                                      <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                      :
                                      <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  }

                                </Grid>
                                <Grid item>{externalAssignee.name}</Grid>
                              </Grid>
                            ))}

                        </Grid>
                        <Grid
                          item
                          container
                          alignItems="center"
                          className="black font-weight-400"
                          columnGap={1}
                          style={{ cursor: clickedAssignee ? 'pointer' : 'unset' }}
                          marginLeft={0.8}
                          onClick={() => {
                            setClickedAssignee([]);
                          }}
                        >
                          <RestartAltIcon fontSize="small" />
                          <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                        </Grid>
                        <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                          <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.stopPropagation();
                            // setClickedAssignee([]);
                            setClickedAssignee(selectedAssignee)
                            setActiveTooltip(null);
                            setIsAssigneeIconClicked(false);
                            // setOpenAssigneesDropdown(false);
                          }}>
                            <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                          </button>
                          <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            setOldRows([]);
                            setNoMoreRows(false);
                            e.stopPropagation();
                            setSelectedAssignee(clickedAssignee);
                            setActiveTooltip(null);
                            setIsAssigneeIconClicked(false);
                            // setOpenAssigneesDropdown(false);
                          }}>
                            <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                          </button>

                        </Grid>
                      </Grid>
                    </React.Fragment>
                  }
                >
                  {/* <ArrowDropDownIcon
              style={{
                transform: openAssigneesDropdown ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                  <img src={selectedAssignee?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', marginTop: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setClickedAssignee(selectedAssignee);
                    setClickedTaskName(selectedTaskName);
                    setClickedStatus(selectedStatus);
                    setClickedType(selectedType);
                    setClickedClient(selectedClient);
                    setClickedRecurring(selectedRecurring);
                    setClickedPeriod(selectedPeriod);
                    setClickedPriority(selectedPriority);
                    setClickedModule(selectedModule)
                    setIsAssigneeIconClicked(true);
                    setActiveTooltip((prev: any) => (prev === 'assignee' ? null : 'assignee'));
                  }} />
                </HtmlTooltip>
              </div>
            </Grid>
          </ClickAwayListener>
        );
      },

      renderCell: (params) => {
        if (params.row.id !== 'Empty') {
          return (
            <Grid item>
              {/* {internalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name ? internalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name
                :
                userType === 2 && externalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name ? externalAssigneesList?.find((i: any) => i.id === params.row.assigneeId)?.name
                  :
                  clientsList?.find((i: any) => i.id === params.row.clientId)?.users?.find((i: any) => i.id === params.row.assigneeId)?.name ? clientsList?.find((i: any) => i.id === params.row.clientId)?.users?.find((i: any) => i.id === params.row.assigneeId)?.name
                    : "Unassigned"} */}
              {params.row.assigneeName === null ? "UnAssigned" : params.row.assigneeName}
            </Grid >
          );
        } return null;
      },


    },
    {
      field: 'dueDate',
      headerName: intl.formatMessage({ id: 'task.due-date' }),
      width: 140,
      sortable: false,
      // renderHeader: (params) => {
      //   return (
      //     <Grid
      //       container
      //       className='table-header'
      //       alignItems='center'
      //       spacing={1}
      //       sx={{
      //         flexWrap: 'nowrap',
      //         justifyContent: 'flex-start'
      //       }}
      //     >
      //       <Grid item>
      //         <Message id="task.due-date" className="Field-input labels font-weight-400" />
      //       </Grid>
      //       <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
      //         <HtmlTooltip
      //           open={tooltipOpen}
      //           onClose={() => setTooltipOpen(false)}
      //           onOpen={() => setTooltipOpen(true)}
      //           PopperProps={{
      //             placement: "bottom-start",
      //             modifiers: [{
      //               name: "offset",
      //               options: {
      //                 offset: [-50, -10],
      //               },
      //             }]
      //           }}
      //           title={
      //             <div style={{
      //               padding: '16px',
      //               display: 'flex',
      //               justifyContent: 'center',
      //               alignItems: 'center'
      //             }}>
      //               <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      //                 <DateCalendar
      //                   views={['day']}
      //                   value={dueDate ? moment(dueDate, 'YYYY-MM-DD') : null}
      //                   onChange={handleDateChange}
      //                   slotProps={{ calendarHeader: { sx: { color: 'black' } } }}
      //                 />
      //               </LocalizationProvider>
      //             </div>
      //           }
      //         >
      //           {/* <ArrowDropDownIcon /> */}
      //           <img src={dueDate ? activeFilterIcon : filterIcon} style={{ cursor: 'pointer', borderRadius: '2px' }} />

      //         </HtmlTooltip>
      //       </Grid>
      //     </Grid>
      //   );
      // },
      renderCell: (params) => {
        if (params.row.id !== 'Empty') {
          return (<span>{moment(params.row.dueDate).format('DD.MM.YYYY')}</span>
          );
        } return null;
      },

    },
    {
      field: 'recurringtask', headerName: intl.formatMessage({ id: 'task.recurring-task' }), width: 236,
      // sortable: (activeTooltip !== 'recurringTask' && !isRecurringIconClicked),
      sortable: false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedRecurring(selectedRecurring)
            const target = event.target as HTMLElement;
            if (
              activeTooltip === 'recurringTask' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenModule(false);
              setActiveTooltip(null);
              setIsRecurringIconClicked(false);
            }
          }}>
            <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenModule((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'recurringTask' ? null : 'recurringTask'));
              if (activeTooltip !== 'recurringTask') {
                setIsRecurringIconClicked(false);
              }
              // e.stopPropagation();
            }}>
              <Message id="task.recurring-task" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'recurringTask'}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                      <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                        {recurringTaskList && recurringTaskList.length > 0 &&
                          recurringTaskList.map((m: any, index: number) => (
                            <Grid
                              key={index}
                              item
                              container
                              alignItems="center"
                              columnGap={2}
                              justifyContent="flex-start"
                              className='black'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              // onMouseOver={(e) => {
                              //   // const target = e.target as HTMLElement;
                              //   // if (clickedModule !== m.id) {
                              //   //   target.style.cursor = 'pointer';
                              //   //   setModuleIndex(index);
                              //   // }
                              //   const target = e.target as HTMLElement;
                              //   if (!clickedModule.includes(m.id)) {
                              //     target.style.cursor = 'pointer';
                              //     setModuleIndex(index);
                              //   }
                              // }}
                              // onMouseOut={() => {
                              //   setModuleIndex(-1);
                              // }}
                              onClick={() => {
                                setClickedRecurring((prev: any) => {
                                  const newValue = prev === m ? null : m;
                                  if (newValue === "Yes") {
                                    setIsRecurring(true);
                                  } else if (newValue === "No") {
                                    setIsRecurring(false);
                                  }
                                  return newValue;
                                });
                              }}

                            >
                              <Grid className='bordered-box'

                                style={{ borderColor: m === clickedRecurring ? '#fff' : '#E6E6E8' }}
                                bgcolor={m === clickedRecurring ? '#CCF0EB' : '#fff'}>
                                {
                                  m === clickedRecurring ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              {/* <Grid item>{m}</Grid> */}
                              <Grid item>
                                {m === 'Yes' ? <Message id="filter.yes" className="Field-input labels font-weight-400" /> : <Message id="filter.no" className="Field-input labels font-weight-400" />}
                              </Grid>
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedRecurring ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedRecurring('');
                          setIsRecurring(null);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedRecurring('');
                          // setIsOpenModule(false);
                          setClickedRecurring(selectedRecurring)
                          setActiveTooltip(null);
                          setIsRecurringIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedRecurring(clickedRecurring);
                          // setIsOpenModule(false);
                          setActiveTooltip(null);
                          setIsRecurringIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                <img src={selectedRecurring ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsRecurringIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'recurringTask' ? null : 'recurringTask'));
                }} />
              </HtmlTooltip>
            </Grid>
          </ClickAwayListener>
        )
      },
      renderCell: (params) => {
        if (params.row.id !== 'Empty') {
          return (<Grid item style={{ marginTop: '14px' }}>
            {params.row.recurringPeriod === 0 ? <Message id="filter.no" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : <Message id="filter.yes" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />}
          </Grid>);
        } return null;
      },

    },
    {
      field: 'recurringperiod', headerName: intl.formatMessage({ id: 'task.recurring-period' }), width: 245,
      // sortable: (activeTooltip !== 'recurring' && !isPeriodIconClicked),
      sortable: false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedPeriod(selectedPeriod)
            const target = event.target as HTMLElement;
            if (
              // isOpenPeriod &&
              activeTooltip === 'recurring' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenPeriod(false);
              setActiveTooltip(null);
              setIsPeriodIconClicked(false);
            }
          }}>
            <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenPeriod((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'recurring' ? null : 'recurring'));
              if (activeTooltip !== 'recurring') {
                setIsPeriodIconClicked(false);
              }
              // e.stopPropagation();
            }}>
              <Message id="task.recurring-period" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'recurring'}
                disableHoverListener
                disableFocusListener
                // onClose={() => setIsOpenPeriod(false)}
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                      <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                        {recurringPeriodsList && recurringPeriodsList.length > 0 &&
                          recurringPeriodsList.map((period: any, index: any) => (
                            <Grid
                              key={period.id}
                              item
                              container
                              alignItems="center"
                              columnGap={2}
                              //className={classes[recurringPeriodIndex === index || selectedPeriod === period.id ? 'filter-selected' : 'filter-not-selected']}
                              justifyContent="flex-start"
                              className='black'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onMouseOver={(e) => {
                                // const target = e.target as HTMLElement;
                                // if (clickedPeriod !== period.id) {
                                //   target.style.cursor = 'pointer';
                                //   setRecurringPeriodIndex(index);
                                // }
                                const target = e.target as HTMLElement;
                                if (!clickedPeriod?.includes(period.id)) {
                                  target.style.cursor = 'pointer';
                                  setPeriodIndex(index);
                                }
                              }}
                              onMouseOut={() => {
                                setRecurringPeriodIndex(-1);
                              }}
                              onClick={() => {
                                setClickedPeriod((prevSelectedPeriod: any) => {
                                  // if (prevSelectedPeriod === period.id) {
                                  //   return null;
                                  // } else {
                                  //   return period.id;
                                  // }
                                  if (prevSelectedPeriod?.includes(period.id)) {
                                    return prevSelectedPeriod?.filter((id: any) => id !== period.id);
                                  }
                                  else {
                                    return [...prevSelectedPeriod, period.id];
                                  }
                                });
                              }}
                            >
                              {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                recurringPeriodIndex === index || selectedPeriod === period.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  recurringPeriodIndex === index || selectedPeriod === period.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}

                              <Grid className='bordered-box'
                                // style={{ borderColor: clickedPeriod === period.id ? '#fff' : '#E6E6E8' }}
                                // bgcolor={clickedPeriod === period.id ? '#CCF0EB' : '#fff'}>
                                // {
                                //   clickedPeriod === period.id ?
                                style={{ borderColor: clickedPeriod?.includes(period.id) ? '#fff' : '#E6E6E8' }}
                                bgcolor={clickedPeriod?.includes(period.id) ? '#CCF0EB' : '#fff'}>
                                {
                                  clickedPeriod?.includes(period.id) ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              {/* <Grid item>{period.name}</Grid> */}
                              <Grid item>
                                {period.id === 1 ? <Message id="filter.daily" className="Field-input labels font-weight-400" /> : period.id === 2 ? <Message id="filter.weekly" className="Field-input labels font-weight-400" /> : period.id === 3 ? <Message id="filter.monthly" className="Field-input labels font-weight-400" /> : period.id === 4 ? <Message id="filter.quarterly" className="Field-input labels font-weight-400" /> : period.id === 5 ? <Message id="filter.yearly" className="Field-input labels font-weight-400" />
                                  : period.id === 6 ? 'Custom' : ""}
                              </Grid>
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedPeriod ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedPeriod([]);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedPeriod([]);
                          // setIsOpenPeriod(false);
                          setClickedPeriod(selectedPeriod)
                          setActiveTooltip(null);
                          setIsPeriodIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedPeriod(clickedPeriod);
                          // setIsOpenPeriod(false);
                          setActiveTooltip(null);
                          setIsPeriodIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
              style={{
                transform: isOpenPeriod ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                <img src={selectedPeriod?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsPeriodIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'recurring' ? null : 'recurring'));
                }}
                />
              </HtmlTooltip>
            </Grid>
          </ClickAwayListener>
        )
      },
      renderCell: (params) => {
        if (params.row.id !== 'Empty') {
          return (<Grid item style={{ marginTop: '14px' }}>
            {/* {params.row.recurringPeriod === 0 ? "N/A" : recurringPeriodsList?.find((i: any) => i.id === params.row.recurringPeriod)?.name} */}
            {params.row.recurringPeriod === 1 ? <Message id="filter.daily" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : params.row.recurringPeriod === 2 ? <Message id="filter.weekly" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : params.row.recurringPeriod === 3 ? <Message id="filter.monthly" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : params.row.recurringPeriod === 4 ? <Message id="filter.quarterly" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : params.row.recurringPeriod === 5 ? <Message id="filter.yearly" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> :
              params.row.recurringPeriod === 6 ? <Message id="filter.custom" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : <Message id="filter.na" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />}
          </Grid>);
        } return null;
      },

    },
    {
      field: 'priority', headerName: intl.formatMessage({ id: 'task.priority' }), width: 140,
      // sortable: (activeTooltip !== 'prio' && !isPriorityIconClicked),
      sortable: false,
      renderHeader: (params) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedPriority(selectedPriority)
            const target = event.target as HTMLElement;
            if (
              // isOpenModule &&
              activeTooltip === 'prio' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenModule(false);
              setActiveTooltip(null);
              setIsPriorityIconClicked(false);
            }
          }}>
            <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenModule((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'prio' ? null : 'prio'));
              if (activeTooltip !== 'prio') {
                setIsPriorityIconClicked(false);
              }
              // e.stopPropagation();
            }}>
              <Message id="task.priority" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'prio'}
                // onClose={() => setIsOpenModule(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                      <Grid item container style={{ height: '40px' }}>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'prio')}

                        // onChange={(e) => {
                        //   const searchTerm = e.target.value;
                        //   setPrioritySearch(searchTerm);

                        //   // If search term is empty, show full list
                        //   if (searchTerm.trim() === '') {
                        //     setFilteredPriorityList(priorityList);
                        //   } else {
                        //     // Filter clients based on search term
                        //     const filtered = priorityList.filter((priority: any) =>
                        //       priority.toString()?.includes(searchTerm)
                        //     );

                        //     setFilteredPriorityList(filtered);
                        //   }
                        // }}
                        /></Grid>
                      <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                        {filteredPriorityList && filteredPriorityList.length > 0 &&
                          filteredPriorityList.map((m: any, index: number) => (
                            <Grid
                              key={index}
                              item
                              container
                              alignItems="center"
                              columnGap={2}
                              //className={classes[moduleIndex === index || selectedModule === m.id ? 'filter-selected' : 'filter-not-selected']}
                              justifyContent="flex-start"
                              className='black'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              // onMouseOver={(e) => {
                              //   // const target = e.target as HTMLElement;
                              //   // if (clickedModule !== m.id) {
                              //   //   target.style.cursor = 'pointer';
                              //   //   setModuleIndex(index);
                              //   // }
                              //   const target = e.target as HTMLElement;
                              //   if (!clickedModule.includes(m.id)) {
                              //     target.style.cursor = 'pointer';
                              //     setModuleIndex(index);
                              //   }
                              // }}
                              // onMouseOut={() => {
                              //   setModuleIndex(-1);
                              // }}
                              onClick={() => {

                                setClickedPriority((prevSelectedPriority: any) => {
                                  if (prevSelectedPriority?.includes(m)) {
                                    return prevSelectedPriority?.filter((id: any) => id !== m);
                                  }
                                  else {
                                    return [...prevSelectedPriority, m];
                                  }
                                })
                              }}
                            >


                              <Grid className='bordered-box'

                                style={{ borderColor: clickedPriority?.includes(m) ? '#fff' : '#E6E6E8' }}
                                bgcolor={clickedPriority?.includes(m) ? '#CCF0EB' : '#fff'}>
                                {
                                  clickedPriority?.includes(m) ?


                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              <Grid item>{m}</Grid>
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedPriority ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedPriority([]);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedPriority([]);
                          // setIsOpenModule(false);
                          setClickedPriority(selectedPriority)
                          setActiveTooltip(null);
                          setIsPriorityIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedPriority(clickedPriority);
                          // setIsOpenModule(false);
                          setActiveTooltip(null);
                          setIsPriorityIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
          style={{
            transform: isOpenModule ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease', 
          }}
        /> */}
                <img src={selectedPriority?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsPriorityIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'prio' ? null : 'prio'));
                }} />
              </HtmlTooltip>
            </Grid>
          </ClickAwayListener>
        )
      }
    },
    {
      field: 'module', headerName: intl.formatMessage({ id: 'task.module' }), width: 125,
      // sortable: (activeTooltip !== 'module' && !isModuleIconClicked),
      sortable: false,
      renderHeader: (params: any) => {
        return (
          <ClickAwayListener onClickAway={(event) => {
            setClickedModule(selectedModule)
            const target = event.target as HTMLElement;
            if (
              // isOpenModule &&
              activeTooltip === 'module' &&
              !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
            ) {
              // setIsOpenModule(false);
              setActiveTooltip(null);
              setIsModuleIconClicked(false);
            }
          }}>
            <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // setIsOpenModule((prev) => !prev)
              setActiveTooltip((prev: any) => (prev === 'module' ? null : 'module'));
              if (activeTooltip !== 'module') {
                setIsModuleIconClicked(false);
              }
              // e.stopPropagation();
            }}>
              <Message id="task.module" className="Field-input labels font-weight-400" />
              <HtmlTooltip
                open={activeTooltip === 'module'}
                // onClose={() => setIsOpenModule(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                      <Grid item container style={{ height: '40px' }}>
                        <TextField
                          value={search}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e: any) => handleSearch(e, 'module')}

                        // onChange={(e) => {
                        //   const searchTerm = e.target.value;
                        //   setModuleSearch(searchTerm);

                        //   // If search term is empty, show full list
                        //   if (searchTerm.trim() === '') {
                        //     setFilteredModuleList(moduleList);
                        //   } else {
                        //     // Filter clients based on search term
                        //     const filtered = moduleList.filter((modules: any) =>
                        //       modules.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                        //     );

                        //     setFilteredModuleList(filtered);
                        //   }
                        // }}
                        /></Grid>
                      <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                        {filteredModuleList && filteredModuleList.length > 0 &&
                          filteredModuleList.map((m: any, index: number) => (
                            <Grid
                              key={m.id}
                              item
                              container
                              alignItems="center"
                              columnGap={2}
                              //className={classes[moduleIndex === index || selectedModule === m.id ? 'filter-selected' : 'filter-not-selected']}
                              justifyContent="flex-start"
                              className='black'
                              style={{
                                flexWrap: 'nowrap',
                                width: '100%',
                                fontSize: '14px',
                                marginBottom: '18px'
                              }}
                              onMouseOver={(e) => {
                                // const target = e.target as HTMLElement;
                                // if (clickedModule !== m.id) {
                                //   target.style.cursor = 'pointer';
                                //   setModuleIndex(index);
                                // }
                                const target = e.target as HTMLElement;
                                if (!clickedModule?.includes(m.id)) {
                                  target.style.cursor = 'pointer';
                                  setModuleIndex(index);
                                }
                              }}
                              onMouseOut={() => {
                                setModuleIndex(-1);
                              }}
                              onClick={() => {
                                // setClickedModule((prevSelectedModule: any) => {
                                //   if (prevSelectedModule === m.id) {
                                //     return null;
                                //   } else {
                                //     return m.id;
                                //   }
                                // });
                                setClickedModule((prevSelectedModule: any) => {
                                  if (prevSelectedModule?.includes(m.id)) {
                                    return prevSelectedModule?.filter((id: any) => id !== m.id);
                                  }
                                  else {
                                    return [...prevSelectedModule, m.id];
                                  }
                                })
                              }}
                            >
                              {/* <Grid
                              className='bordered-box'
                              bgcolor={
                                moduleIndex === index || selectedModule === m.id ?
                                  '#E3F2FD' : '#E0E0E0'
                              }
                            >
                              <CheckIcon
                                fontSize='small'
                                htmlColor={
                                  moduleIndex === index || selectedModule === m.id ?
                                    '#2196F3' : '#9E9E9E'
                                }
                              />
                            </Grid> */}

                              <Grid className='bordered-box'
                                // style={{ borderColor: clickedModule === m.id ? '#fff' : '#E6E6E8' }}
                                // bgcolor={clickedModule === m.id ? '#CCF0EB' : '#fff'}>
                                // {
                                //   clickedModule === m.id ?
                                style={{ borderColor: clickedModule?.includes(m.id) ? '#fff' : '#E6E6E8' }}
                                bgcolor={clickedModule?.includes(m.id) ? '#CCF0EB' : '#fff'}>
                                {
                                  clickedModule?.includes(m.id) ?
                                    // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }
                              </Grid>
                              {/* <Grid item>{m.name}</Grid> */}
                              <Grid item>
                                {m.id === 1 ? <Message id="filter.general" className="Field-input labels font-weight-400" />

                                  : <Message id="filter.hr" className="Field-input labels font-weight-400" />}
                              </Grid>
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedModule ? 'pointer' : 'unset' }}
                        onClick={() => {
                          setClickedModule([]);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedModule([]);
                          // setIsOpenModule(false);
                          setClickedModule(selectedModule)
                          setActiveTooltip(null);
                          setIsModuleIconClicked(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedModule(clickedModule);
                          // setIsOpenModule(false);
                          setActiveTooltip(null);
                          setIsModuleIconClicked(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
              style={{
                transform: isOpenModule ? 'rotate(180deg)' : 'rotate(0deg)', 
                transition: 'transform 0.3s ease', 
              }}
            /> */}
                <img src={selectedModule?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsModuleIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'module' ? null : 'module'));
                }} />
              </HtmlTooltip>
            </Grid>
          </ClickAwayListener>
        );
      },
      renderCell: (params) => {
        if (params.row.id !== 'Empty') {
          return (<Grid item style={{ marginTop: '14px' }} >
            {/* {moduleList?.find((s: any) => s.id === params.row.module)?.name} */}
            {
              params.row.module === 1 ? <Message id="filter.general" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />

                : <Message id="filter.hr" className={params.row.notifications?.length > 0 ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />
            }
          </Grid >);
        } return null;
      },

    },
    //{ field: 'description', headerName: intl.formatMessage({ id: 'task.description' }), width: 600 },
    // { field: 'startdate', headerName: intl.formatMessage({ id: 'task.start-date' }), width: 115 },
    // {
    //   field: 'relevantperiod', headerName: intl.formatMessage({ id: 'task.relevant-period' }), width: 110, sortable: false,
    // },

    // {
    //   field: 'blockedby', headerName: intl.formatMessage({ id: 'task.blocked-by' }), width: 140, sortable: false,
    // },
    // {
    //   field: 'blocking', headerName: intl.formatMessage({ id: 'task.blocking' }), width: 140, sortable: false,
    // },
    {
      field: 'note', headerName: intl.formatMessage({ id: 'task.note' }), width: 300, sortable: false,
    }
  ]




  const updateTaskCall = () => {
    dispatch(setLoading(true));
    updateTask({
      userId: selectedTask.userId,
      //payload
    }).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''));
        }, 4500);
      } else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          dispatch(setActionCallFrom(''));
          dispatch(setModify(!modify));
        }, 1000);
      }
      dispatch(setLoading(false));
    });
  };

  const checkNeedToAddRows = () => {
    const gridContainer = gridRef.current;
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
      const totalRowsHeight = rowHeight * rows.length;

      if (totalRowsHeight <= virtualScroller.clientHeight) {
        console.log("Vertical scrollbar is hidden (no scroll is needed).");
        return true
      } else {
        console.log("Vertical scrollbar is visible (scrolling is possible).");
        return false
      }
    }
  }

  const deleteTaskCall = () => {
    dispatch(setLoading(true));
    deleteTask(selectedTaskToDelete.id).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus('Fail'));
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''))
        }, 4500)
      }
      else {
        dispatch(setCallStatus('Pass'));
        setRows(rows.filter((r: any) => r.id !== selectedTaskToDelete.id))
        setOriginalTasksTable(rows.filter((r: any) => r.id !== selectedTaskToDelete.id))
        console.log("statusif", selectedTaskToDelete.status)
        if (selectedTaskToDelete.status === statusesConsts.CLOSED_STATUS_ID) setDoneTasks(doneTasks - 1);
        else {
          setOpenTasks(openTasks - 1);
          if (selectedTaskToDelete.status === statusesConsts.OVERDUE_STATUS_ID) {
            console.log("testttt")
            setOverdueTasks(overdueTasks - 1);
          }
          else if (selectedTaskToDelete.status === statusesConsts.NEW_STATUS_ID) setNewTasks(newTasks - 1);
        }
        setTimeout(() => {
          dispatch(setErrorMsg(''));
          dispatch(setCallStatus(''));
          setOpenDeleteDialog(false);
          //dispatch(setModify(!modify));
          if (checkNeedToAddRows()) {
            setLoadMoreRows(true);
            setOldRows(rows.filter((r: any) => r.id !== selectedTaskToDelete.id));
            setTake(3)
          }

        }, 1000)
      }
      dispatch(setLoading(false));
    })
  }


  const duplicateTaskCall = (taskObject: any, buttonNb: any) => {
    dispatch(setLoading(true));
    duplicateTask(selectedTaskToDuplicate.taskId, taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          if (buttonNb === 1) {
            setOpen(false);
            dispatch(setModify(!modify));
          }
          if (buttonNb === 2) navigate(`/task-details?id=${x}`)

        }, 1000);
      }
      dispatch(setLoading(false));
    })

  }

  const handlePaginationModelChange = (model: any) => {
    // const adjustedPage = Math.max(1, model.page);
    // // Catch the new page number
    // setPageNb(adjustedPage);
    // setPageSize(model.pageSize);
    // Update the pagination model
    setPaginationModel(model);
  };


  const getRowClassName = (params: any) => {
    return params.row.notifications?.length ? 'bold-row' : ''; // Apply bold class if notifications are present
  };

  // const [atBottom, setAtBottom] = useState(false);
  // const scrollContainerRef = useRef<HTMLDivElement>(null);

  // const handleScroll = () => {
  //   if (scrollContainerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
  //     if (scrollTop + clientHeight >= scrollHeight) {
  //       setAtBottom(true);
  //     } else {
  //       setAtBottom(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const scrollContainer = scrollContainerRef.current;
  //   if (scrollContainer) {
  //     scrollContainer.addEventListener("scroll", handleScroll);
  //     return () => {
  //       scrollContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, []);

  const [atBottom, setAtBottom] = useState(false);

  // const gridRef = useRef<any>(null);

  const prevScrollLeft = useRef<number>(0); // Track the previous horizontal scroll position
  const prevScrollTop = useRef<number>(0); // Track the previous vertical scroll position

  // useEffect(() => {
  //   const handleScroll = (event: Event) => {
  //     const target = event.target as HTMLElement;

  //     // Check if we're inside the DataGrid's virtual scroller content
  //     if (target && target.classList.contains("MuiDataGrid-virtualScroller")) {
  //       const { scrollTop, scrollHeight, clientHeight, scrollLeft } = target;

  //       const isVerticalScroll = Math.abs(scrollTop - prevScrollTop.current) > Math.abs(scrollLeft - prevScrollLeft.current);

  //       // Log the scroll position and sizes
  //       //console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`);
  //       // if (scrollLeft === 0 || scrollLeft === scrollWidth - clientHeight) {
  //       // Determine if the user has reached the bottom

  //       if(notLoad &&  prevScrollLeft.current !== scrollLeft ) setNotLoad(false)


  //       if (isVerticalScroll && !notLoad) {
  //         if (scrollTop + clientHeight >= scrollHeight - 1) {
  //           // console.log("Reached bottom of the scroll!");
  //           // if (!scrollingToEmptyRow) addEmptyRowAndLoad()
  //           setAtBottom(true);
  //           setLoadMoreRows(true);
  //           setOldRows(rows);
  //           setNoVerticalScroll(false);

  //         } else {
  //           //console.log("Not at the bottom yet");
  //           setAtBottom(false);
  //         }
  //         // Update the previous scrollTop for future comparisons
  //         prevScrollTop.current = scrollTop;
  //       }


  //       // Update the previous scrollLeft for detecting horizontal scroll changes
  //       prevScrollLeft.current = scrollLeft;
  //       // }
  //     }
  //   };

  //   const gridContainer = gridRef.current;
  //   if (gridContainer) {
  //     // Get the virtual scroller container from the DataGrid
  //     const virtualScroller = gridContainer.querySelector(
  //       ".MuiDataGrid-virtualScroller"
  //     );

  //     if (virtualScroller) {
  //       // Attach the scroll event listener
  //       //console.log("Found virtual scroller container");
  //       virtualScroller.addEventListener("scroll", handleScroll);
  //     } else {
  //       //console.log("MuiDataGrid-virtualScroller not found");
  //     }
  //   } else {
  //     //console.log("Grid container not found");
  //   }

  //   return () => {
  //     const gridContainer = gridRef.current;
  //     if (gridContainer) {
  //       const virtualScroller = gridContainer.querySelector(
  //         ".MuiDataGrid-virtualScroller"
  //       );
  //       if (virtualScroller) {
  //         // Remove the scroll event listener when the component unmounts
  //         virtualScroller.removeEventListener("scroll", handleScroll);
  //       }
  //     }
  //   };
  // });

  const checkNeedToAddEmptyRow = () => {
    const gridContainer = gridRef.current;
    if (gridContainer) {
      // Get the virtual scroller container from the DataGrid
      const virtualScroller = gridContainer.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
      const totalRowsHeight = rowHeight * (rows.length);

      if (totalRowsHeight >= virtualScroller.clientHeight) {
        return true
      } else {
        return false
      }
    }
  }

  // useEffect(() => {
  //   const gridContainer = gridRef.current;
  //   if (gridContainer) {
  //     // Get the virtual scroller container from the DataGrid
  //     const virtualScroller = gridContainer.querySelector(
  //       ".MuiDataGrid-virtualScroller"
  //     );
  //     const rowHeight = 52; // This is the row height in px, you may need to adjust based on your grid configuration.
  //     const totalRowsHeight = rowHeight * rows.length;

  //     if (totalRowsHeight <= virtualScroller.clientHeight) {
  //       console.log("Vertical scrollbar is hidden (no scroll is needed).");
  //       setNoVerticalScroll(true)
  //       const emptyExists = rows.find((r: any) => r.id === 'Empty') ? true : false
  //       if (!emptyExists && checkNeedToAddEmptyRow()) {
  //         console.log("rows", rows)
  //         let newUserbj = {
  //           id: "Empty",
  //           email: null,
  //           firstName: null,
  //           lastName: null,
  //           phoneNumber: null,
  //           position: null,
  //           creationDate: null,
  //           statusId: 0,
  //           roles: null,
  //           lastActiveDate: null,
  //           taskCount: 0

  //         }
  //         let tmp = [...rows];
  //         tmp.splice(rows?.length, 0, newUserbj);
  //         setRows(tmp);

  //       }


  //     } else {
  //       console.log("Vertical scrollbar is visible (scrolling is possible).");
  //       setNoVerticalScroll(false)

  //     }
  //   }

  // }, [rows])

  // let screenHeight = window.innerHeight; // Get the height of the screen
  // let tableHeight = screenHeight - 440
  const handleResize = () => {
    console.log("rowssss here", rows)

    const screenHeight = window.innerHeight;
    // if (screenHeight < oldHeight) {
    //   console.log("Screen is being minimized");

    // } else if (screenHeight > oldHeight) {
    //   console.log("Screen is being maximized");
    // }

    setTableHeight(screenHeight - 440); // Dynamically update the table height

    if (screenHeight >= 950) {
      console.log("rows", rows)
      setTake(4)
      setLoadMoreRows(true)
    }
    // setOldHeight(screenHeight);

  };

  const addEmptyRowAndLoad = () => {
    setScrollingToEmptyRow(true)
    console.log("testttt", rows)
    if (!scrollingToEmptyRow) {

      let newTaskObj = {
        id: "Empty",
        title: null,
        description: null,
        note: null,
        recurringPeriod: null,
        priority: null,
        dueDate: null,
        module: null,
        modulesName: null,
        status: null,
        type: null,
        startDate: null,
        clientId: null,
        clientName: null,
        assigneeId: null,
        isCreator: true,
        comments: null,
        history: null,
        toBeTagedUsers: null,
        documents: null,
        reminders: [],
        notifications: []

      }
      if (rows.length > 0) {
        let tmp = [...rows];
        tmp.splice(rows?.length, 0, newTaskObj);
        setRows(tmp);
      }

      scrollToBottom();

    }
  }

  const scrollToBottom = () => {
    if (gridRef.current) {
      const virtualScroller = gridRef.current.querySelector('.MuiDataGrid-virtualScroller');
      if (virtualScroller) {
        virtualScroller.scrollTop = virtualScroller.scrollHeight;
        // setAtBottom(true);
        // setLoadMoreRows(true);
        // setOldRows(rows);
      }
    }
  };

  useEffect(() => {
    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("activet", activeTooltip)
  }, [activeTooltip])

  const data: any[] = rows;


  const columns: MRT_ColumnDef<any>[] = [
    {
      id: 'actions',
      header: '',
      size: 50, // control width
      enableSorting: false,
      enableColumnFilter: false,
      enableResizing: false,
      Cell: ({ row }) => {
        const reminders = row.original.reminders;
        const hasReminders = reminders && Array.isArray(reminders) && reminders.length > 0;
        setReminderTypeId(reminders && reminders[0] ? reminders[0].typeId : null);
        const tmpReminderId = reminders && reminders[0] ? reminders[0].typeId : null

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Grid item container rowGap={1.5} padding={1.5} maxWidth='180px' onClick={(e) => e.stopPropagation()}>
                  <Grid item container direction='row' className='neutral-4 labels pointerText' alignItems='center' columnGap={1}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/task-details?id=${row.original.id}`)
                      setSelectedTask(() => ({
                        userId: row.original.id,
                      }));
                    }}
                  >
                    <EditOutlinedIcon htmlColor='black' fontSize='small' /><Message id="task.edit" className="black Field-input labelsLinks font-weight-400 font-14" />
                  </Grid>

                  <Grid item container direction='row' className='black labels pointerText normalFontWeight' alignItems='center' columnGap={1}
                    onClick={() => {

                    }}
                  >
                    <NotificationsActiveOutlinedIcon htmlColor='black' fontSize='small' /><Message id="task.set-reminder" className="black Field-input labelsLinks font-weight-400 font-14" />
                    <HtmlTooltip
                      placement='right-start'
                      title={
                        <React.Fragment>
                          <Grid item container rowGap={1.5} padding={2} maxWidth='250px' className='font-14 font-weight-400 black'>

                            {remindersList?.map((r: any) => (
                              <>
                                <Grid item key={r.id}
                                  // className={hasReminders && reminderTypeId === r.id ? 'bold pointerUnset' : 'normalFontWeight pointerText'}
                                  onClick={() => {
                                    //setOldRows([]);
                                    let tmp = {
                                      taskId: row.original.id,
                                      date: null,
                                      typeId: r.id
                                    }
                                    setTaskId(row.original.id);
                                    setReminderTypeId(r.id)

                                    if (!hasReminders) {
                                      postReminderCall(tmp, null, row.original.id)
                                    }
                                    else postReminderCall(tmp, reminderTypeId, row.original.id)

                                  }}>
                                  {r.name === 'Tomorrow' ? <Message id="task.remind-tomorrow" className={hasReminders && tmpReminderId === r.id ? 'bold pointerUnset font-14' : 'normalFontWeight pointerText font-14'} /> :
                                    r.name === 'InTwoDays' ? <Message id="task.remind-in-2-days" className={hasReminders && tmpReminderId === r.id ? 'bold pointerUnset font-14' : 'normalFontWeight pointerText font-14'} /> :
                                      r.name === 'TwoDaysBeforeDue' ? <Message id="task.remind-before-duedate" className={hasReminders && tmpReminderId === r.id ? 'bold pointerUnset font-14' : 'normalFontWeight pointerText font-14'} /> :
                                        ""}

                                </Grid>
                                {r.name === 'Custom' ?
                                  <Grid item
                                    className={hasReminders && tmpReminderId === r.id ? 'bold pointerText' : 'normalFontWeight pointerText'}
                                    onClick={() => {
                                      //setOldRows([]);
                                      if (hasReminders && reminderTypeId) setReminderDate(reminders?.[0].date)
                                      // if (params.row.reminders.length === 0) {
                                      setTaskId(row.original.id);
                                      setReminderTypeId(r.id)
                                      setOpenCustomReminder(true);
                                      setSelectedRowReminders(reminders);
                                      // }
                                    }
                                    }
                                  >
                                    {hasReminders && tmpReminderId === r.id ?
                                      <Message id="task.change-custom.reminder" className={hasReminders && tmpReminderId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} />
                                      : <Message id="task.custom-reminder" className={hasReminders && tmpReminderId === r.id ? 'bold pointerText font-14' : 'normalFontWeight pointerText font-14'} />}

                                  </Grid> : ""}
                              </>

                            ))}
                            {/* <Grid item>Remind me tomorrow</Grid>
                      <Grid item>Remind me in 2 days</Grid>
                      <Grid item>Remind me 2 days before Due date</Grid>
                      <Grid item
                        onClick={() => setOpenCustomReminder(true)}
                      > Set Custom Reminder
                      </Grid> */}
                            <Grid item
                              className={hasReminders ? 'pointerText' : 'pointerUnset'}
                              onClick={() => {
                                if (hasReminders) {
                                  inActiveReminderCall(reminders[0]?.id, taskId)
                                }
                              }
                              }
                            >
                              <Message id="task.remove-reminder" className={hasReminders ? 'bold pointerText font-14' : 'normalFontWeight font-14'} /></Grid>
                          </Grid>
                        </React.Fragment>
                      }>
                      <ArrowRightOutlinedIcon htmlColor='#93919a' />
                    </HtmlTooltip>
                  </Grid>

                  <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                    onClick={() => {
                      // setOldRows([])
                      setOriginalTaskId(row.original.id)
                      setSelectedTaskToDuplicate(() => ({
                        taskId: row.original.id
                      }))

                      setTaskObject(() => ({
                        title: `${row.getValue('title')} (copy)`,
                        status: 1,
                        description: row.original.description,
                        note: row.getValue('note'),
                        recurringPeriod: row.original.recurringPeriod,
                        priority: row.getValue('priority'),
                        dueDate: row.getValue('dueDate') ? moment(row.getValue('dueDate')).format('YYYY-MM-DD') : null,
                        startDate: row.original.startDate ? moment(row.original.startDate).format('YYYY-MM-DD') : null,
                        type: row.getValue('type'),
                        clientId: row.original.clientId?.toLowerCase(),
                        assigneeId: row.original.assigneeId,
                        assigneeName: row.getValue('assigneeName'),
                        module: row.getValue('module')
                      }))
                      setOpen(true);
                    }}
                  >
                    <ContentCopyOutlinedIcon htmlColor='black' fontSize='small' width='20px' height='20px' /> <Message id="task.duplicate" className="black Field-input labelsLinks font-weight-400 font-14" />
                  </Grid>

                  <Grid item container direction='row' className="black Field-input labelsLinks font-weight-400 font-14" alignItems='center' columnGap={1}
                    onClick={() => {
                      if (row.getValue('status') !== StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(row.original.id, StatusesConsts.CLOSED_STATUS_ID, row.getValue('status'))
                      if (row.getValue('status') === StatusesConsts.CLOSED_STATUS_ID) updateTaskStatusCall(row.original.id, StatusesConsts.OPEN_STATUS_ID, row.getValue('status'))
                    }}
                  >
                    <CheckIcon htmlColor='black' fontSize='small' />
                    {row.getValue('status') === statusesConsts.CLOSED_STATUS_ID ? <Message id="task.open-task" className="black Field-input labelsLinks font-weight-400 font-14" /> : <Message id="task.complete-task" className="black Field-input labelsLinks font-weight-400 font-14" />}
                  </Grid>

                  {userRoles?.includes(userRolesConst.ADMIN_ID)
                    || userRoles?.includes(userRolesConst.TAX_ADVISOR_ID)
                    || userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID) ?
                    <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                      onClick={() => {
                        //setOldRows([]);
                        setselectedTaskToDelete(() => ({
                          id: row.original.id,
                          title: row.getValue('title'),
                          status: row.getValue('status')
                        }));
                        setOpenDeleteDialog(true);
                      }}
                    >
                      <img src={deleteIcon} width='24px' height='24px' />
                      <Message id="ta.delete" className="red Field-input labelsLinks font-weight-400 font-14" />

                      {/* <DeleteOutlineIcon htmlColor='#EF4737' fontSize='small' /><*/}
                    </Grid>
                    :
                    <></>
                  }

                </Grid>
              </React.Fragment>
            }
          >
            <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
          </HtmlTooltip>
        )
      }
    },
    {
      accessorKey: 'title', //access nested data with dot notation
      header: 'Name',
      minSize: 130,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedTaskName(selectedTaskName);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'name' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            setActiveTooltip(null);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setActiveTooltip((prev: any) => (prev === 'name' ? null : 'name'));
          }}>
            <Message id="task.task" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'name'}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'name')}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                      className='font-14 black normalFontWeight' padding={1}
                    >
                      {filteredTaskNameList?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val} columnGap={1}
                          // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if (!clickedRoles?.includes(val.id)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          // onMouseOut={() => {
                          //   setFilterIndex(-1);
                          // }}
                          onClick={(e) => {
                            setClickedTaskName((prevSelected: any) => {
                              if (prevSelected.includes(val)) {
                                return prevSelected.filter((v: any) => v !== val);
                              }
                              else {
                                return [...prevSelected, val];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedTaskName?.includes(val) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedTaskName?.includes(val) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedTaskName?.includes(val) ?
                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          <Grid item>
                            {val}
                          </Grid>
                        </Grid>
                      ))}

                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedTaskName.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedTaskName([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        setActiveTooltip(null);
                        setClickedTaskName(selectedTaskName);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedTaskName(clickedTaskName)
                        setActiveTooltip(null);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <img src={selectedTaskName.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedTaskName(selectedTaskName);
                  setActiveTooltip((prev: any) => (prev === 'name' ? null : 'name'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      // Custom cell renderer
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          // <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
          //   {row.getValue('title')}
          // </Grid>
          <Grid item container direction='row' justifyContent='space-between' alignItems='center' flexWrap='nowrap'>
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
          {row.getValue('title')}
          </Grid>
          {notifications?.length > 0 ?
            <Grid paddingTop={1}>
              {notifications?.find((n: any) => n.type === taskConsts.comment_Id) ?
                <img src={messageNotificationIcon} width='24px' height='24px' alt='icon' style={{ paddingRight: '8px' }} />
                :

                <></>
              }
              {notifications?.find((n: any) => n.type === taskConsts.file_Id) ?
                <img src={fileNotificationIcon} width='15px' height='24px' alt='icon' />
                :
                <></>}
            </Grid>
            :
            <></>}

        </Grid>
        )
      }
    },
    {
      accessorKey: 'status', //access nested data with dot notation
      header: 'Status',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedStatus(selectedStatus)
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'status' &&
            // isOpenStatus &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenStatus(false);
            setActiveTooltip(null);
            setIsStatusIconClicked(false);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenStatus((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
            if (activeTooltip !== 'status') {
              setIsStatusIconClicked(false);
            }
            // e.stopPropagation();
          }}>
            <Message id="user.status" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'status'}
              // onClose={() => setIsOpenStatus(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>

                    <Grid item container style={{ height: '40px' }}>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'status')}

                      // onChange={(e) => {
                      //   const searchTerm = e.target.value;
                      //   setStatusSearch(searchTerm);

                      //   // If search term is empty, show full list
                      //   if (searchTerm.trim() === '') {
                      //     setFilteredStatusList(statusesList);
                      //   } else {
                      //     // Filter clients based on search term
                      //     const filtered = statusesList.filter((status: any) =>
                      //       status.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                      //     );

                      //     setFilteredStatusList(filtered);
                      //   }
                      // }}
                      /></Grid>
                    <Grid container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {filteredStatusList && filteredStatusList.length > 0 &&
                        // filteredStatusList.filter((status: any) => status.id !== 2).map((status: any, index: number) => (
                        filteredStatusList?.map((status: any, index: number) => (
                          <Grid
                            key={status.id}
                            item
                            container
                            alignItems='center'
                            columnGap={2}
                            //className={classes[(filterIndex === index || selectedStatus.includes(status.id)) ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            className='black'

                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (!clickedStatus?.includes(status.id)) {
                                target.style.cursor = 'pointer';
                                setFilterIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setFilterIndex(-1);
                            }}
                            onClick={() => {
                              setClickedStatus((prevSelectedStatus: any) => {
                                if (prevSelectedStatus?.includes(status.id)) {
                                  return prevSelectedStatus?.filter((id: any) => id !== status.id);
                                }
                                else {
                                  return [...prevSelectedStatus, status.id];
                                }
                              });
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedStatus?.includes(status.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedStatus?.includes(status.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedStatus?.includes(status.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid item>{status.name}</Grid> */}
                            <Grid item>
                              {status.id === statusesConsts.NEW_STATUS_ID ? <Message id="filter.new" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.OPEN_STATUS_ID ? <Message id="filter.open" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.OVERDUE_STATUS_ID ? <Message id="filter.overdue" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? <Message id="filter.inprogress" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.CLOSED_STATUS_ID ? <Message id="filter.closed" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? <Message id="filter.waiting-for-others" className="Field-input labels font-weight-400" /> : status.id === statusesConsts.UPCOMING_STATUS_ID ? <Message id="filter.upcoming" className="Field-input labels font-weight-400" /> : ''}
                            </Grid>

                            {/* <Grid
                            className='bordered-box'
                            bgcolor={
                              filterIndex === index || selectedStatus.includes(status.id) ? (
                                status.id === statusesConsts.NEW_STATUS_ID ? '#E3F2FD' :
                                  status.id === statusesConsts.OPEN_STATUS_ID ? '#E8F5E9' :
                                    status.id === statusesConsts.OVERDUE_STATUS_ID ? '#FFEBEE' :
                                      status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? '#FFF3E0' :
                                        status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#F3E5F5' :
                                          status.id === statusesConsts.CLOSED_STATUS_ID ? '#EEEEEE' :
                                            status.id === statusesConsts.UPCOMING_STATUS_ID ? '#E1F5FE' :
                                              '#E0E0E0'
                              ) : '#E0E0E0'
                            }
                          >
                            <CheckIcon
                              fontSize='small'
                              htmlColor={
                                filterIndex === index || selectedStatus.includes(status.id) ? (
                                  status.id === statusesConsts.NEW_STATUS_ID ? '#2196F3' :
                                    status.id === statusesConsts.OPEN_STATUS_ID ? '#4CAF50' :
                                      status.id === statusesConsts.OVERDUE_STATUS_ID ? '#F44336' :
                                        status.id === statusesConsts.IN_PROGRESS_STATUS_ID ? '#FF9800' :
                                          status.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#9C27B0' :
                                            status.id === statusesConsts.CLOSED_STATUS_ID ? '#616161' :
                                              status.id === statusesConsts.UPCOMING_STATUS_ID ? '#03A9F4' :
                                                '#9E9E9E'
                                ) : '#9E9E9E'
                              }
                            />
                          </Grid> */}
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems='center'
                      className='black font-weight-400'
                      columnGap={1}
                      style={{ cursor: clickedStatus ? 'pointer' : 'unset' }}
                      marginLeft={0.8}
                      marginTop={0.5}
                      onClick={() => {
                        setClickedStatus([]);
                      }}
                    >
                      <RestartAltIcon fontSize='small' />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedStatus([]);
                        // setIsOpenStatus(false);  
                        setClickedStatus(selectedStatus)
                        setActiveTooltip(null);
                        setIsStatusIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedStatus(clickedStatus)
                        // setIsOpenStatus(false);
                        console.log("clicked", clickedStatus)
                        if (clickedStatus.length === openStatusesIds.length && openStatusesIds.every((status: any) => clickedStatus.includes(status))) setIsOpenCardSelected(true);
                        else setIsOpenCardSelected(false)
                        setActiveTooltip(null);
                        setIsStatusIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>

                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
            style={{
              transform: isOpenStatus ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          /> */}
              <img src={selectedStatus.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setClickedTaskName(selectedTaskName);
                setClickedStatus(selectedStatus);
                setClickedType(selectedType);
                setClickedClient(selectedClient);
                setClickedRecurring(selectedRecurring);
                setClickedPeriod(selectedPeriod);
                setClickedPriority(selectedPriority);
                setClickedModule(selectedModule)
                setIsStatusIconClicked(true);
                setActiveTooltip((prev: any) => (prev === 'status' ? null : 'status'));
              }} />
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <HtmlTooltip
            open={openTooltipRowId === row.original.id && row.getValue<number>('status') !== StatusesConst.CLOSED_STATUS_ID}
            onClose={() => setOpenTooltipRowId(null)}
            placement='bottom'
            title={
              <React.Fragment>
                <Grid item container direction='column' rowGap={1.5} padding={2} maxWidth='270px' className='font-14 font-weight-400 black'>
                  {allStatusesList?.map((option: any) => (
                    <>
                      {option.id === StatusesConst.IN_PROGRESS_STATUS_ID || option.id === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ?
                        <Grid item
                          key={option.id}
                          style={{
                            color: option.id !== row.getValue<number>('status') ? 'black' : '#93919A',
                            cursor: option.id !== row.getValue<number>('status') ? 'pointer' : 'unset'
                          }}
                          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            e.stopPropagation();
                            if (option.id !== row.getValue<number>('status')) updateTaskStatusCall(row.original.id, option.id, row.getValue<number>('status'))
                            setOpenTooltipRowId(null);
                          }}

                        >
                          {option.id === StatusesConst.IN_PROGRESS_STATUS_ID ? "In Progress" :
                            option.id === StatusesConst.WAITING_FOR_OTHERS_STATUS_ID ? "Waiting For Others" : ""}
                        </Grid>
                        :
                        <></>
                      }

                    </>
                  ))}
                </Grid>
              </React.Fragment>
            }>
            <Grid item container direction='row' columnGap={1.5} marginTop={1} data-type="tooltip-icon"
              className='pointerText'
              onMouseEnter={() => setHoverStatus(row.original.id)}
              onMouseLeave={() => setHoverStatus(null)}
              onClick={(e) => {
                if (row.getValue<number>('status') === statusesConsts.CLOSED_STATUS_ID) {
                  e.stopPropagation();
                }
              }}>
              <Grid item
                className={classes[
                  row.getValue<number>('status') === statusesConsts.NEW_STATUS_ID ? 'new-task' :
                    row.getValue<number>('status') === statusesConsts.OPEN_STATUS_ID ? 'open-task' :
                      row.getValue<number>('status') === statusesConsts.OVERDUE_STATUS_ID ? 'overdue-task' :
                        row.getValue<number>('status') === statusesConsts.IN_PROGRESS_STATUS_ID ? 'inprogress-task' :
                          row.getValue<number>('status') === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? 'completed-task' :
                            row.getValue<number>('status') === statusesConsts.CLOSED_STATUS_ID ? 'closed-task' :
                              row.getValue<number>('status') === statusesConsts.UPCOMING_STATUS_ID ? 'upcoming-task' : ''

                ]}>

                {/* {statusesList?.find((s: any) => s.id === params.row.status)?.name} */}
                {
                  row.getValue<number>('status') === statusesConsts.NEW_STATUS_ID ?
                    <Message id="filter.new" className="Field-input labels font-weight-700" />
                    : row.getValue<number>('status') === statusesConsts.OPEN_STATUS_ID ?
                      <Message id="filter.open" className="Field-input labels font-weight-700" />
                      : row.getValue<number>('status') === statusesConsts.OVERDUE_STATUS_ID ?
                        <Message id="filter.overdue" className="Field-input labels font-weight-700" />
                        : row.getValue<number>('status') === statusesConsts.IN_PROGRESS_STATUS_ID ?
                          <Message id="filter.inprogress" className="Field-input labels font-weight-700" />
                          : row.getValue<number>('status') === statusesConsts.CLOSED_STATUS_ID ?
                            <Message id="filter.closed" className="Field-input labels font-weight-700" />
                            : row.getValue<number>('status') === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ?
                              <Message id="filter.waiting-for-others" className="Field-input labels font-weight-700" />
                              : row.getValue<number>('status') === statusesConsts.UPCOMING_STATUS_ID ?
                                <Message id="filter.upcoming" className="Field-input labels font-weight-700" />
                                :
                                ""
                }

                {row.getValue<number>('status') === statusesConsts.OVERDUE_STATUS_ID ?
                  <LocalFireDepartmentOutlinedIcon sx={{ fontSize: 18, marginLeft: 0.5 }} />

                  :
                  <></>
                }
                {hoverStatus === row.original.id && row.getValue<number>('status') !== statusesConsts.CLOSED_STATUS_ID ?
                  <KeyboardArrowDownIcon
                    data-type="tooltip-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (openTooltipRowId === row.original.id) {
                        setOpenTooltipRowId(null);
                      } else {
                        setOpenTooltipRowId(row.original.id);
                      }
                    }}
                    htmlColor={
                      row.getValue<number>('status') === statusesConsts.NEW_STATUS_ID ? '#1280BE' :
                        row.getValue<number>('status') === statusesConsts.OPEN_STATUS_ID ? '#9F7E3A' :
                          row.getValue<number>('status') === statusesConsts.OVERDUE_STATUS_ID ? '#fff' :
                            row.getValue<number>('status') === statusesConsts.IN_PROGRESS_STATUS_ID ? '#00B69B' :
                              row.getValue<number>('status') === statusesConsts.WAITING_FOR_OTHERS_STATUS_ID ? '#6853A9' :
                                row.getValue<number>('status') === statusesConsts.CLOSED_STATUS_ID ? '#fff' :
                                  row.getValue<number>('status') === statusesConsts.UPCOMING_STATUS_ID ? '#FF62E7' : ''

                    } />
                  : <></>}


              </Grid>

            </Grid>
          </HtmlTooltip>
        )
      }
    },
    {
      accessorKey: 'type', //access nested data with dot notation
      header: 'Type',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedType(selectedType)
          const target = event.target as HTMLElement;
          if (
            // isOpenType &&
            activeTooltip === 'type' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenType(false);
            setActiveTooltip(null);
            setIsTypeIconClicked(false);
          }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenType((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'type' ? null : 'type'));
            if (activeTooltip !== 'type') {
              setIsTypeIconClicked(false);
            }
            // e.stopPropagation();
          }}>
            <Message id="task.type" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'type'}
              // onClose={() => setIsOpenType(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                    <Grid item container style={{ height: '40px' }}>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'type')}

                      // onChange={(e) => {
                      //   const searchTerm = e.target.value;
                      //   setTypeSearch(searchTerm);

                      //   // If search term is empty, show full list
                      //   if (searchTerm.trim() === '') {
                      //     setFilteredTypesList(typesList);
                      //   } else {
                      //     // Filter clients based on search term
                      //     const filtered = typesList.filter((type: any) =>
                      //       type.name.toLowerCase()?.includes(searchTerm.toLowerCase())
                      //     );

                      //     setFilteredTypesList(filtered);
                      //   }
                      // }}
                      /></Grid>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {filteredTypesList && filteredTypesList.length > 0 &&
                        filteredTypesList.map((type: any, index: number) => (
                          <Grid
                            key={type.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[typeIndex === index || selectedType === type.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onMouseOver={(e) => {
                              // const target = e.target as HTMLElement;
                              // if (clickedType !== type.id) {
                              //   target.style.cursor = 'pointer';
                              //   setTypeIndex(index);
                              // }
                              const target = e.target as HTMLElement;
                              if (!clickedType?.includes(type.id)) {
                                target.style.cursor = 'pointer';
                                setTypeIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setTypeIndex(-1);
                            }}
                            onClick={() => {
                              setClickedType((prevSelectedType: any) => {
                                // if (prevSelectedType === type.id) {
                                //   return null;
                                // } else {
                                //   return type.id;
                                // }
                                if (prevSelectedType?.includes(type.id)) {
                                  return prevSelectedType?.filter((id: any) => id !== type.id);
                                }
                                else {
                                  return [...prevSelectedType, type.id];
                                }
                              });
                            }}
                          >
                            {/* <Grid
                            className='bordered-box'
                            bgcolor={
                              typeIndex === index || selectedType === type.id ?
                                '#E3F2FD' : '#E0E0E0'
                            }
                          >
                            <CheckIcon
                              fontSize='small'
                              htmlColor={
                                typeIndex === index || selectedType === type.id ?
                                  '#2196F3' : '#9E9E9E'
                              }
                            />
                          </Grid> */}

                            <Grid className='bordered-box'
                              // style={{ borderColor: clickedType === type.id ? '#fff' : '#E6E6E8' }}
                              // bgcolor={clickedType === type.id ? '#CCF0EB' : '#fff'}>
                              // {
                              //   clickedType === type.id ?
                              style={{ borderColor: clickedType?.includes(type.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedType?.includes(type.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedType?.includes(type.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid item>{type.name}</Grid> */}
                            <Grid item>
                              {typesList?.find((s: any) => s.id === type.id)?.name}
                              {/* {type.id === 1 ? <Message id="filter.type-one" className="Field-input labels font-weight-400" /> : type.id === 2 ? <Message id="filter.type-two" className="Field-input labels font-weight-400" /> : type.id === 3 ? <Message id="filter.type-three" className="Field-input labels font-weight-400" /> : ''} */}
                            </Grid>
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="black font-weight-400"
                      columnGap={1}
                      style={{ cursor: clickedType ? 'pointer' : 'unset' }}
                      onClick={() => {
                        setClickedType([]);
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedType([]);
                        // setIsOpenType(false);
                        setClickedType(selectedType)
                        setActiveTooltip(null);
                        setIsTypeIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedType(clickedType);
                        // setIsOpenType(false);
                        setActiveTooltip(null);
                        setIsTypeIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
            style={{
              transform: isOpenType ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          /> */}
              <img src={selectedType?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setClickedTaskName(selectedTaskName);
                setClickedStatus(selectedStatus);
                setClickedType(selectedType);
                setClickedClient(selectedClient);
                setClickedRecurring(selectedRecurring);
                setClickedPeriod(selectedPeriod);
                setClickedPriority(selectedPriority);
                setClickedModule(selectedModule)
                setIsTypeIconClicked(true);
                setActiveTooltip((prev: any) => (prev === 'type' ? null : 'type'));
              }} />
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {typesList?.find((s: any) => s.id === row.getValue<number>('type'))?.name}
            {/* {row.getValue<number>('type') === 1 ?
              <Message id="filter.type-one" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />
              :
              row.getValue<number>('type') === 2 ? <Message id="filter.type-two" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />
                : row.getValue<number>('type') ? <Message id="filter.type-three" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />
                  : ''} */}
          </Grid>
        )
      }
    },
    {
      accessorKey: 'clientName', //access nested data with dot notation
      header: 'Client',
      enableHiding: true,
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedClient(selectedClient);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'client' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            setActiveTooltip(null);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setActiveTooltip((prev: any) => (prev === 'name' ? null : 'name'));
          }}>
            <Message id="task.client" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'client'}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'client')}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                      className='font-14 black normalFontWeight' padding={1}
                    >
                      {filteredClientsList?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val} columnGap={1}
                          // className={classes[filterIndex === index || selectedRoles?.includes(val.id) ? 'filter-selected' : 'filter-not-selected']}
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          // onMouseOver={(e) => {
                          //   const target = e.target as HTMLElement;
                          //   if (!clickedRoles?.includes(val.id)) {
                          //     target.style.cursor = 'pointer';
                          //     setFilterIndex(index);
                          //   }
                          // }}
                          // onMouseOut={() => {
                          //   setFilterIndex(-1);
                          // }}
                          onClick={(e) => {
                            setClickedClient((prevSelected: any) => {
                              if (prevSelected.includes(val)) {
                                return prevSelected.filter((v: any) => v !== val);
                              }
                              else {
                                return [...prevSelected, val];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedClient?.includes(val) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedClient?.includes(val) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedClient?.includes(val) ?
                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          <Grid item>
                            {val}
                          </Grid>
                        </Grid>
                      ))}

                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedClient.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedClient([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        setActiveTooltip(null);
                        setClickedClient(selectedClient);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedClient(clickedClient)
                        setActiveTooltip(null);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <img src={selectedClient.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedClient(selectedClient);
                  setActiveTooltip((prev: any) => (prev === 'client' ? null : 'client'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {row.getValue('clientName')}
            {/* {typesList?.find((s: any) => s.id === params.row.type)?.name} */}
          </Grid>
        )
      }
    },
    {
      accessorKey: 'assigneeName', //access nested data with dot notation
      header: 'Assginee',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedAssignee(selectedAssignee)
          const target = event.target as HTMLElement;
          if (
            // openAssigneesDropdown &&
            activeTooltip === 'assignee' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setOpenAssigneesDropdown(false);
            setActiveTooltip(null);
            setIsAssigneeIconClicked(false);
          }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal">
            <Message id="task.assignee" className="Field-input labels font-weight-400" />
            <div
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                // setOpenAssigneesDropdown((prev) => !prev)
                setActiveTooltip((prev: any) => (prev === 'assignee' ? null : 'assignee'));
                if (activeTooltip !== 'assignee') {
                  setIsAssigneeIconClicked(false);
                }
                // e.stopPropagation();
              }}
            >
              <HtmlTooltip
                open={activeTooltip === 'assignee'}
                // onClose={() => setOpenAssigneesDropdown(false)}
                disableHoverListener
                disableFocusListener
                title={
                  <React.Fragment>
                    <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                      <Grid item container direction='row' columnGap={2} marginBottom={1.5} justifyContent='center'>
                        <Grid
                          item
                          className={classes[assigneesType === 'Internal' ? "internal-external-active" : "internal-external-button"]}
                          onClick={() => setAssigneesType('Internal')}
                        >
                          Internal
                        </Grid>
                        <Grid
                          item
                          className={classes[assigneesType === 'External' ? "internal-external-active" : "internal-external-button"]}
                          onClick={() => setAssigneesType('External')}
                        >
                          External
                        </Grid>
                      </Grid>
                      <Grid item container style={{ height: '40px' }}>
                        <TextField
                          value={assigneesType === "External" ? externalAssigneeSearch : internalAssigneeSearch}
                          fullWidth
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                            style: {
                              borderRadius: '35px',
                              fontFamily: 'DM Sans',
                              height: '37px',
                              backgroundColor: '#F7F9FF',
                              border: 0,
                              borderColor: '#E6E6E8',
                              fontSize: '12px',
                              marginBottom: '10px'
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-input::placeholder': {
                              fontWeight: 'bold',
                            },
                          }}
                          placeholder={intl.formatMessage({ id: 'text.search' })}
                          onChange={(e) => {
                            const searchTerm = e.target.value;
                            if (assigneesType === 'External') {
                              setExternalAssigneeSearch(searchTerm);
                              if (searchTerm.trim() === '') {
                                setFilteredExternalAssigneesList(externalAssigneesList);
                              } else {
                                const filtered = externalAssigneesList.filter((externalAssignee: any) =>
                                  externalAssignee.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                                );
                                setFilteredExternalAssigneesList(filtered);
                              }
                            } else {
                              setInternalAssigneeSearch(searchTerm);
                              if (searchTerm.trim() === '') {
                                setFilteredInternalAssigneesList(internalAssigneesList);
                              } else {
                                const filtered = internalAssigneesList?.filter((internalAssignee: any) =>
                                  internalAssignee.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                                );
                                setFilteredInternalAssigneesList(filtered);
                              }
                            }
                          }}
                        /></Grid>
                      <Grid item container direction="row" rowGap={0.5} padding={2} style={{ maxHeight: '190px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }} className='font-14 black normalFontWeight'>
                        {assigneesType === 'Internal' ? filteredInternalAssigneesList?.map((internalAssignee: any, index: any) => (
                          <Grid
                            item
                            container
                            alignItems='center'
                            key={internalAssignee.id}
                            columnGap={2}
                            // className={classes[assigneeIndex === index || clickedAssignee === internalAssignee.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent='flex-start'
                            style={{
                              cursor: 'pointer',
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onClick={() => {
                              // setClickedAssignee(prevAssigneeId => prevAssigneeId === internalAssignee.id ? null : internalAssignee.id);
                              setClickedAssignee((prevSelectedAssignee: any = []) => {
                                if (prevSelectedAssignee.includes(internalAssignee.id)) {
                                  return prevSelectedAssignee.filter((id: any) => id !== internalAssignee.id);
                                } else {
                                  return [...prevSelectedAssignee, internalAssignee.id];
                                }
                              });
                            }}
                          >

                            <Grid
                              className='bordered-box'
                              style={{ borderColor: clickedAssignee?.includes(internalAssignee.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={
                                assigneeIndex === index || clickedAssignee?.includes(internalAssignee.id) ?
                                  '#CCF0EB' : '#fff'
                              }
                            >
                              {
                                clickedAssignee?.includes(internalAssignee.id) ?
                                  //   <CheckIcon
                                  //   fontSize='small'
                                  //   htmlColor='#00B69B'
                                  //   style={{width:'20px', height:'20px'}}
                                  // />
                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }

                            </Grid>
                            <Grid item>{internalAssignee.name}</Grid>
                          </Grid>
                        )) :
                          filteredExternalAssigneesList?.map((externalAssignee: any, index: any) => (
                            <Grid
                              item
                              container
                              alignItems='center'
                              justifyContent='flex-start'
                              key={externalAssignee.id}
                              columnGap={2}
                              className={classes[assigneeIndex === index || clickedAssignee === externalAssignee.id ? 'filter-selected' : 'filter-not-selected']}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                // setClickedAssignee(prevAssigneeId => prevAssigneeId === externalAssignee.id ? null : externalAssignee.id);
                                setClickedAssignee((prevSelectedAssignee: any = []) => {

                                  if (prevSelectedAssignee?.includes(externalAssignee.id)) {
                                    return prevSelectedAssignee?.filter((id: any) => id !== externalAssignee.id);
                                  }
                                  else {
                                    return [...prevSelectedAssignee, externalAssignee.id];
                                  }
                                });

                              }}
                            >
                              <Grid
                                className='bordered-box'
                                style={{ borderColor: clickedAssignee?.includes(externalAssignee.id) ? '#fff' : '#E6E6E8' }}
                                bgcolor={
                                  assigneeIndex === index || clickedAssignee?.includes(externalAssignee.id) ?
                                    '#CCF0EB' : '#fff'
                                }
                              >
                                {
                                  clickedAssignee?.includes(externalAssignee.id) ?
                                    //   <CheckIcon
                                    //   fontSize='small'
                                    //   htmlColor='#00B69B'
                                    //   style={{width:'20px', height:'20px'}}
                                    // />
                                    <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                    :
                                    <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                                }

                              </Grid>
                              <Grid item>{externalAssignee.name}</Grid>
                            </Grid>
                          ))}

                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        className="black font-weight-400"
                        columnGap={1}
                        style={{ cursor: clickedAssignee ? 'pointer' : 'unset' }}
                        marginLeft={0.8}
                        onClick={() => {
                          setClickedAssignee([]);
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                        <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                      </Grid>
                      <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                        <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          e.stopPropagation();
                          // setClickedAssignee([]);
                          setClickedAssignee(selectedAssignee)
                          setActiveTooltip(null);
                          setIsAssigneeIconClicked(false);
                          // setOpenAssigneesDropdown(false);
                        }}>
                          <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                        </button>
                        <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                          setOldRows([]);
                          setNoMoreRows(false);
                          e.stopPropagation();
                          setSelectedAssignee(clickedAssignee);
                          setActiveTooltip(null);
                          setIsAssigneeIconClicked(false);
                          // setOpenAssigneesDropdown(false);
                        }}>
                          <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                        </button>

                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              >
                {/* <ArrowDropDownIcon
            style={{
              transform: openAssigneesDropdown ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          /> */}
                <img src={selectedAssignee?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', marginTop: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedAssignee(selectedAssignee);
                  setClickedTaskName(selectedTaskName);
                  setClickedStatus(selectedStatus);
                  setClickedType(selectedType);
                  setClickedClient(selectedClient);
                  setClickedRecurring(selectedRecurring);
                  setClickedPeriod(selectedPeriod);
                  setClickedPriority(selectedPriority);
                  setClickedModule(selectedModule)
                  setIsAssigneeIconClicked(true);
                  setActiveTooltip((prev: any) => (prev === 'assignee' ? null : 'assignee'));
                }} />
              </HtmlTooltip>
            </div>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {row.getValue('assigneeName') === null ? "UnAssigned" : row.getValue<string>('assigneeName')}
            {/* {typesList?.find((s: any) => s.id === params.row.type)?.name} */}
          </Grid>
        )
      }
    },
    {
      accessorKey: 'dueDate',
      header: 'Due Date',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          const target = event.target as HTMLElement;
          if (activeTooltip === 'dueDate' && !target.closest('.table-header')) {
            setDueDateRange([
              {
                startDate: selectedDueDates.length > 0 ? selectedDueDates[0] : null,
                endDate: selectedDueDates.length > 1 ? selectedDueDates[1] : null,
                key: 'selection'
              }
            ])
            setActiveTooltip(null);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setActiveTooltip((prev: any) => (prev === 'dueDate' ? null : 'dueDate'));
          }}>
            <Message id="task.due-date" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'dueDate'}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
                    rowGap={0.5} padding='8px 10px 8px 8px' display='inline-grid' width='307px'>
                    <DateRange
                      style={{ maxWidth: '307px' }}
                      editableDateInputs={false}
                      showDateDisplay={false}
                      onChange={(item: any) => setDueDateRange([{
                        startDate: item.selection.startDate || null,
                        endDate: item.selection.endDate || null,
                        key: 'selection'
                      }])}
                      moveRangeOnFirstSelection={false}
                      ranges={[{
                        startDate: dueDateRange[0].startDate || new Date(),
                        endDate: dueDateRange[0].endDate || new Date(),
                        key: 'selection'
                      }]}
                      locale={enGB}
                      className="custom-date-range"
                      rangeColors={['#5D5FEF']}
                    />
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e) => {
                        e.stopPropagation();
                        setActiveTooltip(null);
                        setClickedDueDates(selectedDueDates);
                        setDueDateRange([
                          {
                            startDate: selectedDueDates.length > 0 ? selectedDueDates[0] : null,
                            endDate: selectedDueDates.length > 1 ? selectedDueDates[1] : null,
                            key: 'selection'
                          }
                        ])
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e) => {
                        e.stopPropagation();
                        if (dueDateRange[0].startDate && dueDateRange[0].endDate) {
                          setSelectedDueDates([
                            dueDateRange[0].startDate.toISOString(),
                            dueDateRange[0].endDate.toISOString()
                          ]);
                        } else {
                          setSelectedDueDates([]);
                        }
                        setActiveTooltip(null);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <img src={selectedDueDates.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setClickedDueDates(selectedDueDates);
                    setActiveTooltip((prev: any) => (prev === 'dueDate' ? null : 'dueDate'));
                  }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),

      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {/* {clientsList?.find((i: any) => i.id === params.row.clientId)?.name} */}
            {moment(row.getValue<string>('dueDate')).format('DD.MM.YYYY')}
          </Grid>
        )
      }

    },
    {
      accessorKey: 'recurringtask', //access nested data with dot notation
      header: 'Recurring Task',
      minSize: 200,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedRecurring(selectedRecurring)
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'recurringTask' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenModule(false);
            setActiveTooltip(null);
            setIsRecurringIconClicked(false);
          }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenModule((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'recurringTask' ? null : 'recurringTask'));
            if (activeTooltip !== 'recurringTask') {
              setIsRecurringIconClicked(false);
            }
            // e.stopPropagation();
          }}>
            <Message id="task.recurring-task" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'recurringTask'}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {recurringTaskList && recurringTaskList.length > 0 &&
                        recurringTaskList.map((m: any, index: number) => (
                          <Grid
                            key={index}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   // const target = e.target as HTMLElement;
                            //   // if (clickedModule !== m.id) {
                            //   //   target.style.cursor = 'pointer';
                            //   //   setModuleIndex(index);
                            //   // }
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedModule.includes(m.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setModuleIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setModuleIndex(-1);
                            // }}
                            onClick={() => {
                              setClickedRecurring((prev: any) => {
                                const newValue = prev === m ? null : m;
                                if (newValue === "Yes") {
                                  setIsRecurring(true);
                                } else if (newValue === "No") {
                                  setIsRecurring(false);
                                }
                                return newValue;
                              });
                            }}

                          >
                            <Grid className='bordered-box'

                              style={{ borderColor: m === clickedRecurring ? '#fff' : '#E6E6E8' }}
                              bgcolor={m === clickedRecurring ? '#CCF0EB' : '#fff'}>
                              {
                                m === clickedRecurring ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid item>{m}</Grid> */}
                            <Grid item>
                              {m === 'Yes' ? <Message id="filter.yes" className="Field-input labels font-weight-400" /> : <Message id="filter.no" className="Field-input labels font-weight-400" />}
                            </Grid>
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="black font-weight-400"
                      columnGap={1}
                      style={{ cursor: clickedRecurring ? 'pointer' : 'unset' }}
                      onClick={() => {
                        setClickedRecurring('');
                        setIsRecurring(null);
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedRecurring('');
                        // setIsOpenModule(false);
                        setClickedRecurring(selectedRecurring)
                        setActiveTooltip(null);
                        setIsRecurringIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedRecurring(clickedRecurring);
                        // setIsOpenModule(false);
                        setActiveTooltip(null);
                        setIsRecurringIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <img src={selectedRecurring ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setClickedTaskName(selectedTaskName);
                setClickedStatus(selectedStatus);
                setClickedType(selectedType);
                setClickedClient(selectedClient);
                setClickedRecurring(selectedRecurring);
                setClickedPeriod(selectedPeriod);
                setClickedPriority(selectedPriority);
                setClickedModule(selectedModule)
                setIsRecurringIconClicked(true);
                setActiveTooltip((prev: any) => (prev === 'recurringTask' ? null : 'recurringTask'));
              }} />
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {row.getValue('recurringPeriod') === 0 ? <Message id="filter.no" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : <Message id="filter.yes" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />}
          </Grid>
        )
      }
    },
    {
      accessorKey: 'recurringPeriod', //access nested data with dot notation
      header: 'Recurring Period',
      minSize: 200,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedPeriod(selectedPeriod)
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'recurring' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            setActiveTooltip(null);
            setIsPeriodIconClicked(false);
          }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setActiveTooltip((prev: any) => (prev === 'recurring' ? null : 'recurring'));
            if (activeTooltip !== 'recurring') {
              setIsPeriodIconClicked(false);
            }
            // e.stopPropagation();
          }}>
            <Message id="task.recurring-period" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'recurring'}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {recurringPeriodsList && recurringPeriodsList.length > 0 &&
                        recurringPeriodsList.map((period: any, index: any) => (
                          <Grid
                            key={period.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onMouseOver={(e) => {
                              const target = e.target as HTMLElement;
                              if (!clickedPeriod?.includes(period.id)) {
                                target.style.cursor = 'pointer';
                                setPeriodIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setRecurringPeriodIndex(-1);
                            }}
                            onClick={() => {
                              setClickedPeriod((prevSelectedPeriod: any) => {
                                if (prevSelectedPeriod?.includes(period.id)) {
                                  return prevSelectedPeriod?.filter((id: any) => id !== period.id);
                                }
                                else {
                                  return [...prevSelectedPeriod, period.id];
                                }
                              });
                            }}
                          >
                            <Grid className='bordered-box'
                              style={{ borderColor: clickedPeriod?.includes(period.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedPeriod?.includes(period.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedPeriod?.includes(period.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>
                              {period.id === 1 ? <Message id="filter.daily" className="Field-input labels font-weight-400" /> : period.id === 2 ? <Message id="filter.weekly" className="Field-input labels font-weight-400" /> : period.id === 3 ? <Message id="filter.monthly" className="Field-input labels font-weight-400" /> : period.id === 4 ? <Message id="filter.quarterly" className="Field-input labels font-weight-400" /> : period.id === 5 ? <Message id="filter.yearly" className="Field-input labels font-weight-400" />
                                : period.id === 6 ? 'Custom' : ""}
                            </Grid>
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="black font-weight-400"
                      columnGap={1}
                      style={{ cursor: clickedPeriod ? 'pointer' : 'unset' }}
                      onClick={() => {
                        setClickedPeriod([]);
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        setClickedPeriod(selectedPeriod)
                        setActiveTooltip(null);
                        setIsPeriodIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedPeriod(clickedPeriod);
                        setActiveTooltip(null);
                        setIsPeriodIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <img src={selectedPeriod?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setClickedTaskName(selectedTaskName);
                setClickedStatus(selectedStatus);
                setClickedType(selectedType);
                setClickedClient(selectedClient);
                setClickedRecurring(selectedRecurring);
                setClickedPeriod(selectedPeriod);
                setClickedPriority(selectedPriority);
                setClickedModule(selectedModule)
                setIsPeriodIconClicked(true);
                setActiveTooltip((prev: any) => (prev === 'recurring' ? null : 'recurring'));
              }}
              />
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item>
            {/* {params.row.recurringPeriod === 0 ? "N/A" : recurringPeriodsList?.find((i: any) => i.id === params.row.recurringPeriod)?.name} */}
            {row.getValue('recurringPeriod') === 1 ? <Message id="filter.daily" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : row.getValue('recurringPeriod') === 2 ? <Message id="filter.weekly" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : row.getValue('recurringPeriod') === 3 ? <Message id="filter.monthly" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : row.getValue('recurringPeriod') === 4 ? <Message id="filter.quarterly" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : row.getValue('recurringPeriod') === 5 ? <Message id="filter.yearly" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> :
              row.getValue('recurringPeriod') === 6 ? <Message id="filter.custom" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} /> : <Message id="filter.na" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />}
          </Grid>
        )
      }
    },
    {
      accessorKey: 'priority', //access nested data with dot notation
      header: 'Priority',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedPriority(selectedPriority)
          const target = event.target as HTMLElement;
          if (
            // isOpenModule &&
            activeTooltip === 'prio' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenModule(false);
            setActiveTooltip(null);
            setIsPriorityIconClicked(false);
          }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenModule((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'prio' ? null : 'prio'));
            if (activeTooltip !== 'prio') {
              setIsPriorityIconClicked(false);
            }
            // e.stopPropagation();
          }}>
            <Message id="task.priority" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'prio'}
              // onClose={() => setIsOpenModule(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                    <Grid item container style={{ height: '40px' }}>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'prio')}

                      // onChange={(e) => {
                      //   const searchTerm = e.target.value;
                      //   setPrioritySearch(searchTerm);

                      //   // If search term is empty, show full list
                      //   if (searchTerm.trim() === '') {
                      //     setFilteredPriorityList(priorityList);
                      //   } else {
                      //     // Filter clients based on search term
                      //     const filtered = priorityList.filter((priority: any) =>
                      //       priority.toString()?.includes(searchTerm)
                      //     );

                      //     setFilteredPriorityList(filtered);
                      //   }
                      // }}
                      /></Grid>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {filteredPriorityList && filteredPriorityList.length > 0 &&
                        filteredPriorityList.map((m: any, index: number) => (
                          <Grid
                            key={index}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[moduleIndex === index || selectedModule === m.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            // onMouseOver={(e) => {
                            //   // const target = e.target as HTMLElement;
                            //   // if (clickedModule !== m.id) {
                            //   //   target.style.cursor = 'pointer';
                            //   //   setModuleIndex(index);
                            //   // }
                            //   const target = e.target as HTMLElement;
                            //   if (!clickedModule.includes(m.id)) {
                            //     target.style.cursor = 'pointer';
                            //     setModuleIndex(index);
                            //   }
                            // }}
                            // onMouseOut={() => {
                            //   setModuleIndex(-1);
                            // }}
                            onClick={() => {

                              setClickedPriority((prevSelectedPriority: any) => {
                                if (prevSelectedPriority?.includes(m)) {
                                  return prevSelectedPriority?.filter((id: any) => id !== m);
                                }
                                else {
                                  return [...prevSelectedPriority, m];
                                }
                              })
                            }}
                          >


                            <Grid className='bordered-box'

                              style={{ borderColor: clickedPriority?.includes(m) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedPriority?.includes(m) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedPriority?.includes(m) ?


                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            <Grid item>{m}</Grid>
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="black font-weight-400"
                      columnGap={1}
                      style={{ cursor: clickedPriority ? 'pointer' : 'unset' }}
                      onClick={() => {
                        setClickedPriority([]);
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedPriority([]);
                        // setIsOpenModule(false);
                        setClickedPriority(selectedPriority)
                        setActiveTooltip(null);
                        setIsPriorityIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedPriority(clickedPriority);
                        // setIsOpenModule(false);
                        setActiveTooltip(null);
                        setIsPriorityIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
        style={{
          transform: isOpenModule ? 'rotate(180deg)' : 'rotate(0deg)', 
          transition: 'transform 0.3s ease', 
        }}
      /> */}
              <img src={selectedPriority?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setClickedTaskName(selectedTaskName);
                setClickedStatus(selectedStatus);
                setClickedType(selectedType);
                setClickedClient(selectedClient);
                setClickedRecurring(selectedRecurring);
                setClickedPeriod(selectedPeriod);
                setClickedPriority(selectedPriority);
                setClickedModule(selectedModule)
                setIsPriorityIconClicked(true);
                setActiveTooltip((prev: any) => (prev === 'prio' ? null : 'prio'));
              }} />
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {row.getValue<string>('priority')}
          </Grid>
        )
      }
    },
    {
      accessorKey: 'module', //access nested data with dot notation
      header: 'Module',
      minSize: 50,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedModule(selectedModule)
          const target = event.target as HTMLElement;
          if (
            // isOpenModule &&
            activeTooltip === 'module' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            // setIsOpenModule(false);
            setActiveTooltip(null);
            setIsModuleIconClicked(false);
          }
        }}>
          <Grid item container className="table-header" alignItems="center" fontWeight="normal" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            // setIsOpenModule((prev) => !prev)
            setActiveTooltip((prev: any) => (prev === 'module' ? null : 'module'));
            if (activeTooltip !== 'module') {
              setIsModuleIconClicked(false);
            }
            // e.stopPropagation();
          }}>
            <Message id="task.module" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'module'}
              // onClose={() => setIsOpenModule(false)}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} style={{ maxHeight: '378px', boxShadow: '0px 2px 4px 0px #0000001F', padding: '10px', borderRadius: '13px' }}>
                    <Grid item container style={{ height: '40px' }}>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'module')}

                      // onChange={(e) => {
                      //   const searchTerm = e.target.value;
                      //   setModuleSearch(searchTerm);

                      //   // If search term is empty, show full list
                      //   if (searchTerm.trim() === '') {
                      //     setFilteredModuleList(moduleList);
                      //   } else {
                      //     // Filter clients based on search term
                      //     const filtered = moduleList.filter((modules: any) =>
                      //       modules.name.toLowerCase()?.includes(searchTerm?.toLowerCase())
                      //     );

                      //     setFilteredModuleList(filtered);
                      //   }
                      // }}
                      /></Grid>
                    <Grid item container rowGap={0.5} padding={2} style={{ maxHeight: '228px', overflowY: 'auto', overflowX: 'hidden', width: '307px' }}>
                      {filteredModuleList && filteredModuleList.length > 0 &&
                        filteredModuleList.map((m: any, index: number) => (
                          <Grid
                            key={m.id}
                            item
                            container
                            alignItems="center"
                            columnGap={2}
                            //className={classes[moduleIndex === index || selectedModule === m.id ? 'filter-selected' : 'filter-not-selected']}
                            justifyContent="flex-start"
                            className='black'
                            style={{
                              flexWrap: 'nowrap',
                              width: '100%',
                              fontSize: '14px',
                              marginBottom: '18px'
                            }}
                            onMouseOver={(e) => {
                              // const target = e.target as HTMLElement;
                              // if (clickedModule !== m.id) {
                              //   target.style.cursor = 'pointer';
                              //   setModuleIndex(index);
                              // }
                              const target = e.target as HTMLElement;
                              if (!clickedModule?.includes(m.id)) {
                                target.style.cursor = 'pointer';
                                setModuleIndex(index);
                              }
                            }}
                            onMouseOut={() => {
                              setModuleIndex(-1);
                            }}
                            onClick={() => {
                              // setClickedModule((prevSelectedModule: any) => {
                              //   if (prevSelectedModule === m.id) {
                              //     return null;
                              //   } else {
                              //     return m.id;
                              //   }
                              // });
                              setClickedModule((prevSelectedModule: any) => {
                                if (prevSelectedModule?.includes(m.id)) {
                                  return prevSelectedModule?.filter((id: any) => id !== m.id);
                                }
                                else {
                                  return [...prevSelectedModule, m.id];
                                }
                              })
                            }}
                          >
                            {/* <Grid
                            className='bordered-box'
                            bgcolor={
                              moduleIndex === index || selectedModule === m.id ?
                                '#E3F2FD' : '#E0E0E0'
                            }
                          >
                            <CheckIcon
                              fontSize='small'
                              htmlColor={
                                moduleIndex === index || selectedModule === m.id ?
                                  '#2196F3' : '#9E9E9E'
                              }
                            />
                          </Grid> */}

                            <Grid className='bordered-box'
                              // style={{ borderColor: clickedModule === m.id ? '#fff' : '#E6E6E8' }}
                              // bgcolor={clickedModule === m.id ? '#CCF0EB' : '#fff'}>
                              // {
                              //   clickedModule === m.id ?
                              style={{ borderColor: clickedModule?.includes(m.id) ? '#fff' : '#E6E6E8' }}
                              bgcolor={clickedModule?.includes(m.id) ? '#CCF0EB' : '#fff'}>
                              {
                                clickedModule?.includes(m.id) ?
                                  // <CheckIcon fontSize='small' htmlColor='#00B69B' style={{ width: '20px', height: '20px' }} />

                                  <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                  :
                                  <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                              }
                            </Grid>
                            {/* <Grid item>{m.name}</Grid> */}
                            <Grid item>
                              {m.id === 1 ? <Message id="filter.general" className="Field-input labels font-weight-400" />

                                : <Message id="filter.hr" className="Field-input labels font-weight-400" />}
                            </Grid>
                          </Grid>
                        ))}

                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      className="black font-weight-400"
                      columnGap={1}
                      style={{ cursor: clickedModule ? 'pointer' : 'unset' }}
                      onClick={() => {
                        setClickedModule([]);
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                      <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        // setClickedModule([]);
                        // setIsOpenModule(false);
                        setClickedModule(selectedModule)
                        setActiveTooltip(null);
                        setIsModuleIconClicked(false);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedModule(clickedModule);
                        // setIsOpenModule(false);
                        setActiveTooltip(null);
                        setIsModuleIconClicked(false);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              {/* <ArrowDropDownIcon
            style={{
              transform: isOpenModule ? 'rotate(180deg)' : 'rotate(0deg)', 
              transition: 'transform 0.3s ease', 
            }}
          /> */}
              <img src={selectedModule?.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                setClickedTaskName(selectedTaskName);
                setClickedStatus(selectedStatus);
                setClickedType(selectedType);
                setClickedClient(selectedClient);
                setClickedRecurring(selectedRecurring);
                setClickedPeriod(selectedPeriod);
                setClickedPriority(selectedPriority);
                setClickedModule(selectedModule)
                setIsModuleIconClicked(true);
                setActiveTooltip((prev: any) => (prev === 'module' ? null : 'module'));
              }} />
            </HtmlTooltip>
          </Grid>
        </ClickAwayListener>
      ),
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {
              row.getValue<number>('module') === 1 ? <Message id="filter.general" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />

                : <Message id="filter.hr" className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"} />
            }
          </Grid>
        )
      }
    },
    {
      accessorKey: 'note', //access nested data with dot notation
      header: 'Note',
      minSize: 250,
      enableResizing: false,
      Header: ({ column }) => (
        <ClickAwayListener onClickAway={(event) => {
          setClickedNotes(selectedNotes);
          const target = event.target as HTMLElement;
          if (
            activeTooltip === 'note' &&
            !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
          ) {
            setActiveTooltip(null);
          }
        }}>
          <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setActiveTooltip((prev: any) => (prev === 'note' ? null : 'note'));
          }}>
            <Message id="task.note" className="Field-input labels font-weight-400" />
            <HtmlTooltip
              open={activeTooltip === 'note'}
              disableHoverListener
              disableFocusListener
              title={
                <React.Fragment>
                  <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                    <Grid item>
                      <TextField
                        value={search}
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                          style: {
                            borderRadius: '35px',
                            fontFamily: 'DM Sans',
                            height: '37px',
                            backgroundColor: '#F7F9FF',
                            border: 0,
                            borderColor: '#E6E6E8',
                            fontSize: '12px',
                            marginBottom: '10px'
                          },
                        }}
                        sx={{
                          '& .MuiInputBase-input::placeholder': {
                            fontWeight: 'bold',
                          },
                        }}
                        placeholder={intl.formatMessage({ id: 'text.search' })}
                        onChange={(e: any) => handleSearch(e, 'note')}
                      /></Grid>
                    <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                      className='font-14 black normalFontWeight' padding={1}
                    >
                      {filteredNotesList?.map((val: any, index: number) => (
                        <Grid item container alignItems='center' key={val} columnGap={1}
                          justifyContent='flex-start'
                          style={{
                            flexWrap: 'nowrap',
                            width: '100%',
                            fontSize: '14px',
                            marginBottom: '18px'
                          }}
                          onClick={(e) => {
                            setClickedNotes((prevSelected: any) => {
                              if (prevSelected.includes(val)) {
                                return prevSelected.filter((v: any) => v !== val);
                              }
                              else {
                                return [...prevSelected, val];
                              }
                            })
                          }}
                        >
                          <Grid className='bordered-box'
                            style={{ borderColor: clickedNotes?.includes(val) ? '#fff' : '#E6E6E8' }}
                            bgcolor={clickedNotes?.includes(val) ? '#CCF0EB' : '#fff'}>
                            {
                              clickedNotes?.includes(val) ?
                                <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                                :
                                <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                            }
                          </Grid>
                          <Grid item>
                            {val}
                          </Grid>
                        </Grid>
                      ))}

                    </Grid>
                    <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedNotes.length > 0 ? 'pointer' : 'unset' }}
                      onClick={() => setClickedNotes([])} ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                    </Grid>
                    <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                      <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.stopPropagation();
                        setActiveTooltip(null);
                        setClickedNotes(selectedNotes);
                      }}>
                        <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                      </button>
                      <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        setOldRows([]);
                        setNoMoreRows(false);
                        e.stopPropagation();
                        setSelectedNotes(clickedNotes)
                        setActiveTooltip(null);
                      }}>
                        <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                      </button>

                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <img src={selectedNotes.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                  e.stopPropagation();
                  setClickedNotes(selectedNotes);
                  setActiveTooltip((prev: any) => (prev === 'notes' ? null : 'notes'));
                }} />
              </Grid>
            </HtmlTooltip>
          </Grid >
        </ClickAwayListener>
      ),
      // Custom cell renderer
      Cell: ({ row }) => {

        const notifications = row.original.notifications;
        const hasNotifications = notifications && Array.isArray(notifications) && notifications.length > 0;

        return (
          <Grid item className={hasNotifications ? "Field-input labels font-weight-700" : "Field-input labels font-weight-400"}>
            {row.getValue<string>('note')}
          </Grid>
        )
      }
    },

  ]


  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    enableColumnResizing: true,
    enablePagination: false,
    enableBottomToolbar: false,
    rowCount: 1000,
    // Disable top toolbar features
    enableTopToolbar: false,
    // Or keep toolbar but disable specific features
    enableToolbarInternalActions: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableSorting: false,
    muiTableContainerProps: {
      sx: {
        height: tableHeight, // Set your desired height here

      }
    },
    // Optional: control what happens when content overflows
    muiTablePaperProps: {
      sx: {
        overflow: 'auto'
      }
    },
    initialState: {
      columnVisibility: {
        // Hide the assignee column if the user type is not '1'
        clientName: localStorage.getItem('userType') === '1',
      },
    },

    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        cursor: 'pointer'
      },
      onClick: (e) => {
        // Access the row data
        const rowData = row.original;
        const target = e.target as HTMLElement;
        // Navigate to specific page with the ID
        if (target.dataset.type === 'tooltip-icon') {
          return;
        }
        else {
          navigate(`/task-details?id=${rowData.id}`)
        }
      }
    })

  });
  return (
    <>

      <div style={{ height: tableHeight, width: '100%', position: 'relative' }}>
        <style>
          {`
          .bold-row {
            font-weight: bold;
          }
        `}
        </style>
        {/* <Backdrop
          sx={{
            position: 'absolute', // Position it within the container
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: '#fff',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: '37.5vh'
          }}
          open={tableSearchLoading && !loadMoreRows}
        >
          <CircularProgress style={{ color: '#c3c2c7' }} />
        </Backdrop> */}

        {/* <div>
      <div
        ref={scrollContainerRef}
        style={{
          width: "300px",
          height: "200px",
          overflowY: "scroll",
          border: "1px solid black",
        }}
      >
        <div style={{ height: "500px" }}>
          <p>Content goes here...</p>
          <p>Scroll down to trigger end detection.</p>
        </div>
      </div>

      {atBottom && <p>You've reached the bottom!</p>}
    </div> */}

        {/* <ScrollableDataGrid/> */}
        {/* {atBottom && <p>You've reached the bottom of the DataGrid!</p>} */}

        {/* <DataGrid
          ref={gridRef}
          rows={rows}
          columns={columns}
          // pagination
          // paginationMode='server'
          // paginationModel={paginationModel}
          // onPaginationModelChange={handlePaginationModelChange} // Handle pagination changes
          // rowCount={dataLength} // Total number of rows for server-side pagination
          pageSizeOptions={[1000]}
          getRowClassName={getRowClassName} // Apply the custom row class
          //  checkboxSelection
          disableColumnMenu
          sx={{
            height: tableHeight,
            width: '100%',
            border: 2,
            borderColor: '#FEFEFF',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
            "& .MuiDataGrid-footerContainer": { display: "none" },
            '& .css-15lx25q-MuiDataGrid-footerContainer': {
              height: '23px',
              minHeight: '23px',
              overflow: 'unset'
            },
            '@media (min-width: 600px)': {
              '& .MuiTablePagination-toolbar': {
                minHeight: '23px', // Apply min-height
                paddingRight: '2px', // Adjust padding-right
              },
            },
            '@media (min-width: 0px)': {
              '& .MuiTablePagination-toolbar': {
                minHeight: '23px', // Apply min-height
                paddingRight: '2px', // Adjust padding-right
              },
            },
            '& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar ': {
              position: 'unset',
              height: '23px',
            },
            '& .css-1o37pnf-MuiTablePagination-root': {
              overflow: 'unset',
              maxHeight: '23px'
            },
            '& .MuiDataGrid-overlay': {
              display: 'none',  // Hide the overlay in general
            },
            '& .MuiDataGrid-emptyOverlay': {
              display: 'none',  // Specifically hide the 'No rows' message
            },

            cursor: 'pointer',

          }}
          onRowClick={(params, e) => {
            const target = e.target as HTMLElement;
            if (target.dataset.type === 'tooltip-icon') {
              return;
            }
            else {
              navigate(`/task-details?id=${params.row.id}`)
            }

          }}
        /> */}


        <MaterialReactTable table={table} />

        {(noVerticalScroll || noMoreRows) && !dialogLoading && !initiallistLoading && !loadMoreRows && !inputDataLoading && !openOrCompleteLoading && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              width: "170px",
              padding: '10px',
              textAlign: "center",
              zIndex: 10,
              color: '#404040',
              left: '45%',
              borderRadius: '10px'

            }}
          >
            {rows.length === 0 ? 'No rows to display' : 'No more rows to load'}
          </div>
        )}
        {/* 
        {loadMoreRows && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              width: "100%",
              textAlign: "center",
              zIndex: 10,
            }}
          >
            <CircularProgress style={{ color: '#5D5FEF', width: '22px', height: '22px' }} />
          </div>
        )} */}

        <DeleteDialog
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          loading={loading}
          callStatus={callStatus}
          errorMsg={errorMsg}
          object={selectedTaskToDelete}
          confirmAction={deleteTaskCall}
          type='task'
        />

        <Dialog
          open={openCustomReminder}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseCustomReminder}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ sx: { borderRadius: "8px", width: '340px' } }}

        >
          <Grid item container direction='column' padding={3} rowGap={1} justifyContent='center' textAlign='center'>
            <Grid item container direction='row' justifyContent='space-between'>
              <Grid item> <Message id="task.custom-reminder" className="Field-input labelsLinks font-weight-400" /> </Grid>
              <Grid item style={{ cursor: 'pointer' }} onClick={() => setOpenCustomReminder(false)}><CloseIcon /></Grid>
            </Grid>
            <Grid item className='greyLine' width='100%'></Grid>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
              <DateCalendar
                views={['day']}
                value={reminderDate ? moment(reminderDate, 'YYYY-MM-DD') : null}
                onChange={handleReminderDateChange}
                slotProps={{ calendarHeader: { sx: { color: 'black' } } }}
                sx={{ width: '300px' }}
              />
            </LocalizationProvider>
            <Grid item container direction='row' justifyContent='flex-end' marginTop={3} marginBottom={2} columnGap={1.5}>
              {errorMsg ?
                <Grid item textAlign='center' className='errorText' paddingTop={1}>{errorMsg}</Grid>
                :
                <></>

              }
              <Grid item xs={3} >
                <button className='greyButton' onClick={() => setOpenCustomReminder(false)}><Message id="button.cancel" className="Field-input labelsLinks labels-extra-bold" /></button>
              </Grid>
              <Grid item xs={5}>
                <button
                  className={actionCallFrom === 'post reminder' ? callStatus === 'Pass' ? 'greenButton' : callStatus === 'Fail' ? 'redButton' : 'blueButton' : 'blueButton'}
                  disabled={loading || !reminderDate || callStatus === 'Pass' || callStatus === 'Fail'}
                  onClick={() => {
                    if (selectedRowReminders?.length === 0) {
                      postReminderCall(reminderObj, null, taskId)
                    }
                    else postReminderCall(reminderObj, selectedRowReminders[0]?.id, taskId)

                  }
                  }
                >
                  {loading && actionCallFrom === 'post reminder' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                    : callStatus === 'Pass' && actionCallFrom === 'post reminder' ? <CheckCircleOutlinedIcon style={{ color: 'white', width: '15px', height: '15px' }} />
                      : callStatus === 'Fail' && actionCallFrom === 'post reminder' ? "Failed"
                        : <Message id="button.set-reminder" className="Field-input labelsLinks labels-extra-bold" />}
                </button>
              </Grid>
            </Grid>


          </Grid>
        </Dialog>
      </div>
    </>
  )
}

export default TasksTable;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});