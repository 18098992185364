import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, FormControlLabel, Grid, InputAdornment, MenuItem, TextField, Tooltip } from '@mui/material';
import classes from '../../../../../assets/styles/Forms.module.css';
import * as TaskServices from "../../../../../services/task-services.proxy";
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { getDialogLoadingState, getLoadingState, setDialogLoading, setLoading } from '../../../../../app/slices/loadingSlice';
import { getActionCallFrom, getCallStatus, getErrorMsg, setActionCallFrom, setCallStatus, setErrorMsg } from '../../../../../app/slices/apiCallSlice';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import LetterLogo from "../../../../../assets/images/letterLogo.png";
import Message from '../../../../../sharedComponents/ui/Message';
import Textfield from '../../../../../sharedComponents/ui/Textfield';
import { selectLocale } from '../../../../../app/slices/localsSlice';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from "moment";
import 'moment/locale/de';
import { getOnboardingForm } from '../../../../../app/slices/formsSlice';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const updateSurvey = TaskServices.updateSurvey;
const getEmployeeTask = TaskServices.getEmployeeTask;
export interface propsInterface {
    taskId: any,
    onBoardingDetails: any;
    setOnBoardingDetails: any;
}

const Onboarding = (props: propsInterface) => {

    const { taskId, onBoardingDetails,
        setOnBoardingDetails } = props;
    const dispatch = useAppDispatch();

    const dialogLoading = useAppSelector(getDialogLoadingState);
    const errorMsg = useAppSelector(getErrorMsg);
    const loading = useAppSelector(getLoadingState);
    const callStatus = useAppSelector(getCallStatus);
    const local = useAppSelector(selectLocale);
    const actionCallFrom = useAppSelector(getActionCallFrom)

    const [, updateState] = React.useState({});
    const forceUpdate = React.useCallback(() => {
        // Updating state with an empty object to trigger a re-render
        updateState({});
    }, []);

    const onBoardingForm = useAppSelector(getOnboardingForm);

    const [onBoardingFormCopy, setOnboardingFormCopy] = useState(JSON.parse(JSON.stringify(onBoardingForm)));

    const [employeeTaskDetails, setEmployeeTaskDetails] = useState<any>();
    const [selectedSectionId, setSelectedSectionId] = useState(onBoardingFormCopy[0].id);
    const [selectedSubSection, setSelectedSubSection] = useState<any>(onBoardingFormCopy[0].Sections[0]);
    const [selectedSubSectionId, setSelectedSubSectionId] = useState(onBoardingFormCopy[0].Sections[0].id);
    const [subSections, setSubSections] = useState<any>(onBoardingFormCopy[0].Sections);



    const updateSurveyCall = () => {
        dispatch(setLoading(true));
        dispatch(setActionCallFrom('update survey'))
        updateSurvey(taskId, onBoardingFormCopy).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                dispatch(setCallStatus('Pass'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    dispatch(setCallStatus(''));
                    dispatch(setActionCallFrom(''))
                }, 1500)
            }
            dispatch(setLoading(false));
        })
    }

    const getEmployeeTaskCall = () => {
        dispatch(setDialogLoading(true));
        getEmployeeTask(taskId).then((x) => {
            if (x.ErrorMessage) {
                dispatch(setErrorMsg(x.ErrorMessage));
                // dispatch(setCallStatus('Fail'));
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    // dispatch(setCallStatus(''))
                }, 4500)
            }
            else {
                // dispatch(setCallStatus('Pass'));
                setEmployeeTaskDetails(x);
                if (x.survey !== null) setOnboardingFormCopy(x.survey)
                setTimeout(() => {
                    dispatch(setErrorMsg(''));
                    // dispatch(setCallStatus(''));
                }, 1500)
            }
            dispatch(setDialogLoading(false));
        })
    }

    const handleFileChangeField = (event: React.ChangeEvent<HTMLInputElement>, field: any) => {
        console.log('field', field)
        if (event.target.files) {
            field.value = event.target.files[0]?.name;
            field.fileId = 'tmp';
            forceUpdate();

        }

    };


    // List of EU countries (as of 2025)
    const euCountries = [
        'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic',
        'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary',
        'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands',
        'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden'
    ];

    // List of all countries
    const allCountries = [
        'non-eu', 'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic',
        'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary',
        'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands',
        'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden',
    ];

    //List of pension types
    const pensionTypes = [
        "Altersvollrente",
        "Altersteilrente",
        "Vollversorgung nach berufsständicshen/beamtenrechtlichen Vorschriften",
        "Teilversorgung nach berufsständicshen/beamtenrechtlichen Vorschriften",
        "Vollrente wegen Erwerbsminderung",
        "Rente wegen teilweiser Erwerbsmindung",
    ]

    const employmentRelationshipTypes = [
        "Sozialversichrungspflichtige Beschäftigung",
        "Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)",
        "Ausbildung",
        "Praktikum",
        "Werkstudententätigkeit",
        "Kurzfristige Beschäftigung"
    ]

    const peopleGroupTypes = [
        "Sozialversicherungspflichtig Beschäftigte",
        "Auszubildende ohne besodere Merkmale",
        "Beschäftigte in Altersteilzeit",
        "Hausgewerbetreibende (nicht Heimarbeiter)",
        "Praktikanten",
        "Werkstudenten",
        "Bezieher von Vorruhestandsgeld",
        "Geringfügig entlohnte Beschäftigte",
        "Kurzfristig Beschäftigte",
        "Personen in berufsfördernden Maßnahmen zur Rehabilitation",
        "Mitarbeitende Familienangehörige in der Landwirtschaft",
        "Nebenerwerbslandwirte",
        "Nebenerwerbslandwirte - saisonal beschäftigt",
        "Ausgleichsempfänger nach dem FELEG",
        "Nicht berufsmäßig unständig Beschäftigte",
        "Berufsmäßig unständig Beschäftigte",
        "Versicherungsfreie Altersvollrentner und Versorgungsbezieher wegen Alters",
        "Versicherungspflichtige Altersvollrentner",
        "Auszubildende, deren Ausbildungsentgelt die Geringverdienergrenze nicht übersteigt",
        "Auszubildende in einer außerbetrieblichen Einrichtung",
        "Personen, die ein freiwilliges soziales oder ökologisches Jahr oder BFD leisten",
        "Heimarbeiter ohne Entgeltfortzahlungsanspruch",
        "Behinderte Menschen, die in einem Integrationsprojekt beschäftigt sind",
        "Beschäftigte, die nur in der gesetzlichen Unfallversicherung versichert sind",
        "Nicht meldepflichtig Beschäftigte"
    ]

    //temporary list
    const tempList = ["test1", "test2"]

    const severeDisablityDegrees = [20, 30, 40, 50, 60, 70, 80, 90, 100];

    const conditionsOptions = ["Yes", "No", "Requested"];

    const healthInsuranceTypes = [
        "Gesetzliche Krankenversicherung",
        "Freiwillig-gesetzliche Krankenversicherung",
        "Private Krankenversicherung",
        "Andere"
    ]


    const calculateAge = (birthDate: string): number | string => {
        console.log("ageee",birthDate)
        // Try to parse the date
        const [month, day, year] = birthDate.split('/').map(Number);

        // Validate date components
        if (isNaN(month) || isNaN(day) || isNaN(year)) {
            return "Invalid date format. Please use MM/DD/YYYY";
        }

        // Create a Date object
        const birthDateObj = new Date(year, month - 1, day);
        const currentDate = new Date();

        // Calculate age
        let age = currentDate.getFullYear() - birthDateObj.getFullYear();

        // Check if birthday has occurred this year
        const monthDiff = currentDate.getMonth() - birthDateObj.getMonth();
        const dayDiff = currentDate.getDate() - birthDateObj.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
        console.log("calc ageee",age)


        return age;
    }

    // Function to check if a nationality is from a non-EU country
    const isNonEUNationality = (nationality: any) => {
        // Convert input to title case for consistent comparison
        const formattedNationality = nationality.trim().toLowerCase().split(' ')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        // Check if the nationality is not in the EU countries list
        return !euCountries.includes(formattedNationality);
    }

    // Function to check the type of a field
    const checkFieldType = (field: any) => {
        const fieldType = typeof field;

        if (fieldType === 'boolean') {
            return 'boolean';
        } else if (fieldType === 'string') {
            return 'string';
        }
        else if (Array.isArray(field)) {
            return 'array'
            // // Check if all items are numbers
            // if (field.length > 0 && field.every(item => typeof item === 'number')) {
            //     return 'array of numbers';
            // }
            // // Check if all items are strings
            // else if (field.length > 0 && field.every(item => typeof item === 'string')) {
            //     return 'array of strings';
            // }
            // else {
            //     return 'array of mixed types';
            // }
        }
        else {
            return 'unknown';
        }
    }

    const checkIfSelectedValueMatchDependantValue = (dependancyObject: any, field: any) => {
        if (dependancyObject.value === field.dependantValue) return true
        else return false;
    }

    const shouldDisplayField = (dependantValueType: string, dependancyObject: any, dependantValue: any, field: any) => {
        console.log("field", field.id)
        if (dependantValueType === 'boolean' && dependancyObject.value === dependantValue) return true;
        else if (dependantValueType === 'string') {
            if (dependancyObject.value && dependancyObject.key === 'nationality' && isNonEUNationality(dependancyObject.value)) return true;
            else return checkIfSelectedValueMatchDependantValue(dependancyObject, field)
        }
        else if (dependantValueType === 'array') {
            if (dependancyObject.value
                && field.dependantCheckType === 'Or' && dependantValue?.includes(dependancyObject?.value)
            ) return true
            else if (dependancyObject.value
                && field.dependantCheckType === 'Not' && !dependantValue?.includes(dependancyObject?.value)
            ) return true
        }
    }

    const checkFieldDependency = (field: any) => {

        let subSection = field.dependantSectionId ?
            onBoardingFormCopy?.find((section: any) => section.id === field.dependantSectionId)?.Sections?.find((subsection: any) => subsection.id === field.dependantSubsectionId)
            : selectedSubSection
        const dependantValueType = checkFieldType(field.dependantValue)
        const dependancyObject = subSection?.fields?.find((f: any) => f.id === field.dependantId);

        const secondDependantValueType = checkFieldType(field.secondDependantValue)
        const secondDependancyObject = subSection?.fields?.find((f: any) => f.id === field.secondDependantId);

        // return shouldDisplayField(dependantValueType,dependancyObject,field.dependantValue)

        const parentDependantValueType = checkFieldType(field.parentDependantValue);
        const parentDependantObject = subSection?.fields?.find((f: any) => f.id === field.parentDependantId);

        const secondParentDependantValueType = checkFieldType(field.secondParentDependantValue);
        const secondParentDependantObject = subSection?.fields?.find((f: any) => f.id === field.secondParentDependantId);

        if (field.parentDependantId) {
            const isParentTrue = shouldDisplayField(parentDependantValueType, parentDependantObject, field.parentDependantValue, field);
            const isSecondParentTrue = shouldDisplayField(secondParentDependantValueType, secondParentDependantObject, field.secondParentDependantValue, field);

            if (isParentTrue || isSecondParentTrue) return shouldDisplayField(dependantValueType, dependancyObject, field.dependantValue, field)
        }
        else if (field.secondDependantId) return (shouldDisplayField(dependantValueType, dependancyObject, field.dependantValue, field) || shouldDisplayField(secondDependantValueType, secondDependancyObject, field.secondDependantValue, field))
        else return shouldDisplayField(dependantValueType, dependancyObject, field.dependantValue, field)

    }

    const resetValuesOfRelatedFields = (field: any) => {


        // Find the current section
        const sectionIndex = onBoardingFormCopy.findIndex((s: any) => s.id === selectedSectionId);
        if (sectionIndex >= 0) {
            // Find the current subsection
            const subSectionIndex = onBoardingFormCopy[sectionIndex].Sections.findIndex(
                (s: any) => s.id === selectedSubSectionId
            );

            if (subSectionIndex >= 0) {

                // If turning off, update related fields that are switches
                if (field.relatedFieldsIds && field.relatedFieldsIds.length > 0) {
                    console.log("checkkk", field)
                    field.relatedFieldsIds.forEach((relatedId: any) => {
                        const relatedFieldIndex = onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields.findIndex(
                            (f: any) => f.id === relatedId
                        );

                        const relatedFieldType = onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex]?.type

                        if (relatedFieldIndex >= 0) {
                            console.log('here', relatedFieldIndex)
                            console.log('here 2', relatedFieldType)
                            if (relatedFieldType === 'switch' && field.dependantValue !== field.value) {
                                onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex].value = false;
                            }
                            if (relatedFieldType === 'textfield' || relatedFieldType === 'textfield-select' || relatedFieldType === 'date-textfield')
                                onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex].value = '';

                        }
                    });
                }

            }
        }

    }

    useEffect(() => {
        if (!localStorage.getItem('isLoggedIn')) {
            getEmployeeTaskCall();
        }
    }, [])

    return (
        <Grid container direction='column'
            bgcolor={localStorage.getItem('isLoggedIn') ? '' : '#F7F9FF'}
            minHeight={localStorage.getItem('isLoggedIn') ? '' : '100vh'} justifyContent='center'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={dialogLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container direction='row'>
                {localStorage.getItem('isLoggedIn') ?
                    <></>
                    :
                    <Grid container bgcolor='white' height='100vh' xs={0.5}
                        justifyContent='center'
                        padding={2}
                        boxShadow='4px 0px 10px 0px #0000000F'>
                        <img src={LetterLogo} alt='logo' style={{ width: '30px', height: '42px' }} />

                    </Grid>
                }
                <Grid container xs={!localStorage.getItem('isLoggedIn') ? 11.5 : 12} direction='column'>
                    {!localStorage.getItem('isLoggedIn') ?
                        <Grid item bgcolor='white' width='100vw' height='10vh'></Grid>
                        :
                        <></>
                    }

                    <Grid container padding={!localStorage.getItem('isLoggedIn') ? 2 : 0} direction='column' width={!localStorage.getItem('isLoggedIn') ? '90%' : '100%'} rowGap={3}>

                        {!localStorage.getItem('isLoggedIn') ?
                            <Grid container direction='column' rowGap={1}>
                                <Grid item className='black desktop-header-5' sx={{ marginBottom: '5px' }}>{employeeTaskDetails?.title}</Grid>

                                <Grid container direction='column'>
                                    <Grid item className='black font-14 labels-extra-bold' sx={{ marginBottom: '5px' }}><Message id="task.description" className='black font-14 labels-extra-bold' /></Grid>
                                    <Grid item> {employeeTaskDetails?.description ? employeeTaskDetails?.description : 'N/A'}</Grid>
                                </Grid>

                                <Grid container direction='row'>
                                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.client" className="Field-input font-weight-700 font-14" /></Grid>
                                    <Grid item>{employeeTaskDetails?.clientName}</Grid>
                                </Grid>

                                <Grid container direction='row'>
                                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.module" className="Field-input font-weight-700 font-14" /></Grid>
                                    <Grid item>{employeeTaskDetails?.module}</Grid>
                                </Grid>
                                <Grid container direction='row'>
                                    <Grid item className='black font-14 labels-extra-bold' xs={1}><Message id="task.type" className="Field-input font-weight-700 font-14" /></Grid>
                                    <Grid item>{employeeTaskDetails?.type}</Grid>
                                </Grid>
                            </Grid>
                            :
                            <></>
                        }
                        <Grid container direction='row' alignItems='center' padding={2} className='whiteContainer' justifyContent='center' columnGap={2} rowGap={1.5}>
                            {onBoardingFormCopy?.map((section: any, i: any) => (
                                <>
                                    <Grid container direction='column' alignItems='center' rowGap='10px'
                                        style={{ cursor: 'pointer', width: 'auto', minWidth: '120px' }} // width set to auto and a minWidth for layout control
                                        onClick={() => {
                                            setSelectedSectionId(section.id);
                                            const subsections = onBoardingFormCopy?.find((item: any) => item.id === section.id)?.Sections;
                                            setSubSections(subsections)
                                            setSelectedSubSectionId(subsections.length > 0 ? subsections[0].id : null)
                                            setSelectedSubSection(subsections.length > 0 ? subsections[0] : null)
                                        }}>
                                        <Grid item key={i} className={classes[selectedSectionId === section.id ? 'selectedCircle' : 'notSelectedCircle']}>{i + 1}</Grid>
                                        <Grid item className={classes[selectedSectionId === section.id ? 'selectedText' : 'notSelectedText']}>
                                            {local === 'en' ? section.nameEn : section.nameGR}
                                        </Grid>

                                    </Grid>

                                    {i !== onBoardingFormCopy.length - 1 ?
                                        <Grid item className='greyLine' width='50px' height='2px'></Grid>
                                        :
                                        <></>}
                                </>
                            ))}

                        </Grid>

                        <Grid container padding={5} direction='column' className='whiteContainer' rowGap={2.5}>

                            <Grid container direction='row' alignItems='center' justifyContent='center' columnGap={2} rowGap={1.5}>
                                {onBoardingFormCopy?.find((s: any) => s.id === selectedSectionId)?.Sections?.map((section: any, i: any) => (
                                    <>
                                        <Grid container direction='column' maxWidth='190px' alignItems='center' rowGap='10px'
                                            style={{ cursor: 'pointer', width: 'auto', minWidth: '60px' }} // width set to auto and a minWidth for layout control
                                            onClick={() => {
                                                setSelectedSubSection(section)
                                                setSelectedSubSectionId(section.id)
                                            }}>
                                            <Grid item key={i} className={classes[selectedSubSectionId === section.id ? 'selectedCircle' : 'notSelectedCircle']}>{i + 1}</Grid>
                                            <Grid item className={classes[selectedSubSectionId === section.id ? 'selectedText' : 'notSelectedText']}>
                                                {local === 'en' ? section.nameEn : section.nameGR}
                                            </Grid>

                                        </Grid>

                                        {i !== subSections.length - 1 ?
                                            <Grid item className='greyLine' width='50px' height='2px'></Grid>
                                            :
                                            <></>}
                                    </>
                                ))}

                            </Grid>
                            {selectedSubSection ?
                                <>
                                    <Grid container direction='row' justifyContent='space-between' justifySelf='right' alignSelf='flex-end' marginTop={2}>
                                        <Grid item className='neutral-5 font-weight-700'>
                                            {local === 'en' ? selectedSubSection.nameEn : selectedSubSection.nameGR}
                                            <br /> <span className='normalFontWeight'>TO BE PROVIDED , Helper text indicating next step </span>
                                        </Grid>
                                        <Grid item className={classes['inprogress-status']}>In Progress</Grid>
                                    </Grid>
                                    <Grid item className='greyLine' width='100%' marginTop={3} marginBottom={2}></Grid>
                                </>
                                :
                                <></>}

                            <Grid container rowGap={2} justifyContent='space-between'>
                                {selectedSubSection?.fields?.map((field: any, index: any) => (
                                    <>
                                        {
                                            // (
                                            // field.dependantId !== null && checkFieldType(selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.value) === 'boolean' && selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.value === true)
                                            // ||
                                            // (field.dependantId !== null && checkFieldType(selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.value) === 'string' &&
                                            //     selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.value
                                            //     &&
                                            //     selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.key === 'nationality'
                                            //     &&
                                            //     isNonEUNationality(selectedSubSection?.fields?.find((f: any) => f.id === field.dependantId)?.value))

                                            // || 
                                            (field.dependantId !== null && checkFieldDependency(field)) ||
                                                field.dependantId === null ?

                                                <Grid container direction='column' key={index} rowGap={0.5} xs={field.xs === 6 ? field.xs - 0.1 : field.xs}>
                                                    <Grid container className='labels font-weight-400' columnGap={2} alignItems='center'>
                                                        <span style={{ maxWidth: '451px' }}>{local === 'en' ? field.nameEn : field.nameGR}</span>
                                                        {field.type === 'switch' ?
                                                            <Tooltip
                                                                open={field.value === false && field.key === 'isWorkPermitAvailable'}
                                                                title={
                                                                    <React.Fragment>
                                                                        <div style={{ width: '320px', padding: '5px' }}>
                                                                            Der Arbeitnehmer hat angegeben,
                                                                            nicht über eine gültige Arbeitserlaubnis zu verfügen. Die Beschäftigung von Personen ohne Arbeitserlaubnis
                                                                            stellt eine Ordnungswidrikgeit dar, die zu hohen Geldbußen führen kann. Bitte prüfen Sie den Sachverhalt.
                                                                        </div>
                                                                    </React.Fragment>
                                                                } placement='right' arrow
                                                                disableFocusListener
                                                                disableHoverListener
                                                                slotProps={{
                                                                    tooltip: {
                                                                        sx: {
                                                                            backgroundColor: '#F24822',
                                                                            color: 'white',
                                                                            borderRadius: '5px',
                                                                            '& .MuiTooltip-arrow': {
                                                                                color: '#F24822', // This sets the arrow color
                                                                            },
                                                                            maxWidth: 'none',
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    checked={field.value}
                                                                    control={<IOSSwitch sx={{ m: 1 }}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            const isChecked = e.target.checked;
                                                                            field.value = e.target.checked;
                                                                            forceUpdate();
                                                                            if (field.relatedFieldsIds?.length > 0) {
                                                                                resetValuesOfRelatedFields(field);

                                                                            }

                                                                            // // Create a new copy of the details array
                                                                            // // const newOnBoardingDetails =  JSON.parse(JSON.stringify(onBoardingFormCopy));

                                                                            // // Find the current section
                                                                            // const sectionIndex = onBoardingFormCopy.findIndex((s: any) => s.id === selectedSectionId);
                                                                            // console.log('sectionIndex', sectionIndex)
                                                                            // if (sectionIndex >= 0) {
                                                                            //     // Find the current subsection
                                                                            //     const subSectionIndex = onBoardingFormCopy[sectionIndex].Sections.findIndex(
                                                                            //         (s: any) => s.id === selectedSubSectionId
                                                                            //     );
                                                                            //     console.log('subSectionIndex', subSectionIndex)


                                                                            //     if (subSectionIndex >= 0) {
                                                                            //         // Find the field being modified
                                                                            //         const fieldIndex = onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields.findIndex(
                                                                            //             (f: any) => f.id === field.id
                                                                            //         );
                                                                            //         console.log('fieldIndex', fieldIndex)


                                                                            //         if (fieldIndex >= 0) {
                                                                            //             // Update the field value
                                                                            //             onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields[fieldIndex].value = isChecked;
                                                                            //             forceUpdate();
                                                                            //             console.log('testt', isChecked)



                                                                            //             // If turning off, update related fields that are switches
                                                                            //             if (!isChecked && field.relatedFieldsIds && field.relatedFieldsIds.length > 0) {
                                                                            //                 field.relatedFieldsIds.forEach((relatedId: any) => {
                                                                            //                     const relatedFieldIndex = onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields.findIndex(
                                                                            //                         (f: any) => f.id === relatedId
                                                                            //                     );

                                                                            //                     if (relatedFieldIndex >= 0) {
                                                                            //                         // Only set to false if the related field is also a switch
                                                                            //                         if (onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex].type === 'switch') {
                                                                            //                             onBoardingFormCopy[sectionIndex].Sections[subSectionIndex].fields[relatedFieldIndex].value = false;
                                                                            //                         }
                                                                            //                         //add check to set fields switched of children obejcts too if this indes have relatedFieldsIds>0
                                                                            //                     }
                                                                            //                 });
                                                                            //             }

                                                                            //             // Set the state with the updated copy
                                                                            //             // setOnBoardingDetails(newOnBoardingDetails);
                                                                            //             // onBoardingFormCopy = [...newOnBoardingDetails];
                                                                            //         }
                                                                            //     }
                                                                            // }
                                                                        }}
                                                                    />}
                                                                    label=""
                                                                />
                                                            </Tooltip>
                                                            :
                                                            <></>
                                                        }
                                                    </Grid>
                                                    <Grid item>
                                                        {field.type === 'textfield' ?
                                                            <TextField
                                                                fullWidth
                                                                label={!field.value ? local === 'en' ? field.nameEn : field.nameGR : ''}
                                                                value={field.value}
                                                                onChange={(e) => {
                                                                    field.value = e.target.value;
                                                                    forceUpdate();
                                                                    if (field.relatedFieldsIds?.length > 0) {
                                                                        resetValuesOfRelatedFields(field);
                                                                    }
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: false,
                                                                }}
                                                                sx={{
                                                                    '& label.Mui-focused': {
                                                                        color: '#C3C2C7',
                                                                    },
                                                                    '& .MuiInputLabel-root': {
                                                                        color: '#C3C2C7',
                                                                        marginTop: '-7px',
                                                                        fontSize: '16px'
                                                                    },
                                                                    '& .MuiInputLabel-root.Mui-focused': {
                                                                        color: '#C3C2C7',
                                                                    },
                                                                    '& .MuiInput-underline:after': {
                                                                        borderBottomColor: '#B2BAC2',
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: '#C3C2C7',
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#B2BAC2',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#6F7E8C',
                                                                        },
                                                                    },
                                                                }}
                                                                InputProps={{ className: 'fieldDesign' }}
                                                            />
                                                            : field.type === 'textfield-select' ?
                                                                <TextField
                                                                    fullWidth
                                                                    select
                                                                    label={!field.value ? local === 'en' ? field.nameEn : field.nameGR : ''}
                                                                    value={field.value}
                                                                    onChange={(e) => {
                                                                        field.value = e.target.value;
                                                                        forceUpdate();
                                                                        if (field.relatedFieldsIds?.length > 0) {
                                                                            resetValuesOfRelatedFields(field);
                                                                        }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        shrink: false,
                                                                    }}
                                                                    sx={{
                                                                        '& label.Mui-focused': {
                                                                            color: '#C3C2C7',
                                                                        },
                                                                        '& .MuiInputLabel-root': {
                                                                            color: '#C3C2C7',
                                                                            marginTop: '-7px',
                                                                            fontSize: '16px'
                                                                        },
                                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                                            color: '#C3C2C7',
                                                                        },
                                                                        '& .MuiInput-underline:after': {
                                                                            borderBottomColor: '#B2BAC2',
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: '#C3C2C7',
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: '#B2BAC2',
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: '#6F7E8C',
                                                                            },
                                                                        },
                                                                    }}
                                                                    SelectProps={{
                                                                        MenuProps: {
                                                                            PaperProps: {
                                                                                style: {
                                                                                    maxHeight: 300,
                                                                                    overflowY: 'auto',
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                    InputProps={{ className: 'fieldDesign' }}
                                                                >
                                                                    {field.key === 'nationality' || field.key === 'countryOfBirth'
                                                                        ? allCountries.map((country) => (
                                                                            <MenuItem value={country} key={country}>{country}</MenuItem>
                                                                        ))
                                                                        : field.key === 'pensionType'
                                                                            ? pensionTypes.map((type) => (
                                                                                <MenuItem value={type} key={type}>{type}</MenuItem>
                                                                            ))
                                                                            : field.key === 'degreeOfSevereDisability'
                                                                                ? severeDisablityDegrees.map((type) => (
                                                                                    <MenuItem value={type} key={type}>{type}</MenuItem>
                                                                                ))
                                                                                : field.key === 'isNoticeOfMultipleCreditingExists' || field.key === 'isNoticeOfApprovalFromEmploymentAgency' || field.key === 'isNoticeOfEqualTreatmentExists' || field.key === 'parentalProperty' 
                                                                                    ? conditionsOptions.map((type) => (
                                                                                        <MenuItem value={type} key={type}>{type}</MenuItem>
                                                                                    ))
                                                                                    : field.key === 'employmentRelationship'
                                                                                        ? employmentRelationshipTypes.map((type) => (
                                                                                            <MenuItem value={type} key={type}>{type}</MenuItem>
                                                                                        ))
                                                                                        : field.key === 'healthInsurance'
                                                                                            ? healthInsuranceTypes.map((type) => (
                                                                                                <MenuItem value={type} key={type}>{type}</MenuItem>
                                                                                            ))
                                                                                            : field.key === 'groupOfPeople'
                                                                                                ? peopleGroupTypes.map((type) => (
                                                                                                    <MenuItem value={type} key={type}>{type}</MenuItem>
                                                                                                ))
                                                                                                :
                                                                                                tempList?.map((i) => (
                                                                                                    <MenuItem value={i} key={i}>{i}</MenuItem>
                                                                                                ))
                                                                    }
                                                                </TextField>
                                                                : field.type === 'date-textfield' ?
                                                                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <div style={{ position: 'relative' }}>
                                                                                <DatePicker
                                                                                    value={field.value ? moment(field.value, 'M/D/YYYY') : null}
                                                                                    onChange={(e) => {
                                                                                        if (e && e.isValid()) {
                                                                                            field.value = moment(e).format('M/D/YYYY');
                                                                                            forceUpdate();
                                                                                            if (field.key === 'dateOfBirth') {
                                                                                                const age = calculateAge(moment(e).format('M/D/YYYY'));
                                                                                                field.age = age;
                                                                                                forceUpdate();
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    format="DD.MM.YYYY"
                                                                                    shouldDisableDate={(day: Moment) => {
                                                                                        if ((field.key === 'residencePermitValidDate' && field.isLimited)) return true;
                                                                                        else return false
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: {
                                                                                            fullWidth: true,
                                                                                            sx: {
                                                                                                '& .MuiOutlinedInput-root': {
                                                                                                    '& fieldset': {
                                                                                                        borderRadius: '8px'
                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                            size: "small",
                                                                                            variant: "outlined",
                                                                                            placeholder: field.isLimited ? "" : "DD.MM.YYYY",

                                                                                        },
                                                                                        popper: {
                                                                                            placement: 'bottom-end',
                                                                                            sx: {
                                                                                                '& .MuiPickersLayout-root': {
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'column'
                                                                                                },
                                                                                                '& .MuiDialogActions-root': {
                                                                                                    borderTop: '1px solid #ddd',
                                                                                                    width: '100%',
                                                                                                    padding: '10px',
                                                                                                    boxSizing: 'border-box',
                                                                                                    marginTop: 0
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                    slots={{
                                                                                        layout: (props) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    {field.key === 'residencePermitValidDate' && (

                                                                                                        <Grid container direction='row' alignItems='center' justifyContent='space-between' padding={2}
                                                                                                            style={{ borderBottom: '1px solid #ddd' }} className='labels'>
                                                                                                            Aufenthaltsgenehmigung ist <br /> unbefristet?
                                                                                                            <FormControlLabel
                                                                                                                checked={field.isLimited}
                                                                                                                control={<IOSSwitch sx={{ m: 1 }}
                                                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                                                        field.value = ''
                                                                                                                        field.isLimited = e.target.checked;
                                                                                                                        forceUpdate();
                                                                                                                    }}
                                                                                                                />}
                                                                                                                label=""
                                                                                                            />
                                                                                                        </Grid>

                                                                                                    )}

                                                                                                    {/* Original calendar layout */}
                                                                                                    {props.children}

                                                                                                    {/* {field.key === 'residencePermitValidDate' && (
                                                                                                    <Grid container direction='row' justifyContent='flex-end' columnGap={1} padding={2}>
                                                                                                        <Grid item>
                                                                                                            <button className='greyButton'>Abbrechen</button>
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <button className='blueButton'>Ok</button>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                )} */}
                                                                                                </div>
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {field.isLimited && (
                                                                                    <div
                                                                                        style={{
                                                                                            position: 'absolute',
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                            width: '100%',
                                                                                            height: '100%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            paddingLeft: '14px', // Adjust padding to match text field padding
                                                                                            fontSize: '1rem', // Adjust font size as needed
                                                                                            pointerEvents: 'none', // Prevent overlay from interfering with clicks
                                                                                            zIndex: 1, // Ensure overlay is on top
                                                                                            color: 'rgba(0, 0, 0, 0.87)',// match the textfield text color.
                                                                                            // backgroundColor:'white'
                                                                                        }}
                                                                                    >
                                                                                        unlimited
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </LocalizationProvider>

                                                                    : field.type === 'file' ?
                                                                        <>
                                                                            <input
                                                                                type="file"
                                                                                onChange={(e) => handleFileChangeField(e, field)}
                                                                                style={{ display: 'none' }} // Hide the default input
                                                                                id={field.nameEn} // Use an ID for the label
                                                                            />
                                                                            <label htmlFor={field.nameEn}>
                                                                                <TextField
                                                                                    disabled
                                                                                    fullWidth
                                                                                    label={!field.value ? local === 'en' ? field.nameEn : field.nameGR : ''}
                                                                                    value={field.value}
                                                                                    // onChange={(e) => {
                                                                                    //     field.value = e.target.value;
                                                                                    //     forceUpdate();
                                                                                    // }}
                                                                                    InputLabelProps={{
                                                                                        shrink: false,
                                                                                    }}
                                                                                    sx={{
                                                                                        '& label.Mui-focused': {
                                                                                            color: '#C3C2C7',
                                                                                        },
                                                                                        '& .MuiInputLabel-root': {
                                                                                            color: '#C3C2C7',
                                                                                            marginTop: '-7px',
                                                                                            fontSize: '16px',
                                                                                            marginLeft: '28px',
                                                                                        },
                                                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                                                            color: '#C3C2C7',
                                                                                        },
                                                                                        '& .MuiInput-underline:after': {
                                                                                            borderBottomColor: '#B2BAC2',
                                                                                        },
                                                                                        '& .MuiOutlinedInput-root': {
                                                                                            '& fieldset': {
                                                                                                borderColor: '#C3C2C7',
                                                                                            },
                                                                                            '&:hover fieldset': {
                                                                                                borderColor: '#B2BAC2',
                                                                                            },
                                                                                            '&.Mui-focused fieldset': {
                                                                                                borderColor: '#6F7E8C',
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    InputProps={{
                                                                                        className: 'fieldDesign',
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <AttachFileIcon sx={{ color: '#93919A', cursor: 'pointer' }} />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                />

                                                                            </label>

                                                                        </>
                                                                        :
                                                                        <></>
                                                        }


                                                    </Grid>




                                                </Grid>
                                                :
                                                <></>
                                        }
                                    </>
                                ))}
                            </Grid>

                            <Grid item container direction="row" columnSpacing={2} justifyContent='flex-end'>
                                <Grid item width='110px'>
                                    <button
                                        className='greyButton'
                                        disabled={selectedSubSectionId <= 1}
                                        onClick={() => {
                                            setSelectedSubSectionId(selectedSubSectionId - 1);
                                            const tmp = onBoardingFormCopy?.find((s: any) => s.id === selectedSectionId)?.Sections?.find((sub: any) => sub.id === selectedSubSectionId - 1)
                                            setSelectedSubSection(tmp)
                                        }
                                        }>
                                        Back
                                    </button>
                                </Grid>
                                <Grid item width='110px'>
                                    <button
                                        className={callStatus === 'Pass' && actionCallFrom === 'update survey' ? 'greenButton' : callStatus === 'Fail' && actionCallFrom === 'update survey' ? 'redButton' : 'blueButton'}
                                        disabled={(loading || callStatus === 'Pass') && actionCallFrom === 'update survey'}
                                        onClick={() => {
                                            if (selectedSubSectionId === subSections[subSections.length - 1].id) updateSurveyCall()
                                            else {
                                                setSelectedSubSectionId(selectedSubSectionId + 1);
                                                const tmp = onBoardingFormCopy?.find((s: any) => s.id === selectedSectionId)?.Sections?.find((sub: any) => sub.id === selectedSubSectionId + 1)
                                                setSelectedSubSection(tmp)
                                            }
                                        }}>
                                        {loading && actionCallFrom === 'update survey' ? <span><CircularProgress style={{ color: 'white', width: '15px', height: '15px' }} /></span>
                                            : callStatus === 'Pass' && actionCallFrom === 'update survey' ? <CheckCircleOutlinedIcon />
                                                : callStatus === 'Fail' && actionCallFrom === 'update survey' ? "Failed"
                                                    :
                                                    selectedSubSectionId === subSections[subSections.length - 1]?.id ? "Save" : "Next"
                                        }</button>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>



        </Grid >

    )
}

export default Onboarding

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 60, // Set the width of the entire switch
    height: 30, // Set the height of the entire switch
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(30px)', // Adjust for new width
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#5D5FEF', // Blue color when checked
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#5D5FEF', // Darker blue in dark mode
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100],
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[600],
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 25, // Set the width of the thumb
        height: 25, // Set the height of the thumb
    },
    '& .MuiSwitch-track': {
        borderRadius: 30 / 2, // Half of the new height for rounded corners
        backgroundColor: '#C3C2C7',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#39393D',
        }),
    },
}));
