import axios from 'axios';
import * as apiConst from "../taxmation-consts";

const DATEV_AUTH_CODE_URL = apiConst.DATEV_AUTH_CODE_URL;
export const getDatevAuthCodeUrl = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + DATEV_AUTH_CODE_URL}`,
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}
const DATEV_CLIENTS = apiConst.DATEV_CLIENTS;
export const getDatevClients =() => {
    return axios
    .get(`${process.env.REACT_APP_URL + DATEV_CLIENTS}`)
    .then((response)=>{
        return response.data;
    })
    .catch((error)=>{
        return error.response.data
    })
}

const GET_REASONS_FOR_ABSENCE = apiConst.GET_REASONS_FOR_ABSENCE;
export const getReasonsForAbsence = (clientId: string) => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_REASONS_FOR_ABSENCE}`,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    clientId: clientId
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}




const GET_LEGAL_FORMS = apiConst.GET_LEGAL_FORMS;
export const getDatevLegalForms = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_LEGAL_FORMS}`,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error.response.data
        })
}





