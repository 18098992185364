//REMINDER TYPES
export const TOMORROW_REMINDER_ID = 1;
export const IN_TWO_DAYS_REMINDER_ID = 2;
export const TWO_DAYS_BEFORE_DUE_REMINDER_ID = 3;
export const CUSTOM_REMINDER_ID = 4;

//module types
export const GENERAL_ID = 1;
export const HR_ID = 2;
export const FINANCIAL_ACCOUNTING_ID = 3;
export const ANNUAL_FINANCIAL_STATEMENTS_ID = 4;
export const ONBOARDING_ID = 5;

export const WORKING_HOURS_TYPE_ID = 7;

//task notifications 
export const comment_Id = 1;
export const file_Id = 2;

export const modules = [
    {
        id: 1,
        name: "General",
        nameGr: "Allgemein",
        types: [
            {
                id: 1,
                name: "General",
                nameGr: "Allgemein"
            }
        ]
    },
    {
        id: 2,
        name: "Personal",
        nameGr: "Personal",
        types: [
            {
                "id": 1,
                "name": "Onboarding",
                "nameGr": "Onboarding"
            },
            {
                "id": 2,
                "name": "Offboarding",
                "nameGr": "Offboarding"
            },
            {
                "id": 3,
                "name": "Electronic sick note (eAU)",
                "nameGr": "Elektronische Krankschreibung (eAU)"
            },
            {
                "id": 4,
                "name": "Maternity Leave / Parental Leave",
                "nameGr": "Mutterschaftsurlaub / Elternurlaub"
            },
            {
                "id": 5,
                "name": "Garnishment",
                "nameGr": "Pfändung"
            },
            {
                "id": 6,
                "name": "Payroll run",
                "nameGr": "Lohn- und Gehaltsabrechnung"
            },
            {
                "id": 7,
                "name": "Working time recording",
                "nameGr": "Arbeitszeiterfassung"
            }
        ]
    },
    {
        id: 3,
        name: "Financial accounting",
        nameGr: "Finanz-buchhaltung",
        types: []
    },
    {
        id: 4,
        name: "Annual financial statements",
        nameGr: "Jahres-abschluss",
        types: []
    },
    {
        id: 5,
        name: "Onboarding",
        nameGr: "Onboarding",
        types: []
    },
];


export const hrModuleId = 2;
export const onBoardingTypeId = 1;

//working hours statuses
export const hoursObjectStatuses = [
    {
        id: 1,
        name: 'New'
    },
    {
        id: 2,
        name: 'Updated'
    },
    {
        id: 3,
        name: 'Deleted'
    },
]

export const NEW_HOUR_OBJECT_ID = 1;
export const UPDATED_HOUR_OBJECT_ID = 2;
export const DELETED_HOUR_OBJECT_ID = 3;

