import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, GridColDef, GridColType } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import filterIcon from '../../../assets/images/icons/filterIcon.png';
import activeFilterIcon from '../../../assets/images/icons/activeFilterIcon.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CheckedIcon from '../../../assets/images/icons/CheckedIcon.png';
import UnCheckedIcon from '../../../assets/images/icons/UncheckedIcon.png';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { CircularProgress, Grid, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getLoadingState, setLoading } from '../../../app/slices/loadingSlice';
import { getCallStatus, getErrorMsg, setCallStatus, setErrorMsg } from '../../../app/slices/apiCallSlice';
import selectedFilter from "../../../assets/images/icons/selectedFilter.png";
import accessIcon from "../../../assets/images/icons/accessIcon.png";
import deleteIcon from "../../../assets/images/icons/delete.png";
import { selectRoles, selectUserType } from '../../../app/slices/userDataSlice';
import { useIntl } from "react-intl";
import Message from '../../../sharedComponents/ui/Message';
import { useMemo } from 'react';
import moment from "moment";
import {MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef, MRT_Cell} from 'material-react-table';

export interface TableProps {
  rows: any;
  setRows: any;
  modify: any,
  setModify: any,
  initiallistLoading: any;
  dialogLoading: any;
  // clickedEmails: any;
  // setClickedEmails: any;
  // selectedEmails: any;
  // setSelectedEmails: any;
  clickedColumns: any;
  setClickedColumns: any;
  selectedColumns: any;
  setSelectedColumns: any;
  emailsList: any;
  clientNamesList: any;
  setClientNamesList: any;
  originalRows: any;
  setOriginalRows: any;
  activeTooltip: any;
  setActiveTooltip: any;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: '0px 2px 4px 0px #0000001F',
    backgroundColor: '#FEFEFF',
    borderRadius: '10px',
    padding: 0,
  },
}));


const EmployeesTable = (props: TableProps) => {
  const { rows, setRows, modify, setModify, initiallistLoading, dialogLoading, clickedColumns, setClickedColumns, selectedColumns, setSelectedColumns, emailsList, clientNamesList, setClientNamesList, originalRows, setOriginalRows, activeTooltip, setActiveTooltip} = props;

const dispatch = useAppDispatch();
const intl = useIntl();
const loading = useAppSelector(getLoadingState);
const errorMsg = useAppSelector(getErrorMsg);
const callStatus = useAppSelector(getCallStatus);

const [tableHeight, setTableHeight] = useState(window.innerHeight - 320);
const [search, setSearch] = useState<string>();
const [filteredEmails, setFilteredEmails] = useState<any>(emailsList);
const [filteredClientNames, setFilteredClientNames]= useState<any>(clientNamesList);
// const [userDataLoading, setUserDataLoading] = useState(false);


const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
  const searchValue = e.target.value.toLowerCase();
  setSearch(searchValue);

    if (type === 'email') {
    const filtered = emailsList?.filter((val: any) =>
      val.toLowerCase()?.includes(searchValue)
    );
    setFilteredEmails(filtered);
  }
  else if (type === 'clientName') {
    const filtered = clientNamesList?.filter((val: any) =>
      val.toLowerCase()?.includes(searchValue)
    );
    setFilteredClientNames(filtered);
  }
  
};

useEffect(() => {

   if (activeTooltip === 'email') {
    setFilteredEmails(emailsList);
    setSearch('');
  }
  else if (activeTooltip === 'clientName') {
    setFilteredClientNames(clientNamesList);
    setSearch('');
  }
}, [activeTooltip]);


const handleResize = () => {
  const screenHeight = window.innerHeight;
  setTableHeight(screenHeight - 320);
};

useEffect(() => {
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

const data: any[] = rows;
const columns: MRT_ColumnDef<any>[] = [
  {

    id: 'actions',
    header: '',
    size: 40, // control width
    enableSorting: false,
    enableColumnFilter: false,
    enableResizing: false,
    Cell: ({ row }) => {
      return (
        <HtmlTooltip
          placement='bottom-start'
          title={
            <React.Fragment>
              <Grid item container rowGap={1.5} padding={1.5} maxWidth='330px' className="font-14">
                <Grid item container direction='row' className='red labels pointerText' alignItems='center' columnGap={1}
                  onClick={() => {
                    // setSelectedUserToDelete(() => ({
                    //   userId: row.original.id,
                    //   email: row.getValue('email'),
                    //   roles: mappedRoles,
                    //   taskCount: row.original.taskCount
                    // }));
                    // setOpenDeleteDialog(true);
                  }}
                >
                  <img src={deleteIcon} width='20px' height='20px' />

                  <Message id="ta.delete" className="Field-input labelsLinks font-weight-400" /></Grid>
              </Grid>
            </React.Fragment>
          }
        >
          <Grid item style={{ cursor: 'pointer' }}><MoreVertIcon htmlColor='#93919a' /></Grid>
        </HtmlTooltip>
      )
    }
  },
  // {
  //   accessorKey: 'name', 
  //   header: 'Name',
  //   minSize: 194,
  //   Cell: ({ cell }) => (
  //     <Grid item>
  //       {cell.getValue<string>()}
  //     </Grid>
  //   ),
  // },
  {
    accessorKey: 'email', 
    header: 'Email',
    minSize: 235,
    Header: ({ column }) => (
      <ClickAwayListener onClickAway={(event) => {
        // setClickedEmails(selectedEmails);
        setClickedColumns({
        ...clickedColumns,
        email: selectedColumns.email,
        })
        const target = event.target as HTMLElement;
        if (
          activeTooltip === 'email' &&
          !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
        ) {
          setActiveTooltip(null);
        }
      }}>
        <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
        }}>
          <Message id="user.profile.email" className="Field-input labels font-weight-400" />
          <HtmlTooltip
            open={activeTooltip === 'email'}
            disableHoverListener
            disableFocusListener
            title={
              <React.Fragment>
                <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                  <Grid item>
                    <TextField
                      value={search}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                        style: {
                          borderRadius: '35px',
                          fontFamily: 'DM Sans',
                          height: '37px',
                          backgroundColor: '#F7F9FF',
                          border: 0,
                          borderColor: '#E6E6E8',
                          fontSize: '12px',
                          marginBottom: '10px'
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-input::placeholder': {
                          fontWeight: 'bold',
                        },
                      }}
                      placeholder={intl.formatMessage({ id: 'text.search' })}
                      onChange={(e: any) => handleSearch(e, 'email')}
                    /></Grid>
                  <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                    className='font-14 black normalFontWeight' padding={1}
                  >
                    {filteredEmails?.map((val: any, index: number) => (
                      <Grid item container alignItems='center' key={val} columnGap={1}
                        justifyContent='flex-start'
                        style={{
                          flexWrap: 'nowrap',
                          width: '100%',
                          fontSize: '14px',
                          marginBottom: '18px'
                        }}
                        // onClick={(e) => {
                        //   setClickedEmails((prevSelected: any) => {
                        //     if (prevSelected.includes(val)) {
                        //       return prevSelected.filter((email: any) => email !== val);
                        //     }
                        //     else {
                        //       return [...prevSelected, val];
                        //     }
                        //   })
                        // }}
                          onClick={(e) => {
                            setClickedColumns((prevState: any) => ({
                              ...prevState,
                              email: prevState.email.includes(val)
                                ? prevState.email.filter((email: any) => email !== val) 
                                : [...prevState.email, val],
                            }));
                          }}
                      >
                        <Grid className='bordered-box'
                          style={{ borderColor: clickedColumns.email?.includes(val) ? '#fff' : '#E6E6E8' }}
                          bgcolor={clickedColumns.email?.includes(val) ? '#CCF0EB' : '#fff'}>
                          {
                            clickedColumns.email?.includes(val) ?
                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                              <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                          }
                        </Grid>
                        <Grid item>
                          {val}
                        </Grid>
                      </Grid>
                    ))}

                  </Grid>
                  <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedColumns.email.length > 0 ? 'pointer' : 'unset' }}
                    onClick={() => 
                    setClickedColumns({
                      ...clickedColumns,
                      email: [],
                      })
                    
                    } ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                  </Grid>
                  <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.stopPropagation();
                      setActiveTooltip(null);
                      // setClickedEmails(selectedEmails);
                      setClickedColumns({
                      ...clickedColumns,
                      email: selectedColumns.email,
                      });
                    }}>
                      <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.stopPropagation();
                      // setSelectedEmails(clickedEmails)
                      setSelectedColumns({
                      ...selectedColumns,
                      email: clickedColumns.email,
                      });
                      setActiveTooltip(null);
                    }}>
                      <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          >
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <img src={selectedColumns.email.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                // setClickedEmails(selectedEmails);
                setClickedColumns({
                ...clickedColumns,
                email: selectedColumns.email,
                });
                setActiveTooltip((prev: any) => (prev === 'email' ? null : 'email'));
              }} />
            </Grid>
          </HtmlTooltip>
        </Grid >
      </ClickAwayListener>
    ),
    Cell: ({ cell }) => (
      <Grid item>
        {cell.getValue<string>()}
      </Grid>
    ),
  },
  {
    accessorKey: 'clientName', 
    header: 'Company Name',
    minSize: 194,
    Header: ({ column }) => (
      <ClickAwayListener onClickAway={(event) => {
        // setClickedEmails(selectedEmails);
        setClickedColumns({
        ...clickedColumns,
        clientId: selectedColumns.clientId,
        })
        const target = event.target as HTMLElement;
        if (
          activeTooltip === 'clientName' &&
          !target.closest('.table-header') && !target.closest('.MuiTooltip-tooltip')
        ) {
          setActiveTooltip(null);
        }
      }}>
        <Grid item container className='table-header' alignItems='center' fontWeight='normal' onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          setActiveTooltip((prev: any) => (prev === 'clientName' ? null : 'clientName'));
        }}>
          <Message id="user.company" className="Field-input labels font-weight-400" />
          <HtmlTooltip
            open={activeTooltip === 'clientName'}
            disableHoverListener
            disableFocusListener
            title={
              <React.Fragment>
                <Grid item container onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()} rowGap={0.5} maxHeight='378px' padding={2} display='inline-grid' width='307px'>
                  <Grid item>
                    <TextField
                      value={search}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                        style: {
                          borderRadius: '35px',
                          fontFamily: 'DM Sans',
                          height: '37px',
                          backgroundColor: '#F7F9FF',
                          border: 0,
                          borderColor: '#E6E6E8',
                          fontSize: '12px',
                          marginBottom: '10px'
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-input::placeholder': {
                          fontWeight: 'bold',
                        },
                      }}
                      placeholder={intl.formatMessage({ id: 'text.search' })}
                      onChange={(e: any) => handleSearch(e, 'clientName')}
                    /></Grid>
                  <Grid item container maxHeight='160px' style={{ overflowX: 'hidden', overflowY: 'auto' }} rowGap={0.5} columnGap={2}
                    className='font-14 black normalFontWeight' padding={1}
                  >
                    {filteredClientNames?.map(({ clientId, clientName }: { clientId: string; clientName: string }) => (
                      <Grid item container alignItems='center' key={clientId} columnGap={1}
                        justifyContent='flex-start'
                        style={{
                          flexWrap: 'nowrap',
                          width: '100%',
                          fontSize: '14px',
                          marginBottom: '18px'
                        }}
                        // onClick={(e) => {
                        //   setClickedEmails((prevSelected: any) => {
                        //     if (prevSelected.includes(val)) {
                        //       return prevSelected.filter((email: any) => email !== val);
                        //     }
                        //     else {
                        //       return [...prevSelected, val];
                        //     }
                        //   })
                        // }}
                          // onClick={(e) => {
                          //   setClickedColumns((prevState: any) => ({
                          //     ...prevState,
                          //     clientName: prevState.clientName.includes(val)
                          //       ? prevState.clientName.filter((clientName: any) => clientName !== val) 
                          //       : [...prevState.clientName, val],
                          //   }));
                          // }}
                          onClick={(e) => {
                            setClickedColumns((prevState: any) => ({
                              ...prevState,
                              clientId: prevState.clientId.includes(clientId.toLowerCase())
                                ? prevState.clientId.filter((clientId: any) => clientId.toLowerCase() !== clientId.toLowerCase()) 
                                : [...prevState.clientId, clientId.toLowerCase()],
                            }));
                          }}
                      >
                        <Grid className='bordered-box'
                          style={{ borderColor: clickedColumns.clientId?.includes(clientId.toLowerCase()) ? '#fff' : '#E6E6E8' }}
                          bgcolor={clickedColumns.clientId?.includes(clientId.toLowerCase()) ? '#CCF0EB' : '#fff'}>
                          {
                            clickedColumns.clientId?.includes(clientId.toLowerCase()) ?
                              <img src={CheckedIcon} style={{ width: '20px', height: '20px' }} />
                              :
                              <img src={UnCheckedIcon} style={{ width: '20px', height: '20px' }} />
                          }
                        </Grid>
                        <Grid item>
                          {clientName}
                        </Grid>
                      </Grid>
                    ))}

                  </Grid>
                  <Grid item container alignItems='center' className='black font-weight-400' columnGap={1} marginLeft={0.5} style={{ cursor: clickedColumns.clientId.length > 0 ? 'pointer' : 'unset' }}
                    onClick={() => 
                    setClickedColumns({
                      ...clickedColumns,
                      clientId: [],
                      })
                    
                    } ><RestartAltIcon fontSize='small' /> <Message id="ta.reset-filter" className="Field-input labels font-weight-400" />
                  </Grid>
                  <Grid item container columnGap={1} justifyContent='flex-end' style={{ padding: '1rem' }}>
                    <button className="greyButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.stopPropagation();
                      setActiveTooltip(null);
                      // setClickedEmails(selectedEmails);
                      setClickedColumns({
                      ...clickedColumns,
                      clientId: selectedColumns.clientId,
                      });
                    }}>
                      <Message id="button.cancel" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                    <button className="blueButton" style={{ width: '80px' }} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      e.stopPropagation();
                      // setSelectedEmails(clickedEmails)
                      setSelectedColumns({
                      ...selectedColumns,
                      clientId: clickedColumns.clientId,
                      });
                      setActiveTooltip(null);
                    }}>
                      <Message id="button.apply" className='Field-input font-weight-700 labelsLinks' />
                    </button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          >
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <img src={selectedColumns.clientId.length > 0 ? activeFilterIcon : filterIcon} style={{ marginLeft: '5px', cursor: 'pointer', borderRadius: '2px' }} onClick={(e) => {
                e.stopPropagation();
                // setClickedEmails(selectedEmails);
                setClickedColumns({
                ...clickedColumns,
                clientId: selectedColumns.clientId,
                });
                setActiveTooltip((prev: any) => (prev === 'clientName' ? null : 'clientName'));
              }} />
            </Grid>
          </HtmlTooltip>
        </Grid >
      </ClickAwayListener>
    ),
    Cell: ({ cell }) => (
      <Grid item>
        {cell.getValue<string>()}
      </Grid>
    ),
  },
]

const table = useMaterialReactTable({
  columns,
  data,
  enableStickyHeader: true,
  enablePagination: false,
  enableBottomToolbar: false,
  enableColumnResizing: true,
  rowCount: 1000,
  enableTopToolbar: false,
  enableToolbarInternalActions: false,
  enableColumnFilters: false,
  enableGlobalFilter: false,
  enableFullScreenToggle: false,
  enableColumnActions: false,
  enableSorting: false,
  muiTableContainerProps: {
    sx: {
      height: tableHeight,
    }
  },
  muiTablePaperProps: {
    sx: {
      overflow: 'auto'
    }
  },
});


  return (
    <div style={{ height: tableHeight, width: '100%' }}>
 
    <MaterialReactTable table={table} />

    {/* { !dialogLoading && !initiallistLoading && (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "170px",
          padding: '10px',
          textAlign: "center",
          zIndex: 10,
          color: '#404040',
          left: '45%',
          borderRadius: '10px'

        }}
      >
        {rows.length === 0 ? 'No rows to display' : 'No more rows to load'}
      </div>
    )} */}


    {/* {selectedUserToDelete ?
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        loading={loading}
        callStatus={callStatus}
        errorMsg={errorMsg}
        object={selectedUserToDelete}
        confirmAction={deleteUserCall}
        type='team member'
      />
      :
      <></>
    } */}

  </div>
)
}

export default EmployeesTable
