import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Grid, MenuItem, TextField } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Logo from "../assets/images/logoGrey.png";
import LetterLogo from "../assets/images/letterLogo.png";
import { Locale, selectLocale, setLocale } from '../app/slices/localsSlice';
import { selectIsLoggedIn, selectIsCMS, selectIsSSO, selectIsSubscribed, selectIsOtpEnabled, selectIsOtpVerified, selectUserType, selectRoles } from '../app/slices/userDataSlice';
import LoginPage from '../pages/LoginPage';
import PageNotFound from '../pages/PageNotFound';
import SideNavbar from './SideNavbar';
import UpperNavbar from './UpperNavbar';
import AdminManageUsers from '../Admin/pages/ManageUsers';
// import EditProfile from '../Admin/pages/EditProfile';
import EditProfile from '../pages/EditProfile';
import Payment from '../pages/Payment';
import TwoFactorAuthentication from '../pages/TwoFactorAuthentication';
import PlanSelection from '../pages/PlanSelection';
import ManageUsers from '../pages/Dashboard/ManageUsers';
import AddUsers from '../pages/Dashboard/AddUsers';
import * as userRolesConst from "../user-roles-consts";
import ManageTasks from '../pages/Dashboard/ManageTasks/ManageTasks';
import TaskDetails from '../pages/Dashboard/ManageTasks/TaskDetails';
import AddTaxAdvisors from '../Admin/pages/AddTaxAdvisors';
import Onboarding from '../components/Dashboard/ManageTasks/Forms/Onboarding/Onboarding';
import Employees from '../pages/Dashboard/Employees';

const drawerWidth = '15vw';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // [theme.breakpoints.up('sm')]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
  width: '4vw'
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const Navigate = () => {

  const [open, setOpen] = useState<any>(false);
  const [modifyProfile, setModifyProfile] = useState<boolean>(false);
  const [loginFormState, setLoginFormState] = useState<string>('default');
  const [openRightDrawer, setOpenRightDrawer] = useState(true);
  const [selectedModule, setSelectedModule] = useState<any[]>([]);
  const [selectedClient, setSelectedClient] = useState<string[]>([]);
  const [companiesTab, setCompaniesTab] = useState(false);
  const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);
  const [selectedColumns, setSelectedColumns]= useState<{
    name: string[],
    email: string[],
    clientName: string[],
    clientId: string[],
    }>({
    name: [],  
    email: [],
    clientName: [],
    clientId: [],
    })

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectLocale);
  const isCMS = useAppSelector(selectIsCMS);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const userType = useAppSelector(selectUserType);
  const isSubscribed = useAppSelector(selectIsSubscribed);
  const isSSO = useAppSelector(selectIsSSO);
  const isOtpEnabled = useAppSelector(selectIsOtpEnabled);
  const isOtpVerified = useAppSelector(selectIsOtpVerified);
  const userRoles = useAppSelector(selectRoles);

  const [onBoardingDetails, setOnBoardingDetails] = useState<any>([
    {
      id: 1,
      nameGR: 'Persönliche Angaben',
      nameEn: 'Personal Declarations',
      Sections: [
        {
          id: 1,
          key: "reportingData",
          nameGR: 'Meldedaten',
          nameEn: 'Reporting data',
          statusEn: 'In Progress',
          statusGR: '',
          fields: [
            {
              id: 1,
              key: 'insuranceNb',
              nameGR: 'Versicherungsnummer gem. Sozialvers.-Ausweis',
              nameEn: 'Insurance number according to social insurance card',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 2,
              key: 'birthName',
              nameGR: 'Geburtsname',
              nameEn: 'Birth name',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 3,
              key: 'birthDate',
              nameGR: 'Geburtsdatum',
              nameEn: 'Birth date',
              value: "4/19/2025",
              type: 'dateTextfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 4,
              key: 'street',
              nameGR: 'Straße',
              nameEn: 'Street',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 5,
              key: 'houseNb',
              nameGR: 'Hausnummer',
              nameEn: 'House number',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 6,
              key: 'postalCode',
              nameGR: 'Postleitzahl',
              nameEn: 'Postal code',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 7,
              key: 'location',
              nameGR: 'Ort',
              nameEn: 'Location',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 8,
              key: 'addressSuplement',
              nameGR: 'Anschriftenzusatz',
              nameEn: 'Address supplement',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 9,
              key: 'land',
              nameGR: 'Land',
              nameEn: 'Land',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 10,
              key: 'placeOfBirth',
              nameGR: 'Geburtsort',
              nameEn: 'Place of birth',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 11,
              key: 'countryOfBirth',
              nameGR: 'Geburtsland',
              nameEn: 'Country of birth',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 12,
              key: "gender",
              nameGR: 'Geschlecht',
              nameEn: 'Gender',
              value: 'test',
              type: 'textfield',
              xs: 6,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 13,
              key: 'isObligedtoSubmitImmediateReports',
              nameGR: 'Unternehmen zur Abgabe von Sofortmeldungen verpflichtet',
              nameEn: 'Companies are obliged to submit immediate reports',
              value: false,
              type: 'switch',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 14,
              key: 'isNonEUCountry',
              nameGR: 'Is Nationality of a non-EU Country?',
              nameEn: 'Ist die Staatsangehörigkeit eines Nicht-EU-Landes?',
              value: true,
              type: 'switch',
              xs: 12,
              relatedFieldsIds: [15, 16],
              dependantId: null,
            },
            {
              id: 15,
              key: "isWorkPermit",
              nameGR: 'Arbeitserlaubnis liegt vor?',
              nameEn: 'Do you have a work permit?',
              value: true,
              type: 'switch',
              xs: 12,
              relatedFieldsIds: [16, 17, 18],
              dependantId: 14,
            },
            {
              id: 16,
              key: "workPermitFile",
              nameGR: 'Arbeitserlaubnis',
              nameEn: 'Work Permit',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 15,
            },
            {
              id: 17,
              key: "workPermitFile",
              nameGR: 'Arbeitserlaubnis gültig bis',
              nameEn: 'Work permit valid until',
              value: "4/21/2025",
              type: 'dateTextfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 15,
            },
            {
              id: 18,
              key: "workPermitFile",
              nameGR: 'Aufenthaltsgenehmigung gültig bis',
              nameEn: 'Residence permit valid until',
              value: "9/21/2025",
              type: 'dateTextfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 15,
            },
          ]
        },
        {
          id: 2,
          key: "bankDetails",
          nameGR: 'Bankdaten',
          nameEn: 'Bank details',
          statusEn: 'In Progress',
          statusGR: '',
          fields: [
            {
              id: 1,
              key: 'iban',
              nameGR: 'IBAN',
              nameEn: 'IBAN',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 2,
              key: 'bic',
              nameGR: 'BIC',
              nameEn: 'BIC',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 3,
              key: 'diffAccountHolder',
              nameGR: 'ggf. abweichender Kontoinhaber',
              nameEn: 'Possibly different account holder',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
          ]
        },
        {
          id: 3,
          key: "constructionWages",
          nameGR: 'Baulohn',
          nameEn: 'Construction wages',
          statusEn: 'In Progress',
          statusGR: '',
          fields: [
            {
              id: 1,
              key: 'socialFundEmployeenb',
              nameGR: 'Arbeitnehmernummer Sozialkasse',
              nameEn: 'Social fund employee number',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 2,
              key: 'workInConstructionSince',
              nameGR: 'Im Baugewerbe Beschäftigt seit',
              nameEn: 'Worked in the construction industry since',
              value: "4/21/2025",
              type: 'dateTextfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },

          ]
        },
        {
          id: 4,
          key: "education",
          nameGR: 'Bildung',
          nameEn: 'Education',
          statusEn: 'In Progress',
          statusGR: '',
          fields: [
            {
              id: 1,
              key: 'highestSchoolDegree',
              nameGR: 'Höchster Schulabschluss',
              nameEn: 'Highest school degree',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },
            {
              id: 2,
              key: 'highestProfessionalQualification',
              nameGR: 'Höchster Berufsabschluss',
              nameEn: 'Highest professional qualification',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: null,
            },

          ]
        },
        {
          id: 5,
          key: "difficultDisability",
          nameGR: 'Schwer-behinderung',
          nameEn: 'Difficult-disability',
          statusEn: 'In Progress',
          statusGR: '',
          fields: [
            {
              id: 1,
              key: 'isSevereDisability',
              nameGR: 'Liegt eine Schwerbehinderung vor?',
              nameEn: 'Is there a severe disability?',
              value: true,
              type: 'switch',
              xs: 12,
              relatedFieldsIds: [2, 3, 4, 5, 6, 7],
              dependantId: null,
            },
            {
              id: 2,
              key: 'severelyDisabledCardFile',
              nameGR: 'Schwerbehindertenausweis',
              nameEn: 'Severely disabled ID card',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },
            {
              id: 3,
              key: 'severeDisabilityValidFrom',
              nameGR: 'Schwerbehinderung gültig ab',
              nameEn: 'Severe disability valid from',
              value: "4/19/2025",
              type: 'dateTextfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },
            {
              id: 4,
              key: 'severeDisabilityValidUntil',
              nameGR: 'Schwerbehinderung gültig bis',
              nameEn: 'Severe disability valid until',
              value: "8/19/2025",
              type: 'dateTextfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },
            {
              id: 5,
              key: 'degreeOfSevereDisability',
              nameGR: 'Grad der Schwerbehinderung',
              nameEn: 'Degree of severe disability',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },
            {
              id: 6,
              key: 'idNb',
              nameGR: 'Ausweis-Nr. / Akten- oder Geschäftszeichen',
              nameEn: 'ID number / File or business symbols',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },
            {
              id: 7,
              key: 'issuingOffice',
              nameGR: 'Austellende Dienststelle',
              nameEn: 'Issuing office',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },
            {
              id: 8,
              key: 'officeLocation',
              nameGR: 'Ort der Dienststelle',
              nameEn: 'Location of the office',
              value: 'test',
              type: 'textfield',
              xs: 12,
              relatedFieldsIds: [],
              dependantId: 1,
            },


          ]
        },


      ]
    },

  ])
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const taskId = query.get("taskId");

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('loginFormState')) {
      localStorage.setItem('loginFormState', 'default');
      setLoginFormState('default')
    }
    if (taskId) navigate(`/onboarding-form?taskId=${taskId}`)
  }, [])

  return (
    <Grid container>
      <Grid item container justifyContent='flex-end' position='absolute' top='2%' right='50%' zIndex={3} width='100px'>
        <TextField
          defaultValue={locale}
          select
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
          InputProps={{
            style: {
              borderRadius: '12px',
              fontFamily: 'DM Sans',
              height: '50px',
              borderTopLeftRadius: '10px',
              backgroundColor: '#F7F9FF',
              // width: '100%'
            },
          }}
          sx={{
            '& label.Mui-focused': {
              color: '#C3C2C7',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#B2BAC2',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#C3C2C7',
              },
              '&:hover fieldset': {
                borderColor: '#B2BAC2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#6F7E8C',
              },
            },
          }}
          onChange={(e) => {
            dispatch(setLocale(e.target.value as Locale));

          }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="gr">Deutsch</MenuItem>
        </TextField>
      </Grid>
      {isLoggedIn && (isCMS || (!isCMS && isOtpEnabled && isOtpVerified && ((userType === 1 && isSubscribed) || (!isCMS && isOtpEnabled && isOtpVerified && userType === 2)
      ))) ?
        <>
          <Grid item position='absolute' top='10%' left={open ? '14%' : '3.2%'} zIndex={2}
            style={{ backgroundColor: '#FEFEFF', boxShadow: '0px 4px 4px 0px #0000001F', width: '34px', height: '34px', borderRadius: '50px', textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {
              if (open) handleDrawerClose()
              else handleDrawerOpen()
            }
            }
          >
            {open ?
              <ArrowBackIosIcon style={{ color: '#93919A', marginLeft: 9, marginTop: 7 }} />
              :
              <ArrowForwardIosIcon style={{ color: '#93919A', marginLeft: 6, marginTop: 7 }} />
            }
          </Grid>
          <Box sx={{ display: 'flex', zIndex: 1, position: 'relative', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader
                onMouseOver={(e) => {
                  const target = e.target as HTMLElement;
                  target.style.cursor = 'pointer';
                }}>
                {open ? <img src={Logo} alt='logo' onClick={() => {
                  navigate('/tasks')
                  console.log('img clicked');
                }} style={{ width: 'clamp(108px,14vw,200px)' }} /> :
                  <img src={LetterLogo} alt='logo' onClick={() => {
                    navigate('/tasks')
                    console.log('img clicked');
                  }} style={{ width: 'clamp(25px, 3vw, 80px)' }} />
                }
              </DrawerHeader>
              <SideNavbar open={open} selectedModule={selectedModule} setSelectedModule={setSelectedModule} selectedClient={selectedClient} setSelectedClient={setSelectedClient} companiesTab={companiesTab} setCompaniesTab={setCompaniesTab} selectedUserNames={selectedUserNames} setSelectedUserNames={setSelectedUserNames} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100vh' }}>
              <DrawerHeader>
                <Grid container height='5vh'>
                  <UpperNavbar
                    modifyProfile={modifyProfile}
                    companiesTab={companiesTab}
                    setCompaniesTab={setCompaniesTab}
                  />
                </Grid>
              </DrawerHeader>
              <Grid container bgcolor='#F7F9FF' width={open ? '85vw' : '96vw'} padding={openRightDrawer ? '16px 16px 16px 16px' : '16px 0 16px 16px'} height='95vh' overflow='auto'>
                {isLoggedIn && isCMS ?
                  <Routes>
                    <>
                      <Route
                        path="/admin-panel/users"
                        element={<AdminManageUsers />}
                      />
                      <Route
                        path="/admin-panel/profile"
                        element={<EditProfile modifyProfile={modifyProfile} setModifyProfile={setModifyProfile} />}
                      />
                      <Route
                        path="/admin-panel/add-ta"
                        element={<AddTaxAdvisors />}
                      />
                      {window.location.pathname.toString() !== "/admin-panel/profile" || window.location.pathname.toString() !== "/admin-panel/users" ?
                        <Route path="*" element={<PageNotFound cmsOpen={open} />} />
                        :
                        <></>

                      }
                    </>
                  </Routes>
                  : (isLoggedIn && !isCMS && isOtpEnabled && isOtpVerified && ((userType === 1 && isSubscribed) || userType === 2)) ?
                    // roles.includes(userRolesoConst.SUPER_ADMIN_ID || userRolesoConst.ADMIN_ID)?
                    <Routes>
                      <Route
                        path="/users"
                        element={<ManageUsers companiesTab={companiesTab} setCompaniesTab={setCompaniesTab} selectedUserNames={selectedUserNames} setSelectedUserNames={setSelectedUserNames} />}
                      />
                      <Route
                        path="/add-users"
                        element={<AddUsers />}
                      />
                      <Route
                        path="/tasks"
                        element={<ManageTasks openDrawer={open} selectedModule={selectedModule} setSelectedModule={setSelectedModule} selectedClient={selectedClient} setSelectedClient={setSelectedClient} />}
                      />
                      <Route
                        path="/task-details"
                        element={<TaskDetails openRightDrawer={openRightDrawer} setOpenRightDrawer={setOpenRightDrawer} openDrawer={open} />}
                      />
                      <Route
                        path="/profile"
                        element={<EditProfile modifyProfile={modifyProfile} setModifyProfile={setModifyProfile} />}
                      />
                      <Route
                        path="/employees"
                        element={<Employees selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />}
                      />
                    </Routes>
                    //   :
                    //   <Routes>
                    //   <Route
                    //     path="/profile"
                    //     element={<EditProfile modifyProfile={modifyProfile} setModifyProfile={setModifyProfile} />}
                    //   />
                    // </Routes>
                    :
                    <Routes>
                      <Route path="*" element={<PageNotFound cmsOpen={open} />} />
                    </Routes>
                }
              </Grid>
            </Box>
          </Box>
        </>
        : isLoggedIn && !isCMS && !isSubscribed && userType === 1 && isOtpEnabled && isOtpVerified ?
          <Routes>
            <Route path="/plan-selection" element={<PlanSelection />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
          : isLoggedIn && !isCMS && !isOtpVerified ?
            <Routes>
              <Route path="/2fa" element={<TwoFactorAuthentication setLoginFormState={setLoginFormState} />} />
            </Routes>
            :
            <Routes>
              <Route
                path="/"
                element={<LoginPage setLoginFormState={setLoginFormState} loginFormState={loginFormState} />}
              />
              <Route path="*" element={<PageNotFound cmsOpen={open} />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/onboarding-form" element={<Onboarding taskId={taskId} onBoardingDetails={onBoardingDetails} setOnBoardingDetails={setOnBoardingDetails} />} />

            </Routes>
      }

    </Grid>
  )
}

export default Navigate