import * as apiConst from "../taxmation-consts";
import axios from "axios";

const GET_EMPLOYEE_LIST = apiConst.GET_EMPLOYEE_LIST;
export const getEmployeeList = () => {
    return axios
        .get(`${process.env.REACT_APP_URL + GET_EMPLOYEE_LIST}`,
            {
                withCredentials: true,
            }
        )
        .then((response) => {
            return response.data
        })
        .catch((error) => {
            return error.response.data
        })

}