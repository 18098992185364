import React, { useState, useEffect } from 'react';
import UserBlackIcon from "../assets/images/icons/navbar/userBlack.png";
import UserBlueIcon from "../assets/images/icons/navbar/userBlue.png";
import taskBlackIcon from "../assets/images/icons/navbar/taskBlack.png";
import taskBlueIcon from "../assets/images/icons/navbar/taskBlue.png";
import clientsBlackIcon from "../assets/images/icons/navbar/clientsBlackIcon.png";
import newsBlack from "../assets/images/icons/navbar/newsBlack.png";
import documentsBlack from "../assets/images/icons/navbar/documentBlack.png";
import modulesBlack from "../assets/images/icons/navbar/moduleBlack.png";
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupIcon from '@mui/icons-material/Group';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import { selectRoles, selectTaxAdvisorClients, selectUserType } from '../app/slices/userDataSlice';
import { useAppSelector } from '../app/hooks';
import * as userRolesConst from "../user-roles-consts";
import { Grid } from '@mui/material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { selectLocale } from '../app/slices/localsSlice';
import Message from '../sharedComponents/ui/Message';

const SideNavbar = ({ open, selectedModule, setSelectedModule, selectedClient, setSelectedClient, companiesTab, setCompaniesTab, selectedUserNames, setSelectedUserNames, selectedColumns, setSelectedColumns }: { open: any; selectedModule: any; setSelectedModule: any; selectedClient: any; setSelectedClient: any; companiesTab: any; setCompaniesTab: any; selectedUserNames: any; setSelectedUserNames: any; selectedColumns: any; setSelectedColumns: any;}) => {

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Users');

    const userType = useAppSelector(selectUserType);
    const userRoles = useAppSelector(selectRoles);
    const taxAdvisorClients = useAppSelector(selectTaxAdvisorClients);

    const [isClientsOpen, setIsClientsOpen] = useState(false);
    const [isModulesOpen, setIsModulesOpen] = useState(false);
    const [isTypesOpen, setIsTypesOpen]= useState<{ [key: number]: boolean }>({});
    const [isTypesInfoOpen, setIsTypesInfoOpen]= useState<{ [key: number]: boolean }>({});
    const [selectedTypeId, setSelectedTypeId] = useState<string[]>([]);
    const [selectedModuleId, setSelectedModuleId] = useState<string[]>([]);
    const [selectedTypeInfoId, setSelectedTypeInfoId] = useState<string[]>([]);


    const local = useAppSelector(selectLocale)

    const sideBarModules = [
        {
            id: 2,
            name: "Personal",
            nameGr: "Personal",
            types: [
                {
                    "id": 1,
                    "name": "Employees",
                    "nameGr": "Mitarbeiter",
                    "types": [
                        {
                            "id": 1,
                            "name":"Profile",
                            "nameGr":"Profile"
                        },
                        {
                            "id": 2,
                            "name":"New installation",
                            "nameGr":"Neuanlage"
                        },
                        {
                            "id": 3,
                            "name":"Changes",
                            "nameGr":"Änderungen"
                        },
                        {
                            "id": 4,
                            "name":"Exits",
                            "nameGr":"Austritte"
                        },
                        {
                            "id": 5,
                            "name":"eAU",
                            "nameGr":"eAU"
                        }
                    ]
                },
                {
                    "id": 2,
                    "name": "Monthly closing",
                    "nameGr": "Monatsabschluss"
                },
                {
                    "id": 3,
                    "name": "Evaluations",
                    "nameGr": "Auswertungen"
                },
            ]
        },
        {
            id: 3,
            name: "Financial accounting",
            nameGr: "Finanzbuchhaltung",
            types: []
        },
        {
            id: 4,
            name: "Annual financial statements",
            nameGr: "Jahresabschluss",
            types: []
        },
        {
            id: 5,
            name: "Onboarding",
            nameGr: "Onboarding",
            types: []
        },
    ];

    useEffect(() => {
        if (window.location.pathname.includes('task-details') || window.location.pathname.includes('tasks'))
            setActiveTab('Tasks')
        if (window.location.pathname.includes('users'))
            setActiveTab('Users')
        if(window.location.pathname.includes('employees'))
            setActiveTab('Employees')
        if(window.location.pathname.includes('payroll-reports'))
            setActiveTab('Payroll')
    })

    const toggleTypes = (id: any) => {
        setIsTypesOpen((prev: any) => ({
          ...prev,
          [id]: !prev[id],
        }));
      };
      const toggleTypesInfo = (id: any) => {
        setIsTypesInfoOpen((prev: any) => ({
          ...prev,
          [id]: !prev[id],
        }));
      };


      const location = useLocation();

        useEffect(() => {
            setSelectedClient([]);
            setSelectedUserNames([]);
            setSelectedColumns((prevState: any) => ({
                ...prevState,
                clientId: [],
            }));
        }, [location.pathname]);

    return (

        <List>
            {/* {(userType === 2 && (!userRoles?.includes(userRolesConst.CLIENT_ADMIN_ID))) ?
                <></> :
                <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        onClick={() => {
                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                            else navigate('/users')
                            setActiveTab("Users")
                        }
                        }
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={activeTab === "Users" ? UserBlueIcon : UserBlackIcon} />
                        </ListItemIcon>
                        <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0, color: activeTab === "Users" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>
                </ListItem>
            } */}


            {localStorage.getItem('isCMS') === 'true' ?
                <> </>


                :
                <ListItem disablePadding sx={{
                    display: 'block', '& .MuiListItemButton-root': {
                        marginBottom: '10px' // This adds space after each ListItemButton
                    },
                }}>
                    <ListItemButton
                        onClick={() => {
                            if (localStorage.getItem('isCMS') === 'true') navigate('/admin-panel/users')
                            else navigate('/tasks')
                            setActiveTab("Tasks")
                        }
                        }
                        sx={{
                            minHeight: 28,
                            justifyContent: open ? 'initial' : 'center',
                            backgroundColor: activeTab === "Tasks" ? "#E6E6E8" : "white",
                            marginRight: '20px',
                            marginLeft: '20px',
                            padding: '0px',
                            '&.MuiListItemButton-root': {
                                padding: '0px',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={activeTab === "Tasks" ? taskBlueIcon : taskBlackIcon} alt='icon' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}
                            primary={local === 'en' ? 'Tasks' : 'Aufgaben'}
                            sx={{ opacity: open ? 1 : 0, color: activeTab === "Tasks" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>

                    <ListItemButton

                        sx={{
                            minHeight: 28,
                            justifyContent: open ? 'initial' : 'center',
                            backgroundColor: activeTab === "News" ? "#E6E6E8" : "white",
                            marginRight: '20px',
                            marginLeft: '20px',
                            padding: '0px',
                            '&.MuiListItemButton-root': {
                                padding: '0px',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={newsBlack} alt='icon' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}
                            primary={local === 'en' ? 'News' : 'Nachrichten'}
                            sx={{ opacity: open ? 1 : 0, color: activeTab === "News" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>


                    <ListItemButton

                        sx={{
                            minHeight: 28,
                            justifyContent: open ? 'initial' : 'center',
                            backgroundColor: activeTab === "Documents" ? "#E6E6E8" : "white",
                            marginRight: '20px',
                            marginLeft: '20px',
                            padding: '0px',
                            '&.MuiListItemButton-root': {
                                padding: '0px',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                width: 35,
                                mr: open ? 1 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={documentsBlack} alt='icon' />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'bold',
                                fontSize: '14px'
                            }}
                            primary={local === 'en' ? 'Documents' : 'Dokumente'}
                            sx={{ opacity: open ? 1 : 0, color: activeTab === "Documents" ? "#5D5FEF" : "black" }} className='neutral-4 labels' />
                    </ListItemButton>

                    <Grid item className='greyLine' style={{width:'100%'}} marginBottom={2} marginTop={1}></Grid>

                    <>
                        <ListItemButton
                            sx={{
                                minHeight: 28,
                                justifyContent: open ? 'initial' : 'center',
                                backgroundColor: activeTab === "Modules" ? "#E6E6E8" : "white",
                                marginRight: '20px',
                                marginLeft: '20px',
                                padding: '0px',
                                '&.MuiListItemButton-root': {
                                    padding: '0px',
                                },
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    width: 35,
                                    mr: open ? 1 : 0,
                                    ml: open ? 0 : 3,
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={modulesBlack} alt='icon' />

                            </ListItemIcon>
                            <Grid container direction='row' alignItems='center' columnGap={0.5}>
                                <Grid item>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            fontWeight: 'bold',
                                            fontSize: '14px'
                                        }}
                                        primary='Modules'
                                        sx={{ opacity: open ? 1 : 0, color: activeTab === "Modules" ? "#5D5FEF" : "black", fontWeight: 'normal', fontSize: '14px' }}
                                    />
                                </Grid>
                                {open ?
                                    <Grid item
                                        onClick={() => setIsModulesOpen(!isModulesOpen)}>
                                        {isModulesOpen ?
                                            <KeyboardArrowDownIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                            :
                                            <KeyboardArrowRightIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                        }
                                    </Grid>

                                    :
                                    <></>}
                            </Grid>
                        </ListItemButton>

                        {open && isModulesOpen ?
                            <Grid container direction='column' rowGap={2} paddingLeft={7} marginBottom={isModulesOpen ? 2 : 0}>
                                {sideBarModules?.map((d: any, index: any) => (
                                    <Grid container key={index} className='labels' style={{width:'90%', padding: d.types.length > 0 ? '4px 0 0 4px' : '4px 0 4px 4px'}} rowGap={1.2}>
                                        <Grid container direction='row' className={`selected-module ${selectedModuleId.includes(d.id) ? 'selected' :''}`} 
                                        >
                                            <Grid item style={{cursor:'pointer',wordBreak: 'break-word', whiteSpace: 'normal', overflowWrap: 'break-word', maxWidth: '100%'}} 
                                            // onClick={(e)=>{
                                            //     e.stopPropagation();
                                                
                                            //     if(window.location.pathname.includes('task')){
                                            //     setSelectedModuleId((prev: any) => prev.includes(d.id) ? prev.filter((id: any) => id !== d.id) : [...prev, d.id]);
                                            //     setSelectedModule((prevSelectedModule: any) => {
                                            //     if (prevSelectedModule?.includes(d.id)) {
                                            //       return prevSelectedModule?.filter((id: any) => id !== d.id);
                                            //     }
                                            //     else {
                                            //       return [...prevSelectedModule, d.id];
                                            //     }
                                            //   });
                                            // }
                                            // }}
                                            ><span style={{fontWeight: isTypesOpen[d.id] ? 'bold' : 'normal'}}>{local === 'en' ? d.name : d.nameGr}</span> </Grid>
                                          {d.types.length > 0 && (
                                        <Grid item onClick={() => toggleTypes(d.id)}>
                                            {isTypesOpen[d.id] ? (
                                            <KeyboardArrowDownIcon
                                                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                            />
                                            ) : (
                                            <KeyboardArrowRightIcon
                                                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                            />
                                            )}
                                        </Grid>
                                        )}
                                    </Grid>

                                    {/* Types Mapping */}
                                    {isTypesOpen[d.id] &&
                                        d.types.map((type: any) => (
                                        <Grid
                                            container
                                            key={type.id}
                                            className={isTypesInfoOpen[type.id] ? 'openedTaskType' :`selected-type ${
                                            selectedTypeId.includes(`${d.id}-${type.id}`) ? "selected" : ""
                                            }`}
                                            rowGap={2}
                                             //   onClick={()=>{
                                            //     if (window.location.pathname.includes('tasks')) {
                                            //         setSelectedTypeId((prev: any) => {
                                            //           const typeId = `${d.id}-${type.id}`; 
                                            //           return prev.includes(typeId)
                                            //             ? prev.filter((id: any) => id !== typeId) 
                                            //             : [...prev, typeId]; 
                                            //         });
                                            //       }
                                            //     }}
                                        >
                                            <Grid item style={{ cursor: "pointer" }}>
                                            <span style={{ fontWeight: isTypesInfoOpen[type.id] ? "bold" : "normal" }}>
                                                {local === "en" ? type.name : type.nameGr}
                                            </span>
                                            </Grid>
                                            {type.types && type.types.length > 0 && (
                                            <Grid item onClick={() => toggleTypesInfo(type.id)}>
                                                {isTypesInfoOpen[type.id] ? (
                                                <KeyboardArrowDownIcon
                                                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                                />
                                                ) : (
                                                <KeyboardArrowRightIcon
                                                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                                                />
                                                )}
                                            </Grid>
                                            )}

                                            {/* Types Info Mapping */}
                                            {isTypesInfoOpen[type.id] &&
                                            type.types &&
                                            type.types.map((typeInfo: any) => (
                                                <Grid
                                                container
                                                key={typeInfo.id}
                                                className={`selected-type-info ${
                                                    selectedTypeInfoId.includes(`${d.id}-${type.id}-${typeInfo.id}`) ? "selected" : ""
                                                }`}
                                                rowGap={2}
                                                paddingLeft={3}
                                                 //   onClick={()=>{
                                            //     if (window.location.pathname.includes('tasks')) {
                                            //         setSelectedTypeInfoId((prev: any) => {
                                            //           const typeId = `${d.id}-${type.id}-${typeInfo.id}`; 
                                            //           return prev.includes(typeId)
                                            //             ? prev.filter((id: any) => id !== typeId) 
                                            //             : [...prev, typeId]; 
                                            //         });
                                            //       }
                                            //     }}
                                            onClick={()=>{
                                                if(typeInfo.id === 1){
                                                    navigate('/employees');
                                                }
                                            }}
                                                >
                                                <Grid item style={{ cursor: "pointer" }}>
                                                    {local === "en" ? typeInfo.name : typeInfo.nameGr}
                                                </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        ))}
                                    </Grid>
                                ))}
                                </Grid>
                            :
                            <></>
                        }

                    </>

                    {userType === 1 ?

                        <>
                            <ListItemButton
                             onClick={()=>{
                                if(window.location.pathname.includes('tasks')){
                                    navigate('/users')
                                    setCompaniesTab(true);
                                }
                                else if(window.location.pathname.includes('users')){
                                    setCompaniesTab(true);
                                }
                            }}
                                sx={{
                                    minHeight: 28,
                                    justifyContent: open ? 'initial' : 'center',
                                    backgroundColor: activeTab === "News" ? "#E6E6E8" : "white",
                                    marginRight: '20px',
                                    marginLeft: '20px',
                                    padding: '0px',
                                    '&.MuiListItemButton-root': {
                                        padding: '0px',
                                    },
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        width: 35,
                                        mr: open ? 1 : 0,
                                        ml: open ? 0 : 3,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={clientsBlackIcon} alt='icon' />

                                </ListItemIcon>
                                <Grid container direction='row' alignItems='center' columnGap={0.5}>
                                    <Grid item>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontWeight: 'bold',
                                                fontSize: '14px'
                                            }}
                                            primary={local === 'en' ? 'Clients' : 'Mandanten'}
                                            sx={{ opacity: open ? 1 : 0, color: activeTab === "News" ? "#5D5FEF" : "black", fontWeight: 'normal', fontSize: '14px' }}
                                        />
                                    </Grid>
                                    {open ?
                                        <Grid item
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsClientsOpen(!isClientsOpen)
                                            }}>
                                            {isClientsOpen ?
                                                <KeyboardArrowDownIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                                :
                                                <KeyboardArrowRightIcon style={{ width: '20px', height: '20px', marginTop: '4px', cursor: 'pointer' }} />
                                            }
                                        </Grid>

                                        :
                                        <></>}
                                </Grid>
                            </ListItemButton>

                            {open && isClientsOpen ?
                                <Grid container direction='column' style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden', boxSizing: 'border-box', display: 'block' }} paddingLeft={5}>
                                    {taxAdvisorClients?.map((c: any) => (
                                        <>
                                            {c.name ?
                                                <Grid item width='100%'>
                                                    <ListItemButton
                                                    
                                                        onClick={(e)=>{
                                                            e.stopPropagation();
                                                            if(window.location.pathname.includes('tasks')){
                                                            setSelectedClient((prevSelected: any) => {
                                                                if (prevSelected.includes(c.name)) {
                                                                  return prevSelected.filter((v: any) => v !== c.name);
                                                                }
                                                                else {
                                                                  return [...prevSelected, c.name];
                                                                }
                                                              })
                                                            }
                                                            else if(window.location.pathname.includes('users')){
                                                                setSelectedUserNames((prevSelected: any) => {
                                                                    if (prevSelected.includes(c.name)) {
                                                                      return prevSelected.filter((v: any) => v !== c.name);
                                                                    }
                                                                    else {
                                                                      return [...prevSelected, c.name];
                                                                    }
                                                                  })
                                                            }
                                                            else if(window.location.pathname.includes('employees')){
                                                                setSelectedColumns((prevState: any) => ({
                                                                    ...prevState,
                                                                    clientId: prevState.clientId.includes(c.id.toLowerCase())
                                                                      ? prevState.clientId.filter((clientId: any) => clientId.toLowerCase() !== c.id.toLowerCase()) 
                                                                      : [...prevState.clientId, c.id.toLowerCase()],
                                                                  }));
                                                            }
                                                        }}
                                                    
                                                        sx={{
                                                            minHeight: 28,
                                                            marginTop: '10px',
                                                            justifyContent: open ? 'initial' : 'center',
                                                            backgroundColor: activeTab === "News" ? "#E6E6E8" : "white",
                                                            marginRight: '20px',
                                                            marginLeft: '20px',
                                                            padding: '0px',
                                                            fontSize: '14px',
                                                            '&.MuiListItemButton-root': {
                                                                padding: '0px',
                                                            },

                                                        }}
                                                    >

                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                fontWeight: 'normal',
                                                                fontSize: '14px'
                                                            }}
                                                            primary={c.name} sx={{ opacity: open ? 1 : 0, 
                                                                // color: activeTab === "News" ? "#5D5FEF" : "black",
                                                                color: (window.location.pathname.includes('tasks') && selectedClient.includes(c.name)) ||
                                                                (window.location.pathname.includes('users') && selectedUserNames.includes(c.name)) ||
                                                                (window.location.pathname.includes('employees') && selectedColumns.clientName.includes(c.name)) ? '#5D5FEF' : 'black', 
                                                                backgroundColor: (window.location.pathname.includes('tasks') && selectedClient.includes(c.name)) ||
                                                                (window.location.pathname.includes('users') && selectedUserNames.includes(c.name)) ||
                                                                (window.location.pathname.includes('employees') && selectedColumns.clientId.includes(c.id.toLowerCase())) ?
                                                         '#E6E6E8' : 'transparent' }} className='neutral-4 labels' />
                                                    </ListItemButton>
                                                </Grid> : ""}
                                        </>

                                    ))}
                                </Grid>
                                :
                                <></>
                            }

                        </>
                        :
                        <></>
                    }

                </ListItem>
            }


        </List>
    )
}

export default SideNavbar