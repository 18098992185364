import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface FormsState {
    onboardingFormOld: any[],
    onboardingForm: any[],
}

const initialState: FormsState = {
    onboardingFormOld: [
        // {
        //     id: 1,
        //     key: 'personalDetails',
        //     nameGR: 'Persönliche Angaben',
        //     nameEn: 'Personal Details',
        //     Sections: [
        //         {
        //             id: 1,
        //             key: "reportingData",
        //             nameGR: 'Meldedaten',
        //             nameEn: 'Reporting data',
        //             statusEn: 'In Progress',
        //             statusGR: '',
        //             fields: [
        //                 {
        //                     id: 1,
        //                     key: 'insuranceNb',
        //                     nameGR: 'Versicherungsnummer gem. Sozialvers.-Ausweis',
        //                     nameEn: 'Insurance number according to social insurance card',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 2,
        //                     key: 'birthName',
        //                     nameGR: 'Geburtsname',
        //                     nameEn: 'Birth name',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 3,
        //                     key: 'birthDate',
        //                     nameGR: 'Geburtsdatum',
        //                     nameEn: 'Birth date',
        //                     value: "4/19/2025",
        //                     type: 'date-textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 4,
        //                     key: 'street',
        //                     nameGR: 'Straße',
        //                     nameEn: 'Street',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 5,
        //                     key: 'houseNb',
        //                     nameGR: 'Hausnummer',
        //                     nameEn: 'House number',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 6,
        //                     key: 'postalCode',
        //                     nameGR: 'Postleitzahl',
        //                     nameEn: 'Postal code',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 7,
        //                     key: 'location',
        //                     nameGR: 'Ort',
        //                     nameEn: 'Location',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 8,
        //                     key: 'addressSuplement',
        //                     nameGR: 'Anschriftenzusatz',
        //                     nameEn: 'Address supplement',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 9,
        //                     key: 'land',
        //                     nameGR: 'Land',
        //                     nameEn: 'Land',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 10,
        //                     key: 'placeOfBirth',
        //                     nameGR: 'Geburtsort',
        //                     nameEn: 'Place of birth',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 11,
        //                     key: 'countryOfBirth',
        //                     nameGR: 'Geburtsland',
        //                     nameEn: 'Country of birth',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 12,
        //                     key: "gender",
        //                     nameGR: 'Geschlecht',
        //                     nameEn: 'Gender',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 6,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 13,
        //                     key: 'isObligedtoSubmitImmediateReports',
        //                     nameGR: 'Unternehmen zur Abgabe von Sofortmeldungen verpflichtet',
        //                     nameEn: 'Companies are obliged to submit immediate reports',
        //                     value: false,
        //                     type: 'switch',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 14,
        //                     key: 'isNonEUCountry',
        //                     nameGR: 'Is Nationality of a non-EU Country?',
        //                     nameEn: 'Ist die Staatsangehörigkeit eines Nicht-EU-Landes?',
        //                     value: true,
        //                     type: 'switch',
        //                     xs: 12,
        //                     relatedFieldsIds: [15, 16],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 15,
        //                     key: "isWorkPermit",
        //                     nameGR: 'Arbeitserlaubnis liegt vor?',
        //                     nameEn: 'Do you have a work permit?',
        //                     value: true,
        //                     type: 'switch',
        //                     xs: 12,
        //                     relatedFieldsIds: [16, 17, 18],
        //                     dependantId: 14,
        //                 },
        //                 {
        //                     id: 16,
        //                     key: "workPermitFile",
        //                     nameGR: 'Arbeitserlaubnis',
        //                     nameEn: 'Work Permit',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 15,
        //                 },
        //                 {
        //                     id: 17,
        //                     key: "workPermitFile",
        //                     nameGR: 'Arbeitserlaubnis gültig bis',
        //                     nameEn: 'Work permit valid until',
        //                     value: "4/21/2025",
        //                     type: 'date-textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 15,
        //                 },
        //                 {
        //                     id: 18,
        //                     key: "workPermitFile",
        //                     nameGR: 'Aufenthaltsgenehmigung gültig bis',
        //                     nameEn: 'Residence permit valid until',
        //                     value: "9/21/2025",
        //                     type: 'date-textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 15,
        //                 },
        //             ]
        //         },
        //         {
        //             id: 2,
        //             key: "bankDetails",
        //             nameGR: 'Bankdaten',
        //             nameEn: 'Bank details',
        //             statusEn: 'In Progress',
        //             statusGR: '',
        //             fields: [
        //                 {
        //                     id: 1,
        //                     key: 'iban',
        //                     nameGR: 'IBAN',
        //                     nameEn: 'IBAN',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 2,
        //                     key: 'bic',
        //                     nameGR: 'BIC',
        //                     nameEn: 'BIC',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 3,
        //                     key: 'diffAccountHolder',
        //                     nameGR: 'ggf. abweichender Kontoinhaber',
        //                     nameEn: 'Possibly different account holder',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //             ]
        //         },
        //         {
        //             id: 3,
        //             key: "constructionWages",
        //             nameGR: 'Baulohn',
        //             nameEn: 'Construction wages',
        //             statusEn: 'In Progress',
        //             statusGR: '',
        //             fields: [
        //                 {
        //                     id: 1,
        //                     key: 'socialFundEmployeenb',
        //                     nameGR: 'Arbeitnehmernummer Sozialkasse',
        //                     nameEn: 'Social fund employee number',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 2,
        //                     key: 'workInConstructionSince',
        //                     nameGR: 'Im Baugewerbe Beschäftigt seit',
        //                     nameEn: 'Worked in the construction industry since',
        //                     value: "4/21/2025",
        //                     type: 'date-textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },

        //             ]
        //         },
        //         {
        //             id: 4,
        //             key: "education",
        //             nameGR: 'Bildung',
        //             nameEn: 'Education',
        //             statusEn: 'In Progress',
        //             statusGR: '',
        //             fields: [
        //                 {
        //                     id: 1,
        //                     key: 'highestSchoolDegree',
        //                     nameGR: 'Höchster Schulabschluss',
        //                     nameEn: 'Highest school degree',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 2,
        //                     key: 'highestProfessionalQualification',
        //                     nameGR: 'Höchster Berufsabschluss',
        //                     nameEn: 'Highest professional qualification',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: null,
        //                 },

        //             ]
        //         },
        //         {
        //             id: 5,
        //             key: "difficultDisability",
        //             nameGR: 'Schwer-behinderung',
        //             nameEn: 'Difficult-disability',
        //             statusEn: 'In Progress',
        //             statusGR: '',
        //             fields: [
        //                 {
        //                     id: 1,
        //                     key: 'isSevereDisability',
        //                     nameGR: 'Liegt eine Schwerbehinderung vor?',
        //                     nameEn: 'Is there a severe disability?',
        //                     value: true,
        //                     type: 'switch',
        //                     xs: 12,
        //                     relatedFieldsIds: [2, 3, 4, 5, 6, 7],
        //                     dependantId: null,
        //                 },
        //                 {
        //                     id: 2,
        //                     key: 'severelyDisabledCardFile',
        //                     nameGR: 'Schwerbehindertenausweis',
        //                     nameEn: 'Severely disabled ID card',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },
        //                 {
        //                     id: 3,
        //                     key: 'severeDisabilityValidFrom',
        //                     nameGR: 'Schwerbehinderung gültig ab',
        //                     nameEn: 'Severe disability valid from',
        //                     value: "4/19/2025",
        //                     type: 'date-textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },
        //                 {
        //                     id: 4,
        //                     key: 'severeDisabilityValidUntil',
        //                     nameGR: 'Schwerbehinderung gültig bis',
        //                     nameEn: 'Severe disability valid until',
        //                     value: "8/19/2025",
        //                     type: 'date-textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },
        //                 {
        //                     id: 5,
        //                     key: 'degreeOfSevereDisability',
        //                     nameGR: 'Grad der Schwerbehinderung',
        //                     nameEn: 'Degree of severe disability',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },
        //                 {
        //                     id: 6,
        //                     key: 'idNb',
        //                     nameGR: 'Ausweis-Nr. / Akten- oder Geschäftszeichen',
        //                     nameEn: 'ID number / File or business symbols',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },
        //                 {
        //                     id: 7,
        //                     key: 'issuingOffice',
        //                     nameGR: 'Austellende Dienststelle',
        //                     nameEn: 'Issuing office',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },
        //                 {
        //                     id: 8,
        //                     key: 'officeLocation',
        //                     nameGR: 'Ort der Dienststelle',
        //                     nameEn: 'Location of the office',
        //                     value: '',
        //                     type: 'textfield',
        //                     xs: 12,
        //                     relatedFieldsIds: [],
        //                     dependantId: 1,
        //                 },


        //             ]
        //         },


        //     ]
        // },
        // {
        //     id: 2,
        //     key: 'wageTax',
        //     nameGR: 'Lohnsteuer',
        //     nameEn: 'Wage tax',
        //     Sections: []
        // },
        // {
        //     id: 3,
        //     value: 'socialInsurance',
        //     nameGR: 'Sozial-versicherung',
        //     nameEn: 'Social Insurance',
        //     Sections: []
        // },
        // {
        //     id: 4,
        //     nameGR: 'Weitere Angaben',
        //     nameEn: 'More Details',
        //     Sections: []
        // },
        // {
        //     id: 5,
        //     nameGR: 'Beschäftigung',
        //     nameEn: 'Employment',
        //     Sections: []
        // },
        // {
        //     id: 6,
        //     nameGR: 'Abrechnungs-daten',
        //     nameEn: 'Billing Data',
        //     Sections: []
        // },
    ],
    onboardingForm: [
        {
            id: 1,
            key: 'personalDetails',
            nameGR: 'Persönliche Angaben',
            nameEn: 'Personal Details',
            Sections: [
                {
                    id: 1,
                    key: "reportingData",
                    nameGR: 'Meldedaten',
                    nameEn: 'Reporting Data',
                    statusEn: 'In Progress',
                    statusGR: '',
                    helperText: 'To be provided',
                    fields: [
                        {
                            id: 1,
                            key: 'employmentRelationship',
                            nameGR: 'Arbeitsverhältnis',
                            nameEn: 'Employment relationship',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 2,
                            key: 'employeeType',
                            nameGR: 'Arbeitnehmertyp',
                            nameEn: 'Employee type',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 3,
                            key: 'entryDate',
                            nameGR: 'Eintrittsdatum',
                            nameEn: 'Entry date',
                            value: '5/5/2025',
                            type: 'date-textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 4,
                            key: 'firstName',
                            nameGR: 'Vorname',
                            nameEn: 'First name',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 5,
                            key: 'familyName',
                            nameGR: 'Familienname',
                            nameEn: 'Family name',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 6,
                            key: 'email',
                            nameGR: 'Email-Adresse bei Minderjährigen Email Adresse des Erziehungsberechtigten',
                            nameEn: 'Email address for minors Email address of legal guardian',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 7,
                            key: 'isSevereDisability',
                            nameGR: 'Schwerbehindert',
                            nameEn: 'Severely disabled',
                            value: true,
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 8,
                            key: 'insuranceNb',
                            nameGR: 'Versicherungsnummer gem. Sozialversicherungsausweis',
                            nameEn: 'Insurance number according to social security card',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 10,
                            key: 'birthName',
                            nameGR: 'Geburtsname',
                            nameEn: 'Birth name',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 11,
                            key: 'dateOfBirth',
                            nameGR: 'Geburtsdatum',
                            nameEn: 'Date of birth',
                            value: "4/21/2023",
                            age: 2,
                            type: 'date-textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 12,
                            key: 'street',
                            nameGR: 'Straße',
                            nameEn: 'Street',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 13,
                            key: 'houseNb',
                            nameGR: 'Hausnummer',
                            nameEn: 'House number',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 14,
                            key: 'postalCode',
                            nameGR: 'Postleitzahl',
                            nameEn: 'Postal code',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 15,
                            key: 'location',
                            nameGR: 'Ort',
                            nameEn: 'Location',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 16,
                            key: 'addressSupplement',
                            nameGR: 'Anschriftenzusatz',
                            nameEn: 'Address supplement',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 17,
                            key: 'land',
                            nameGR: 'Land',
                            nameEn: 'Land',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 18,
                            key: 'placeOfBirth',
                            nameGR: 'Geburtsort',
                            nameEn: 'Place of birth',
                            value: '',
                            type: 'textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 19,
                            key: 'countryOfBirth',
                            nameGR: 'Geburtsland',
                            nameEn: 'Country of birth',
                            value: '',
                            type: 'textfield-select',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 20,
                            key: 'gender',
                            nameGR: 'Geschlecht',
                            nameEn: 'Gender',
                            value: '',
                            type: 'textfield-select',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 21,
                            key: 'nationality',
                            nameGR: 'Staatsangehörigkeit',
                            nameEn: 'Nationality',
                            value: '',
                            type: 'textfield-select',
                            xs: 6,
                            relatedFieldsIds: [23,24,25,26],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 22,
                            key: 'identityDocument',
                            nameGR: 'Ausweisdokument',
                            nameEn: 'Identity document',
                            value: '',
                            type: 'file',
                            fileId: '',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                            dependantValue: null
                        },
                        {
                            id: 23,
                            key: 'isWorkPermitAvailable',
                            nameGR: 'Arbeitserlaubnis liegt vor?',
                            nameEn: 'Do you have a work permit?',
                            value: false,
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [24, 25, 26],
                            dependantId: 21,
                            dependantValue: 'non-eu'
                        },
                        {
                            id: 24,
                            key: 'workPermitFile',
                            nameGR: 'Arbeitserlaubnis',
                            nameEn: 'Work permit',
                            value: '',
                            type: 'file',
                            fileId: '',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 23,
                            dependantValue: true
                        },
                        {
                            id: 25,
                            key: 'workpermitValidDate',
                            nameGR: 'Arbeitserlaubnis gültig bis',
                            nameEn: 'Work permit valid until',
                            value: '',
                            type: 'date-textfield',
                            fileId: '',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 23,
                            dependantValue: true
                        },
                        {
                            id: 26,
                            key: 'residencePermitValidDate',
                            nameGR: 'Aufenthaltsgenehmigung gültig bis',
                            nameEn: 'Residence permit valid until',
                            value: '',
                            isLimited: true,
                            type: 'date-textfield',
                            fileId: '',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 23,
                            dependantValue: true
                        },
                    ]
                },
                {
                    id: 2,
                    key: "bankDetails",
                    nameGR: 'Bankdaten',
                    nameEn: 'Bank details',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'iban',
                            nameGR: 'IBAN',
                            nameEn: 'IBAN',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'bic',
                            nameGR: 'BIC',
                            nameEn: 'BIC',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 3,
                            key: 'diffAccountHolder',
                            nameGR: 'ggf. abweichender Kontoinhaber',
                            nameEn: 'Possibly different account holder',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                    ]
                },
                {
                    id: 3,
                    key: "constructionWages",
                    nameGR: 'Baulohn',
                    nameEn: 'Construction wages',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'socialFundEmployeenb',
                            nameGR: 'Arbeitnehmernummer Sozialkasse',
                            nameEn: 'Social fund employee number',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'workInConstructionSince',
                            nameGR: 'Im Baugewerbe Beschäftigt seit',
                            nameEn: 'Worked in the construction industry since',
                            value: "4/21/2025",
                            type: 'date-textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },

                    ]
                },
                {
                    id: 4,
                    key: "education",
                    nameGR: 'Bildung',
                    nameEn: 'Education',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'highestSchoolDegree',
                            nameGR: 'Höchster Schulabschluss',
                            nameEn: 'Highest school degree',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'highestProfessionalQualification',
                            nameGR: 'Höchster Berufsabschluss',
                            nameEn: 'Highest professional qualification',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },

                    ]
                },
                {
                    id: 5,
                    key: "difficultDisability",
                    nameGR: 'Schwer-behinderung',
                    nameEn: 'Difficult-disability',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'isSevereDisability',
                            nameGR: 'Liegt eine Schwerbehinderung vor?',
                            nameEn: 'Is there a severe disability?',
                            value: true,
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            dependantId: null,
                            dependantValue: null,
                        },
                        {
                            id: 2,
                            key: 'severelyDisabledIDCardFile',
                            nameGR: 'Kopie des Schwerbehindertenausweises',
                            nameEn: "Copy of the severely disabled person's ID card",
                            value: '',
                            type: 'file',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true,
                        },
                        {
                            id: 3,
                            key: 'severeDisabilityValidFrom',
                            nameGR: 'Schwerbehinderung gültig ab',
                            nameEn: 'Severe disability valid from',
                            value: "4/19/2025",
                            type: 'date-textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true,
                        },
                        {
                            id: 4,
                            key: 'severeDisabilityValidUntil',
                            nameGR: 'Schwerbehinderung gültig bis',
                            nameEn: 'Severe disability valid until',
                            value: "8/19/2025",
                            type: 'date-textfield',
                            xs: 6,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true,
                        },
                        {
                            id: 5,
                            key: 'degreeOfSevereDisability',
                            nameGR: 'Grad der Schwerbehinderung',
                            nameEn: 'Degree of severe disability',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [6, 7],
                            dependantId: 1,
                            dependantValue: true
                        },
                        {
                            id: 6,
                            key: 'isNoticeOfEqualTreatmentExists',
                            nameGR: 'Gibt es einen Bescheid über die Gleichstellung der Agentur für Arbeit?',
                            nameEn: 'Is there a notice of equal treatment from the employment agency?',
                            value: false,
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [7, 9],
                            dependantId: 5,
                            dependantValue: [20, 30, 40],
                            dependantCheckType: 'Or',
                        },
                        {
                            id: 7,
                            key: 'isAtLeast18hrs/week',
                            nameGR: 'Mindestens 18 Arbeitsstunden pro Woche?',
                            nameEn: 'At least 18 working hours per week?',
                            value: false,
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [8, 9],
                            dependantId: 6,
                            dependantValue: ['Yes'],
                            secondDependantId: 5,
                            secondDependantValue: [50, 60, 70, 80, 90],
                            dependantCheckType: 'Or',
                        },
                        {
                            id: 8,
                            key: 'isNoticeOfApprovalFromEmploymentAgency',
                            nameGR: 'Gibt es einen Zulassungsbescheid der Agentur für Arbeit wegen Art und Schwere der Behinderung zur Anrechnung als Pflichtarbeitsplatz?',
                            nameEn: 'Is there a notice of approval from the employment agency due to the nature and severity of the disability for recognition as a compulsory job?',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [10, 11],
                            dependantId: 7,
                            dependantValue: false,
                            secondDependantId: 6,
                            secondDependantValue: ['Yes'],
                            parentDependantId: 5,
                            parentDependantValue: [50, 60, 70, 80, 90, 100],
                            secondParentDependantId: 6,
                            secondParentDependantValue: ['Yes'],
                            dependantCheckType: 'Or',
                        },
                        {
                            id: 9,
                            key: 'iWorkingTimeUnder18hrhWithApproval',
                            nameGR: 'Arbeitszeit unter 18 Stunden mit Zulassung der Arbeitsagentur',
                            nameEn: 'Working time under 18 hours with approval from the employment agency',
                            value: false,
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 10,
                            dependantValue: 'Yes',
                        },
                        {
                            id: 10,
                            key: 'isNoticeOfMultipleCreditingExists',
                            nameGR: 'Gibt es einen Bescheid über die Mehrfachanrechnung der Agentur für Arbeit?',
                            nameEn: 'Is there a notice of multiple crediting from the employment agency?',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 10,
                            dependantValue: 'Yes',
                            secondDependantId: 7,
                            secondDependantValue: true,
                        },
                        {
                            id: 11,
                            key: 'mandatoryJobsNb',
                            nameGR: 'Anzahl Pflichtarbeitsplätze laut Bescheid der Agentur für Arbeit Values DE:',
                            nameEn: 'Number of mandatory jobs according to the employment agency Values DE:',
                            value: false,
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 10,
                            dependantValue: 'Yes',
                        },
                        {
                            id: 12,
                            key: 'personGroupElan',
                            nameGR: 'Personengruppe ELAN',
                            nameEn: 'Person group ELAN',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 10,
                            dependantValue: ['Requested', 'No'],
                            secondDependantId: 10,
                            secondDependantValue: ['Yes', 'No', 'Requested'],
                            dependantCheckType: 'Or',
                            // secondDependantId: 11,
                            // secondDependantValue: ['Requested']
                            // parentDependantId: 9,
                            // parentDependantValue: true
                        },
                        {
                            id: 14,
                            key: 'idNb',
                            nameGR: 'Ausweis-Nr. / Akten- oder Geschäftszeichen',
                            nameEn: 'ID number / File or business symbols',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true
                        },
                        {
                            id: 15,
                            key: 'issuingOffice',
                            nameGR: 'Austellende Dienststelle',
                            nameEn: 'Issuing office',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true
                        },
                        {
                            id: 16,
                            key: 'officeLocation',
                            nameGR: 'Ort der Dienststelle',
                            nameEn: 'Location of the office',
                            value: '',
                            type: 'textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true
                        },


                    ]
                },
                {
                    id: 6,
                    key: "pensioner",
                    nameGR: 'Pensioner',
                    nameEn: 'Rentner',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'isPensionReceived',
                            nameGR: 'Wird Rente bezogen?',
                            nameEn: 'Is a pension being drawn?',
                            value: true,
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [2],
                            dependantId: null,
                            dependantValue: null

                        },
                        {
                            id: 2,
                            key: 'pensionType',
                            nameGR: 'Art der bezogenen Rente',
                            nameEn: 'Type of pension received',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [3],
                            dependantId: 1,
                            dependantValue: true
                        },
                        {
                            id: 3,
                            key: 'pensionStartDate',
                            nameGR: 'Beginn der Rente laut Rentenbescheid',
                            nameEn: 'Start of the pension according to the pension notice',
                            value: '',
                            type: 'date-textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 2,
                            dependantValue: ['Rente wegen teilweiser Erwerbsmindung'],
                            dependantCheckType: 'Not'
                        },
                        {
                            id: 4,
                            key: 'pensionStartDateAt65',
                            nameGR: 'Regelaltersgrenze bei 65 Jahren (Es wurde Anpassungsgeld für entlassene Arbeitnehmer des Bergbaus bezogen - § 235 SGB VI)',
                            nameEn: 'Start of pension according to standard retirement age at 65 (adjustment allowance was received for dismissed employees in the mining industry - § 235 SGB VI)',
                            value: '',
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 2,
                            dependantValue: ['Rente wegen teilweiser Erwerbsmindung'],
                            dependantCheckType: 'Not'
                        },

                    ]
                },
            ]
        },
        {
            id: 2,
            key: 'wageTax',
            nameGR: 'Lohnsteuer',
            nameEn: 'Wage tax',
            dependantSectionId: 1,
            dependantSubsectionId: 1,
            Sections: [{
                id: 1,
                key: "incomeTaxDeduction",
                nameGR: 'Lohnsteuerabzug',
                nameEn: 'Income tax deduction',
                statusEn: 'In Progress',
                statusGR: '',
                fields: [
                    {
                        id: 1,
                        key: 'taxIdentificationNb',
                        nameGR: 'Steueridentifikationsnummer',
                        nameEn: 'Tax identification number',
                        value: '',
                        type: 'textfield',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: null,
                    },
                    {
                        id: 2,
                        key: 'factor',
                        nameGR: 'Faktor',
                        nameEn: 'Factor',
                        value: '',
                        type: 'textfield',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 3,
                        key: 'childAllowance',
                        nameGR: 'Kinderfreibetrag',
                        nameEn: 'Child allowance',
                        value: '',
                        type: 'textfield-select',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 4,
                        key: 'taxBracket',
                        nameGR: 'Steuerklasse',
                        nameEn: 'Tax bracket',
                        value: '',
                        type: 'textfield-select',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 5,
                        key: 'monthlyAllowanceNb',
                        nameGR: 'Freibeträge (monatlich)',
                        nameEn: 'Allowances (monthly)',
                        value: '',
                        type: 'date-textfield',
                        xs: 6,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 6,
                        key: 'annualyAllowanceNb',
                        nameGR: 'Freibeträge (jährlich)',
                        nameEn: 'Allowances (annual)',
                        value: '',
                        type: 'date-textfield',
                        xs: 6,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 7,
                        key: 'denomination',
                        nameGR: 'Konfession',
                        nameEn: 'Denomination',
                        value: '',
                        type: 'textfield-select',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 8,
                        key: 'spouseDenomination',
                        nameGR: 'Konfession Ehepartner falls abweichend und Zusammenveranlagung',
                        nameEn: 'Denomination of spouse if different and joint assessment',
                        value: '',
                        type: 'textfield-select',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 9,
                        key: 'isMainoccupation',
                        nameGR: 'Hauptbeschäftigung',
                        nameEn: 'Main occupation',
                        value: '',
                        type: 'switch',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 1,
                        dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        dependantCheckType: 'Not',
                        dependantSectionId: 1,
                        dependantSubsectionId: 1
                    },
                    {
                        id: 10,
                        key: 'desiredAnnualAllowanceNb',
                        nameGR: 'Gewünschter jährlicher Freibetrag (bei Nebenbeschäftigung)',
                        nameEn: 'Desired annual allowance (for secondary employment)',
                        value: '',
                        type: 'textfield',
                        xs: 12,
                        relatedFieldsIds: [],
                        dependantId: 9,
                        dependantValue: true,
                    },
                ]
            },]
        },
        {
            id: 3,
            value: 'socialInsurance',
            nameGR: 'Sozial-versicherung',
            nameEn: 'Social Insurance',
            Sections: [
                {
                    id: 1,
                    key: "healthAndLogTermInsurance",
                    nameGR: 'Kranken-und Pflegeversicherung',
                    nameEn: 'Health and long-term care insurance',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'healthInsurance',
                            nameGR: 'Krankenversicherung',
                            nameEn: 'Health insurance',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'statutoryhealthInsuranceFund',
                            nameGR: 'Gesetzl. Krankenkasse (bei PKV letzte gesetzliche Krankenkasse)',
                            nameEn: 'Statutory health insurance fund (last statutory health insurance fund for private health insurance)',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: ['Andere'],
                            dependantCheckType: 'Not'
                        },
                        // {
                        //     id: 3,
                        //     key: 'exemptionFromRVObligation',
                        //     nameGR: 'Befreiung von der RV-Pflicht',
                        //     nameEn: 'Exemption from the RV obligation',
                        //     value: '',
                        //     type: 'textfield',
                        //     xs: 12,
                        //     relatedFieldsIds: [],
                        //     dependantId: 1,
                        //     dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        //     dependantCheckType: 'Or',
                        //     dependantSectionId: 1,
                        //     dependantSubsectionId: 1
                        // },
                        // {
                        //     id: 4,
                        //     key: 'applicationForExemption',
                        //     nameGR: 'Antrag Befreiung von der Rentenversicherungspflicht bei geringfügig Beschäftigten',
                        //     nameEn: 'Application for exemption from compulsory pension insurance for marginally employed persons',
                        //     value: '',
                        //     type: 'file',
                        //     xs: 12,
                        //     relatedFieldsIds: [],
                        //     dependantId: 1,
                        //     dependantValue: ['Geringfügige Beschäftigung (bis 556 € pro Monat / 6.672 € pro Jahr)', 'Kurzfristige Beschäftigung'],
                        //     dependantCheckType: 'Or',
                        //     dependantSectionId: 1,
                        //     dependantSubsectionId: 1
                        // },    
                        {
                            id: 5,
                            key: 'healthInsuranceCoverageExplanation',
                            nameGR: 'Bitte erläutern Sie, wie Sie krankenversichert sind',
                            nameEn: 'Please explain how you are covered by health insurance',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: 'Andere',
                        },
                        {
                            id: 6,
                            key: 'healthInsuranceProofFile',
                            nameGR: 'Nachweis der Krankenversicherung',
                            nameEn: 'Proof of health insurance',
                            value: '',
                            type: 'file',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: 'Andere',
                        },
                    ]
                },
                {
                    id: 2,
                    key: "healthAndLogTermInsurance",
                    nameGR: 'Kinder',
                    nameEn: 'Children',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'parentalProperty',
                            nameGR: 'Elterneigenschaft',
                            nameEn: 'Parental property',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [2,3],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'proofOfParentalStatus',
                            nameGR: 'Nachweis Elterneigenschaft',
                            nameEn: 'Proof of parental status',
                            value: '',
                            type: 'date-textfield',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue:'Yes'
                        },
                        {
                            id: 3,
                            key: 'childUnder25',
                            nameGR: 'Kinder, die das 25. Lebensjahr noch nicht vollendet haben',
                            nameEn: 'Children who have not yet reached the age of 25',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                             dependantId: 1,
                            dependantValue:'Yes'
                        },


                    ]
                },]
        },
        {
            id: 4,
            nameGR: 'Weitere Angaben',
            nameEn: 'More Details',
            Sections: [
                {
                    id: 1,
                    key: "otherOccupations",
                    nameGR: 'Weitere Beschäftigungen',
                    nameEn: 'Other Occupations',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'isOtherOccupations',
                            nameGR: 'Weitere Beschäftigungen?',
                            nameEn: 'Other occupations?',
                            value: '',
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [2,3],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'employmentType',
                            nameGR: 'Beschäftigungstyp',
                            nameEn: 'Employment type',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true
                        },
                        {
                            id: 3,
                            key: 'monthlyRemuneration',
                            nameGR: 'Monatliche Entlohnung',
                            nameEn: 'Monthly remuneration',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true
                        },
                        {
                            id: 4,
                            key: 'daysOfEmploymentNb',
                            nameGR: 'Anzahl der Beschäftigungstage',
                            nameEn: 'Number of days of employment',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: 1,
                            dependantValue: true
                        },


                    ]
                },
                {
                    id: 2,
                    key: "internship",
                    nameGR: 'Praktikum',
                    nameEn: 'Internship',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [


                    ]
                },
                {
                    id: 3,
                    key: "previousOccupation",
                    nameGR: 'Vorherige Beschäftigungen',
                    nameEn: 'Previous Occupations',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [


                    ]
                },
                {
                    id: 4,
                    key: "previousOccupation",
                    nameGR: 'Vermögenswirksame Leistungen',
                    nameEn: 'Capital-forming benefits',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [


                    ]
                },
            ]
        },
        {
            id: 5,
            nameGR: 'Beschäftigung',
            nameEn: 'Employment',
            Sections: [
                {
                    id: 1,
                    key: "contractData",
                    nameGR: 'Vertragsdaten',
                    nameEn: 'Contract data',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                    ]
                },
                {
                    id: 2,
                    key: "remuneration",
                    nameGR: 'Entlohnung',
                    nameEn: 'Remuneration',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [


                    ]
                },
                {
                    id: 3,
                    key: "organization[",
                    nameGR: 'Organisation',
                    nameEn: 'Organization',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                    ]
                },
            ]
        },
        {
            id: 6,
            nameGR: 'Abrechnungs-daten',
            nameEn: 'Billing Data',
            Sections: [
                {
                    id: 1,
                    key: "contributionGroupKey",
                    nameGR: 'Beitragsgruppenschlüssel',
                    nameEn: 'Contribution group key',
                    statusEn: 'In Progress',
                    statusGR: '',
                    fields: [
                        {
                            id: 1,
                            key: 'groupOfPeople',
                            nameGR: 'Personengruppe',
                            nameEn: 'Group of people',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 2,
                            key: 'employeeType',
                            nameGR: 'Arbeitnehmertyp',
                            nameEn: 'Employee type',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 3,
                            key: 'kvHealthInsurance',
                            nameGR: 'KV Health Insurance',
                            nameEn: 'KV Health Insurance',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 4,
                            key: 'rvHealthInsurance',
                            nameGR: 'RV Health Insurance',
                            nameEn: 'RV Health Insurance',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 5,
                            key: 'avHealthInsurance',
                            nameGR: 'AV Health Insurance',
                            nameEn: 'AV Health Insurance',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 6,
                            key: 'pvHealthInsurance',
                            nameGR: 'PV Health Insurance',
                            nameEn: 'PV Health Insurance',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 7,
                            key: 'isParentalStatusProven',
                            nameGR: 'Elterneigenschaft nachgewiesen',
                            nameEn: 'Parental status proven',
                            value: '',
                            type: 'switch',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                        {
                            id: 8,
                            key: 'insuranceStatus',
                            nameGR: 'Versicherungsstatus',
                            nameEn: 'Insurance status',
                            value: '',
                            type: 'textfield-select',
                            xs: 12,
                            relatedFieldsIds: [],
                            dependantId: null,
                        },
                    ]
                },
            ]
        },
    ],
};


export const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        setOnboardingForm: (state, action: PayloadAction<any[]>) => {
            state.onboardingForm = action.payload
        },
        // Add these new reducer actions
        updateFieldValue: (state, action: PayloadAction<{
            sectionId: number,
            subsectionId: number,
            fieldId: number,
            value: any
        }>) => {
            const { sectionId, subsectionId, fieldId, value } = action.payload;

            console.log("Updating field with ID:", fieldId, "in subsection:", subsectionId, "of section:", sectionId, "to value:", value);

            // Find the relevant section
            const sectionIndex = state.onboardingForm.findIndex(s => s.id === sectionId);
            if (sectionIndex >= 0) {
                const subsectionIndex = state.onboardingForm[sectionIndex].Sections.findIndex((s: any) => s.id === subsectionId);
                if (subsectionIndex >= 0) {
                    const fieldIndex = state.onboardingForm[sectionIndex].Sections[subsectionIndex].fields.findIndex((f: any) => f.id === fieldId);
                    if (fieldIndex >= 0) {
                        // Update field value immutably using the spread operator
                        state.onboardingForm[sectionIndex].Sections[subsectionIndex].fields[fieldIndex] = {
                            ...state.onboardingForm[sectionIndex].Sections[subsectionIndex].fields[fieldIndex],
                            value: value
                        };
                    }
                }
            }
        },


        updateSwitchValue: (state, action: PayloadAction<{
            sectionId: number,
            subsectionId: number,
            fieldId: number,
            isChecked: boolean
        }>) => {
            const { sectionId, subsectionId, fieldId, isChecked } = action.payload;

            const section = state.onboardingForm.find(s => s.id === sectionId);
            if (section) {
                const subsection = section.Sections.find((s: any) => s.id === subsectionId);
                if (subsection) {
                    subsection.fields = subsection.fields.map((field: any) => {
                        if (field.id === fieldId) {
                            return { ...field, value: isChecked }; // Update the field value immutably
                        }
                        return field;
                    });

                    // Handle related fields if switch is turned off
                    if (!isChecked) {
                        subsection.fields = subsection.fields.map((field: any) => {
                            if (field.relatedFieldsIds && field.relatedFieldsIds.length > 0) {
                                field.relatedFieldsIds.forEach((relatedId: number) => {
                                    const relatedField = subsection.fields.find((f: any) => f.id === relatedId);
                                    if (relatedField && relatedField.type === 'switch') {
                                        relatedField.value = false; // Turn off related switch field
                                    }
                                });
                            }
                            return field;
                        });
                    }
                }
            }
        }

    }
});

export const getOnboardingForm = (state: RootState) => state.forms.onboardingForm;

export const { setOnboardingForm, updateFieldValue, updateSwitchValue } = formsSlice.actions;

export default formsSlice.reducer;