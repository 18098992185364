import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Grid, TextField } from '@mui/material';
import { getDialogLoadingState, getLoadingState, setLoading, setDialogLoading } from '../../app/slices/loadingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { getModifyValue, setModify } from '../../app/slices/modifySlice';
import * as EmployeeServices from "../../services/employee-services.proxy";
import * as TaskServices from '../../services/task-services.proxy';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { getErrorMsg, setErrorMsg, getCallStatus, setCallStatus } from '../../app/slices/apiCallSlice';
import Message from '../../sharedComponents/ui/Message';
import { useIntl } from 'react-intl';
import { selectRoles, selectUserType } from '../../app/slices/userDataSlice';
import HomeIcon from "../../assets/images/icons/homeIcon.png";
import EmployeesTable from '../../components/Dashboard/Employees/EmployeesTable';
import CreateTask from '../../components/Dashboard/ManageTasks/CreateTask';
import * as taskConsts from "../../task-consts";

const getEmployeeList = EmployeeServices.getEmployeeList;
const addTask = TaskServices.addTask;
const getTaskInputData = TaskServices.getTaskInputData;

const Employees = ({selectedColumns, setSelectedColumns}:{selectedColumns: any; setSelectedColumns: any;}) => {

const dispatch = useAppDispatch();
const navigate = useNavigate();
const intl = useIntl();
const modify = useAppSelector(getModifyValue);
const dialogLoading = useAppSelector(getDialogLoadingState);
const loading = useAppSelector(getLoadingState);
const errorMsg = useAppSelector(getErrorMsg);
const userType = useAppSelector(selectUserType);
const [initiallistLoading, setInitialListLoading] = useState(false);

const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
const [taskObject, setTaskObject] = useState({
  title: "",
  dueDate: "",
  startDate: "",
  endDate: "",
  description: "",
  status: 1,
  type: taskConsts.onBoardingTypeId,
  module: taskConsts.HR_ID,
  clientId: selectedColumns?.clientId[0],
  assigneeId: "",
  priority: 3,
  recurringPeriod: 0,
  note: ""
})
const callStatus = useAppSelector(getCallStatus);
const [assigneesList, setAssigneesList] = useState<any>();
const [internalAssigneesList, setInternalAssigneesList] = useState([]);
const [externalAssigneesList, setExternalAssigneesList] = useState([]);
const [clientsList, setClientsList] = useState<any>();
const [typesList, setTypesList] = useState<any>();
const [moduleList, setModuleList] = useState<any>();
const [statusesList, setStatusesList] = useState<any>();
const [recurringPeriodsList, setRecurringPeriodsList] = useState<any>();
const [inputDataLoading, setInputDataLoading] = useState(false);


const [employeesTable, setEmployeesTable] = useState<any>([]);
const [originalEmployeesTable, setOriginalEmployeesTable] = useState<any>([]);
// const [clickedEmails, setClickedEmails] = useState<string[]>([]);
// const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
const [clickedColumns, setClickedColumns]= useState<{
name: string[],  
email: string[],
clientName: string[],
clientId: string[],
}>({
name: [],  
email: [],
clientName: [], 
clientId: [],
})

const [emailsList, setEmailsList] = useState([]);
const [clientNamesList, setClientNamesList]= useState([]);

const [search, setSearch] = useState<string>();
const [searchInput, setSearchInput] = useState<string>();
const [activeTooltip, setActiveTooltip] = useState<any>(null);


const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchInput(value);

      const debounceTimeout = setTimeout(() => {
        setSearch(value);
      }, 500);

      return () => clearTimeout(debounceTimeout);
    },
    [setSearch]
  );

  const getEmployeesListCall = () => {
   
      dispatch(setDialogLoading(true));
      setInitialListLoading(true);

    getEmployeeList().then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);
      } else {
        
         
          setEmployeesTable(x.employees);
          setClientNamesList(x.employees?.map(({ clientId, clientName }: { clientId: string; clientName: string }) => ({ clientId, clientName })).filter((value: any, index: any, self: any) => index === self.findIndex((obj: any) => obj.clientId === value.clientId)));
          setEmailsList(x.employees?.map((u: any) => u.email));
          setOriginalEmployeesTable(x.employees)
      }
      dispatch(setDialogLoading(false));
      setInitialListLoading(false);
    })
  }

  useEffect(()=>{
getEmployeesListCall();
  },[])

  useEffect(() => {

      const filteredEmployees = originalEmployeesTable?.filter((e: any) => {

        const searchCheck = search ?
        (e.email?.toLowerCase().includes(search.toLowerCase())
          || e.clientName?.toLowerCase().includes(search.toLowerCase())
          // || e.name?.toLowerCase().includes(search.toLowerCase())
        ) : true;
        // const nameCheck = selectedColumns.name.length > 0 ? selectedColumns.name.includes(e.name) : true;
        const emailCheck = selectedColumns.email.length > 0 ? selectedColumns.email.includes(e.email) : true;
        const clientIdCheck = selectedColumns.clientId.length > 0 ? selectedColumns.clientId.includes(e.clientId.toLowerCase()) : true;
        const clientNameCheck = selectedColumns.clientName.length > 0 ? selectedColumns.clientName.includes(e.clientName) : true;
       
        return searchCheck && emailCheck && clientIdCheck && clientNameCheck;

      }
      )
      setEmployeesTable(filteredEmployees)

  }, [search, selectedColumns])

  const addTaskCall = (taskObject: {}, buttonNb: any) => {
    dispatch(setLoading(true));
    addTask(taskObject).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        dispatch(setCallStatus("Fail"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
        }, 4500);

      }
      else {
        dispatch(setCallStatus("Pass"));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
          dispatch(setCallStatus(""));
          setOpenAddTaskDialog(false);
          if (buttonNb === 1) {
            setOpenAddTaskDialog(false);
            dispatch(setModify(!modify));
          }
          if (buttonNb === 2) navigate(`/task-details?id=${x}`)

        }, 1000);
      }
      dispatch(setLoading(false));
    })
  }


  const getTaskInputDataCall = (module: any, clientId: any) => {
    setInputDataLoading(true);
    getTaskInputData(module, clientId).then((x) => {
      if (x.ErrorMessage) {
        dispatch(setErrorMsg(x.ErrorMessage));
        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 4500);

      }
      else {
        // setAssigneesList(x.users);
        setInternalAssigneesList(x.internalUsers);
        if (userType === 2) setExternalAssigneesList(x.externalUsers);
        else {
          const selectedClientlientTMs = x.clients?.find((i: any) => i.id === clientId)?.users;
          setExternalAssigneesList(selectedClientlientTMs);
        }
        setClientsList(x.clients);
        setTaskObject({ ...taskObject, clientId: clientId });
        setStatusesList(x.statuses);
        setTypesList(x.types);
        setModuleList(x.modules);
        setRecurringPeriodsList(x.recurringPeriods);

        setTimeout(() => {
          dispatch(setErrorMsg(""));
        }, 1000);
      }
      setInputDataLoading(false);
    })
  }

  
    useEffect(() => {
      if (openAddTaskDialog) getTaskInputDataCall(taskObject.module,taskObject.clientId);
  
  
    }, [openAddTaskDialog, taskObject.module, taskObject.clientId])

  useEffect(()=>{
    console.log('taskObject in employees: ', taskObject);
  },[taskObject])

    return (
        <Grid container rowGap={0.5} width='100%' maxHeight='90vh'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={dialogLoading || initiallistLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item className='desktop-header-5' xs={12}>
  
          <Message id="text.employee-profile" className="Field-input desktop-header-5 font-weight-400" />
        </Grid>
        <Grid item container direction='row' className='neutral-3 desktop-paragraph' alignItems='center' columnGap={0.5} xs={12}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
               navigate('/tasks')
            }
            }
          >
            <img src={HomeIcon} width='20px' height='20px' style={{ marginTop: '5px' }} />
  
          </span><Message id="user.profile.home" className="Field-input desktop-paragraph font-weight-400 pointerText"
            onClick={() => {
               navigate('/tasks')
            }
            } /> /
          <Message id="text.personal" className="Field-input desktop-paragraph font-weight-400" /> /
          <Message id= "text.employee" className="Field-input desktop-paragraph font-weight-400" />

          <Grid item container marginTop={2} alignItems='center'>
          <Grid item className='activeTabs'>
            <Message id="text.employee" className="labelsLinks labels-extra-bold Field-input " />
          </Grid>
        </Grid>
  
          {/* <Grid item container marginTop={2} alignItems='center'>
            <Grid item className={activeTab === 'Team' ? 'activeTabs' : 'tabs'}
              onClick={() => handleTabChange('Team')}>
              <Message id="text.users" className="labelsLinks labels-extra-bold Field-input " />
  
            </Grid>
            {localStorage.getItem('userType') === '1' ?
              <Grid item className={activeTab === 'Clients' ? 'activeTabs' : 'tabs'}
                onClick={() => handleTabChange('Clients')}>
                <Message id="text.companies" className="labelsLinks labels-extra-bold Field-input " />
  
              </Grid>
              :
              <></>
            }
  
            {errorMsg ?
              <Grid item className='errorText' marginLeft={2}>{errorMsg}</Grid> :
              <></>
            }
          </Grid> */}
          <Grid item container bgcolor='#FEFEFF' borderRadius='0px 8px 8px 8px' boxShadow='0px 2px 4px 0px #0000001F'>
            <Grid item container direction='row' columnGap={2} padding={1}>
              <Grid item xs={5}>
                <TextField
                  value={searchInput}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon htmlColor='#C3C2C7' /></InputAdornment>,
                    style: {
                      borderRadius: '35px',
                      fontFamily: 'DM Sans',
                      height: '48px',
                      backgroundColor: '#F7F9FF',
                      border: '1px solid #E6E6E8'
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-input::placeholder': {
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder={intl.formatMessage({ id: 'text.search' })}
                  // onChange={(e) => {
                  //   setOldRows([]);
                  //   setSearch(e.target.value)}
                  // }
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item style={{ border: '1px solid #E6E6E8', borderRadius: '8px', display: 'flex', alignItems: 'center', padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setSearch('');
                  dispatch(setModify(!modify))
                  setSearchInput('');
                  // setSelectedEmails([]);
                  // setClickedEmails([]);
                  setSelectedColumns({
                    name: [],
                    email: [],
                    clientName: [],
                    clientId: [],
                  });
                  setClickedColumns({
                    name: [],
                    email: [],
                    clientName: [],
                    clientId: [],
                  })
                  setActiveTooltip(null);
                  setEmployeesTable(originalEmployeesTable)
                }
                }><RestartAltIcon htmlColor='#C3C2C7' />
              </Grid>
              <Grid item width='220px'>
                <button className='blueButton'
                disabled={selectedColumns.clientId.length === 0 || selectedColumns.clientId.length > 1}
                  style={{ height: '48px', width: '220px'}}
                  onClick={() => {
                    setTaskObject({
                      title: "",
                      dueDate: "",
                      startDate: "",
                      endDate: "",
                      description: "",
                      status: 1,
                      type: taskConsts.onBoardingTypeId,
                      module: taskConsts.HR_ID,
                      clientId: selectedColumns.clientId[0]?.toLowerCase(),
                      assigneeId: "",
                      priority: 3,
                      recurringPeriod: 0,
                      note: ""
                    })
                    setOpenAddTaskDialog(true);
                  }
                  }
                 >
                  <Grid item container alignItems='center' columnGap={1} justifyContent='center'>
                    <GroupAddOutlinedIcon />
                      <Message id="text.add-employees" className="labels-extra-bold Field-input" />
                  </Grid>
  
                </button>
              </Grid>
            </Grid>
          
              <EmployeesTable
                rows={employeesTable}
                setRows={setEmployeesTable}
                modify={modify}
                setModify={setModify}
                initiallistLoading={initiallistLoading}
                dialogLoading={dialogLoading}
                clickedColumns={clickedColumns}
                setClickedColumns={setClickedColumns}
                selectedColumns={selectedColumns}
                setSelectedColumns={setSelectedColumns}
                emailsList={emailsList}
                clientNamesList={clientNamesList}
                setClientNamesList={setClientNamesList}
                originalRows={originalEmployeesTable}
                setOriginalRows={setOriginalEmployeesTable}
                activeTooltip={activeTooltip}
                setActiveTooltip={setActiveTooltip}
              />
           
          </Grid>
  
        </Grid>
        <CreateTask
          loading={loading}
          open={openAddTaskDialog}
          setOpen={setOpenAddTaskDialog}
          callStatus={callStatus}
          errorMsg={errorMsg}
          confirmAction={addTaskCall}
          assigneesList={assigneesList}
          clientsList={clientsList}
          statusesList={statusesList}
          typesList={typesList}
          moduleList={moduleList}
          recurringPeriodsList={recurringPeriodsList}
          internalAssigneesList={internalAssigneesList}
          setInternalAssigneesList={setInternalAssigneesList}
          externalAssigneesList={externalAssigneesList}
          setExternalAssigneesList={setExternalAssigneesList}
          taskObject={taskObject}
          setTaskObject={setTaskObject}
          inputDataLoading={inputDataLoading}
        />
      </Grid>
    )
  }

export default Employees
